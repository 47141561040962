<div class="rew-container">
    <div class="rew-item">
        <img src="assets/img/Reward_Points.svg" />
        <div class="rew-item-text">
            <div class="rew-item-label rew-item-label-earn">
                {{ "You will earn" | translate }}
            </div>
            <div class="rew-item-value rew-item-value-points">
                +{{ rewardPoints | number : "1.0-0" }}{{ "pts" | translate }}
            </div>
        </div>
    </div>
    <div class="rew-item">
        <!-- <img [src]="rewardBadge.iconUrl" />
        <div class="rew-item-text">
            <div class="rew-item-label rew-item-label-badge">
                {{ rewardBadge.label }}
            </div>
            <div class="rew-item-value rew-item-value-badge">
                {{ rewardBadge.group }}
            </div>
        </div> -->
    </div>
</div>

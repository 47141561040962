import { Component, OnInit } from '@angular/core';
import {
    GiftCardHistory,
    GiftCardTransactionHistory,
    GiftsService,
} from './gifts.service';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { RewardsAndWalletService } from '../rewards-and-wallet.service';
import { encodeForUrl } from 'src/app/utilities/encoders';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-gift-voucher',
    templateUrl: './gift-voucher.component.html',
    styleUrls: ['../rewards-and-wallet.component.scss'],
})
export class GiftVoucherComponent implements OnInit {
    isShowDummyGiftCard: boolean = false;

    public userLogin: boolean = false;
    giftCode = new UntypedFormControl('', []);
    giftCardHistory: GiftCardHistory[] = [];
    giftCardTransactionHistory: GiftCardTransactionHistory[] = [];

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    showGiftBalanceView: boolean = false;

    constructor(
        private giftsService: GiftsService,
        private router: Router,
        private translate: TranslateService,
        private currencyLanguageService: CurrencyLanguageService,
        private eventService: EventService,
        private rewardsAndWalletService: RewardsAndWalletService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.userLogin = isLoggedIn;
            if (isLoggedIn) {
                this.getListItem();
            }
        });
    }

    addGiftCardPressed() {
        if (!this.giftCode) {
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                this.translate.instant('Invalid Gift Card Code')
            );
            return;
        } else {
            this.giftsService
                .addGiftCardToAccount(this.giftCode.value)
                .subscribe(() => {
                    // TODO: The response here contains the newly added gift card with everything. Should just use that
                    this.getListItem();
                });
        }
    }

    viewGiftCardBalancePressed() {
        this.showGiftBalanceView = !this.showGiftBalanceView;
    }

    getGiftStatusColor(giftItem: GiftCardHistory) {
        let color = '#90C752';
        const { status } = giftItem;
        if (status == 'Used' || status == 'Pending') {
            color = '#FEBA02';
        } else if (status == 'Disabled' || status == 'Expired') {
            color = '#EF3434';
        }
        return color;
    }

    getGiftCodeDate(date: string) {
        const orderDate = dayjs(date);
        return orderDate.format('DD MMM YYYY');
    }

    getListItem() {
        this.isShowDummyGiftCard = true;
        this.giftsService
            .getGiftCardBalanceAndHistory()
            .subscribe((response) => {
                this.isShowDummyGiftCard = false;
                this.giftCardHistory = response.giftCardHistory;
                this.giftCardTransactionHistory = response.transactionHistory;
                this.rewardsAndWalletService.updateGiftCardBalance(
                    response.giftCardBalance
                );
            });
    }

    protected readonly ProfileSection = ProfileSection;
}

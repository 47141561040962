import { Injectable } from '@angular/core';
import { AuthHttpClientService } from 'src/app/services/auth-http-client.service';
import { getMyAccountUrl } from 'src/app/utilities/url.utils';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(private authhttpClient: AuthHttpClientService) {}

    changePassword(
        currentPassword: string,
        newPassword: string,
        confirmPassword: string
    ) {
        return this.authhttpClient.post(
            `${getMyAccountUrl()}security/password-reset`,
            { currentPassword, newPassword, confirmPassword }
        );
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'md-logo',
    template: `
        <svg
            [attr.width]="width"
            [attr.height]="height"
            viewBox="0 0 104 19"
            [attr.fill]="internalColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.6196 4.94791C44.5114 4.94791 43.4694 5.22781 42.5483 5.7244C42.4649 5.76954 42.3641 5.70935 42.3641 5.61003V5.601C42.3641 5.38431 42.1972 5.20975 41.9899 5.20975H39.952C39.7448 5.20975 39.5778 5.38431 39.5778 5.601V18.6088C39.5778 18.8255 39.7448 19 39.952 19H41.9899C42.1972 19 42.3641 18.8255 42.3641 18.6088V18.1212V14.2809V10.4285C42.3641 9.82958 42.6145 9.25774 43.0463 8.86649C43.7342 8.24349 44.6352 7.86427 45.6167 7.86427C45.8671 7.86427 46.1118 7.88835 46.3478 7.93349C46.6356 7.99068 46.8976 7.75291 46.8976 7.44593V5.49867C46.8976 5.25188 46.7249 5.04121 46.4917 5.0081C46.2039 4.96897 45.9132 4.94791 45.6138 4.94791H45.6196Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M101.118 0.14447C100.911 0.14447 100.744 0.319031 100.744 0.535726V18.6087C100.744 18.8254 100.911 19 101.118 19H103.156C103.363 19 103.53 18.8254 103.53 18.6087V0.535726C103.53 0.319031 103.363 0.14447 103.156 0.14447H101.118Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M34.6126 5.60099V6.02535C34.6126 6.13069 34.5004 6.19389 34.4198 6.13671C33.2483 5.31507 31.8177 4.86964 30.2835 4.96294C26.8554 5.17061 24.1007 8.10804 23.9683 11.6986C23.8187 15.7044 26.8813 19.003 30.6807 19.003C32.0652 19.003 33.3519 18.5666 34.4198 17.8142C34.5004 17.757 34.6126 17.8202 34.6126 17.9225V18.6118C34.6126 18.8285 34.7796 19.003 34.9868 19.003H37.0247C37.232 19.003 37.3989 18.8285 37.3989 18.6118V5.60099C37.3989 5.38429 37.232 5.20973 37.0247 5.20973H34.9868C34.7796 5.20973 34.6126 5.38429 34.6126 5.60099ZM30.9369 16.0776C28.5622 16.2341 26.6078 14.1876 26.7575 11.7076C26.8813 9.66102 28.4701 8.0027 30.4246 7.87328C32.7964 7.71678 34.7537 9.76034 34.604 12.2433C34.4802 14.2899 32.8913 15.9512 30.934 16.0806L30.9369 16.0776Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M95.7784 5.60099V6.02535C95.7784 6.13069 95.6661 6.19389 95.5855 6.13671C94.414 5.31507 92.9835 4.86964 91.4493 4.96294C88.0211 5.17061 85.2665 8.10804 85.1341 11.6986C84.9844 15.7044 88.047 19.003 91.8465 19.003C93.231 19.003 94.5176 18.5666 95.5855 17.8142C95.6661 17.757 95.7784 17.8202 95.7784 17.9225V18.6118C95.7784 18.8285 95.9453 19.003 96.1526 19.003H98.1905C98.3977 19.003 98.5647 18.8285 98.5647 18.6118V5.60099C98.5647 5.38429 98.3977 5.20973 98.1905 5.20973H96.1526C95.9453 5.20973 95.7784 5.38429 95.7784 5.60099ZM92.1027 16.0776C89.728 16.2341 87.7736 14.1876 87.9233 11.7076C88.047 9.66102 89.6359 8.0027 91.5903 7.87328C93.9621 7.71678 95.9194 9.76034 95.7697 12.2433C95.646 14.2899 94.0571 15.9512 92.0998 16.0806L92.1027 16.0776Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M82.6184 13.08C82.8084 13.08 82.9695 12.9295 82.9897 12.7339C83.0156 12.4841 83.03 12.2313 83.03 11.9755C83.03 11.3524 82.9523 10.7445 82.8055 10.1697C82.0456 7.16601 79.4262 4.9509 76.3118 4.9509C73.1974 4.9509 70.5781 7.16601 69.8182 10.1697C69.6714 10.7475 69.5937 11.3524 69.5937 11.9755C69.5937 12.3517 69.6225 12.7218 69.6771 13.083C69.7693 13.691 69.9362 14.2748 70.1665 14.8196C71.2085 17.2815 73.5687 19 76.3118 19C78.8333 19 81.0295 17.5493 82.178 15.4004C82.3162 15.1416 82.1348 14.8196 81.8498 14.8196H79.3054C79.2075 14.8196 79.1125 14.8617 79.0405 14.9339C78.3325 15.6472 77.3711 16.0866 76.3089 16.0866C75.1921 16.0866 74.1847 15.5991 73.468 14.8196C73.065 14.3802 72.757 13.8475 72.5699 13.2576C72.544 13.1733 72.6016 13.086 72.6879 13.086H80.094H82.6126L82.6184 13.08ZM72.9844 10.1666C72.8894 10.1666 72.829 10.0583 72.875 9.97101C73.5457 8.71298 74.8323 7.86124 76.3089 7.86124C77.7856 7.86124 79.0722 8.71298 79.7457 9.97101C79.7918 10.0583 79.7342 10.1666 79.6364 10.1666H72.9815H72.9844Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M65.0429 0.14447C64.8357 0.14447 64.6687 0.319031 64.6687 0.535726V6.02535C64.6687 6.13069 64.5565 6.19389 64.4759 6.13671C63.3073 5.31808 61.8767 4.86964 60.3454 4.96294C56.9172 5.1676 54.1598 8.10804 54.0245 11.6956C53.8748 15.7044 56.9374 19 60.7369 19C62.1214 19 63.4051 18.5636 64.4759 17.8142C64.5565 17.757 64.6687 17.8202 64.6687 17.9225V18.6087C64.6687 18.8254 64.8357 19 65.0429 19H67.0808C67.2881 19 67.455 18.8254 67.455 18.6087V0.535726C67.455 0.319031 67.2881 0.14447 67.0808 0.14447H65.0429ZM60.7397 16.0836C58.4745 16.0836 56.6553 14.0792 56.8194 11.6745C56.9547 9.655 58.5176 8.01775 60.449 7.87027C62.7086 7.69872 64.5968 9.52258 64.6716 11.828C64.6716 11.8761 64.6716 11.9243 64.6716 11.9694C64.6716 12.0146 64.6716 12.0627 64.6716 12.1109C64.5997 14.317 62.8698 16.0806 60.7426 16.0806L60.7397 16.0836Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M18.9974 5.88692C18.0101 5.29101 16.8645 4.94791 15.6412 4.94791C14.4178 4.94791 13.2722 5.288 12.285 5.88692C11.81 6.17585 11.3725 6.52196 10.9782 6.91623C10.9292 6.96438 10.8544 6.96438 10.8055 6.91623C10.4111 6.51895 9.97361 6.17284 9.49868 5.88692C8.51139 5.29101 7.3658 4.94791 6.14248 4.94791C4.99688 4.94791 3.91749 5.24887 2.9705 5.77857C2.88702 5.82673 2.78628 5.76352 2.78628 5.66421V5.59799C2.78628 5.3813 2.61933 5.20674 2.41209 5.20674H0.37419C0.166947 5.20674 0 5.3813 0 5.59799V18.6058C0 18.8225 0.166947 18.997 0.37419 18.997H2.41497C2.62221 18.997 2.78916 18.8225 2.78916 18.6058V10.7626C2.78916 9.96501 3.13456 9.20959 3.73615 8.72202C4.40106 8.18329 5.23579 7.86126 6.14536 7.86126C7.45503 7.86126 8.61502 8.53241 9.33173 9.56172C9.44399 9.72424 9.50156 9.91686 9.50156 10.1185V14.1153V14.8226V18.6088C9.50156 18.8255 9.66851 19 9.87575 19H11.9136C12.1209 19 12.2878 18.8255 12.2878 18.6088V10.1185C12.2878 9.91987 12.3483 9.72424 12.4577 9.56172C13.1715 8.53241 14.3315 7.86126 15.644 7.86126C16.4183 7.86126 17.1408 8.09601 17.751 8.49931C18.5397 9.02299 19.0002 9.94997 19.0002 10.9281V14.1183V18.061V18.6088C19.0002 18.8255 19.1672 19 19.3744 19H21.4152C21.6225 19 21.7894 18.8255 21.7894 18.6088V10.9973C21.7894 8.91464 20.7561 6.96438 19.0377 5.911C19.0261 5.90498 19.0146 5.89595 19.0002 5.88993L18.9974 5.88692Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M49.4277 4.94791C49.2204 4.94791 49.0535 5.12247 49.0535 5.33916V18.6058C49.0535 18.8225 49.2204 18.997 49.4277 18.997H51.4685C51.6757 18.997 51.8426 18.8225 51.8426 18.6058V5.33916C51.8426 5.12247 51.6757 4.94791 51.4685 4.94791H49.4277Z"
                [attr.fill]="internalColor"
            />
            <path
                d="M50.4495 3.55442C51.354 3.55442 52.0873 2.78771 52.0873 1.84192C52.0873 0.896137 51.354 0.129425 50.4495 0.129425C49.545 0.129425 48.8117 0.896137 48.8117 1.84192C48.8117 2.78771 49.545 3.55442 50.4495 3.55442Z"
                [attr.fill]="internalColor"
            />
        </svg>
    `,
    styles: [
        `
            /* Your SCSS styles go here */
        `,
    ],
})
export class MdLogoComponent {
    @Input() set color(value: 'white' | 'blue') {
        this.internalColor = value === 'white' ? '#ffffff' : '#092e41';
    }
    @Input() width = 104;
    @Input() height = 19;


    internalColor: '#ffffff' | '#092e41' = '#ffffff';
}

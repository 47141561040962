import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    DealDto,
    DealOfferOption,
} from 'src/app/model/deal.model';
import { BookingConfiguredData } from '../booking-config.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'md-spa-booking',
    templateUrl: './spa-booking.component.html',
    styleUrls: ['./spa-booking.component.scss'],
})
export class SpaBookingComponent implements OnInit {
    @Input() productDetails: DealDto;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();

    set selectedServiceOption(productServiceOption: DealOfferOption) {
        this.bookingConfigured.emit({
            totalPrice: productServiceOption.sellingPrice,
            totalFullPrice: productServiceOption.crossedPrice,
            productBookingData: {
                id: this.productDetails.dealId,
                selectedDealOptions: [productServiceOption],
            },
        });
    }

    constructor(private currencyLanguageService: CurrencyLanguageService) {}
    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }
}

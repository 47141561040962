import {
    Component,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { RegisterService } from './register.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../utilities/event.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DeviceCommsService } from '../services/device-comms.service';
import { CreateUserDto } from './model/register.model';
import { Country } from '@capgo/ngx-intl-tel-input/lib/model/country.model';

import { transformNgbDateToYmd } from '../controls/calendar/calendar.utils';
import { ModalOffcanvasComponent } from '../controls/modal-offcanvas/modal-offcanvas.component';
import { noWhitespaceValidator } from '../utilities/utils';
import { CurrencyLanguageService } from '../shared/currency-language.service';

export interface MDCountry {
    name: string;
    code: string;
}

@Component({
    selector: 'md-app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss', '../../common.scss'],
})
export class RegisterComponent implements OnInit {
    private readonly MAURITIUS = { name: 'Mauritius', code: 'mu' } as MDCountry;
    private readonly REUNION = { name: 'Reunion', code: 're' } as MDCountry;
    private readonly OTHER_COUNTRY = {
        name: 'Others',
        code: 'ot',
    } as MDCountry;

    registerForm!: UntypedFormGroup;
    showHidePass = false;
    isCoreValueModalOpen = false;
    shouldDesiplayOtherCountriesSelect = false;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.Mauritius,
        CountryISO.Réunion,
    ];
    otpMobileNumber: string;
    selectedCountryCode = CountryISO.Mauritius;
    countries = [this.MAURITIUS, this.REUNION, this.OTHER_COUNTRY];
    currCountry: string;
    passwordInputType = 'password';
    passwordIconClass = 'fa-eye';

    firstNameClass = '';
    firstNameErrorClass = '';
    lastNameClass = '';
    lastNameErrorClass = '';
    emailClass = '';
    emailErrorClass = '';
    mobileClass = '';
    mobileErrorClass = '';
    passwordClass = '';
    dobClass = '';
    passwordErrorClass = '';
    passwordErrors = {
        minLength: false,
        hasSymbolOrNumber: false,
        noNameOrEmail: false,
    };
    passwordStrength = false;

    @ViewChild('verifyOtpAccount') verifyOtpAccountModal: TemplateRef<never>;
    @ViewChild('ourCoreValues') ourCoreValuesModal: ModalOffcanvasComponent;

    @ViewChild('email') emailInput: ElementRef;
    @ViewChild('firstName') firstNameInput: ElementRef;
    @ViewChild('lastName') lastNameInput: ElementRef;
    @ViewChild('password') passwordInput: ElementRef;
    @ViewChild('mobile') phoneInput: ElementRef;
    selectedDate: NgbDate;
    formattedDate = '-';
    startDate: NgbDateStruct;

    constructor(
        private eventService: EventService,
        private registerService: RegisterService,
        private deviceCommsService: DeviceCommsService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    ngOnInit(): void {
        this.registerForm = new UntypedFormGroup({
            firstName: new UntypedFormControl('', [
                Validators.required,
                noWhitespaceValidator,
            ]),
            lastName: new UntypedFormControl('', [
                Validators.required,
                noWhitespaceValidator,
            ]),
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.email,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ]),
            country: new UntypedFormControl('', [Validators.required]),
            others: new UntypedFormControl('', []),
            dialCode: new UntypedFormControl('', []),
            mobile: new UntypedFormControl('', []),
            countryCode: new UntypedFormControl('', []),
            dob: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(/^\d{4}-\d{2}-\d{2}$/),
            ]),
            otp: new UntypedFormControl('', []),
            customerId: new UntypedFormControl('', []),
            password: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
            receive_marketing_messages: new UntypedFormControl('', []),
        });
        this.registerForm.controls['country'].setValue('mu');

        this.currencyLanguageService.getLocaleData().subscribe((data) => {
            const country = data.country.toLocaleLowerCase();
            this.registerForm.controls['country'].setValue(country);
            this.shouldDesiplayOtherCountriesSelect = country === 'ot';
            this.currCountry = data.countryName;
            this.selectedCountryCode =
                data.country === 'MU'
                    ? CountryISO.Mauritius
                    : CountryISO.Réunion;
        });
    }

    onFirstNameChange(): void {
        this.registerForm.controls['firstName'].setValue(
            this.registerForm.controls['firstName'].value.replace(
                /[^A-Za-z -]/g,
                ''
            )
        );
        this.firstNameClass = this.registerForm.controls['firstName'].value
            ? 'has-input'
            : '';
        if (this.firstNameErrorClass) {
            this.firstNameErrorClass = '';
        }
    }

    onLastNameChange(): void {
        this.registerForm.controls['lastName'].setValue(
            this.registerForm.controls['lastName'].value.replace(
                /[^A-Za-z -]/g,
                ''
            )
        );
        this.lastNameClass = this.registerForm.controls['lastName'].value
            ? 'has-input'
            : '';
        if (this.lastNameErrorClass) {
            this.lastNameErrorClass = '';
        }
    }

    onDobSelected(date: NgbDate): void {
        this.selectedDate = date;
        this.dateSelected(date);
    }

    onEmailChange(): void {
        this.emailClass = this.registerForm.controls['email'].value
            ? 'has-input'
            : '';
        if (this.emailErrorClass) {
            this.emailErrorClass = '';
        }
    }

    onMobileChange(): void {
        this.mobileClass = this.registerForm.controls['mobile'].value
            ? 'has-input'
            : '';
        if (this.mobileErrorClass) {
            this.mobileErrorClass = '';
        }
    }

    onPasswordChange(): void {
        this.passwordClass = this.registerForm.controls['password'].value
            ? 'has-input'
            : '';
        if (this.passwordErrorClass) {
            this.passwordErrorClass = '';
        }
    }

    validateFirstName(shouldFocus = false): boolean {
        if (this.registerForm.controls['firstName'].invalid) {
            if (shouldFocus) {
                this.firstNameInput.nativeElement.focus();
            }
            this.firstNameErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validateLastName(shouldFocus = false): boolean {
        if (this.registerForm.controls['lastName'].invalid) {
            if (shouldFocus) {
                this.lastNameInput.nativeElement.focus();
            }
            this.lastNameErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validateEmail(shouldFocus = false): boolean {
        if (this.registerForm.controls['email'].invalid) {
            if (shouldFocus) {
                this.emailInput.nativeElement.focus();
            }
            this.emailErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validateMobile(shouldFocus = false): boolean {
        if (this.registerForm.controls['mobile'].invalid) {
            if (shouldFocus) {
                this.phoneInput.nativeElement.focus();
            }
            this.mobileErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validatePassword(shouldFocus = false): boolean {
        if (this.registerForm.controls['password'].invalid) {
            if (shouldFocus) {
                this.passwordInput.nativeElement.focus();
            }
            this.passwordErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    submitRegistrationForm() {
        if (this.registerForm.invalid) {
            let shouldFocus = true;
            shouldFocus = this.validateFirstName(shouldFocus);
            shouldFocus = this.validateLastName(shouldFocus);
            shouldFocus = this.validateEmail(shouldFocus);
            this.validatePassword(shouldFocus);

            if (this.registerForm.controls['country'].status === 'INVALID') {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant('Please select country')
                );
                return;
            }
        } else {
            // this.registerForm.controls['dob'].setValue('1993-01-01');
            const payload = this.registerForm.getRawValue() as CreateUserDto;
            payload.country = payload.country.toUpperCase();
            this.closeCoreValuesModal();
            this.isCoreValueModalOpen = false;

            this.registerService.createUser(payload).subscribe(() => {
                // TODO: This should be the userId most likely
                this.deviceCommsService.successfullyLogin('userId');
            });
        }
    }

    openCoreValuesModal() {
        this.isCoreValueModalOpen = true;
        this.openModal(this.ourCoreValuesModal);
    }

    closeCoreValuesModal() {
        this.closeModel(this.ourCoreValuesModal);
    }

    decline() {
        this.isCoreValueModalOpen = false;
        this.closeCoreValuesModal();
        // this.eventService.emitEvent(EventType.REGISTER);
    }

    onPhoneCountryChange(event: Country) {
        this.registerForm.controls['countryCode'].setValue(event.iso2);
        this.registerForm.controls['dialCode'].setValue(event.dialCode);
    }

    onChangeCountry(value) {
        if (value == 1) {
            if (this.registerForm.controls['country'].value === 'mu') {
                this.shouldDesiplayOtherCountriesSelect = false;
                this.selectedCountryCode = CountryISO.Mauritius;
                this.registerForm.controls['others'].setValue('');
            } else if (this.registerForm.controls['country'].value == 're') {
                this.shouldDesiplayOtherCountriesSelect = false;
                this.selectedCountryCode = CountryISO.Réunion;
                this.registerForm.controls['others'].setValue('');
            } else {
                this.shouldDesiplayOtherCountriesSelect = true;
                this.selectedCountryCode = CountryISO.Afghanistan;
                this.registerForm.controls['others'].setValue('Afghanistan');
            }
        } else {
            this.selectedCountryCode = this.getCountryISO(
                this.registerForm.controls['country'].value
            );
        }
    }

    getCountryISO(value: string): CountryISO {
        const keys = Object.keys(CountryISO).filter(
            (x) => CountryISO[x] === value
        );
        return keys.length > 0 ? CountryISO[keys[0]] : null;
    }

    toggleShowPassword() {
        this.showHidePass = !this.showHidePass;
        if (this.showHidePass) {
            this.passwordIconClass = 'fa-eye-slash';
            this.passwordInputType = 'text';
        } else {
            this.passwordIconClass = 'fa-eye';
            this.passwordInputType = 'password';
        }
    }

    navigateToLogin() {
        this.eventService.emitEvent(EventType.LOGIN);
    }

    protected readonly CountryISO = CountryISO;

    dateSelected(date: NgbDateStruct): void {
        this.registerForm.controls['dob'].setValue(transformNgbDateToYmd(date));
    }

    validatePasswordStrength() {
        const password = this.registerForm.value.password;
        const email = this.registerForm.value.email; // Replace with logic to get the email
        const name = this.registerForm.value.firstName; // Replace with logic to get the email

        this.passwordErrors.minLength = password.length < 8;
        this.passwordErrors.hasSymbolOrNumber =
            !/[!@#$%^&*(),.?":{}|<>0-9]/.test(password);
        this.passwordErrors.noNameOrEmail =
            password.includes(email) || password.includes(name);

        if (
            !this.passwordErrors.minLength &&
            !this.passwordErrors.hasSymbolOrNumber &&
            !this.passwordErrors.noNameOrEmail
        ) {
            this.passwordStrength = true;
        } else {
            this.passwordStrength = false;
        }
    }

    openModal(content: ModalOffcanvasComponent) {
        content.open();
    }

    closeModel(content: ModalOffcanvasComponent) {
        content.close();
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { RatingColor, RatingLabel, ReviewData } from '../reviews.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'md-review-summary',
    templateUrl: './review-summary.component.html',
    styleUrls: ['./review-summary.component.scss'],
})
export class ReviewSummaryComponent implements OnDestroy {
    @Input() large = false;
    @Input() showReviews = true;
    @Input() set reviewData(value: ReviewData) {
        this.averageRating = value.average;
        this.reviewCount = value.total;
        this.trimmedAverageRating = this.getTrimmedAverageRating();
        this.strokeDasharray = `${this.averageRating * 10} 100`;
        const { color, label } = this.getStrokeColorAndRatingLabel(
            this.averageRating
        );
        this.strokeColor = color;
        this.ratingLabel = label;
    }

    ratingLabel: RatingLabel;
    strokeDasharray: string;
    strokeColor: RatingColor;
    trimmedAverageRating: string;
    averageRating: number;
    reviewCount: number;

    @Output() summaryClick = new EventEmitter();

    private reviewDataSubscription: Subscription;

    ngOnDestroy(): void {
        if (this.reviewDataSubscription) {
            this.reviewDataSubscription.unsubscribe();
        }
    }

    onClick(): void {
        this.summaryClick.emit();
    }

    private getTrimmedAverageRating(): string {
        if (!this.averageRating) return '-';
        return this.averageRating.toFixed(1);
    }

    private getStrokeColorAndRatingLabel(rating: number): {
        color: RatingColor;
        label: RatingLabel;
    } {
        if (rating >= 8.0) {
            return { color: '#90c652', label: 'Great' };
        } else if (rating >= 6.0) {
            return { color: '#6fc2eb', label: 'Good' };
        } else if (rating >= 4.0) {
            return { color: '#feba02', label: 'Average' };
        } else if (rating > 0) {
            return { color: '#f68e8e', label: 'Poor' };
        } else {
            return { color: '#f68e8e', label: 'No Reviews' };
        }
    }
}

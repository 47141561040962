import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { EventService, EventType } from '../utilities/event.service';
import { LOGGED_IN_KEY, UserService } from '../services/user.service';
import { map, skip } from 'rxjs';

export const canAccessLoggedInResource: CanActivateFn = () => {
    const userService = inject(UserService);
    const eventService = inject(EventService);
    // this is so the guard is forced into yes before the user token has been decoded.
    // this is suboptimal because in implies that the localstorage item is always in sync with the user service
    if (localStorage.getItem(LOGGED_IN_KEY) === 'true') {
        return true;
    }

    return userService.isLoggedIn$.pipe(
        map(({ isLoggedIn }) => {
            if (isLoggedIn) {
                return true;
            } else {
                eventService.emitEvent(EventType.LOGIN);
                return false;
            }
        })
    );
};

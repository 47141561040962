import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { map, Observable } from 'rxjs';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { getBookingUrl } from 'src/app/utilities/url.utils';
import { environment } from 'src/environments/environment';

export interface GetAllOrdersResponse {
    company_profile_data: string;
    total: number;
    invoice_status: string;
    orders: Order[];
}

interface GetAllOrdersResponseDto {
    orders: {
        id: string;
        number: string;
        status: string;
        createdAt: string;
        items: {
            id: string;
            orderId: string;
            couponCode: string;
            dealName: string;
            dealDescription: string;
            checkIn: string;
            checkOut: string;
            expiryDate: string;
            nights: number;
        }[];
    }[];
    status: string;
    grandTotal: number;
    page: number;
    pageSize: number;
}

export interface Order {
    createdAt: Date;
    order_id: string;
    status: string;
    id: string;
    number: number;
    items: OrderProduct[];
}

export interface OrderProduct {
    image: string;
    name: string;
    check_ins: Date;
    check_outs: Date;
    day_to_send: Date;
    expiry: Date;
    customdescription: string;
    pdf_url: InvoicePdfUrl[];
    type_of_room: string;
    no_of_nights: number;
    meal_plan: string;
    location: string;
    coupon_code: string;
}

export interface InvoicePdfUrl {
    code: string;
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class BookingsService {
    constructor(private authhttpClient: AuthHttpClientNoXService) {}

    getAllOrders(
        customerId: any,
        pageSize: number,
        currentPage: number
    ): Observable<GetAllOrdersResponse> {
        return this.authhttpClient
            .get<GetAllOrdersResponseDto>(
                `${getBookingUrl()}orders?page=${currentPage}&pageSize=${pageSize}&customer_id=${customerId}`
            )
            .pipe(
                map((dto) =>
                    this.transformGetAllOrdersResponseDtoToGetAllOrdersResponse(
                        dto
                    )
                )
            );
    }

    private transformGetAllOrdersResponseDtoToGetAllOrdersResponse(
        dto: GetAllOrdersResponseDto
    ): GetAllOrdersResponse {
        return {
            company_profile_data: '',
            total: dto.grandTotal,
            invoice_status: dto.status,
            orders: dto.orders.map((order) => ({
                status: order.status,
                number: parseInt(order.number),
                createdAt: dayjs(
                    order.createdAt,
                    'YYYY-MM-DD HH:mm:ss'
                ).toDate(),
                order_id: order.id,
                id: order.number,
                items: order.items.map((item) => ({
                    image: '',
                    name: item.dealName,
                    check_ins: dayjs(item.checkIn, 'YYYY-MM-DD').toDate(),
                    check_outs: dayjs(item.checkOut, 'YYYY-MM-DD').toDate(),
                    day_to_send: new Date(),
                    expiry: item.expiryDate
                        ? dayjs(item.expiryDate, 'YYYY-MM-DD').toDate()
                        : undefined,
                    customdescription: item.dealDescription,
                    pdf_url: item.couponCode
                        ? [this.constructPdfUrl(item.couponCode)]
                        : [],
                    type_of_room: '',
                    no_of_nights: item.nights,
                    meal_plan: '',
                    location: '',
                    coupon_code: item.couponCode,
                })),
            })),
        };
    }

    private constructPdfUrl(couponCode: string): InvoicePdfUrl {
        const staticUrl = `https://marideal-partners-${this.getEnvironment()}.s3.ap-southeast-1.amazonaws.com/media/webcoupons/Deal_${couponCode}.pdf`;
        return {
            code: couponCode,
            url: staticUrl,
        };
    }

    private getEnvironment(): string {
        return environment.production ? 'prod' : 'dev';
    }
}

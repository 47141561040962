import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Router } from '@angular/router';
import { BadgeService } from './badge.service';
import { SwiperComponent } from 'ngx-useful-swiper';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { getCategoryRoutesArray } from 'src/app/static-content/menu-routes';
import { DeviceCommsService } from 'src/app/services/device-comms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { screenSizes } from 'src/app/utilities/theme';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-my-badges',
    templateUrl: './my-badges.component.html',
    styleUrls: ['./my-badges.component.scss'],
})
export class MyBadgesComponent implements OnInit {
    badges_percentage: number;
    unLockBadges: number;
    totalBages: number;
    arrBadgesData: any;
    userBages: any;
    customerId: number;
    transformval: string;
    isMyBadgePageSkeletonShow: boolean = true;
    locale: 'en-MU' | 'fr-RE';

    @ViewChild('usefulSwiper', { static: false })
    usefulSwiper: typeof SwiperComponent;

    constructor(
        private badgeService: BadgeService,
        private router: Router,
        private eventService: EventService,
        private currencyLanguageService: CurrencyLanguageService,
        private spinner: NgxSpinnerService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.usefulSwiper = SwiperComponent;
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            if (isLoggedIn) {
                this.customerId = userData?.userId;
            }
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    badgesListConfig: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            [screenSizes.largeDesktop]: {
                slidesPerView: 4,
                spaceBetween: 92,
            },
            [screenSizes.desktop]: {
                slidesPerView: 4,
                spaceBetween: 92,
            },
            0: {
                slidesPerView: 3,
                spaceBetween: 27,
            },
        },
    };

    setpercentageBar() {
        this.badges_percentage = Math.round(
            (this.unLockBadges / this.totalBages) * 100
        );
        let valDeg = 45.0;
        let percentVal = (180.0 / this.totalBages) * this.unLockBadges;
        percentVal = percentVal + valDeg;
        this.transformval = 'rotate(' + percentVal + 'deg)';
    }

    BadgesPressed(
        event: Event,
        is_clickable: boolean,
        link: string,
        linkType: string,
        is_availed: boolean
    ) {
        if (is_clickable) {
            if (this.isSocialLinkType(linkType)) {
                this.handleSocialBadgeClick(linkType, link);
            } else if (linkType === 'Identified') {
                if (!is_availed) {
                    this.router.navigate(['customer/address/edit']);
                }
            } else if (linkType === 'Verified') {
                if (!is_availed) {
                    this.router.navigate(['customeraccount/settings']);
                }
            } else if (linkType === 'beginner' || linkType === 'expert') {
                if (!is_availed) {
                    this.router.navigate(['/']);
                }
            } else if (getCategoryRoutesArray().indexOf(link) > -1) {
                if (!is_availed) {
                    this.redirectToCategoryPage(link, event);
                }
            }
        }
    }

    private isSocialLinkType(
        linkType: string
    ): linkType is 'Facebook' | 'Instagram' | 'Youtube' | 'Tiktok' {
        return ['Facebook', 'Instagram', 'Youtube', 'Tiktok'].includes(
            linkType
        );
    }

    private handleSocialBadgeClick(
        linkType: 'Facebook' | 'Instagram' | 'Youtube' | 'Tiktok',
        link: string
    ) {
        this.spinner.show();
        const data = {
            customerId: this.customerId,
            platformType: linkType,
        };
        // this.badgeService.getRewardPoint(data).subscribe((response: any) => {
        //     this.spinner.hide();
        //     if (response.status == 'success') {
        //         this.userBages = response.availed_badges;
        //         // this.common.balancePoints = response.updated_reward_point;
        //         setRewardPoints(response.updated_reward_point);
        //         this.arrBadgesData = response.badges_info
        //             ? response.badges_info
        //             : [];
        //         this.unLockBadges = response.availed_badges;
        //         // this.common.badges = response.availed_badges;
        //         setBadgeCount(response.availed_badges);
        //         this.setpercentageBar();
        //     }
        //     this.deviceCommsService.openLinkInNewTabOrBrowser(link);
        // });
    }

    redirectToCategoryPage(path: string, event: Event) {
        event.preventDefault();
        this.router.navigate([path]);
    }

    protected readonly ProfileSection = ProfileSection;
}

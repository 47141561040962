<div class="pdbc-activity-container">
    <div *ngFor="let service of productDetails.dealOffers">
        <h4 class="pdbc-activity-name">{{ service.title }}</h4>
        <div
            class="pdbc-activity-service-option"
            *ngFor="let option of service.dealOptions"
        >
            <div class="pdbc-activity-service-details">
                <div class="pdbc-activity-service-name">{{ option.title }}</div>
                <div>{{ option.subTitle }}</div>
            </div>
            <md-counter
                [minValue]="option.minPax"
                [maxValue]="option.maxPaxDynamic"
                [startValue]="option.minPax"
                [counterValue]="option.qty"
                (valueChanged)="onQuantityChanged(option, $event)"
            />
            <div class="pdbc-activity-service-prc">
                <div class="pdbc-activity-service-price">
                    {{
                        option.sellingRate === 0
                            ? ("FREE" | translate)
                            : (option.sellingRate
                              | currency
                                  : currencySymbol
                                  : "symbol"
                                  : "1.0-2"
                                  : locale)
                    }}
                </div>
                <div
                    *ngIf="option.sellingRate !== 0"
                    class="pdbc-activity-service-full-price"
                >
                    {{
                        option.crossedRate
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-2"
                                : locale
                    }}
                </div>
            </div>
        </div>
    </div>
</div>

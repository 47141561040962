import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DealDto, PackageTypeId } from 'src/app/model/deal.model';
import {
    BookingConfigVariant,
    BookingConfiguredData,
    CartDto,
    HotelSearchBookingData,
    ProductBookingData,
    getBookingVariantBasedOnCategory,
} from './booking-config.model';
import { BookingService } from './booking-config.service';
import { Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { ProductAddOnConfiguration } from './add-ons/add-ons.model';
import { applyDiscountToSubtotal } from './special-offers/special-offers.utils';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, of } from 'rxjs';
import { createDateFromString } from 'src/app/controls/calendar/calendar.utils';
import { transformToMinimalProductBookingData } from './booking-config.transformer';
import { UserService } from 'src/app/services/user.service';
import { RewardsService } from 'src/app/services/rewards.service';
import { HotelBookingComponent } from './hotel-booking/hotel-booking.component';
import { screenSizes } from '@app/utilities/theme';
import { DatedActivityBookingComponent } from './dated-activity-booking/dated-activity-booking.component';

// eslint-disable-next-line @typescript-eslint/ban-types
declare const fbq: Function;

@Component({
    selector: 'md-booking-config',
    templateUrl: './booking-config.component.html',
    styleUrls: ['./booking-config.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingConfigComponent implements OnInit {
    @Input() set productDetails(value: DealDto) {
        this.productDetailsInternal = value;
        const { categoryId, packageTypeId } = value;

        const showCalendar = packageTypeId !== PackageTypeId.NON_CALENDAR;

        this.bookingConfigVariant = getBookingVariantBasedOnCategory(
            categoryId,
            showCalendar
        );
    }

    @ViewChild('hotelBooking') hotelBookingComponent: HotelBookingComponent;
    @ViewChild('datedActivity')
    datedActivityComponent: DatedActivityBookingComponent;

    productDetailsInternal: DealDto;
    bookingConfigVariant: BookingConfigVariant;
    bookingData: BookingConfiguredData = {};
    initialBookingData: HotelSearchBookingData;
    addonConfigsData: ProductAddOnConfiguration[];
    rewardsPoints = 0;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private bookingService: BookingService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private generalErrorService: GeneralErrorService,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private translate: TranslateService,
        private ref: ChangeDetectorRef,
        private rewardsService: RewardsService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });

        // const queryParams = this.router.parseUrl(this.router.url).queryParams;
        // this.initialBookingData =
        //     this.getBookingDataFromQueryParams(queryParams);
    }

    onBookingConfigured(data: BookingConfiguredData) {
        this.bookingData = applyDiscountToSubtotal(
            this.productDetailsInternal,
            data
        );

        if (this.bookingData.productBookingData) {
            this.bookingData.productBookingData.addOnConfigurations =
                this.addonConfigsData;
        }

        const addOnsTotalPrice = this.calculateAddOnsTotalPrice(
            this.addonConfigsData
        );

        if (this.bookingData.totalPrice > 0) {
            this.bookingData.totalPrice += addOnsTotalPrice;
            this.bookingData.totalFullPrice += addOnsTotalPrice;
        }

        if (this.bookingData.totalPrice) {
            this.rewardsService
                .getRewardPoints(
                    this.bookingData.totalPrice,
                    this.currencyLanguageService.getLocaleDataImmediate()
                )
                .subscribe((response) => {
                    this.rewardsPoints = response;
                    this.bookingData.rewardPointsEarned = this.rewardsPoints;
                    this.ref.markForCheck();
                });

            // this.addBookingDataToUrl(this.bookingData);
        }

        this.ref.detectChanges();
    }

    calculateAddOnsTotalPrice(
        addonConfigs: ProductAddOnConfiguration[]
    ): number {
        return addonConfigs
            ? addonConfigs.reduce(
                  (totalPrice, addOn) =>
                      totalPrice + addOn.quantity * addOn.addonItem.price,
                  0
              )
            : 0;
    }

    onAddonConfigChanged(addonConfigs: ProductAddOnConfiguration[]) {
        this.bookingData.productBookingData.addOnConfigurations = addonConfigs;
        this.bookingData = this.addAddonPricesToBookingData(
            this.bookingData,
            addonConfigs
        );
        this.addBookingDataToUrl(this.bookingData);
    }

    // Return Observable with boolean indicating can proceed / no error
    onAddToCartClicked(): Observable<boolean> {
        if (!this.isBookingDataValid(this.bookingData.productBookingData)) {
            this.generalErrorService.showGeneralError(
                this.translate.instant(
                    'Invalid selection. Please check occupancy and dates and try again.'
                ),
                { showMailto: false, showImage: false }
            );
            return of(false);
        }

        const {
            isHotel,
            isDatedActivity,
            hasMissingCheckDates,
            hasMissingSelectedDate,
        } = this.getMissingDates();

        if (
            (isHotel && hasMissingCheckDates) ||
            (isDatedActivity && hasMissingSelectedDate)
        ) {
            this.generalErrorService.showGeneralError(
                this.translate.instant('Please select date first'),
                { showMailto: false, showImage: false }
            );
            return of(false);
        }

        this.spinner.show();
        // this should just do the call to add booking to cart
        // in case the booking is not valid it should point to what is invalid
        fbq('track', 'AddToCart', {
            source: 'magento',
            version: '1.9.3.8',
            pluginVersion: '2.2.4',
            content_type: 'product',
            content_ids: [this.productDetailsInternal.dealId],
        });

        const { currencyId } =
            this.currencyLanguageService.getLocaleDataImmediate();

        return new Observable<boolean>((observer) => {
            this.bookingService
                .addToCartV2(
                    this.bookingData,
                    this.productDetailsInternal,
                    currencyId
                )
                .subscribe({
                    next: (response: CartDto) => {
                        this.spinner.hide();
                        this.userService.setLocalCart({
                            userCartId: response.id,
                            itemCount: response.itemsCount,
                        });
                        observer.next(true);
                        observer.complete();
                    },
                    error: () => {
                        this.spinner.hide();
                        this.generalErrorService.showGeneralError(
                            this.translate.instant(
                                'Could not add item to cart. Please refresh the page and try again.'
                            )
                        );
                        observer.error();
                    },
                });
        });
    }

    private isBookingDataValid(
        productBookingData: ProductBookingData
    ): boolean {
        if (!productBookingData) return false;
        if (this.allSelectedBookingOptionQuantitiesAreZero(productBookingData))
            return false;

        return true;
    }

    private allSelectedBookingOptionQuantitiesAreZero(
        productBookingData: ProductBookingData
    ): boolean {
        if (!productBookingData.selectedDealOptionQuantities) return false;
        const values = Array.from(
            productBookingData.selectedDealOptionQuantities.values()
        );

        const allQuantitiesAreZero = values.every(
            (quantity) => !quantity || quantity === 0
        );
        return allQuantitiesAreZero;
    }

    // Need to subscribe to the onAddToCartClicked or
    // else the call won't get made because no one is expecting a result
    onAddToCartClickedExternal() {
        const { shouldContinue } = this.openCalendarIfNeeded();

        if (!shouldContinue) return;

        this.onAddToCartClicked().pipe(filter(Boolean)).subscribe();
    }

    onBookNowClicked() {
        const { shouldContinue } = this.openCalendarIfNeeded();

        if (!shouldContinue) return;

        this.onAddToCartClicked()
            .pipe(filter(Boolean))
            .subscribe(() => {
                this.router.navigate(['/checkout']);
            });
    }

    private openCalendarIfNeeded(): { shouldContinue: boolean } {
        const isDesktop = window.innerWidth > screenSizes.mobile;

        if (isDesktop) return { shouldContinue: true };

        const {
            isHotel,
            isDatedActivity,
            hasMissingCheckDates,
            hasMissingSelectedDate,
        } = this.getMissingDates();

        if (isHotel && hasMissingCheckDates) {
            this.hotelBookingComponent.onDatesClick();
            return { shouldContinue: false };
        }

        if (isDatedActivity && hasMissingSelectedDate) {
            this.datedActivityComponent.calendarDatedActivity.openCalendar();
            return { shouldContinue: false };
        }

        return { shouldContinue: true };
    }

    private getBookingDataFromQueryParams(
        queryParams: Params
    ): HotelSearchBookingData {
        const fromDateString = queryParams['fromDate'];
        const toDateString = queryParams['toDate'];
        const occupancyId = queryParams['occupancyId'];
        const fromDate = createDateFromString(fromDateString);
        const toDate = createDateFromString(toDateString);
        return { fromDate, toDate, occupancyId };
    }

    private addAddonPricesToBookingData(
        bookingData: BookingConfiguredData,
        addonConfigs: ProductAddOnConfiguration[]
    ): BookingConfiguredData {
        const bookingDataClone = { ...bookingData };
        this.addonConfigsData = addonConfigs;
        let totalPrice = 0;
        let totalFullPrice = 0;
        for (const [
            serviceOption,
            quantity,
        ] of bookingDataClone.productBookingData.selectedDealOptionQuantities.entries()) {
            totalPrice += serviceOption.sellingPrice * quantity;
            totalFullPrice += serviceOption.crossedPrice * quantity;
        }

        const addOnsTotalPrice = this.calculateAddOnsTotalPrice(addonConfigs);
        bookingDataClone.totalPrice = totalPrice + addOnsTotalPrice;
        bookingDataClone.totalFullPrice = totalFullPrice + addOnsTotalPrice;
        return bookingDataClone;
    }

    private addBookingDataToUrl(bookingData: BookingConfiguredData) {
        const nonUndefinedQueryParams = transformToMinimalProductBookingData(
            bookingData.productBookingData
        );
        const queryParams = {
            bookingData: JSON.stringify(nonUndefinedQueryParams),
        };

        const currentUrlTree = this.router.parseUrl(this.router.url);
        const mergedQueryParams = {
            ...currentUrlTree.queryParams,
            ...queryParams,
        };
        const updatedUrlTree = this.router.createUrlTree([], {
            queryParams: mergedQueryParams,
        });
        const updatedUrl = this.router.serializeUrl(updatedUrlTree);
        history.replaceState({}, '', updatedUrl);
    }

    private getMissingDates() {
        const { selectedDate, checkinDate, checkoutDate } =
            this.bookingData.productBookingData ?? {};

        const isHotel = this.bookingConfigVariant === 'hotel';
        const isDatedActivity = this.bookingConfigVariant === 'datedActivity';

        const hasMissingCheckDates = !checkinDate || !checkoutDate;

        const hasMissingSelectedDate = !selectedDate;

        return {
            isHotel,
            isDatedActivity,
            hasMissingCheckDates,
            hasMissingSelectedDate,
        };
    }
}

import {
    DealDto,
    ProductDiscount,
} from 'src/app/model/deal.model';
import { BookingConfiguredData } from '../booking-config.model';

export const applyDiscountToSubtotal = (
    productDetails: DealDto,
    data: BookingConfiguredData
): BookingConfiguredData => {
    const { productBookingData } = data;
    const { subtotalDiscounts } = productDetails;
    if (!productBookingData || !subtotalDiscounts) {
        return data;
    }

    const { totalNights } = productBookingData;
    subtotalDiscounts.sort((a, b) => b.minNights - a.minNights);

    let bestDiscount: ProductDiscount;
    for (const discount of subtotalDiscounts) {
        if (totalNights >= discount.minNights) {
            bestDiscount = discount;
            break;
        }
    }

    if (bestDiscount) {
        const { totalPrice } = data;
        const discountedPrice =
            totalPrice - (totalPrice * bestDiscount.discountPercent) / 100;
        data.totalPrice = discountedPrice;
    }

    return data;
};

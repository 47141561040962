import { Component, HostListener, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { screenSizes } from '../utilities/theme';
import { NavigationItem } from '../model/menu';
import { NavigationEnd, Router } from '@angular/router';
import { removeQueryParams } from '../utilities/url.utils';
import { Section, SectionsService } from '../shared/sections.service';

@Component({
    selector: 'md-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    drawerItems: NavigationItem[];
    isLoading = true;
    loadingDrawerItemPlaceholders: number[] = [];
    isVisible: boolean;
    urlNoQueryParams: string;
    sectionId: string;
    scrollablePane: Element;

    constructor(
        private route: Router,
        private sectionsService: SectionsService
    ) {}

    ngOnInit(): void {
        this.setLoadingPlaceholders();
        this.scrollablePane = document.getElementsByClassName('pane')[0];
        this.sectionsService.getSections().subscribe((data) => {
            const { sections } = data;
            this.drawerItems = sections.map((section: Section) => {
                return {
                    id: section.sectionId,
                    name: section.sectionName,
                    icon: section.icon,
                    path: `/plp/${section.sectionName}/${section.sectionId}`,
                };
            });

            const index = this.drawerItems.findIndex(item => item.id === 34);
            if (index > -1) {
                const [section34] = this.drawerItems.splice(index, 1);
                this.drawerItems.unshift(section34);
            }

            this.isLoading = false;
        });
        this.route.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // TODO: leave the iso
                this.urlNoQueryParams = removeQueryParams(
                    event.urlAfterRedirects
                );
                const urlSegments = this.urlNoQueryParams.split('/');
                this.sectionId = urlSegments[urlSegments.length - 1];

                this.setShowDrawerStateBasedOnPathnameAndScreenWidth();
            }
        });
    }

    public config: SwiperOptions = {
        slidesPerView: 'auto',
        freeMode: true,
        breakpoints: {
            [screenSizes.desktop]: {
                slidesPerView: 9,
            },
            [screenSizes.mobile]: {
                slidesPerView: 7.5,
            },
            0: {
                slidesPerView: 'auto',
                spaceBetween: 16,
                freeMode: true,
            },
        },
    };

    redirectToCategoryPage(drawerItem: NavigationItem, event: Event) {
        event.preventDefault();
        const { id, path } = drawerItem;

        const screenWidth = window.innerWidth;
        if (screenWidth < screenSizes.mobile) {
            setTimeout(() => {
                this.scrollablePane.scrollTop = 50;
            }, 0);
        }

        this.route.navigate([path], {
            state: {
                catId: id,
                isAll: 0, // this ended up always being 0
                advancesearch: '',
                filterdata: '',
                category_name: path,
            },
        });
    }

    @HostListener('window:resize')
    onResize(): void {
        if (this.isLoading) {
            this.setLoadingPlaceholders();
        }

        this.setShowDrawerStateBasedOnPathnameAndScreenWidth();
    }

    setLoadingPlaceholders(): void {
        const width = window.innerWidth;
        let nrOfLoadingItems = 4;
        if (width >= screenSizes.desktop) {
            nrOfLoadingItems = 9;
        } else if (width >= screenSizes.mobile) {
            nrOfLoadingItems = 7;
        }
        this.loadingDrawerItemPlaceholders = new Array(nrOfLoadingItems);
    }

    setShowDrawerStateBasedOnPathnameAndScreenWidth() {
        const previousVisibility = this.isVisible;
        const isHomePage = this.urlNoQueryParams === '/';
        const isCategoryPage = this.urlNoQueryParams.includes('/plp/');

        this.isVisible =
            isHomePage || isCategoryPage || this.isDrawerDesktopPage();
        if (previousVisibility !== this.isVisible) {
            if (this.isVisible) {
                this.showDrawer();
            } else {
                this.hideDrawer();
            }
        }
    }

    private isDrawerDesktopPage(): boolean {
        const isDesktopScreen = window.innerWidth >= screenSizes.mobile;

        return isDesktopScreen;
    }

    showDrawer(): void {
        const routerContainer =
            document.getElementsByClassName('routerContainer')[0];
        if (routerContainer) {
            routerContainer.classList.remove('a-router-container--no-drawer');
            routerContainer.classList.add('a-router-container');
        }
    }

    hideDrawer(): void {
        const routerContainer =
            document.getElementsByClassName('routerContainer')[0];
        if (routerContainer) {
            routerContainer.classList.add('a-router-container--no-drawer');
            routerContainer.classList.remove('a-router-container');
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHttpClientService } from 'src/app/services/auth-http-client.service';
import { CommonService } from 'src/app/shared/common.service';
import { getMyAccountUrl } from 'src/app/utilities/url.utils';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService,
        private authhttpClient: AuthHttpClientService
    ) {}

    getCustomerVerificationInfo(customerId: any) {
        return this.httpClient.get(
            this.commonService.api_url +
                'getCustomerVerificationInfo?store=' +
                this.commonService.getStoreID() +
                '&currency=MUR&service=getCustomerVerificationInfo&customerid=' +
                customerId +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }

    getPrivacySettings() {
        let customer_id: string = '';
        if (localStorage.getItem('marideal-pwa_user')) {
            customer_id = JSON.parse(localStorage.getItem('marideal-pwa_user'))
                .data.id;
        }
        return this.httpClient.get(
            this.commonService.api_url +
                'getPrivacySettings?store=' +
                this.commonService.getStoreID() +
                '&service=getPrivacySettings' +
                '&customerid=' +
                customer_id +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }

    changePassword(
        currentPassword: string,
        newPassword: string,
        confirmPassword: string
    ) {
        return this.authhttpClient.post(
            `${getMyAccountUrl()}security/password-reset`,
            { currentPassword, newPassword, confirmPassword }
        );
    }

    downloadPrivacyCsv(customerId: any, encodedCurrentPass: any) {
        return this.httpClient.get(
            this.commonService.api_url +
                'downloadPrivacyCSV?store=' +
                this.commonService.getStoreID() +
                '&service=downloadPrivacyCSV&customerid=' +
                customerId +
                '&password=' +
                encodedCurrentPass +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }

    deleteRequest(customerId: any, encodedCurrentPass: any) {
        return this.httpClient.get(
            this.commonService.api_url +
                'deleteRequest?store=' +
                this.commonService.getStoreID() +
                '&service=deleteRequest&customerid=' +
                customerId +
                '&password=' +
                encodedCurrentPass +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }
}

<div class="main-div-payment" *ngIf="isUserLoggedIn">
    <div class="skeleton" *ngIf="paymentSuccessSkeleton">
        <div
            class="animated-background"
            style="
                height: 94vh;
                border: 2px solid white;
                width: auto;
                box-sizing: border-box;
            "
        ></div>
    </div>
    <div
        class="checkoutpayment_successpage"
        *ngIf="!paymentSuccessSkeleton"
        has-header="true"
        scroll="false"
    >
        <div class="page-side-space">
            <div class="checkoutpayment_successcontent">
                <div class="payment-success-messageblock">
                    <div class="payment-success-emoticon">
                        <img src="assets/img/payment-success-emoticon.png" />
                    </div>
                    <div
                        *ngIf="orderdata.orderStatus === 'Completed'"
                        class="payment-success-msg"
                    >
                        <p>
                            {{ "Payment Successful!" | translate }}
                        </p>
                        <p>
                            {{
                                "Booking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal"
                                    | translate
                            }}
                            .
                        </p>
                    </div>
                </div>
                <div class="checkoutorderpreview-table">
                    <div class="table-preview-div">
                        <div>
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{ "Order Id" | translate }}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{ orderdata.orderId }}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="orderdata.name">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{ "Name" | translate }}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{ orderdata.name }}
                                </label>
                            </div>
                        </div>
                        <div>
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{ "Amount Payable" | translate }}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{
                                        orderdata.amount
                                            | currency
                                                : currencySymbol
                                                : "symbol"
                                                : "1.0-0"
                                                : locale
                                    }}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="orderdata.orderStatus">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{ "Payment status" | translate }}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{ orderdata.orderStatus }}
                                </label>
                            </div>
                        </div>
                        <div>
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{ "Email Address" | translate }}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{ orderdata.email }}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="paymentStatus == 'failure'">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{ "Payment Status" | translate }}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{ "Error" | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="height30"></div>
                <div class="securecheckout_textblock">
                    <div class="sercurecheckout_msg">
                        <p>
                            {{
                                "MariDeal Management, staff or employees at no time will ever see your credit card number, your account name, expiration dates or CVV codes"
                                    | translate
                            }}
                            .
                            {{
                                "All details regarding your payment will be processed directly by the Bank, State Bank of Mauritius"
                                    | translate
                            }}
                            .
                            {{
                                "All data and personal details are protected by bank at the highest encryption possible"
                                    | translate
                            }}
                            .
                        </p>
                    </div>
                </div>
                <button
                    class="button button-block button-positiveBtn-rounded continue-shopping ripleEffect"
                    (click)="continueShoping()"
                >
                    {{ "Home Page" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="c-os-title-container">
    <h2 class="c-os-title">{{ "Order Summary" | translate }}</h2>
    <div class="c-os-items-in-cart">
        {{ cartItems.length | i18nPlural : itemPluralMapping }}
    </div>
</div>
<div class="c-os-item-container">
    <md-cart-items
        [items]="cartItems"
        [removeCartItem]="removeCartItem"
        [changeQuantity]="changeQuantity"
    />
    <md-booking-summary
        [cart_total_amount]="cart_total_amount"
        [coupon_code_discount]="coupon_code_discount"
        [discountCodeTitle]="discountCodeTitle"
        [totalPrice]="totalPrice"
        [desktopOnly]="true"
        [giftDiscount]="giftDiscount"
        [storeCreditDiscount]="storeCreditDiscount"
        [rewardPointDiscount]="rewardPointDiscount"
        [rewardPointsEarned]="rewardPointsEarned"
    />
</div>

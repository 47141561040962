import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHttpClientService } from 'src/app/services/auth-http-client.service';
import { getMyAccountUrl } from 'src/app/utilities/url.utils';

interface ProfileUserRole {
    role: 'md-storefront-user';
}

interface ProfileAddress {
    street: string;
    town: string;
}
export interface ProfileData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    dateOfBirth?: string;
    gender?: string;
    address?: ProfileAddress;
    language: string;
    userRole: ProfileUserRole;
}

interface EditProfileProps extends ProfileData {
    orgId: number;
}

@Injectable({
    providedIn: 'root',
})
export class EditService {
    constructor(private authHttpService: AuthHttpClientService) {}

    // TODO: These have to handle failure cases too
    getProfileData(): Observable<ProfileData> {
        return this.authHttpService.get<ProfileData>(
            `${getMyAccountUrl()}view-profile`
        );
    }

    editProfile(data: ProfileData): Observable<void> {
        return this.authHttpService.post<void, EditProfileProps>(
            `${getMyAccountUrl()}edit-profile`,
            {
                ...data,
                orgId: 1,
            }
        );
    }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MoreDealsService {
    // getDeals(productId: number): Observable<GetMerchantDealsResponseDto> {
    //     return new Observable((observer) => {
    //         observer.next([])
    // }
}

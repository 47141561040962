<div class="h-banner-container h-block" *ngIf="!hideBannerIfNoContent">
    <swiper [config]="swiperConfig" class="h-banner-swiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let banner of bannerData">
                <img
                    class="h-banner-image"
                    [src]="
                        showMobileBanner
                            ? banner.fileName
                            : banner.fileNameDesktop
                    "
                    (click)="goToPromotionLink(banner.urlString)"
                />
            </div>
        </div>
    </swiper>
</div>

<div
    class="cart-without-items text-center"
    *ngIf="!items || items.length === 0"
>
    <div id="empty_cart_block">
        <ion-icon class="cart-icon" name="cart-outline"></ion-icon>
    </div>
    <div id="empty_cart_msg_block">
        <h4>{{ "Your cart is empty!" | translate }}</h4>
        <div>{{ "Add item to it now?" | translate }}</div>
        <button
            mdMainButton="light"
            class="button button-block ripleEffect"
            (click)="goHome()"
        >
            {{ "Shop Now" | translate }}
        </button>
    </div>
</div>

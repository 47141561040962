import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateInvoiceService } from './create-invoice.service';
import { User } from '../../model/user.model';
import { getCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';

@Component({
    selector: 'app-create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss'],
})
export class CreateInvoiceComponent implements OnInit {
    isGenrateInvoiceShowSkeleton: boolean = true;
    loginStatus!: number;
    private subscription: Subscription;
    public loginResponse: User;
    public userLogin: boolean = false;
    createInvoiceFormGroup = new UntypedFormGroup({
        PO: new UntypedFormControl('', []),
        companyProfile: new UntypedFormControl('', []),
    });
    customerId: any;
    showPopupBox: boolean = false;
    showPopupBoxMsg: string = '';

    constructor(
        private createInvoiceService: CreateInvoiceService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private generalErrorService: GeneralErrorService
    ) {}

    ngOnInit(): void {
        const companyDataRaw = getCache('company_profile_data');
        let company_profile_data =
            companyDataRaw && companyDataRaw.data ? companyDataRaw.data : '';
        this.createInvoiceFormGroup.controls['companyProfile'].setValue(
            company_profile_data
        );
        this.isGenrateInvoiceShowSkeleton = false;
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            if (isLoggedIn) {
                this.customerId = userData?.userId;
            }
        });
    }

    saveOrderInvoice() {
        const payload = {
            customerId: this.customerId,
            po: this.createInvoiceFormGroup.controls['PO'].value,
            order_id: this.activatedRoute.snapshot.params['order_id'],
        };
        // this.createInvoiceService
        //     .saveOrderInvoice(payload)
        //     .subscribe((response: any) => {
        //         this.generalErrorService.showGeneralError(response.message);
        //         window.history.back();
        //     });
    }
}

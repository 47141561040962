<div class="refactoringTemp">
    <button
        class="btn calendar gcf-calendar-button"
        (click)="d.toggle()"
        type="button"
    ></button>
    <input
        placeholder="dd/mm/yyyy"
        [(ngModel)]="dateModel"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [startDate]="startDate"
        ngbDatepicker
        (change)="manuallyChangedDate($event)"
        (dateSelect)="dateSelect($event)"
        #d="ngbDatepicker"
        class="refactoringTemp gcf-calendar-input"
        id="deliveryDate"
    />
</div>

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '../services/token.service';
import { getCategoryRoutesArray } from '../static-content/menu-routes';
import { getApiUrlMofluid } from '../utilities/url.utils';
import { getCache, setCache } from '../utilities/cache.utils';

declare let $: any;

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public customerGroupID: number = 0;
    public searchText: string = '';
    public language: string = '';
    public pageTitle: string = '';

    public isSearchEnable: boolean = false;

    /**
     * replace general error with error event emitter
     * @deprecated
     */
    public generalError: boolean = false;
    /**
     * replace general error with error event emitter
     * @deprecated
     */
    public generalErrorMsg: string = '';

    public generalErrorRequest: boolean = false;
    public generalErrorMsgRequest: string = '';
    public generalErrorCal: boolean = false;
    public generalErrorMsgCal: string = '';
    public isLoggedIn: boolean = false;
    public readonly api_url: string = '';
    private _keyStr: string =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    public cdnUrl: string = '';
    public newappLogo: string = '';
    public dataStore: any = [];
    public RecentcontentLoaded: boolean = false;
    public count = 2;
    public loadingText: string = '';

    // THIS MEANS THAT THE CALENDAR IS OPEN. BASICALLY COULD HAVE BEEN CALLED isCalendarOpen
    public isCalender: boolean = false;
    public data = new BehaviorSubject<any>({
        searchBar: '',
        adults: 0,
        childs: 0,
        infants: 0,
        isShowProductDetailSkeleton: false,
        guestCounter: 0,
        currentInfant: 0,
        adult: 0,
        child: 0,
        currentChild: 0,
        currentAdult: 0,
        openCalendar: '',
        searchBox: false,
        singleDateCalCatArray: ['4', '8', '7', '17', '6', '4'],
        advance_search_optionid: 0,
        isShowMultiplePriceSkeleton: false,
        fromCheckout: false,
        eventMonthArray: [],
        cdn: '',
        header: '',
        page_title: '',
        is_promotion_started: '',
        counter_digit_color: '',
        counter_digit_text_color: '',
        counter_digit_background_color: '',
        before_background_image: '',
        after_background_image: '',
        counter_background_color: '',
        from_showOccupancy: false,
        ch_date: '',
        single_dt: '',
        selectedCategoryId: '',
        departure_date_travel: '',
        modal: '',
        modalOpenFlag_calender: false,
        finalCalculatedSubtotal: '',
        finalCalculatedCrossPrice: '',
        tempSubTotal: '',
        callfromdatetype: '',
        promotion_id: '',
        filterScrollCheck: false,
        modalOpenFlag: false,
        filterCheck: false,
        toProductDetails: false,
        toHome: false,
        categoryNameArray: getCategoryRoutesArray(),
        checkout: {},
        isCartSkeletonShow: false,
        isShowGetPaymentMethodSkeleton: false,
        get_device_OS: '',
        otherQueryKeys: {
            bookdateclone: 'bookdateclone',
            fbclid: 'fbclid',
        },
        advSearchValForCat: '',
        maribnbGuestOptionsSelectedDate: '',
    });

    // TODO: this one is used a lot and should at least be strongly typed
    currentData: any;
    imgCdnPath: string = '';
    cmsContent: any;
    catId: any;
    filter_data!: string;
    advancesearch!: string;
    params: any;
    sortType: any;
    sortOrder: any;
    page: any;
    pagesize: any;
    total!: number;
    userData: any;
    uId: string;
    quoteid: string; // this is an identifier given to a cart that is not logged in. Not used because we wrongly use localStorage. Leaving here for an explanation

    constructor(
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private tokenService: TokenService
    ) {
        this.currentData = this.data.asObservable();
        this.api_url = getApiUrlMofluid();
    }
    emitData(data: any) {
        this.data.next(data);
    }

    /**
     * Method to get Token
     */

    getTokenHeaders() {
        let headers = new HttpHeaders({
            Authorization: 'Bearer 0yq8qo7n0vuk0amrhskk2sjsookvrrt7',
        });
        return headers;
    }

    getTokenJWTheaders() {
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.tokenService.getToken(),
            'jwt-token': 'Bearer ' + this.tokenService.getJwtToken(),
        });
        return headers;
    }

    // TODO: This needs to be checked and updated with proper routes
    organizeTitle(url: string = ''): string {
        let routeTitlesArray: any = {
            '/': '',
            '/help-new': this.translate.instant('Help')
                ? this.translate.instant('Help')
                : 'Help',
            '/customer/mainhelp': this.translate.instant('Help')
                ? this.translate.instant('Help')
                : 'Help',
            '/catalogsearch/result': this.translate.instant('Search')
                ? this.translate.instant('Search')
                : 'Search',
            '/catalogsearch/result/index': this.translate.instant('Search')
                ? this.translate.instant('Search')
                : 'Search',
            '/search': this.translate.instant('Search'),
            '/sales/order/history': this.translate.instant('My Orders')
                ? this.translate.instant('My Orders')
                : 'My Orders',
            '/companyprofile/index/index': this.translate.instant(
                'Company Profile'
            )
                ? this.translate.instant('Company Profile')
                : 'Company Profile',
            '/review/customer': this.translate.instant('My Reviews')
                ? this.translate.instant('My Reviews')
                : 'My Reviews',
            '/addgift-voucher': this.translate.instant('My Gift Card')
                ? this.translate.instant('My Gift Card')
                : 'My Gift Card',
            '/giftvoucher/index/history': this.translate.instant('My Gift Card')
                ? this.translate.instant('My Gift Card')
                : 'My Gift Card',
            '/storecredit': this.translate.instant('My Store Credit')
                ? this.translate.instant('My Store Credit')
                : 'My Store Credit',
            '/storecredit.html': this.translate.instant('My Store Credit')
                ? this.translate.instant('My Store Credit')
                : 'My Store Credit',
            '/sales/order/history/detail': this.translate.instant('My Orders')
                ? this.translate.instant('My Orders')
                : 'My Orders',
            '/login': this.translate.instant('Login')
                ? this.translate.instant('Login')
                : 'Login',
            '/register': this.translate.instant('Sign Up')
                ? this.translate.instant('Sign Up')
                : 'Sign Up',
            '/password': '',
            '/customer/account/edit/changepass/1/': this.translate.instant(
                'Change Password'
            )
                ? this.translate.instant('Change Password')
                : 'Change Password',
            '/customer/account/createPassword': '',
            '/customer/account/forgotpassword': '',
            '/address': '',
            '/checkout/address': '',
            '/invoice': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/order/invoice': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/invoicenew': this.translate.instant('Success!')
                ? this.translate.instant('Success!')
                : 'Success!',
            '/customer/account/edit': this.translate.instant('Settings')
                ? this.translate.instant('Settings')
                : 'Settings',
            '/customeraccount/settings': this.translate.instant('Settings')
                ? this.translate.instant('Settings')
                : 'Settings',
            '/gdpr/customer/settings/': this.translate.instant('Settings')
                ? this.translate.instant('Settings')
                : 'Settings',
            '/write-review/': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/rewardpoints/index/index/': this.translate.instant('My Rewards')
                ? this.translate.instant('My Rewards')
                : 'My Rewards',
            '/rewardpoints': this.translate.instant('My Rewards')
                ? this.translate.instant('My Rewards')
                : 'My Rewards',
            '/customer/recent': this.translate.instant('Recent')
                ? this.translate.instant('Recent')
                : 'Recent',
            '/customer/address/edit': this.translate.instant('My Profile')
                ? this.translate.instant('My Profile')
                : 'My Profile',
            '/badges': this.translate.instant('My Badges')
                ? this.translate.instant('My Badges')
                : 'My Badges',
            '/customer/wishlist': this.translate.instant('Saved')
                ? this.translate.instant('Saved')
                : 'Saved',
            '/customer/trips': this.translate.instant('My Trips')
                ? this.translate.instant('My Trips')
                : 'My Trips',
            '/customer/trips-detail': this.translate.instant('My Trip')
                ? this.translate.instant('My Trip')
                : 'My Trip',
            '/customer-notification': this.translate.instant('Notifications')
                ? this.translate.instant('Notifications')
                : 'Notifications',
            '/checkoutpaymentSuccess': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/checkout/checkoutpaymentSuccess': this.translate.instant(
                'Order Review'
            )
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/checkoutpaymentFailure': '',
            '/checkout/checkoutpaymentFailure': '',
            '/gift-voucher': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/gift-voucher.html': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/giftcard': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/giftcard.html': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/writereview': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/newsletter/manage': '',
        };
        let dynamicRouteTitlesArray: any = {
            '/companyprofile/index/invoiceform': this.translate.instant(
                'Generate VAT Invoice'
            )
                ? this.translate.instant('Generate VAT Invoice')
                : 'Generate VAT Invoice',
            '/writereview': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/customer/address/edit/id': this.translate.instant('My Profile')
                ? this.translate.instant('My Profile')
                : 'My Profile',
            '/checkout': this.translate.instant('Checkout')
                ? this.translate.instant('Checkout')
                : 'Checkout',
            '/order/checkout': this.translate.instant('Checkout')
                ? this.translate.instant('Checkout')
                : 'Checkout',
            '/customer/account': this.translate.instant('Reset Password')
                ? this.translate.instant('Reset Password')
                : 'Reset Password',

            '/deal/': '',
        };
        let arrayUrls = Object.keys(routeTitlesArray);
        for (let arrUrl of arrayUrls) {
            if (arrUrl.length > url.length || arrUrl.length == url.length) {
                if (arrUrl.includes(url)) {
                    return routeTitlesArray[arrUrl];
                }
            } else {
                let dynamicArrayUrls = Object.keys(dynamicRouteTitlesArray);
                for (let dynamicArrUrl of dynamicArrayUrls) {
                    if (url.includes(dynamicArrUrl)) {
                        return dynamicRouteTitlesArray[dynamicArrUrl];
                    }
                }
            }
        }
        return 'Home';
    }

    startProgress() {
        this.spinner.show();
    }

    /**
     * Method to hide loader
     */

    endProgress() {
        this.loadingText = '';
        this.spinner.hide();
    }
    getStoreID(): number {
        return getCache('storeID') ? (getCache('storeID').data as number) : 1;
    }

    // TODO: these 2 methods must be duplicated somewhere before we continue and used from there
    setCustomerGroupID(id: any) {
        const customerGroupID = parseInt(id);
        setCache('customer_group_id', customerGroupID);
        this.customerGroupID = customerGroupID;
    }

    getCustomerGroupID(): number {
        if (getCache('customer_group_id')) {
            return getCache('customer_group_id').data as number;
        } else if (this.customerGroupID > 0) {
            return this.customerGroupID;
        } else {
            return 0;
        }
    }

    // TODO: There's a copy of this in the currencyLanguageService
    getCurrency(): string {
        const customerGroupID = this.getCustomerGroupID();
        return customerGroupID == 0 || customerGroupID == 1 ? 'Rs.' : '€';
    }
}

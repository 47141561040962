import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    EGiftAddToCartDto,
    EGiftCardDealInfo,
    EGiftCardDetailsResponseDto,
} from './egift-card.model';
import { Observable, switchMap } from 'rxjs';
import { CurrencyLanguageService } from '../shared/currency-language.service';
import { getBookingUrl, getResultsUrl } from '../utilities/url.utils';
import {
    AddCartItemDto,
    CartDto,
} from '../product-detail/booking-config/booking-config.model';
import { UserService } from '../services/user.service';
import { AuthHttpClientNoXService } from '../services/auth-http-client-noX.service';

@Injectable({
    providedIn: 'root',
})
export class EgiftCardService {
    customerId: string;

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private authHttpClient: AuthHttpClientNoXService
    ) {
        const customerData = localStorage.getItem('marideal-pwa_user');
        if (customerData) {
            this.customerId = JSON.parse(customerData).data.id;
        }
    }

    getEGiftDetails(): Observable<EGiftCardDetailsResponseDto> {
        return this.currencyLanguageService.getLocaleData().pipe(
            switchMap((localeData) => {
                return this.httpClient.get<EGiftCardDetailsResponseDto>(
                    `${getResultsUrl()}gift-cards?lang=${
                        localeData.language
                    }&cc=${localeData.country}`
                );
            })
        );
    }

    uploadGiftCardImage(fd: FormData) {
        // this has nothing until the backend is implemented
    }

    addToCartV2(productData: EGiftAddToCartDto): Observable<CartDto> {
        const apiUrl = this.userService.isLoggedIn
            ? `${getBookingUrl()}carts/items`
            : `${getBookingUrl()}carts/items/as-guest`;
        const localCart = this.userService.getLocalCart();
        // if there is a user cart use that, else use the local cart
        const cartId = localCart?.userCartId || localCart?.localCartId;

        const postBody: AddCartItemDto = {
            cartId,
            outletId: 2,
            dealId: 2098, // Deal ID for eGift Card
            dealCategoryId: 2, // Deal Category ID for eGift Card
            sectionId: productData.sectionId,
            dealTitle: productData.giftcard_template_image,
            dealSubtitle: '',
            quantity: productData.qty,
            subtotal: productData.price_amount,
            purchaseCurrencyId: 1,
            grandTotal: productData.amount,
            dealInfo: this.createEGCDealInfo(productData),
        };

        return this.authHttpClient.post<CartDto, AddCartItemDto>(
            apiUrl,
            postBody
        );
    }

    private createEGCDealInfo(productData: EGiftAddToCartDto): string {
        const dealInfo: EGiftCardDealInfo = {
            templateId: productData.giftcard_template_id,
            currencyId: 1,
            amount: productData.amount,
            sendDate: productData.day_to_send,
            sendNotice: productData.notify_success,
            expiryDate: productData.expiry_date,
            buyerName: productData.customer_name,
            specialMessage: productData.message,
            recipientName: productData.recipient_name,
            recipientEmail: productData.recipient_email,
            packageTypeId: 4,
            dealOptionId: 4,
        };

        return JSON.stringify(dealInfo);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAuthUrl } from '../utilities/url.utils';
import { AuthHttpClientService } from '../services/auth-http-client.service';
import { CurrencyLanguageService } from '../shared/currency-language.service';

interface ForgottenPasswordProps {
    email: string;
    countryCode: string;
    language: 'en' | 'fr';
}
@Injectable({
    providedIn: 'root',
})
export class ForgetPasswordService {
    constructor(
        private httpClient: HttpClient,
        private authHttpService: AuthHttpClientService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    forgotPassword(email: string) {
        const { country, language } =
            this.currencyLanguageService.getLocaleDataImmediate();
        return this.authHttpService.postNotLoggedIn<
            void,
            ForgottenPasswordProps
        >(`${getAuthUrl()}forgot-password`, {
            email,
            countryCode: country,
            language,
        });
    }
}

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { PackageTypeId } from '../model/deal.model';

export interface EGiftCardDealInfo {
    templateId: number;
    currencyId: number;
    amount: number;
    sendDate: string | dayjs.Dayjs;
    sendNotice: boolean;
    expiryDate: string | dayjs.Dayjs;
    buyerName: string;
    specialMessage: string;
    recipientName: string;
    recipientEmail: string;
    packageTypeId: PackageTypeId.NON_CALENDAR; // eGift Card
    dealOptionId: 4; // eGift Card
}
export interface EGiftCardFormData {
    value: number;
    recipientName: string;
    deliveryToEmail: string;
    deliveryFromName: string;
    deliveryMessage: string;
    quantity: number;
    deliveryDate: NgbDateStruct;
}

export interface GiftCardImage extends GiftCardValidation {
    name: string;
    path: string;
    thumb: string;
}

export interface EGiftCardTemplate {
    id: number;
    name: string;
    position: number;
    images: GiftCardImage[];
}

export interface GiftCardValidation {
    maxMessageLength: number;
    giftCardAmount: { min: number; max: number };
}

export interface EGiftCardDetailsResponse {
    gitftcardTemplates: EGiftCardTemplate[];
    // maxImageUploadSizeKb: number;
    // id: string;
}

export interface EGiftCardTemplateDto {
    giftcard_template_id: number;
    images: {
        img_name: string;
        img_path: string;
    }[];
    position: number;
    template_name: string;
}

export interface EGiftCardDetailsResponseDto {
    [key: string]: {
        name: string;
        giftCards: {
            id: number;
            name: string;
            imageId: number;
            imageUrl: string;
            imageAltText: string;
            imagePosition: number;
            minAmount: number;
            maxAmount: number;
            currencyCode: 'MUR' | 'EUR';
        }[];
    };
}

export interface EGiftAddToCartDto {
    price_amount: number;
    amount: number;
    sectionId: number;
    giftcard_template_id: number;
    giftcard_template_image: string;
    giftcard_use_custom_image: boolean;
    send_friend: boolean;
    customer_name: string;
    recipient_name: string;
    recipient_email: string;
    reward_points_eanred: number;
    message: string;
    notify_success: boolean;
    day_to_send: string; // mm-dd-yyyy
    expiry_date: string; // mm-dd-yyyy
    timezone_to_send: 'Indian/Mauritius';
    qty: number;
}

// TODO: I think this is actually just a generic response object but will have to figure out as the refactoring progresses
export interface AddEGiftCardToCartResponseDto {
    status: 'success' | 'error';
    msg: string;
    quoteid: string;
    cart_items_qty: number;
}

export interface UploadGcImageDto {
    updated_filename: string;
    url: string;
}

export const transform = (
    dao: EGiftCardDetailsResponseDto
): EGiftCardDetailsResponse => {
    return {
        gitftcardTemplates: Object.values(dao)
            .slice(1) // the first item is some sort of timestamp
            .map((section, index) => {
                const firstGiftCard = section.giftCards[0];
                if (!firstGiftCard) {
                    return undefined;
                }

                return {
                    id: firstGiftCard.id,
                    name: section.name,
                    position: index,
                    images: section.giftCards.map((giftCard) => ({
                        name: giftCard.imageAltText,
                        path: giftCard.imageUrl,
                        thumb: giftCard.imageUrl,
                        maxMessageLength: 200,
                        giftCardAmount: {
                            min: giftCard.minAmount,
                            max: giftCard.maxAmount,
                        },
                    })),
                };
            }),
    };
};

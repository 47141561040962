import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '../../config/locale';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { getCache, removeCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-order-review',
    templateUrl: './order-review.component.html',
    styleUrls: ['./order-review.component.scss'],
})
// TODO: this may not be used by anything anymore
export class OrderReviewComponent implements OnInit {
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    localeTxt: any;
    orderdata: any;
    ipaytext = '';
    mipstext = this.translate.instant(
        'Payment Successful!\nBooking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal'
    );
    paymentStatus: any;
    orderReviewSkeleton: boolean = false;
    isUserLoggedIn: boolean = false;
    payment_data: any;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private localeObj: Locale,
        private currencyLanguageService: CurrencyLanguageService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.localeTxt = this.localeObj.getTextMessages();
        this.currencyLanguageService.getCurrency().subscribe((symbol) => {
            this.currencySymbol = symbol;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            this.isUserLoggedIn = isLoggedIn;
        });
        this.orderdata = JSON.parse(getCache('orderdata').data as string);
        if (this.orderdata.address == '' || this.orderdata.address == ' ') {
            this.orderdata.address = '-';
        }
        if (this.orderdata.contactno == '' || this.orderdata.contactno == ' ') {
            this.orderdata.contactno = '-';
        }
        this.paymentStatus = 'success';
        if (this.orderdata.payment_method == 'ipay') {
            this.ipaytext = this.translate.instant(
                'Payment Successful!\nBooking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal'
            );
            if (
                this.orderdata.orderStatus == 2 ||
                this.orderdata.orderStatus == '2'
            ) {
            } else {
                this.paymentStatus = 'success';
                this.paymentStatus = 'failure';
            }
        }

        if (this.orderdata.payment_method == 'paypal_standard') {
            this.payment_data = JSON.parse(
                localStorage.getItem('payment_data')
            );
        }
        removeCache('cartItemsdataCount');
    }

    continueShoping() {
        removeCache('orderdata');
        this.router.navigate(['/']);
    }
}

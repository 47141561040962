import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CustomersAlsoViewedService {
    // getCustomersAlsoViewed(
    //     productId: number
    // ): Observable<CustomerAlsoViewedResponseDto> {
    //     const apiUrl = getApiUrlMofluid();
    //     const customerId = getCustomerId();
    //     const customerGroupId = this.common.getCustomerGroupID();
    //     const storeId = this.common.getStoreID();
    //     const url = `${apiUrl}getCustomerAlsoViewed?service=getCustomerAlsoViewed&productid=${productId}&customerid=${customerId}&store=${storeId}&customer_group_id=${customerGroupId}`;
    //     return this.httpClient.get<CustomerAlsoViewedResponseDto>(url, {
    //         headers: this.common.getTokenHeaders(),
    //     });
    // }
}

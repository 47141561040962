import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, switchMap, throwError } from 'rxjs';
import { sortBy } from 'lodash';

import { getApiUrlV2 } from '../utilities/url.utils';
import { getTomorrow, validateISODateYYYYMMDD } from '../controls/calendar/calendar.utils';
import { CurrencyLanguageService } from '../shared/currency-language.service';
import { DealDto, PackageTypeId } from '../model/deal.model';
import {
    AgeGroup,
    AgeGroupResponseDto,
    InventoryDto,
    InventoryResponseDto,
    DealOffer,
    DealsResponseDto,
    AccomOptionDto
} from '../model/deal.model';

@Injectable({
    providedIn: 'root',
})
export class ProductService {

    private packageTypeIdToDealOffersEndpointMap: Record<number, string> = {
        [PackageTypeId.STAY]: null,
        [PackageTypeId.ROOM_USE]: 'calendar',
        [PackageTypeId.CALENDAR]: 'calendar',
        [PackageTypeId.NON_CALENDAR]: 'non-calendar'
    };

    constructor(
        private httpClient: HttpClient,
        private currencyLanguageService: CurrencyLanguageService
    ) { }

    get locale() {
        return this.currencyLanguageService.getLocaleDataImmediate();
    }

    public getProductDetails(
        dealId: number,
        productUrl: string,
        fromDate: string,
        toDate: string
    ): Observable<[
        DealDto,
        { dealOffers: DealOffer[], calendarInventory: InventoryDto[] },
        { accomOptions: AccomOptionDto[], accomInventory: InventoryDto[] },
        AgeGroup[]
    ]
    > {
        return this.fetchProductDetailsDto(dealId)
            .pipe(
                switchMap((productDetails) => {
                    const [deal] = productDetails.deals;

                    if (!deal)
                        return throwError(() => new Error('DEAL_NOT_FOUND: ' + dealId));

                    const isFromDateValid = validateISODateYYYYMMDD(fromDate)

                    if (!isFromDateValid)
                        fromDate = getTomorrow();

                    return forkJoin([
                        of(deal),
                        this.fetchDealOffers(deal, fromDate),
                        this.fetchAccomOptions(deal, fromDate),
                        this.fetchAgeGroups(deal),
                    ]);
                })
            )
    }

    private fetchProductDetailsDto(
        dealId: number,
    ) {
        const params = new HttpParams()
            .set('cc', this.locale.country)
            .set('lang', this.locale.language)
            .set('pid', dealId);

        return this.httpClient.get<DealsResponseDto>(
            `${getApiUrlV2()}results/deals/pdp`,
            { params }
        )
    }

    private fetchDealOffers(
        deal: DealDto,
        checkIn: string = getTomorrow()
    ): Observable<{ dealOffers: DealOffer[], calendarInventory: InventoryDto[] }> {
        const endpoint = this.packageTypeIdToDealOffersEndpointMap[deal.packageTypeId];

        if (!endpoint)
            return of({ dealOffers: [], calendarInventory: [], });

        const params = new HttpParams()
            .set('countryCode', this.locale.country)
            .set('language', this.locale.language)
            .set('checkIn', checkIn)
            .set('dealId', deal.dealId);

        return this.httpClient
            .get<{ dealOffers: DealOffer[] }>(
                `${getApiUrlV2()}availability/deals/pdp/${endpoint}/options`,
                { params }
            )
            .pipe(
                catchError(() => of({ dealOffers: [] })),
                switchMap(res => this.fetchCalendarInventory(deal, res.dealOffers))
            )
    }

    public fetchAccomOptions(
        deal: DealDto,
        checkIn: string = getTomorrow(),
        occupancyId = 1332
    ): Observable<{ accomOptions: AccomOptionDto[], accomInventory: InventoryDto[] }> {
        if (deal.packageTypeId !== PackageTypeId.STAY)
            return of({ accomOptions: [], accomInventory: [] });

        const params = new HttpParams()
            .set('orgId', 1)
            .set('countryCode', this.locale.country)
            .set('language', this.locale.language)
            .set('customerGroupId', this.locale.customerGroupId)
            .set('packageTypeId', deal.packageTypeId)
            .set('outletId', deal.outletId)
            .set('dealId', deal.dealId)
            .set('checkIn', checkIn)
            .set('occupancyId', occupancyId)

        return this.httpClient
            .get<{ options: AccomOptionDto[] }>(
                `${getApiUrlV2()}availability/deals/pdp/accommodation/options`,
                { params }
            )
            .pipe(
                map(({ options }) => ({ options: sortBy(options, 'sellingPrice') })),
                catchError(() => of({ options: [] })),
                switchMap(res => this.fetchAccomInventory(deal, occupancyId, res.options))
            )
    }

    public fetchCalendarInventory(deal: DealDto, dealOffers: DealOffer[]) {
        if ([PackageTypeId.STAY, PackageTypeId.NON_CALENDAR].includes(deal.packageTypeId))
            return of({ dealOffers, calendarInventory: [] });

        const params = new HttpParams()
            .set('orgId', 1)
            .set('countryCode', this.locale.country)
            .set('customerGroupId', this.locale.customerGroupId)
            .set('packageTypeId', deal.packageTypeId)
            .set('dealOptionIds', this.getDealOptionIdsFromDealOffers(dealOffers).join());

        return this.httpClient
            .get<InventoryResponseDto>(`${getApiUrlV2()}availability/deals/inventory/pdp/calendar`, { params })
            .pipe(
                map(this.processCommonInventory),
                map(calendarInventory => ({ dealOffers, calendarInventory })),
                catchError(() => of({ dealOffers, calendarInventory: [] })),
            );
    }

    private getDealOptionIdsFromDealOffers(dealOffers: DealOffer[]) {
        return dealOffers
            .flatMap(dealOffer => dealOffer.dealOptions)
            .map(dealOption => dealOption.dealOptionId);
    }

    public fetchAccomInventory(
        deal: DealDto,
        occupancyId: number,
        accomOptions: AccomOptionDto[],
        selectedAccomOption: AccomOptionDto = accomOptions[0],
    ) {
        if (deal.packageTypeId !== PackageTypeId.STAY)
            return of({ accomOptions, accomInventory: [] });

        if (!selectedAccomOption)
            return of({ accomOptions, accomInventory: [] });

        const params = new HttpParams()
            .set('orgId', 1)
            .set('countryCode', this.locale.country)
            .set('customerGroupId', this.locale.customerGroupId)
            .set('packageTypeId', deal.packageTypeId)
            .set('outletId', deal.outletId)
            .set('mealPlanId', selectedAccomOption.mealPlanId)
            .set('roomCrossedPercent', selectedAccomOption.crossedPercent)
            .set('occupancyId', occupancyId)
            .set('roomId', selectedAccomOption.roomId);

        return this.httpClient
            .get<InventoryResponseDto>(`${getApiUrlV2()}availability/deals/inventory/pdp/accommodation`, { params })
            .pipe(
                map(this.processCommonInventory),
                map(accomInventory => ({ accomOptions, accomInventory })),
                catchError(() => of({ accomOptions, accomInventory: [] })),
            );
    }

    private processCommonInventory(res: InventoryResponseDto): InventoryDto[] {
        const tomorrow = getTomorrow();

        return res.inventory.filter(day => day.date >= tomorrow);
    }

    private fetchAgeGroups(deal: DealDto): Observable<AgeGroup[]> {
        if (deal.packageTypeId !== PackageTypeId.STAY)
            return of(null);

        const params = new HttpParams()
            .set('orgId', 1)
            .set('customerGroupId', this.locale.customerGroupId)
            .set('outletId', deal.outletId);

        return this.httpClient
            .get<AgeGroupResponseDto>(`${getApiUrlV2()}availability/occupancies/age-groups`, { params })
            .pipe(
                catchError(() => of({ ageGroups: [] })),
                map(res => res.ageGroups),
                map(this.processAgeGroups)
            )
    }

    private processAgeGroups(ageGroups: AgeGroup[]): AgeGroup[] {
        return ageGroups
            .filter(ageGroup => ageGroup.ageGroupType !== 'Any')
            .map(ageGroup => {
                if (ageGroup.ageGroupType === 'Adult') {
                    ageGroup.startValue = ageGroup.qty = 2;
                }
                return ageGroup;
            })

    }
}

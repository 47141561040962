import { Component, OnInit, ViewChild } from '@angular/core';
import { CheckoutUtilsService } from './checkout.utils.service';
import {
    CheckoutCartItem,
    CheckoutData,
    CheckoutGuest,
    PaymentOption,
    PaymentSuccessData as OrderSuccessData,
    OrderStatusDto,
    CheckoutState,
} from './checkout.model';
import { Router } from '@angular/router';
import { CheckoutService } from './checkout.service';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalOffcanvasComponent } from 'src/app/controls/modal-offcanvas/modal-offcanvas.component';
import { isFirefox, isSafari } from 'src/app/utilities/utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
    CurrencyLanguageService,
    LocaleData,
} from 'src/app/shared/currency-language.service';
import { catchError, map, Observable, of } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'md-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
    showCartSkeleton = true;
    mipsIframeLoading = false;
    mipsIframeUrl: SafeResourceUrl = '';
    showPaymentMethods = false;
    placedOrderNumber: number;
    storeCreditBalance: number;
    rewardPointsBalance: number;
    areRewardPointsApplied: boolean;

    discountCode: string;
    discountCodeTitle: string = 'Discount code';
    isDiscountCodeApplied = false;
    paymentList: PaymentOption[] = [
        {
            payment_method_id: 9,
            payment_method_code: 'mipspaymentsystemiframe',
            payment_method_title: 'Card, POP, MyT or Blink',
        },
        {
            payment_method_id: 5,
            payment_method_code: 'banktransfer',
            payment_method_title: 'Internet Banking OR Juice',
        },
        {
            payment_method_id: 1,
            payment_method_code: 'cashondelivery',
            payment_method_title: 'Direct Bank Deposit',
        },
    ];

    guests: CheckoutGuest[][];
    checkoutData: CheckoutData;

    checkout: CheckoutState = {};

    giftcardbalance: number = 0;

    isSafari: boolean = isSafari();
    isFirefox: boolean = isFirefox();
    localeData: LocaleData;

    @ViewChild('paymentModal') paymentModal: ModalOffcanvasComponent;

    constructor(
        public checkoutUtilsService: CheckoutUtilsService,
        private router: Router,
        private checkoutService: CheckoutService,
        private ngxSpinnerService: NgxSpinnerService,
        private sanitizer: DomSanitizer,
        private currencyLanguageService: CurrencyLanguageService,
        private userService: UserService,
        private generalErrorService: GeneralErrorService
    ) {
        this.removeCartItem = this.removeCartItem.bind(this);
        this.changeQuantity = this.changeQuantity.bind(this);
    }

    ngOnInit(): void {
        this.showCartSkeleton = true;
        window.addEventListener(
            'message',
            this.handleIframeMessage.bind(this),
            false
        );
        this.setSubscriptions();

        this.checkoutService.getCartItems().subscribe({
            next: (checkoutData) => {
                this.showCartSkeleton = false;
                if (!checkoutData) {
                    // this means there's no cart yet
                    return;
                }

                this.checkoutData = checkoutData;
                this.populateGuestArray(checkoutData);
                this.setDiscounts(checkoutData);
            },
            error: (error) => {
                this.showCartSkeleton = false;
                this.generalErrorService.showGeneralError(
                    error?.error?.message,
                    { showMailto: false }
                );
            },
        });
    }

    private setSubscriptions() {
        this.currencyLanguageService.getLocaleData().subscribe((localeData) => {
            this.localeData = localeData;
        });
        this.checkoutService.getStoreCreditAmount().subscribe((response) => {
            this.storeCreditBalance = response;
        });
        this.checkoutService.getRewardPointsBalance().subscribe((response) => {
            this.rewardPointsBalance = response;
        });
    }

    private setDiscounts(checkoutData: CheckoutData) {
        if (checkoutData.totalDiscount > 0) {
            this.isDiscountCodeApplied = checkoutData.totalDiscount > 0;
            this.discountCode = checkoutData.discountCode;
        }

        if (this.checkoutData.rewardPointsSpent > 0) {
            this.areRewardPointsApplied = true;
            this.checkout.rewardPointsToApply = checkoutData.rewardPointsSpent;
        }
        if (this.checkoutData.storeCreditAmount > 0) {
            this.checkout.storeCreditToApply =
                this.checkoutData.storeCreditAmount;
        }
    }

    private populateGuestArray(checkoutData: CheckoutData) {
        this.guests = checkoutData.cartItems.map((item, index) => {
            return this.createGuestListForItem(item, index);
        });
    }

    private updateGuestsArray(checkoutData: CheckoutData) {
        if (!this.guests) {
            return;
        }
        const newGuests = checkoutData.cartItems.flatMap((item, index) => {
            return this.createGuestListForItem(item, index);
        });

        const groupedGuests: CheckoutGuest[][] = Object.values(
            _.groupBy(newGuests, 'item')
        );
        this.guests = groupedGuests;
    }

    private createGuestListForItem(item: CheckoutCartItem, index: number) {
        const { userName, email } = this.userService.getUserDataImmediate();
        return _.times(item.qty, (qtyIdx) => {
            return {
                name: userName,
                email: email,
                categoryproductId: item.cat_id.toString(),
                dealId: item.dealId,
                product_name: item.name,
                guest_id: `${item.id}-${index}-${qtyIdx}`,
                room_count: item.qty,
                child_range: [],
                isForGiftCard: item.isGiftCard,
            };
        }).filter((guest) => !guest.isForGiftCard);
    }

    private handleIframeMessage(toast: { origin: string; data: string }) {
        if (
            toast.origin === 'https://go2.mips.mu' &&
            toast.data === 'processingOrder'
        ) {
            this.placeOrder().subscribe(() => {
                this.closePaymentModalIfProdEnvionment();
                this.ngxSpinnerService.show();
                this.userService.deleteLocalCart();
                this.checkoutService
                    .retryCheckMipsPaymentStatus(this.placedOrderNumber)
                    .subscribe({
                        next: (response) => {
                            this.ngxSpinnerService.hide();
                            if (response) {
                                const paymentSuccessData =
                                    this.constructSuccessQueryParams(response);
                                this.router.navigate(
                                    ['checkoutpaymentSuccess'],
                                    {
                                        queryParams: paymentSuccessData,
                                    }
                                );
                            } else {
                                this.ngxSpinnerService.hide();
                                this.router.navigate([
                                    'checkoutpaymentFailure',
                                ]);
                            }
                        },
                        error: () => {
                            this.ngxSpinnerService.hide();
                            this.router.navigate(['checkoutpaymentFailure']);
                        },
                    });
            });
        }
        // TODO: not sure this will be used
        // else if (toast.origin === 'https://go2.mips.mu') {
        //     this.paymentModal.close();
        //     this.router.navigate(['checkoutpaymentFailure']);
        // }
    }

    // On dev there is another step from mofluid that requires the tester to confirm the payment has
    // been processed so we leave it open to be closed manually
    private closePaymentModalIfProdEnvionment() {
        if (environment.production) {
            this.paymentModal.close();
        }
    }

    private constructSuccessQueryParams(
        orderStatusDto: OrderStatusDto
    ): OrderSuccessData {
        const { orderId } = this.checkoutData;
        return {
            orderId,
            orderNumber: this.placedOrderNumber.toString(),
            orderStatus: orderStatusDto.status,
            amount: orderStatusDto.grandTotal,
            name: `${orderStatusDto.customerFirstName} ${orderStatusDto.customerLastName}`,
            email: orderStatusDto.customerEmail,
        };
    }

    removeCartItem(item: CheckoutCartItem) {
        this.showCartSkeleton = true;
        this.checkoutService
            .removeItemFromCart(item)
            .subscribe((checkoutData) => {
                this.handleChangeQuantity(checkoutData);
            });
    }

    changeQuantity(item: CheckoutCartItem) {
        this.showCartSkeleton = true;
        this.checkoutService.updateCart(item).subscribe((checkoutData) => {
            this.handleChangeQuantity(checkoutData);
        });
    }

    private handleChangeQuantity(checkoutData: CheckoutData) {
        this.showCartSkeleton = false;
        const suceeded = this.checkAndSetCheckoutData(checkoutData);
        if (!suceeded) {
            return;
        }
        this.updateLocalItemsCount();
        this.updateGuestsArray(checkoutData);
    }

    private updateLocalItemsCount() {
        const localCart = this.userService.getLocalCart();
        localCart.itemCount = this.checkoutData.itemsCount;
        this.userService.setLocalCart(localCart);
    }

    applyDiscountCode() {
        if (!this.isDiscountCodeApplied) {
            this.checkoutService
                .applyDiscountCode(this.discountCode)
                .subscribe((checkoutData) => {
                    const succeeded =
                        this.checkAndSetCheckoutData(checkoutData);
                    if (!succeeded) {
                        return;
                    }
                    this.isDiscountCodeApplied = !this.isDiscountCodeApplied;
                });
        } else {
            this.checkoutService
                .removeDiscountCode(this.discountCode)
                .subscribe((checkoutData) => {
                    const succeeded =
                        this.checkAndSetCheckoutData(checkoutData);
                    if (!succeeded) {
                        return;
                    }
                    this.isDiscountCodeApplied = !this.isDiscountCodeApplied;
                });
        }
    }

    private checkAndSetCheckoutData(checkoutData: CheckoutData): boolean {
        if (!checkoutData) {
            return false;
        }

        this.checkoutData = checkoutData;
        return true;
    }

    selectPaymentMethod(paymentOption: PaymentOption) {
        this.checkout.selectedPaymentMethodId = paymentOption.payment_method_id;
        if (paymentOption.payment_method_code === 'mipspaymentsystemiframe') {
            this.mipsIframeLoading = true;
            const { orderId, orderTotal } = this.checkoutData;

            this.checkoutService
                .getMipsIframeUrl(orderId, orderTotal)
                .subscribe((url) => {
                    this.mipsIframeLoading = false;
                    if (!url) {
                        return;
                    }
                    const safeUrl =
                        this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.mipsIframeUrl = safeUrl;
                });
        }
    }

    applyGiftCard() {
        const code = this.checkout.giftCode;
        this.checkoutService.applyGiftCode(code).subscribe((checkoutData) => {
            this.checkAndSetCheckoutData(checkoutData);
        });
    }

    removeGiftCardDiscount(code: string) {
        this.checkoutService.removeGiftCode(code).subscribe((checkoutData) => {
            this.checkAndSetCheckoutData(checkoutData);
        });
    }

    applyRewardPoints() {
        if (this.areRewardPointsApplied) {
            this.checkoutService
                .removeRewardPoints(this.checkoutData.rewardPointsSpent)
                .subscribe((checkoutData) => {
                    const succeeded =
                        this.checkAndSetCheckoutData(checkoutData);
                    if (!succeeded) {
                        return;
                    }
                    this.areRewardPointsApplied = false;
                });
        } else {
            const pointsToApply = this.checkout.rewardPointsToApply;
            this.checkoutService
                .applyRewardPoints(pointsToApply)
                .subscribe((checkoutData) => {
                    const succeded = this.checkAndSetCheckoutData(checkoutData);
                    if (!succeded) {
                        return;
                    }
                    this.areRewardPointsApplied = true;
                });
        }
    }

    placeOrderNoPayment() {
        this.placeOrder()
            .pipe(
                map((response) => {
                    const paymentSuccessData: OrderSuccessData = {
                        orderId: response.id,
                        orderNumber: this.placedOrderNumber.toString(),
                        orderStatus: response.status,
                        amount: response.grandTotal,
                        name: `${response.customerFirstName} ${response.customerLastName}`,
                        email: response.customerEmail,
                    };
                    this.userService.deleteLocalCart();
                    this.paymentModal.close();
                    this.router.navigate(['checkoutpaymentSuccess'], {
                        queryParams: paymentSuccessData,
                    });
                }),
                catchError(() => {
                    this.ngxSpinnerService.hide();
                    return of(null);
                })
            )
            .subscribe();
    }

    private placeOrder(): Observable<OrderStatusDto> {
        this.ngxSpinnerService.show();
        const flatGuests = this.guests.flat();
        return this.checkoutService
            .placeOrder(
                this.checkout.selectedPaymentMethodId,
                flatGuests,
                this.checkoutData
            )
            .pipe(
                map((response) => {
                    this.ngxSpinnerService.hide();
                    if (!response) {
                        return null;
                    }
                    this.placedOrderNumber = response.number;
                    return response;
                }),
                catchError((error) => {
                    this.ngxSpinnerService.hide();
                    return of(null);
                })
            );
    }

    getImageSourceBasedOnCode(code: string): string {
        switch (code) {
            case 'mipspaymentsystemiframe':
                return 'assets/icons/checkout/payment/juice_blink.svg';
            case 'banktransfer':
                return 'assets/icons/checkout/payment/credit_card.svg';
            default:
                return 'assets/icons/checkout/payment/pay_later.svg';
        }
    }

    openModal(content: ModalOffcanvasComponent) {
        this.checkoutService
            .checkAvailability()
            .subscribe((everythingAvailable: boolean) => {
                if (everythingAvailable) {
                    content.open();
                }
            });
    }

    goToGiftCardManagement() {
        this.router.navigate(['/addgift-voucher']);
    }

    applyStoreCredit() {
        if (this.checkoutData.storeCreditAmount === 0) {
            this.checkoutService
                .applyStoreCredit(this.checkout.storeCreditToApply)
                .subscribe((checkoutData) => {
                    this.checkAndSetCheckoutData(checkoutData);
                });
        } else {
            this.checkoutService
                .removeStoreCredit(this.checkoutData.storeCreditAmount)
                .subscribe((checkoutData) => {
                    this.checkAndSetCheckoutData(checkoutData);
                });
        }
    }
}

<div class="row main-div">
    <div class="fp-parent-div forgot-password card">
        <form
            [formGroup]="forgotPasswordForm"
            (ngSubmit)="onSubmitForgotPassForm()"
        >
            <div class="form-group">
                <p class="emailPara">
                    {{
                        "Please enter your email address below. You will receive a link to reset your password"
                            | translate
                    }}
                </p>
            </div>
            <div class="form-group">
                <label
                    for="email"
                    [ngClass]="
                        forgotPasswordForm.value.email.length > 0
                            ? 'has-input'
                            : ''
                    "
                    >{{ "Email" | translate }}</label
                >
                <input
                    type="email"
                    name=""
                    id=""
                    formControlName="email"
                    [placeholder]="'Email Address' | translate"
                />
            </div>
            <div class="form-group button">
                <button class="ripleEffect" type="submit">
                    {{ "Submit" | translate }}
                </button>
            </div>
        </form>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { GiftCardTransactionHistory } from '../gifts.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'md-gift-card-balance',
    templateUrl: './gift-card-balance.component.html',
    styleUrls: ['../../rewards-and-wallet.component.scss'],
})
export class GiftCardBalanceComponent implements OnInit {
    isShowDummyGiftCardBalance: boolean = false;
    giftCardItem: any = [];

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    @Input() giftCardTransactionHistory: GiftCardTransactionHistory[];

    constructor(private currencyLanguageService: CurrencyLanguageService) {}

    ngOnInit(): void {
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    getMinus(transaction: GiftCardTransactionHistory) {
        const sign = transaction.action === 'Used' ? '-' : '+';
        return sign;
    }

    getGiftCodeDate(date: string) {
        const orderDate = dayjs(date);
        return orderDate.format('DD MMM YYYY');
    }

    protected readonly ProfileSection = ProfileSection;
}

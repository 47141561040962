import { Injectable } from '@angular/core';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    constructor(private authhttpClient: AuthHttpClientNoXService) {}

    getCompanyProfile() {
        return this.authhttpClient.get('no url');
    }

    submitCompanyProfile(data: any) {
        return this.authhttpClient.post('no url', data);
    }
}

import { Directive, EventEmitter, OnInit, Output } from '@angular/core';

// TODO: Seems unused
@Directive({
    selector: '[appMyInit]',
})
export class MyInitDirective implements OnInit {
    @Output()
    appMyInit: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {
        console.log('ngInit');
        this.appMyInit.emit();
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    OccupancyOption,
    transformOccupancyOptions,
} from './search-occupancy.model';

@Injectable({
    providedIn: 'root',
})
export class SearchOccupancyService {
    getOccupancyOptions(): Observable<OccupancyOption[]> {
        return new Observable<OccupancyOption[]>((observer) => {
            observer.next(
                transformOccupancyOptions(this.getOccupancyOptionsStatic())
            );
            observer.complete();
        });
    }

    private getOccupancyOptionsStatic() {
        return {
            data: [
                {
                    value: '45',
                    label: '1 Adult',
                },
                {
                    value: '4269',
                    label: '1 Adult + 1 Child (0-11)',
                },
                {
                    value: '4599',
                    label: '1 Adult + 2 Children (0-11)',
                },
                {
                    value: '4608',
                    label: '1 Adult + 1 Child (0-11) + 1 Teen (12-17)',
                },
                {
                    value: '4270',
                    label: '1 Adult + 1 Teen (12-17)',
                },
                {
                    value: '4600',
                    label: '1 Adult + 2 Teens (12-17)',
                },
                {
                    value: '44',
                    label: '2 Adults',
                },
                {
                    value: '5225',
                    label: '2 Adults + 1 Infant (0-2)',
                },
                {
                    value: '117',
                    label: '2 Adults + 1 Child (0-11)',
                },
                {
                    value: '2790',
                    label: '2 Adults + 2 Children (0-11)',
                },
                {
                    value: '122',
                    label: '2 Adults + 3 Children (0-11)',
                },
                {
                    value: '5312',
                    label: '2 Adults + 4 Children (0-11)',
                },
                {
                    value: '43',
                    label: '2 Adults + 1 Teen (12-17)',
                },
                {
                    value: '3128',
                    label: '2 Adults + 2 Teens (12-17)',
                },
                {
                    value: '4268',
                    label: '2 Adults + 3 Teens (12-17)',
                },
                {
                    value: '3129',
                    label: '2 Adults + 1 Child (0-11) + 1 Teen (12-17)',
                },
                {
                    value: '3265',
                    label: '2 Adults + 1 Child (0-11) + 2 Teens (12-17)',
                },
                {
                    value: '3266',
                    label: '2 Adults + 2 Children (0-11) + 1 Teen (12-17)',
                },
                {
                    value: '5313',
                    label: '2 Adults + 2 Children (0-11) + 2 Teens (12-17)',
                },
                {
                    value: '4034',
                    label: '2 Teens (16-17)',
                },
                {
                    value: '41',
                    label: '3 Adults',
                },
                {
                    value: '3130',
                    label: '3 Adults + 1 Child (0-11)',
                },
                {
                    value: '4590',
                    label: '3 Adults + 2 Children (0-11)',
                },
                {
                    value: '4655',
                    label: '3 Adults + 1 Teen (12-17)',
                },
                {
                    value: '3131',
                    label: '4 Adults',
                },
                {
                    value: '4146',
                    label: '4 Adults + 1 Child (0-11)',
                },
                {
                    value: '4147',
                    label: '4 Adults + 2 Children (0-11)',
                },
                {
                    value: '4595',
                    label: '4 Adults + 3 Children (0-11)',
                },
                {
                    value: '4596',
                    label: '4 Adults + 4 Children (0-11)',
                },
                {
                    value: '4145',
                    label: '5 Adults',
                },
                {
                    value: '4306',
                    label: '5 Adults + 1 Child (0-11)',
                },
                {
                    value: '4636',
                    label: '5 Adults + 2 Children (0-11)',
                },
                {
                    value: '4208',
                    label: '6 Adults',
                },
                {
                    value: '4637',
                    label: '6 Adults + 1 Child (0-11)',
                },
                {
                    value: '4307',
                    label: '6 Adults + 2 Children (0-11)',
                },
                {
                    value: '4594',
                    label: '7 Adults',
                },
                {
                    value: '4666',
                    label: '7 Adults + 2 Children (0-11)',
                },
                {
                    value: '4214',
                    label: '8 Adults',
                },
                {
                    value: '4667',
                    label: '8 Adults + 2 Children (0-11)',
                },
                {
                    value: '4668',
                    label: '9 Adults',
                },
                {
                    value: '4669',
                    label: '10 Adults',
                },
                {
                    value: '5123',
                    label: 'Up to 2 Pax',
                },
                {
                    value: '5124',
                    label: 'Up to 4 Pax',
                },
                {
                    value: '5125',
                    label: 'Up to 6 Pax',
                },
                {
                    value: '5126',
                    label: 'Up to 8 Pax',
                },
                {
                    value: '5127',
                    label: 'Up to 10 Pax',
                },
                {
                    value: '4204',
                    label: 'Up to 20 Pax',
                },
                {
                    value: '4659',
                    label: '2 Adults + 1 Child (3-10) FREE',
                },
                {
                    value: '3127',
                    label: 'Stay Deals',
                },
                {
                    value: '5239',
                    label: 'Up to 8 Adults + 4 Children (0-11)',
                },
                {
                    value: '5294',
                    label: 'Up to 3 Pax',
                },
                {
                    value: '5295',
                    label: 'Up to 7 Pax',
                },
                {
                    value: '5364',
                    label: '1 Teen (12-17)',
                },
                {
                    value: '5365',
                    label: '1 Teen (12-17) + 1 Child (3-11)',
                },
            ],
        };
    }
}

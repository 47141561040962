import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../shared/common.service';
import {
    countryCodeToFullCountryNameMap,
    languageIdToLanguageCodeMap,
} from '../utilities/location.utils';

import { UserService } from '../services/user.service';
import { CurrencyLanguageService } from '../shared/currency-language.service';

interface LanguageDropdownOption {
    name: 'English' | 'French';
    code: 'en' | 'fr';
}

interface CurrencyDropdownOption {
    name: 'Mauritius' | 'Reunion' | 'Other';
    code: 'MU' | 'RE' | 'OT';
}

@Component({
    selector: 'md-language-modal-popup',
    templateUrl: './language-modal-popup.component.html',
    styleUrls: ['./language-modal-popup.component.scss'],
})
export class LanguageModalPopupComponent implements OnInit {
    @Input() isCountryVisible = false;
    @Input() isDark = false;

    country: 'MU' | 'RE' | 'OT' = 'MU';
    language: 'en' | 'fr' = 'en';

    languages: LanguageDropdownOption[] = [
        { name: 'English', code: 'en' },
        { name: 'French', code: 'fr' },
    ];
    countries: CurrencyDropdownOption[] = [
        { name: 'Mauritius', code: 'MU' },
        { name: 'Reunion', code: 'RE' },
        { name: 'Other', code: 'OT' },
    ];
    countryPickerHidden = false;

    constructor(
        public router: Router,
        public common: CommonService,
        private modalService: NgbModal,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    ngOnInit() {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.countryPickerHidden = isLoggedIn;
        });
        this.currencyLanguageService.getLocaleData().subscribe((data) => {
            this.country = data.country;
            this.language = data.language;
        });
    }

    openModal(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'sm',
            centered: true,
        });
    }

    switchCountry(country: 'MU' | 'RE' | 'OT') {
        this.country = country;
    }

    switchLanguage(language: 'en' | 'fr') {
        this.language = language;
    }

    applyLangBtn() {
        this.currencyLanguageService.setLocaleData(
            this.country,
            this.language,
            true
        );
        this.modalService.dismissAll();
    }

    transformCountryCodeToCountryName(code: 'MU' | 'RE' | 'OT'): string {
        return countryCodeToFullCountryNameMap.get(code);
    }

    transformLanguageCodeToLanguageName(code: 'en' | 'fr'): string {
        return languageIdToLanguageCodeMap.get(code);
    }
}

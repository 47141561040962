<div
    class="sb-container"
    [ngClass]="{ 'sb-open': sidebarOpen }"
    (click)="closeSidebar()"
>
    <div class="sb-top">
        <div class="sb-top-logo-container">
            <img
                src="assets/img/logout_new.png"
                alt="marideal-logo"
                class="sb-top-logo"
                *ngIf="!userName"
            />
            <h1 *ngIf="!!userName" [routerLink]="['/customer/address/edit']">
                {{ userName[0] }}
            </h1>
        </div>
        <div class="sb-top-login">
            <p *ngIf="!userName">
                {{ "Not logged in..." | translate }}
            </p>
            <p *ngIf="!!userName">
                {{ userName }}
            </p>
            <div class="sb-top-counts">
                <a class="sb-top-badges" (click)="redirectToChallengesPage()"
                    ><span class="sb-top-values">{{ badgeCount }}</span
                    ><span class="sb-top-text">{{
                        "BADGES" | translate
                    }}</span></a
                >
                <a class="sb-top-points" (click)="redirectToChallengesPage()"
                    ><span class="sb-top-values">{{ balancePoints }}</span
                    ><span class="sb-top-text">
                        {{ "POINTS" | translate }}</span
                    ></a
                >
            </div>
        </div>
    </div>
    <nav>
        <ul class="sb-nav-ul">
            <li>
                <md-language-modal-popup
                    [isCountryVisible]="true"
                    [isDark]="true"
                />
            </li>
            <li *ngIf="!isUserLoggedIn">
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    routerLinkActive
                    (click)="redirectToLogin()"
                >
                    <ion-icon
                        name="person-outline"
                        style="font-size: 28px"
                    ></ion-icon>
                    <span>{{ "Login/Register" | translate }}</span>
                </a>
            </li>
            <li *ngIf="isUserLoggedIn">
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    (click)="toggleAccountLinks($event)"
                >
                    <ion-icon
                        *ngIf="showAccountLinks"
                        name="chevron-back-outline"
                        style="font-size: 28px"
                    ></ion-icon>
                    <ion-icon
                        *ngIf="!showAccountLinks"
                        name="person-outline"
                        style="font-size: 28px"
                    ></ion-icon>

                    <span>{{
                        showAccountLinks
                            ? ("Back" | translate)
                            : ("My Account" | translate)
                    }}</span>
                </a>
            </li>
        </ul>
        <ul class="sb-nav-ul" *ngIf="!showAccountLinks">
            <li>
                <a type="button" class="sb-nav-li-inner" [routerLink]="['/']">
                    <ion-icon
                        name="home-outline"
                        style="font-size: 28px"
                    ></ion-icon>
                    <span>{{ "Home" | translate }}</span>
                </a>
            </li>
            <li
                *ngFor="let sidebarItem of sidebarItems"
                [ngStyle]="{
                    'background-color': sidebarItem.areChildrenExpanded
                        ? 'white'
                        : undefined
                }"
            >
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    style="justify-content: space-between"
                    (click)="onSidebarItemClick(sidebarItem, $event)"
                >
                    <div>
                        <img
                            class="sb-nav-img"
                            [src]="sidebarItem.icon"
                            alt="{{ sidebarItem.name }}"
                        />
                        <span>{{ sidebarItem.name }}</span>
                    </div>
                    <ion-icon
                        *ngIf="
                            sidebarItem.hasChildren &&
                            !sidebarItem.areChildrenExpanded
                        "
                        style="margin-right: 24px"
                        name="chevron-down-outline"
                    ></ion-icon>
                    <ion-icon
                        *ngIf="
                            sidebarItem.hasChildren &&
                            sidebarItem.areChildrenExpanded
                        "
                        style="margin-right: 24px"
                        name="chevron-up-outline"
                    ></ion-icon>
                </a>
                <ul
                    class="sb-nav-ul"
                    style="border-bottom: none"
                    *ngIf="
                        sidebarItem.hasChildren &&
                        sidebarItem.areChildrenExpanded
                    "
                >
                    <li>
                        <a
                            class="sb-nav-li-inner"
                            (click)="
                                redirectToRodriguesPage(sidebarItem, $event)
                            "
                            [state]="{ catId: [sidebarItem.id] }"
                            >{{ "All" | translate }}</a
                        >
                    </li>
                    <li
                        *ngFor="
                            let dropdownItem of sidebarItem.children;
                            let i = index
                        "
                    >
                        <a
                            class="sb-nav-li-inner"
                            (click)="
                                redirectToRodriguesPage(sidebarItem, $event, i)
                            "
                            [state]="{ catId: [dropdownItem.id] }"
                            [queryParams]="{ cat: dropdownItem.id }"
                            >{{ dropdownItem.label }}</a
                        >
                    </li>
                </ul>
            </li>
            <li>
                <a type="button" class="sb-nav-li-inner">
                    <img
                        class="sb-nav-img"
                        src="assets/img/menuicons/General Icons_Notification Bell - AI Blue3 24x24px.svg"
                    />
                    <span>{{ "Notifications" | translate }}</span>
                </a>
            </li>
        </ul>
        <ul class="sb-nav-ul" *ngIf="showAccountLinks">
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    [routerLink]="['/customer/address/edit']"
                >
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Edit Profile 3PTS.png"
                    />
                    <span>{{ "Edit Profile" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    [routerLink]="['/customeraccount/settings']"
                >
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Settings 3PTS.png"
                    />
                    <span>{{ "Settings" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    [routerLink]="['/customer/bookings']"
                >
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Orders 2PTS.png"
                    />
                    <span>{{ "Bookings" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner disabled"
                    title="{{ 'Temporarily disabled' | translate }}"
                >
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Reviews 3PTS.png"
                    />
                    <span>{{ "My Reviews" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    [routerLink]="['/rewardpoints']"
                >
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Challenges 3PTS.png"
                    />
                    <span>{{ "Loyalty program" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    [routerLink]="['customer/rewardsandwallet']"
                >
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Trips 2PTS.png"
                    />
                    <span>{{ "Rewards & Wallet" | translate }}</span>
                </a>
            </li>
            <li>
                <a type="button" class="sb-nav-li-inner" (click)="logout()">
                    <img
                        class="sb-nav-img sb-nav-img--padding"
                        src="assets/img/icons-d/Log-out 3PTS.png"
                    />
                    <span>{{ "Logout" | translate }}</span>
                </a>
            </li>
        </ul>
        <ul class="sb-nav-ul">
            <li>
                <a
                    type="button"
                    class="sb-nav-li-inner"
                    [routerLink]="
                        isUserLoggedIn ? '/help-new' : '/customer/mainhelp'
                    "
                >
                    <img
                        class="sb-nav-img"
                        src="assets/img/menuicons/General Icons_Help - AI Blue3 24x24px.svg"
                    />
                    <span>{{ "Help" | translate }}</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<div
    class="sb-overlay"
    [ngClass]="{ 'sb-overlay-closed': !sidebarOpen }"
    (click)="closeSidebar()"
></div>

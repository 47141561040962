import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddReviewData,
    AddReviewResponseDto,
    NewReviewDataResponseDto,
    ReviewDataDto,
} from './reviews.model';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';

@Injectable({
    providedIn: 'root',
})
export class ReviewsService {
    constructor(private authHttpClient: AuthHttpClientNoXService) {}

    private reviewCache: Map<string, Observable<ReviewDataDto>> = new Map();

    getProductReview(productId: number, currentPage: number) {
        // const cacheKey = `${productId}-${currentPage}`;
        // if (this.reviewCache.has(cacheKey)) {
        //     return this.reviewCache.get(cacheKey);
        // }
        // const apiUrl = getApiUrlMofluid();
        // const customerId = getCustomerId();
        // const customerGroupId = this.commonService.getCustomerGroupID();
        // const storeId = this.commonService.getStoreID();
        // const url = `${apiUrl}getProductReview?service=getProductReview&productid=${productId}&pagesize=10&currentpage=${currentPage}&customerid=${customerId}&store=${storeId}&customer_group_id=${customerGroupId}`;
        // const reviewData$ = this.httpClient
        //     .get<ReviewDataDto>(url, {
        //         headers: this.commonService.getTokenHeaders(),
        //     })
        //     .pipe(shareReplay(1));
        // this.reviewCache.set(cacheKey, reviewData$);
        // return reviewData$;
    }

    getNewReviewData(productId: number): Observable<NewReviewDataResponseDto> {
        return this.authHttpClient.get<NewReviewDataResponseDto>('no url');
    }

    addReviewData(data: AddReviewData): Observable<AddReviewResponseDto> {
        return this.authHttpClient.post<AddReviewResponseDto, AddReviewData>(
            'no url',
            data
        );
    }
}

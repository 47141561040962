import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { removeCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';
import { CurrencyLanguageService } from '@app/shared/currency-language.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'md-payment-failure',
    templateUrl: './payment-failure.component.html',
    styleUrls: ['./payment-failure.component.scss'],
})
export class PaymentFailureComponent implements OnInit {
    isUserLoggedIn: boolean = false;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    paymentFailureReason: string;

    constructor(
        private router: Router,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((symbol) => {
            this.currencySymbol = symbol;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });

        combineLatest([
            this.route.queryParams,
            this.userService.isLoggedIn$,
        ]).subscribe(([params, { isLoggedIn }]) => {
            this.isUserLoggedIn = isLoggedIn;
            this.paymentFailureReason = params['paymentDetails'];
        });
    }

    continueShoping() {
        removeCache('orderdata');
        this.router.navigate(['/']);
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { Locale } from 'src/app/config/locale';
import { DeviceCommsService } from 'src/app/services/device-comms.service';
import { CheckoutCartItem } from '../../checkout.model';
import * as dayjs from 'dayjs';
import { CartItemWithMaxQty } from '../../checkout.component';

@Component({
    selector: 'md-cart-items',
    templateUrl: './cart-items.component.html',
    styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent implements OnInit {
    @Input() items: CartItemWithMaxQty[] = [];
    @Input() removeCartItem: (item: CheckoutCartItem) => void;
    @Input() changeQuantity: (item: CheckoutCartItem) => void;

    currenttime = Date.now();
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    localeAlert: { [key: string]: string };

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private localeObj: Locale,
        private deviceCommsService: DeviceCommsService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.localeAlert = this.localeObj.getAlertMessages();
    }

    redirectToProdDetail(id: number, name: string) {
        this.deviceCommsService.openLinkInNewTabIfBrowser(`deal/${name}/${id}`);
    }

    increaseQuantity(item: CheckoutCartItem) {
        item.qty = item.qty + 1;
        this.changeQuantity(item);
    }

    decreaseQuantity(item: any) {
        item.qty = item.qty - 1;
        this.changeQuantity(item);
    }

    removeItem(item: CheckoutCartItem) {
        this.removeCartItem(item);
    }

    convert_to_date(date: dayjs.Dayjs, withYear: boolean) {
        if (withYear) {
            return date.format('DD MMM, YYYY');
        } else {
            return date.format('DD MMM');
        }
    }

    canIncreaseQuantity(item: CartItemWithMaxQty): boolean {
        if (item.isGiftCard) return true;

        if (item.maxQty) {
            return item.qty < item.maxQty;
        } else if (item.maxQtyObject) {
            let shouldExit = false;
            item.quantitiesDealInfo?.dealOptionIds?.forEach?.((dealOptionId) => {
                const maxAllowedQty = item.maxQtyObject.find(
                    (maxQty) =>
                        maxQty.dealOptionId === dealOptionId.dealOptionId
                );
                if (
                    maxAllowedQty &&
                    maxAllowedQty.quantity <= item.qty * dealOptionId.quantity
                ) {
                    shouldExit = true;
                }
            });

            return !shouldExit;
        }

        return true;
    }
}

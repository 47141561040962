import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { DealDto, DealOfferOption } from 'src/app/model/deal.model';
import {
    BookingConfiguredData,
    MinimalProductBookingData,
} from '../booking-config.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { CounterComponent } from 'src/app/controls/counter/counter.component';

@Component({
    selector: 'md-activity-booking',
    templateUrl: './activity-booking.component.html',
    styleUrls: ['./activity-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBookingComponent implements OnInit, OnChanges {
    @Input() productDetails: DealDto;
    @Input() initialBookingData: MinimalProductBookingData;
    @Input() set totalAllotment(value: number) {
        this.totalAllotmentInternal = value;
    }

    totalAllotmentInternal = -1;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    selectedDealOptionQuantities: Map<DealOfferOption, number> = new Map();

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChildren(CounterComponent) counters!: QueryList<CounterComponent>;

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setDealOffersDefaultQty(changes);
    }

    private setDealOffersDefaultQty(changes: SimpleChanges) {
        if (!changes['productDetails'].currentValue) return;

        if (!this.productDetails.dealOffers.length) return;

        // On first load only
        if (changes['productDetails'].previousValue !== undefined) return;

        this.productDetails.dealOffers.forEach((offer) =>
            offer.dealOptions.forEach((option) =>
                this.selectedDealOptionQuantities.set(option, option.minPax)
            )
        );

        this.emitBookingConfigured();
    }

    onQuantityChanged(
        option: DealOfferOption,
        quantity: number,
        categoryIndex: number,
        optionIndex: number
    ) {
        const isSameCategoryId = this.checkSameCategoryIdAndNotifyCustomer(
            option,
            this.selectedDealOptionQuantities
        );
        if (!isSameCategoryId) {
            const index = this.getListIndexBasedOnCategoryAndOptionIndex(
                categoryIndex,
                optionIndex
            );
            this.counters.toArray()[index].resetCounter();
            return;
        }
        option.qty = quantity;
        this.setSelectedServiceOptionQuantitiesOrRemoveOption(option, quantity);
        this.emitBookingConfigured();
    }

    private setSelectedServiceOptionQuantitiesOrRemoveOption(
        option: DealOfferOption,
        quantity: number
    ) {
        if (quantity === 0) {
            this.selectedDealOptionQuantities.delete(option);
        } else {
            this.selectedDealOptionQuantities.set(option, quantity);
        }
    }

    private getListIndexBasedOnCategoryAndOptionIndex(
        categoryIndex: number,
        optionIndex: number
    ): number {
        let index = 0;
        for (let i = 0; i < categoryIndex; i++) {
            index += this.productDetails.dealOffers[i].dealOptions.length;
        }
        index += optionIndex;
        return index;
    }

    private checkSameCategoryIdAndNotifyCustomer(
        option: DealOfferOption,
        selectedDealOptionQuantities: Map<DealOfferOption, number>
    ): boolean {
        const selectedServiceOption = Array.from(
            selectedDealOptionQuantities.keys()
        ).find(
            (serviceOption) => serviceOption.categoryId !== option.categoryId
        );
        if (selectedServiceOption) {
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                'Multiple options cannot be added to the cart simultaneously. Please add each option separately.'
            );
            return false;
        }
        return true;
    }

    private emitBookingConfigured() {
        const totalPrice = this.calculateTotalSellingPrice();
        const totalFullPrice = this.calculateTotalCrossedPrice();
        this.bookingConfigured.emit({
            totalPrice,
            totalFullPrice,
            productBookingData: {
                id: this.productDetails.dealId,
                selectedDealOptionQuantities: this.selectedDealOptionQuantities,
            },
        });
    }

    private calculateTotalSellingPrice() {
        let totalPrice = 0;
        this.selectedDealOptionQuantities.forEach((quantity, option) => {
            totalPrice += option.sellingPrice * quantity;
        });
        return totalPrice;
    }

    private calculateTotalCrossedPrice() {
        let totalFullPrice = 0;
        this.selectedDealOptionQuantities.forEach((quantity, option) => {
            totalFullPrice += option.crossedPrice * quantity;
        });
        return totalFullPrice;
    }
}

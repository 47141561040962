<div class="rw-container" #reviewsComponent>
    <div class="rw-top-container">
        <md-review-summary [reviewData]="reviewData" [large]="true" />
        <button
            mdDesktopOnly
            mdMainButton="medium"
            class="rw-write-review-button"
            (click)="writeReview()"
        >
            {{ "Write a review" | translate }}
        </button>
    </div>
    <div class="rw-rating-items">
        <div *ngFor="let ratingGroup of ratingGroups" class="rw-rating-item">
            <div class="rw-rating-text">{{ ratingGroup.label }}</div>
            <div class="rw-rating-bkg">
                <div
                    class="rw-rating-value"
                    [ngStyle]="{
                        'background-color': ratingColorsMap.get(
                            ratingGroup.label
                        ),
                        width: ratingGroup.percent + '%'
                    }"
                ></div>
            </div>
            <div class="rw-rating-text rw-rating-number">
                {{ ratingGroup.count }}
            </div>
        </div>
    </div>
    <button
        mdMobileOnly
        mdMainButton="medium"
        class="rw-write-review-button"
        disabled
        (click)="writeReview()"
    >
        {{ "Write a review" | translate }}
    </button>
    <div class="rw-review-container">
        <div
            *ngFor="let review of reviewData?.allReviews"
            class="rw-review-item"
        >
            <div
                class="rw-review-item-emoji"
                [ngClass]="review.ratingLabel"
            ></div>
            <div class="rw-review-item-date">
                <ion-icon name="calendar-outline"></ion-icon
                >{{ review.createdAtString }}
            </div>
            <div class="rw-review-item-text">{{ review.detail }}</div>
            <div class="rw-review-item-user">{{ review.userNickname }}</div>
        </div>
    </div>
    <button
        *ngIf="!loadingMore && reviewData?.allReviews?.length > 0"
        mdTextButton
        class="rw-review-view-more"
        (click)="loadMoreReviews()"
    >
        {{ "View more" | translate }}
    </button>
    <md-load-more *ngIf="loadingMore" />
</div>
<ng-template #modalNewReviewForm let-modal>
    <div mdMobileOnly class="modal-header rw-modal-title">
        <h4 class="modal-title">
            {{ "Reviews by customers only" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body gc-form-modal-body">
        <md-write-review
            [productId]="internalProductId"
            (reviewSubmitted)="onReviewSubmitted()"
        ></md-write-review>
    </div>
</ng-template>

<div class="md-account-wrapper">
    <div *ngIf="!(isMobile | async)" class="md-account-sidenav">
        <div class="sidenav-links">
            <div
                [ngClass]="[
                    'sidenav-link',
                    activeSection === ProfileSection.EDIT_PROFILE
                        ? 'active'
                        : ''
                ]"
                [routerLink]="['/customer/address/edit']"
            >
                <img
                    alt="Edit Profile"
                    src="assets/img/icons-d/Edit Profile 3PTS.png"
                />
                <span>{{ "Edit Profile" | translate }}</span>
            </div>
            <div
                [ngClass]="[
                    'sidenav-link',
                    activeSection === ProfileSection.SETTINGS ? 'active' : ''
                ]"
                [routerLink]="['/customeraccount/settings']"
            >
                <img
                    alt="Settings"
                    src="assets/img/icons-d/Settings 3PTS.png"
                />
                <span>{{ "Security" | translate }}</span>
            </div>
            <div
                [ngClass]="[
                    'sidenav-link',
                    activeSection === ProfileSection.ORDERS ? 'active' : ''
                ]"
                [routerLink]="['/customer/bookings']"
            >
                <img alt="Orders" src="assets/img/icons-d/Orders 2PTS.png" />
                <span>{{ "Bookings" | translate }}</span>
            </div>
            <div
                [ngClass]="[
                    'sidenav-link',
                    'disabled',
                    activeSection === ProfileSection.REVIEWS ? 'active' : ''
                ]"
                title="{{ 'Temporarily disabled' | translate }}"
            >
                <img alt="Reviews" src="assets/img/icons-d/Reviews 3PTS.png" />
                <span>{{ "My Reviews" | translate }}</span>
            </div>
            <div
                [ngClass]="[
                    'sidenav-link',
                    activeSection === ProfileSection.CHALLENGES ? 'active' : ''
                ]"
                [routerLink]="['/rewardpoints']"
            >
                <img
                    alt="Challenges"
                    src="assets/img/icons-d/Challenges 3PTS.png"
                />
                <span>{{ "Loyalty program" | translate }}</span>
            </div>
            <div
                [ngClass]="[
                    'sidenav-link',
                    activeSection === ProfileSection.GIFT_CARDS ? 'active' : ''
                ]"
                [routerLink]="['/customer/rewardsandwallet']"
            >
                <img
                    alt="Rewards & Wallet"
                    src="assets/img/menuicons/General Icons_Gift Card  - AI Blue3 24x24px.svg"
                />
                <span>{{ "Rewards & Wallet" | translate }}</span>
            </div>
            <div class="sidenav-link" (click)="logout()">
                <img alt="Logout" src="assets/img/icons-d/Log-out 3PTS.png" />
                <span>{{ "Sign Out" | translate }}</span>
            </div>
        </div>
    </div>
    <div [ngClass]="['md-account-section']">
        <ng-content></ng-content>
    </div>
</div>

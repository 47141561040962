<div class="pro-map" id="pro-map-outer" *ngIf="sitemapValue">
    <div class="card custom-product-detail-div-133" id="map-block">
        <div class="pro-info-heading">
            <div class="heading-txt">
                <h3>
                    {{
                        ("Map" | translate) === "" ? "Map" : ("Map" | translate)
                    }}
                </h3>
            </div>
            <div class="heading-icon">
                <i
                    class="icon"
                    [ngClass]="
                        shownMap === undefined
                            ? 'fa fa-angle-up'
                            : 'fa fa-angle-down'
                    "
                ></i>
            </div>
        </div>
        <div
            class="pro-info-text map-outer-block mapValue"
            [hidden]="shownMap !== undefined"
        >
            <div [innerHtml]="sitemapValue | safeHtml"></div>
        </div>
    </div>
</div>

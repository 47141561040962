<md-special-offers [productDetails]="productDetails" />
<div class="bc-mbnb-booking-container">
    <div class="bc-mbnb-date-picker">
        <md-calendar
            #calendarMariBnb
            [infoForEachDay]="additionalCalendarDayInfo"
            (dateRangeSelected)="onDateRangeSelected($event)"
            [showDesktopInPopup]="true"
            [discounts]="maribnbServiceOption?.optionDiscounts"
            ><div popupTargetControl class="bc-mbnb-date-picker-inside">
                <div class="bc-mbnb-date-picker-in">
                    <span class="bc-mbnb-date-picker-text">{{
                        "Check-In" | translate
                    }}</span>
                    <span class="bc-mbnb-date-picker-value">{{
                        formattedFromDate
                    }}</span>
                </div>
                <div class="bc-mbnb-date-picker-out">
                    <span class="bc-mbnb-date-picker-text">{{
                        "Check-out" | translate
                    }}</span>
                    <span class="bc-mbnb-date-picker-value">{{
                        formattedToDate
                    }}</span>
                </div>
            </div>
            <ng-content
                summaryControl
                select="[summaryControlCal]"
            ></ng-content>
        </md-calendar>
    </div>

    <md-calendar-occupancy
        #occupancyControl
        [config]="occupancyConfig"
        (occupancyChanged)="onOccupancyChanged($event)"
        ><div datesControl class="bc-mbnb-occupancy-date">
            {{ formattedFromDate }} - {{ formattedToDate }}
        </div>
        <ng-content summaryControl select="[summaryControlOcc]"></ng-content>
        <div
            class="bc-mbnb-occupancy-picker"
            innerControl
            (click)="onOccupancyClicked()"
        >
            {{ occupancyString ? occupancyString : ("Guests" | translate) }}
        </div></md-calendar-occupancy
    >
</div>

import * as dayjs from 'dayjs';
import { EGiftCardDealInfo } from 'src/app/egift-card/egift-card.model';
import {
    CartDto,
    CartItemDto,
    DealInfoWithAccomOptionDto,
} from 'src/app/product-detail/booking-config/booking-config.model';

export interface CheckoutState {
    termsAndConditions?: boolean;
    iAmMauritian?: boolean;
    rewardPointsToApply?: number;
    selectedPaymentMethodId?: number;
    storeCreditToApply?: number;
    giftCode?: string;
}

export interface CheckoutAddress {
    defaultAddress: 1 | 2;
    firstname: string;
    lastname: string;
    // TODO: understand how these actually funciton
    phone: any;
    address: string;
    city: string;
    country: any;
    state: any;
    stateCustom: string;
    zipcode: string;
    email: string;
}

export interface BillingAddressDto {
    billfname: string;
    billlname: string;
    billstreet1: string;
    billstreet2: string;
    billcity: string;
    billpostcode: string;
    billstate: string;
    billcountry: string;
    billphone: string;
}

export interface ShippingAddressDto {
    shippfname: string;
    shipplname: string;
    shippstreet1: string;
    shippstreet2: string;
    shippcity: string;
    shippostcode: string;
    shippstate: string;
    shippcountry: string;
    shipphone: string;
}

export interface CheckoutCartItem {
    name: string;
    description: string;
    check_in: dayjs.Dayjs;
    check_out: dayjs.Dayjs;
    product_addon_data: string;
    qty: number;
    item_row_total: number;
    options: {
        value: string;
        label: string;
    }[];
    max_qty: number;
    quantity_arr: number[];
    type: string;
    id: string;
    dealId: number;
    cat_id: number;
    nights: number;
    isGiftCard?: boolean;
    hotelDealInfo?: DealInfoWithAccomOptionDto;
    eGiftCardDealInfo?: EGiftCardDealInfo;
}

export interface CheckoutData {
    cartItems: CheckoutCartItem[];
    itemsCount: number;
    orderId: string;
    orderTotal: number;
    totalBeforeDiscounts: number;
    totalDiscount: number;
    giftDiscount: number;
    appliedGiftCardCodes: string[];
    discountCode: string;
    serviceFee: number;
    storeCreditAmount: number;
    rewardPointsEarned: number;
    rewardPointsSpent: number;
    rewardPointsDiscountAmount: number;
}

export interface PaymentOption {
    payment_method_id: number;
    payment_method_code: string; //mipspaymentsystemiframe, ipay, banktransfer
    payment_method_title: string;
}

export interface CheckoutGuest {
    categoryproductId: string;
    product_name: string;
    guest_id: string;
    dealId: number;
    name: string;
    email: string;
    room_count: number;
    child_range: {
        item: any[];
    }[];
}

export interface VerifiedCartDto {
    cart: CartDto;
    notAvailableCartItems: string[]; // Array of strings (UUIDs)
    invalidDiscounts: Array<{
        value: string;
    }>;
    itemsWithRatesChanged: string[]; // Array of strings (UUIDs)
}

export interface OrderStatusDto {
    id: string;
    number: number;
    status: string;
    customerEmail: string;
    customerFirstName: string;
    customerLastName: string;
    grandTotal: number;
    subtotal: number;
}

export interface PaymentSuccessData {
    orderId: string;
    orderNumber: string;
    orderStatus: string;
    amount: number;
    name: string;
    email: string;
}

export const transformCheckoutItemDtoToCheckoutCartItem = (
    item: CartItemDto
): CheckoutCartItem => {
    const checkInDate = dayjs(item.checkIn);
    const checkOutDate = dayjs(item.checkOut);
    const nights = checkOutDate.diff(checkInDate, 'day');
    const isGiftCard = item.dealCategoryId === 2;

    return {
        name: item.dealTitle,
        description: item.dealSubtitle,
        check_in: checkInDate,
        check_out: checkOutDate,
        product_addon_data: '',
        qty: item.quantity,
        item_row_total: item.subtotal,
        options: [],
        max_qty: 100, // TODO: this needs to change once we have the actual value
        quantity_arr: [],
        type: 'custom',
        id: item.id,
        dealId: item.dealId,
        cat_id: item.dealCategoryId,
        nights,
        isGiftCard,
        hotelDealInfo: !isGiftCard
            ? (JSON.parse(item.dealInfo) as DealInfoWithAccomOptionDto)
            : undefined,
        eGiftCardDealInfo: isGiftCard
            ? fixDatesInEGiftCardDealInfo(JSON.parse(item.dealInfo))
            : undefined,
    };
};

const fixDatesInEGiftCardDealInfo = (
    dealInfo: EGiftCardDealInfo
): EGiftCardDealInfo => {
    return {
        ...dealInfo,
        sendDate: dayjs(dealInfo.sendDate),
        expiryDate: dayjs(dealInfo.expiryDate),
    };
};

export const transformCartDtoToCheckoutData = (cart: CartDto): CheckoutData => {
    const cartItems = cart.items.map((item) =>
        transformCheckoutItemDtoToCheckoutCartItem(item)
    );

    return {
        cartItems,
        itemsCount: cart.itemsCount,
        orderId: cart.orderId,
        orderTotal: cart.subtotal,
        totalBeforeDiscounts: cart.subtotalBeforeIncentives,
        totalDiscount: cart.totalDiscountedSubtotal,
        discountCode: getDiscountCodeFromCart(cart),
        giftDiscount: cart.giftCardsAmount,
        appliedGiftCardCodes: cart.giftCards ? cart.giftCards.split(',') : [],
        serviceFee: cart.serviceFee,
        storeCreditAmount: cart.storeCreditAmount,
        rewardPointsEarned: cart.rewardPointsEarned,
        rewardPointsSpent: cart.rewardPointsSpent,
        rewardPointsDiscountAmount: cart.rewardPointsDiscountAmount,
    };
};

export const transformVerifiedCartDtoToCheckoutData = (
    verifiedCart: VerifiedCartDto
): CheckoutData => {
    return transformCartDtoToCheckoutData(verifiedCart.cart);
};

const getDiscountCodeFromCart = (cart: CartDto): string => {
    return cart.discountCode ?? cart.items.length > 0
        ? cart.items[0].discountCode
        : '';
};

import {
    MinimalProductBookingData,
    ProductBookingData,
    RewardsData,
    RewardsResponseDto,
} from './booking-config.model';

export const transformToMinimalProductBookingData = (
    productBookingData: ProductBookingData
): MinimalProductBookingData => {
    return {
        id: productBookingData.id,
        checkinDate: productBookingData.checkinDate,
        checkoutDate: productBookingData.checkoutDate,
        selectedDate: productBookingData.selectedDate,
        selectedServiceOptions: productBookingData.selectedServiceOptions
            ? productBookingData.selectedServiceOptions.map((option) => {
                  return {
                      id: option.id,
                      occupancyId: option.occupancyId,
                      categoryId: option.categoryId,
                  };
              })
            : undefined,
        selectedServiceOptionQuantities:
            productBookingData.selectedServiceOptionQuantities
                ? new Map(
                      Array.from(
                          productBookingData.selectedServiceOptionQuantities.entries()
                      ).map(([option, quantity]) => {
                          return [
                              {
                                  id: option.id,
                                  occupancyId: option.occupancyId,
                                  categoryId: option.categoryId,
                              },
                              quantity,
                          ];
                      })
                  )
                : undefined,
    };
};

export const transformRewardsData = (
    rewardsResponseDto: RewardsResponseDto
): RewardsData => {
    return {
        amount: rewardsResponseDto.amount,
        earningPoints: rewardsResponseDto.earningPoints,
    };
};

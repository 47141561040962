import { NgModule } from '@angular/core';
import { SocialLoginModule } from '../social-login/social-login.module';
import { RegisterComponent } from './register.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { RouterModule } from '@angular/router';
import { RegisterRoutingModule } from './register-routing.module';
import { ControlsModule } from '../controls/controls.module';

@NgModule({
    declarations: [RegisterComponent],
    exports: [RegisterComponent],
    imports: [
        RegisterRoutingModule,
        SocialLoginModule,
        CommonModule,
        ControlsModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        NgOtpInputModule,
        RouterModule,
    ],
})
export class RegisterModule {}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    BestSellerCategories,
    BestSellerEntry,
    HomeDealsDto,
    UpcomingTripDto,
    transformHomeDealsDtoToBestSellerEntry,
} from './home.model';
import { SwiperOptions } from 'swiper';
import { screenSizes } from '../utilities/theme';
import { HomeService } from './home.service';
import { Router } from '@angular/router';
import { menuRoutes } from '../static-content/menu-routes';
import { DeviceCommsService } from '../services/device-comms.service';
import { UserService } from '../services/user.service';

// Swiper configurations
const swiperConfig: SwiperOptions = {
    autoHeight: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    spaceBetween: 16,
    breakpoints: {
        [screenSizes.largeDesktop]: {
            slidesPerView: 5.5,
        },
        [screenSizes.desktop]: {
            slidesPerView: 4,
        },
        [screenSizes.mobile]: {
            slidesPerView: 3,
        },
        0: {
            slidesPerView: 1.3,
        },
    },
};

const swiperConfigTrending: SwiperOptions = {
    ...swiperConfig,
    navigation: {
        nextEl: '.swiper-trending-button-next',
        prevEl: '.swiper-trending-button-prev',
    },
};

const swiperConfigRecentlyViewed: SwiperOptions = {
    ...swiperConfig,
    navigation: {
        nextEl: '.swiper-recently-viewed-button-next',
        prevEl: '.swiper-recently-viewed-button-prev',
    },
};

@Component({
    selector: 'md-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    bannerUrl = '';
    bannerUrl1 = '';

    private bannerConfig = {
        banner: {
            mobile: 'assets/img/banners/HotelFlightMob.png',
            desktop: 'assets/img/banners/HotelFlightHP.png',
        },
        banner1: {
            mobile: 'assets/img/banners/HotelFlightMob.png',
            desktop: 'assets/img/banners/HotelFlightHP.png',
        },
    };

    redirectToLink() {
        window.open('/plp/Rodrigues/9', '_blank');
    }
    redirectToLink1() {
        window.open('/plp/Rodrigues/9', '_blank');
    }

    swiperConfigTrending = swiperConfigTrending;
    swiperConfigRecentlyViewed = swiperConfigRecentlyViewed;
    swiperConfig = swiperConfig;

    isUserLoggedIn = false;
    top10TrendingEntries: BestSellerEntry[];
    bestSellerCategories: BestSellerCategories;
    trendingEntries: BestSellerEntry[];
    trendingEntriesLoadingPlaceholder: number[] = this.getRepeatArray(10);
    recentlyViewedEntries: BestSellerEntry[];
    showRecentlyViewed = false;
    showUpcomingTrip = false;
    menuRoutesInternal = menuRoutes;
    upcomingTrip: UpcomingTripDto;

    constructor(
        private homeService: HomeService,
        private router: Router,
        private deviceCommsService: DeviceCommsService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.updateBannerUrls();
        this.getBaseData();
        // this.getRecentlyViewed();
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.isUserLoggedIn = isLoggedIn;
            this.showRecentlyViewed = isLoggedIn;
            // if (isLoggedIn) this.getUpcomingTrip();
        });
    }

    @HostListener('window:resize')
    onResize(): void {
        this.setTrendingEntriesBasedOnScreenSize();
        this.updateBannerUrls();
    }

    private updateBannerUrls(): void {
        const screenWidth = window.innerWidth;
        this.bannerUrl = this.getBannerUrl(
            screenWidth,
            this.bannerConfig.banner
        );
        this.bannerUrl1 = this.getBannerUrl(
            screenWidth,
            this.bannerConfig.banner1
        );
    }

    private getBannerUrl(
        screenWidth: number,
        config: { mobile: string; desktop: string }
    ): string {
        return screenWidth < screenSizes.mobile
            ? config.mobile
            : config.desktop;
    }

    getBaseData(): void {
        this.trendingEntriesLoadingPlaceholder = this.getRepeatArray(
            this.getTrendingEntryNumberBasedOnScreenSize()
        );

        this.subscriptions.push(
            this.homeService.getDeals().subscribe((response: HomeDealsDto) => {
                this.bestSellerCategories =
                    transformHomeDealsDtoToBestSellerEntry(response);
                const trendingOffers =
                    this.bestSellerCategories?.trendingOffers;
                this.top10TrendingEntries = trendingOffers.slice(0, 10);
                this.setTrendingEntriesBasedOnScreenSize();
            })
        );
    }

    // getRecentlyViewed(): void {
    //     this.subscriptions.push(
    //         this.homeService.getRecentlyViewedData().subscribe({
    //             next: (response: RecentlyViewedDto) => {
    //                 this.recentlyViewedEntries = response.data.map((entry) =>
    //                     trasnformBestSellerEntryDto(entry)
    //                 );
    //             },
    //             error: () => {
    //                 // Error handling
    //                 this.recentlyViewedEntries = [];
    //             },
    //         })
    //     );
    // }

    // getUpcomingTrip(): void {
    //     this.subscriptions.push(
    //         this.homeService.getUpcomingTrip().subscribe({
    //             next: (response: UpcomingTripDto) => {
    //                 if (response) {
    //                     this.upcomingTrip = response;
    //                     this.showUpcomingTrip = true;
    //                 }
    //             },
    //             error: () => {
    //                 // Error handling
    //             },
    //         })
    //     );
    // }

    hideUpcomingTrip(): void {
        this.showUpcomingTrip = false;
    }

    goToTripDetail() {
        this.router.navigate(['/customer/trips-detail'], {
            state: { tripDetail: this.upcomingTrip },
        });
    }

    seeAll(e: Event, catId: number): void {
        e.preventDefault();
        const route = this.menuRoutesInternal.get(catId);
        this.router.navigate([route], {
            state: {
                catId,
            },
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    dealSelected(dealEntry: BestSellerEntry): void {
        const { productPath, id } = dealEntry;
        this.deviceCommsService.openLinkInNewTabIfBrowser(
            `/deal/${productPath}/${id}`
        );
    }

    setTrendingEntriesBasedOnScreenSize(): void {
        this.trendingEntries = this.top10TrendingEntries?.slice(
            0,
            this.getTrendingEntryNumberBasedOnScreenSize()
        );
    }

    getTrendingEntryNumberBasedOnScreenSize(): number {
        const width = window.innerWidth;
        if (width >= screenSizes.largeDesktop) {
            return 10; // 5 entries per row means 10 entries look good
        } else if (width >= screenSizes.desktop) {
            return 8; // 4 entries per row
        } else if (width >= screenSizes.mobile) {
            return 9; // 3 entries per row
        } else {
            return 10; // Carousel
        }
    }

    getRepeatArray(length: number): number[] {
        return new Array(length);
    }

    getSwiperConfig(index: string): SwiperOptions {
        return {
            ...swiperConfig,
            navigation: {
                nextEl: `.swiper-best-sellers-button-next-${index}`,
                prevEl: `.swiper-best-sellers-button-prev-${index}`,
            },
        };
    }
}

import * as _ from 'lodash';
import { createDateString } from 'src/app/controls/calendar/calendar.utils';
import { parseDate } from 'src/app/utilities/date.utils';
import { CalendarInventory, InventoryDto } from 'src/app/model/deal.model';

export const transformInventoryResponseDto = (
    inventory: InventoryDto[]
): CalendarInventory[] => {
    const inventoryGroupedByDate = _.groupBy(inventory, 'date');

    const groupedData = _.map(
        inventoryGroupedByDate,
        (inventory, dateUnformatted) => {
            const date = parseDate(dateUnformatted);

            return {
                date,
                dateAsString: createDateString(date),
                options: inventory.map((calendarInventory) => ({
                    optionId: calendarInventory.dealOptionId,
                    price: calendarInventory.sellingPrice,
                    priceBeforeDiscount: calendarInventory.crossedOutPrice,
                    parentId: calendarInventory.dealOptionId,
                })),
            };
        }
    );

    return groupedData;
};

<header>
    <div class="md-container">
        <div class="header-wrapper">
            <div mdMobileOnly>
                <div class="he-left-container">
                    <a *ngIf="showBackButton" (click)="goBack()">
                        <ion-icon
                            class="he-button"
                            name="chevron-back-outline"
                        ></ion-icon>
                    </a>
                    <button
                        mdTextButton
                        #navbarToggler
                        style="padding: 0"
                        (click)="openSidebar()"
                    >
                        <ion-icon
                            class="he-button"
                            name="menu-outline"
                        ></ion-icon>
                    </button>
                </div>
            </div>
            <div class="he-logo he-logo--desktop">
                <a [routerLink]="['/']">
                    <md-logo />
                </a>
            </div>
            <div class="he-middle-container">
                <div *ngIf="showMobileLogo" class="he-logo he-logo--mobile">
                    <md-logo />
                </div>
                <h1 mdMobileOnly *ngIf="showMobilePageTitle" class="he-title">
                    {{ common.pageTitle | translate }}
                </h1>
                <md-header-search-bar
                    [catId]="catId"
                    [visibleSearchBarType]="visibleSearchBarType"
                />
            </div>
            <div class="he-right-container">
                <md-language-modal-popup mdDesktopOnly />
                <button mdTextButton class="he-cart" (click)="goToCheckout()">
                    <ion-icon class="he-button" name="cart-outline"></ion-icon>
                    <span
                        class="he-cart-count"
                        *ngIf="getCartItemCount() !== 0"
                        >{{ getCartItemCount() }}</span
                    >
                </button>
                <button
                    mdTextButton
                    mdMobileOnly
                    style="padding: 0"
                    (click)="togglePlainSearch()"
                >
                    <ion-icon
                        class="he-button"
                        name="search-outline"
                    ></ion-icon>
                </button>
                <button
                    mdTextButton
                    mdDesktopOnly
                    [ngbPopover]="popContent"
                    [popperOptions]="popperOptions"
                    class="he-button he-button--user"
                >
                    <ion-icon
                        name="person-outline"
                        style="font-size: 26px"
                    ></ion-icon>
                    <ion-icon
                        style="padding-left: 0; height: 100%; font-size: 12px"
                        name="chevron-down-outline"
                    ></ion-icon>
                </button>
            </div>
        </div>
    </div>
</header>
<ng-template #popContent>
    <ul class="h-account-ul">
        <li *ngIf="!isUserLoggedIn; else userLinks">
            <a class="h-account-inner" type="button" (click)="openLogin()">
                <ion-icon
                    name="log-in-outline"
                    class="h-account-icon"
                ></ion-icon>
                <span>{{ "Login/Register" | translate }}</span>
            </a>
        </li>
        <ng-template #userLinks>
            <li>
                <a
                    class="h-account-inner"
                    type="button"
                    [routerLink]="['/customer/address/edit']"
                >
                    <img
                        class="h-account-icon"
                        src="assets/img/icons-d/Edit Profile 3PTS.png"
                    />
                    <span>{{ "Edit Profile" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    class="h-account-inner"
                    type="button"
                    [routerLink]="['/customeraccount/settings']"
                >
                    <img
                        class="h-account-icon"
                        src="assets/img/icons-d/Settings 3PTS.png"
                    />
                    <span>{{ "Settings" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    class="h-account-inner"
                    type="button"
                    [routerLink]="['/customer/bookings']"
                >
                    <img
                        class="h-account-icon"
                        src="assets/img/icons-d/Orders 2PTS.png"
                    />
                    <span>{{ "Bookings" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    class="h-account-inner disabled"
                    type="button"
                    title="{{ 'Temporarily disabled' | translate }}"
                >
                    <img
                        class="h-account-icon"
                        src="assets/img/icons-d/Reviews 3PTS.png"
                    />
                    <span>{{ "My Reviews" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    class="h-account-inner"
                    type="button"
                    [routerLink]="['/rewardpoints']"
                >
                    <img
                        class="h-account-icon"
                        src="assets/img/icons-d/Challenges 3PTS.png"
                    />
                    <span>{{ "Loyalty program" | translate }}</span>
                </a>
            </li>
            <li>
                <a
                    class="h-account-inner"
                    type="button"
                    [routerLink]="['customer/rewardsandwallet']"
                >
                    <img
                        class="h-account-icon"
                        src="assets/img/icons-d/Trips 2PTS.png"
                    />
                    <span>{{ "Rewards & Wallet" | translate }}</span>
                </a>
            </li>
            <li>
                <a class="h-account-inner" type="button" (click)="logout()">
                    <ion-icon
                        class="h-account-icon"
                        name="log-out-outline"
                    ></ion-icon>
                    <span>{{ "Logout" | translate }}</span>
                </a>
            </li>
        </ng-template>
        <li>
            <a
                class="h-account-inner"
                type="button"
                [routerLink]="
                    isUserLoggedIn ? '/help-new' : '/customer/mainhelp'
                "
            >
                <ion-icon
                    class="h-account-icon"
                    name="help-circle-outline"
                ></ion-icon>
                <span>{{ "Help" | translate }}</span>
            </a>
        </li>
    </ul>
</ng-template>

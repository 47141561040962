<div>
    <md-activity-booking
        [productDetails]="internalProductDetails"
        (bookingConfigured)="onBookingConfigured($event)"
        [totalAllotment]="totalAllotment"
    />
    <div style="display: flex; justify-content: center; margin-top: 20px">
        <md-calendar
            #calendarDatedActivity
            [isSingleDate]="true"
            [infoForEachDay]="additionalCalendarDayInfo"
            (dateRangeSelected)="onDateRangeSelected($event)"
            [errorMessage]="calendarErrorMessage"
        />
    </div>
</div>

import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProductAddOnConfiguration } from './add-ons/add-ons.model';
import {
    ACTIVITY_CATEGORY,
    DAY_PACKAGE_CATEGORY,
    EVENT_CATEGORY,
    HOTEL_CATEGORY,
    MARIBNB_CATEGORY,
    RESTAURANT_CATEGORY,
    SPA_CATEGORY,
    TRAVEL_CATEGORY,
} from 'src/app/static-content/menu-routes';
import {
    AccomOptionDto,
    DealDto,
    DealOfferOption,
} from 'src/app/model/deal.model';

export type BookingConfigVariant =
    | 'hotel'
    | 'activity'
    | 'datedActivity'
    | 'spa'
    | 'maribnb'
    | 'travel';

export const getBookingVariantBasedOnCategory = (
    category: number,
    showCalendar: boolean
): BookingConfigVariant => {
    switch (category) {
        case HOTEL_CATEGORY:
            return 'hotel';
        case EVENT_CATEGORY:
            return 'activity';
        case ACTIVITY_CATEGORY:
        case RESTAURANT_CATEGORY: {
            return showCalendar ? 'datedActivity' : 'activity';
        }
        case DAY_PACKAGE_CATEGORY:
            return 'datedActivity';
        case SPA_CATEGORY:
            return 'spa';
        case MARIBNB_CATEGORY:
            return 'maribnb';
        case TRAVEL_CATEGORY:
            return 'hotel';
    }
};

// TODO: may need to have separate types for different booking types
export interface BookingConfiguredData {
    totalPrice?: number;
    totalFullPrice?: number;
    flightPrice?: number;
    rewardPointsEarned?: number;
    productBookingData?: ProductBookingData;
}

export const emptyBookingConfiguredData: BookingConfiguredData = {
    totalPrice: 0,
    totalFullPrice: 0,
    rewardPointsEarned: 0,
    productBookingData: undefined,
};

// this is a mapping of the chosen product but instead of
// containing all of the options it just contains whatever was selected
export interface ProductBookingData {
    id: number;
    checkinDate?: NgbDate;
    checkoutDate?: NgbDate;
    selectedDate?: NgbDate;
    totalNights?: number;
    selectedDealOptions?: DealOfferOption[];
    selectedDealOptionQuantities?: Map<DealOfferOption, number>;
    selectedAccomOptions?: AccomOptionDto[];
    mariBnbOccupancy?: MariBnbOccupancyData;
    addOnConfigurations?: ProductAddOnConfiguration[];
}

export interface MariBnbOccupancyData {
    adults: number;
    children: number;
    infants: number;
}

export interface MinimalProductBookingData {
    id: number;
    checkinDate?: NgbDate;
    checkoutDate?: NgbDate;
    selectedDate?: NgbDate;
    selectedDealOptions?: MinimalProductServiceOptionData[];
    selectedDealOptionQuantities?: Map<MinimalProductServiceOptionData, number>;
}
export interface HotelSearchBookingData {
    fromDate: NgbDate;
    toDate: NgbDate;
    occupancyId: string;
}

export interface MinimalProductServiceOptionData {
    id: number;
    occupancyId: number;
    categoryId: number;
}

export const getProductServiceOptionsFromProductDetails = (
    productDetails: DealDto,
    minimalProductServiceOptions: MinimalProductServiceOptionData[]
): DealOfferOption[] => {
    return minimalProductServiceOptions.map((option) => {
        const { occupancyId, categoryId } = option;
        const dealOffer = productDetails.dealOffers.find(
            (option) => option.dealOfferId === categoryId
        );
        const dealOption = dealOffer.dealOptions.find(
            (option) => option.dealOptionId === occupancyId
        );
        return dealOption;
    });
};
export interface HodelBookingDataDto {
    qty: number;
    type: string;
    id: number;
    options: { [key: number]: string }; // TODO: This needs to be explained
    booking_from: string;
    booking_to: string;
    total_nights: number;
}

export interface MaribnbBookingDataDto extends HodelBookingDataDto {
    adults: number;
    children: number;
    infants: number;
}

export interface ProductBookingDto {
    qty: number;
    id: number;
    options?:
        | string
        | {
              [key: number]: number;
          };
    dropdown_options?:
        | string
        | {
              [key: number]: number;
          };
    booking_from?: string;
    booking_to?: string;
    booking_date?: string;
    total_nights: number;
    adults?: number;
    children?: number;
    infants?: number;
}

export interface AddBookingToCartResponseDto {
    status: 'success' | 'error';
    msg: string;
    quoteid: string;
    cart_items_qty: number;
    cart_items_count: number;
}

export interface RewardsData {
    amount: number;
    earningPoints: number;
}

// V2

type CurrencyDto = 'EUR' | 'MUR' | 'NOT_SUPPORTED' | 'USD';

export interface AddCartItemDto {
    cartId: string; // uuid (string format)
    outletId: number; // int32 (integer)
    dealId: number; // int32 (integer)
    dealCategoryId: number; // int32 (integer)
    sectionId: number; // int32 (integer)
    dealTitle: string; // string
    dealSubtitle: string; // string
    dealInfo: string; // serialized DealInfoDto
    quantity: number; // int32 (integer)
    subtotal: number; // double (number)
    grandTotal: number; // double (number)
    checkIn?: string; // date (ISO 8601 string)
    checkOut?: string; // date (ISO 8601 string)
    purchaseCurrencyId: number;
    rewardPointsEarned: number; // int32 (integer)
}

export interface DealInfoDto {
    dealConditions: string;
    packageTypeId: number;
}

export interface DealInfoWithAccomOptionDto extends DealInfoDto {
    roomId: number;
    roomCategory: string;
    mealPlan: string;
}

export interface DealInfoWithDealOptionDto extends DealInfoDto {
    dealOptionId: number;
}

export interface CartItemDto {
    id: string; // uuid
    cartId: string; // uuid
    outletId: number; // int32
    dealId: number; // int32
    dealCategoryId: number; // int32
    isCustomDeal: boolean; // boolean
    sectionId: number; // int32
    dealTitle: string; // string
    dealSubtitle: string; // string
    quantity: number; // int32
    subtotal: number; // double
    subtotalBeforeIncentives: number; // double
    grandTotal: number; // double
    grandTotalBeforeIncentives: number; // double
    discountCode: string; // string
    subtotalDiscountAmount: number; // double
    grandTotalDiscountAmount: number; // double
    checkIn: Date; // date (ISO 8601 string)
    checkOut: Date; // date (ISO 8601 string)
    dealInfo: string; // string
    createdAt: Date; // ISO 8601 date string
    updatedAt: Date; // ISO 8601 date string
}

export interface CartDto {
    id: string; // uuid
    orderId?: string; // uuid
    customerId: number; // int32
    isGuestCustomer: boolean; // boolean
    status: string; // string
    storeViewId: number; // int32
    subtotal: number; // double
    subtotalBeforeIncentives: number; // double
    totalDiscountedSubtotal: number; // double
    grandTotal: number; // double
    grandTotalBeforeIncentives: number; // double
    totalDiscountedGrandTotal: number; // double
    itemsCount: number; // int32
    discountCode: string; // string
    subtotalDiscountAmount: number; // double
    grandTotalDiscountAmount: number; // double
    giftCards: string; // comma separated ids
    giftCardsAmount: number; // double
    storeCreditAmount: number; // double
    rewardPointsSpent: number; // int32
    rewardPointsEarned: number; // int32
    rewardPointsDiscountAmount: number; // double
    serviceFee: number; // double
    servicePercentage: number; // double
    baseCurrencyCode: CurrencyDto; // Currency type
    currency: CurrencyDto; // Currency type
    createdAt: string; // ISO 8601 date string
    updatedAt: string; // ISO 8601 date string
    items: CartItemDto[]; // array of items
}

import { Component, OnInit } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { HelpService } from './help.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../../utilities/event.service';
import { User } from '../../model/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
    subject: string;
    body: string;
    email: string;
    helpFormGroup = new UntypedFormGroup({
        customerId: new UntypedFormControl('', []),
        subject: new UntypedFormControl('', [Validators.required]),
        upload: new UntypedFormControl('', []),
        email: new UntypedFormControl('', [Validators.required]),
    });
    file: File;
    upload: any;
    loginResponse: User;
    isUserLoggedIn: boolean = false;
    customerId: number;
    userEmail: string;

    constructor(
        private helpService: HelpService,
        private eventService: EventService,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            this.isUserLoggedIn = isLoggedIn;
            if (isLoggedIn) {
                this.customerId = userData?.userId;
                this.userEmail = userData?.email;
            }
        });
    }

    submitForm() {
        const pattern: RegExp =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
        if (
            !this.helpFormGroup.controls['subject'].value ||
            !this.helpFormGroup.controls['email'].value
        ) {
            const textMsg = this.helpFormGroup.controls['subject'].value
                ? 'Email'
                : 'Message';
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                this.translate.instant('Please Enter Your ') + textMsg
            );
            return;
        }

        const isEmailValid = pattern.test(
            this.helpFormGroup.controls['email'].value
        );
        if (!isEmailValid) {
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                this.translate.instant('Please Enter Valid Email')
            );
            return;
        } else {
            if (this.file) {
                let form = new FormData();
                form.append(
                    'ticket_file_upload',
                    this.file[0],
                    this.file[0].name
                );
                form.append('filename', this.file[0].name);
                // this.helpService.upload(form).subscribe((response) => {
                //     if (response) {
                //         const type = response.type;
                //         const fileName = response.name;
                //         this.createTickets(type, fileName);
                //     }
                // });
            } else {
                this.createTickets();
            }
        }
    }

    createTickets(fileType?: string, fileName?: string) {
        const data = this.helpFormGroup.getRawValue();
        data.fileType = fileType;
        data.filename = fileName;
        data.customerId = this.customerId;
        data.body = data.subject;
        data.upload = this.upload;
        // this.helpService.sumbitTicket(data).subscribe((response) => {
        //     this.eventService.emitEvent(EventType.MODAL_INFO, response.message);
        //     if (response.status === 'success') {
        //         window.history.back();
        //     }
        // });
    }

    onUploadFile(fileDetails: any) {
        this.file = fileDetails.currentTarget.files;
    }

    redirectToLoginPage() {
        this.eventService.emitEvent(EventType.LOGIN, '/');
    }
}

<div class="notification-component-parent-div">
    <div class="dummy-nc-wrapper" *ngIf="isShowNotificationSkeleton">
        <div class="dummy-nc-container">
            <div class="dummy-nc-image animated-background"></div>
            <div class="dummy-nc-info">
                <div class="dummy-nc-desc-box">
                    <div class="dummy-nc-title animated-background"></div>
                    <div class="dummy-nc-desc animated-background"></div>
                </div>
                <div class="dummy-nc-footer">
                    <div class="dummy-nc-date animated-background"></div>
                    <div class="dummy-nc-date animated-background"></div>
                </div>
            </div>
        </div>
        <div class="dummy-nc-container">
            <div class="dummy-nc-image animated-background"></div>
            <div class="dummy-nc-info">
                <div class="dummy-nc-desc-box">
                    <div class="dummy-nc-title animated-background"></div>
                    <div class="dummy-nc-desc animated-background"></div>
                </div>
                <div class="dummy-nc-footer">
                    <div class="dummy-nc-date animated-background"></div>
                    <div class="dummy-nc-date animated-background"></div>
                </div>
            </div>
        </div>
        <div class="dummy-nc-container">
            <div class="dummy-nc-image animated-background"></div>
            <div class="dummy-nc-info">
                <div class="dummy-nc-desc-box">
                    <div class="dummy-nc-title animated-background"></div>
                    <div class="dummy-nc-desc animated-background"></div>
                </div>
                <div class="dummy-nc-footer">
                    <div class="dummy-nc-date animated-background"></div>
                    <div class="dummy-nc-date animated-background"></div>
                </div>
            </div>
        </div>
        <div class="dummy-nc-container">
            <div class="dummy-nc-image animated-background"></div>
            <div class="dummy-nc-info">
                <div class="dummy-nc-desc-box">
                    <div class="dummy-nc-title animated-background"></div>
                    <div class="dummy-nc-desc animated-background"></div>
                </div>
                <div class="dummy-nc-footer">
                    <div class="dummy-nc-date animated-background"></div>
                    <div class="dummy-nc-date animated-background"></div>
                </div>
            </div>
        </div>
        <div class="dummy-nc-container">
            <div class="dummy-nc-image animated-background"></div>
            <div class="dummy-nc-info">
                <div class="dummy-nc-desc-box">
                    <div class="dummy-nc-title animated-background"></div>
                    <div class="dummy-nc-desc animated-background"></div>
                </div>
                <div class="dummy-nc-footer">
                    <div class="dummy-nc-date animated-background"></div>
                    <div class="dummy-nc-date animated-background"></div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            parsedDataLength > 0 &&
            isUserLoggedIn &&
            !isShowNotificationSkeleton
        "
    >
        <div
            class="notification-for-loop"
            *ngFor="let notificationDetails of parsedData"
            style="display: flex; flex-wrap: wrap; justify-content: center"
        >
            <div
                class="row-1 custom-notifications-type"
                *ngIf="notificationDetails.type == 'custom_notification'"
            >
                <div [routerLink]="['/badges']">
                    <div class="column-1 notification-image">
                        <img
                            [src]="
                                notificationDetails.badge_image != ''
                                    ? notificationDetails.badge_image
                                    : '../../../assets/img/default_mofluid.jpg'
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="column-2 product-name-price-container-notification custom-notification-div-2"
                    >
                        <h5 class="notification-title">
                            {{ notificationDetails.message_title }}
                        </h5>
                        <p class="notification-desc">
                            {{ decodeBase64(notificationDetails.message) }}
                        </p>
                        <div class="inner-row-1">
                            <div class="inner-col-1">
                                <div
                                    class="prd-lstng-review custom-notification-div-5"
                                >
                                    <div
                                        class="product-listing-location ng-binding"
                                    >
                                        {{
                                            timeAgo(
                                                notificationDetails.send_date
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="inner-col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="row-2 categoryDeal-type"
                *ngIf="notificationDetails.type == 'categoryDeal'"
            >
                {{ calculateDateDiff(notificationDetails.send_date) }}
                <div
                    (click)="
                        redirectFromCatDealNoti(
                            notificationDetails.data.name,
                            notificationDetails.data.filter_data
                        )
                    "
                    *ngIf="daysHrsMinsSecs.day < 15"
                >
                    <div class="column-1 notification-image">
                        <img
                            [src]="
                                notificationDetails.imageurl != ''
                                    ? decodeBase64(notificationDetails.imageurl)
                                    : '../../../assets/img/default_mofluid.jpg'
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="column-2 product-name-price-container-notification custom-notification-div-2"
                    >
                        <h5 class="notification-title">
                            {{ notificationDetails.title }}
                        </h5>
                        <p class="notification-desc">
                            {{ notificationDetails.message }}
                        </p>
                        <div class="inner-row-1">
                            <div class="inner-col-1">
                                <div
                                    class="prd-lstng-review custom-notification-div-5"
                                >
                                    <div
                                        class="product-listing-location ng-binding"
                                    >
                                        {{
                                            timeAgo(
                                                notificationDetails.send_date
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="inner-col-2">
                                <a>{{ "View Deals" | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="row-3 productDeal-type"
                *ngIf="notificationDetails.type == 'productDeal'"
            >
                {{ calculateDateDiff(notificationDetails.send_date) }}
                <div
                    (click)="
                        redirectFromProdDealNoti(
                            notificationDetails.data.url_key
                        )
                    "
                    *ngIf="daysHrsMinsSecs.day < 15"
                >
                    <div class="column-1 notification-image">
                        <img
                            [src]="
                                notificationDetails.imageurl != ''
                                    ? decodeBase64(notificationDetails.imageurl)
                                    : '../../../assets/img/default_mofluid.jpg'
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="column-2 product-name-price-container-notification custom-notification-div-2"
                    >
                        <h5 class="notification-title">
                            {{ notificationDetails.title }}
                        </h5>
                        <p class="notification-desc">
                            {{ notificationDetails.message }}
                        </p>
                        <div class="inner-row-1">
                            <div class="inner-col-1">
                                <div
                                    class="prd-lstng-review custom-notification-div-5"
                                >
                                    <div
                                        class="product-listing-location ng-binding"
                                    >
                                        {{
                                            timeAgo(
                                                notificationDetails.send_date
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="inner-col-2">
                                <a>{{ "View Deals" | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="userNotLogin"
        style="overflow: hidden"
        *ngIf="parsedDataLength == 0 || !isUserLoggedIn"
    >
        <div>
            <h2>{{ "No Notification Available!" | translate }}</h2>
        </div>
    </div>
</div>

import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { SidebarService } from './sidebar.service';
import { Router } from '@angular/router';
import { NavigationItem } from '../model/menu';
import { EventService, EventType } from '../utilities/event.service';
import { SectionsService } from '../shared/sections.service';
import { UserService } from '../services/user.service';
import { RewardsService } from '../services/rewards.service';

@Component({
    selector: 'md-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
    sidebarItems: NavigationItem[] = [];

    sidebarOpen = false;
    showAccountLinks = false;
    isUserLoggedIn = false;
    balancePoints = 0;
    badgeCount = 0;
    userName = '';

    private touchStartListener: (event: TouchEvent) => void;
    private touchEndListener: (event: TouchEvent) => void;

    constructor(
        private service: SidebarService,
        private router: Router,
        private eventService: EventService,
        private sectionsService: SectionsService,
        private userService: UserService,
        private rewardsService: RewardsService,
        private elRef: ElementRef
    ) {}

    ngOnInit(): void {
        this.service.toggleEvent.subscribe((isOpen) => {
            this.sidebarOpen = isOpen;
        });
        this.sectionsService.getSections().subscribe((data) => {
            const { sections } = data;
            this.sidebarItems = sections.map((section) => {
                return {
                    id: section.sectionId,
                    name: section.sectionName,
                    icon: section.icon,
                    // TODO: this needs to be handled on the new plp
                    path: `/plp/${section.sectionName}/${section.sectionId}`,
                };
            });
        });

        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            this.isUserLoggedIn = isLoggedIn;
            if (isLoggedIn) {
                this.userName = userData.userName;
                this.badgeCount = userData.badgeCount;
            } else {
                this.userName = '';
            }
        });

        this.rewardsService.rewardsPoints$.subscribe((points) => {
            this.balancePoints = points?.rewardPointsBalance;
        });
    }

    ngAfterViewInit(): void {
        const element = this.elRef.nativeElement.querySelector('.sb-overlay');

        // Add event listeners with passive: true using native addEventListener
        this.touchStartListener = (event: TouchEvent) =>
            this.swipe(event, 'start');
        this.touchEndListener = (event: TouchEvent) => this.swipe(event, 'end');
        element.addEventListener('touchstart', this.touchStartListener, {
            passive: true,
        });
        element.addEventListener('touchend', this.touchEndListener, {
            passive: true,
        });
    }

    ngOnDestroy(): void {
        const element = this.elRef.nativeElement.querySelector('.sb-overlay');
        if (element) {
            element.removeEventListener('touchstart', this.touchStartListener);
            element.removeEventListener('touchend', this.touchEndListener);
        }
    }

    closeSidebar() {
        this.service.toggle();
    }

    logout() {
        this.userService.setIsLoggedIn(false, {
            withMessage: true,
            notifyServerOfSignOut: true,
            isManualSignOut: true,
        });
    }

    toggleAccountLinks(e: Event) {
        e.stopPropagation();
        this.showAccountLinks = !this.showAccountLinks;
    }

    numberWithCommas(x: number) {
        return new Intl.NumberFormat().format(x);
    }

    redirectToChallengesPage() {
        if (this.badgeCount != 0 || this.balancePoints != 0) {
            this.router.navigate(['/rewardpoints']);
        }
    }

    onSidebarItemClick(sidebarItem: NavigationItem, event: Event) {
        if (sidebarItem.hasChildren) {
            this.toggleChildrenVisibility(sidebarItem, event);
        } else {
            this.redirectToCategoryPage(sidebarItem, event);
        }
    }

    redirectToRodriguesPage(
        sidebarItem: NavigationItem,
        event: Event,
        subpageIndex: number
    ) {
        if (subpageIndex !== undefined) {
            this.redirectToCategoryPage(
                {
                    ...sidebarItem,
                    id: Number(sidebarItem.children[subpageIndex].id),
                },
                event,
                true
            );
        } else {
            this.redirectToCategoryPage(sidebarItem, event, true);
        }
    }

    toggleChildrenVisibility(drawerItem: NavigationItem, event: Event) {
        event.stopPropagation();
        drawerItem.areChildrenExpanded = !drawerItem.areChildrenExpanded;
    }

    redirectToCategoryPage(
        drawerItem: NavigationItem,
        event: Event,
        navigateInPlace = false
    ) {
        event.preventDefault();

        const { id, path } = drawerItem;
        const navigationParams = {
            state: {
                catId: id,
                isAll: 0, // this ended up always being 0
                advancesearch: '',
                filterdata: '',
                category_name: path,
            },
        };

        this.router.navigate([path], navigationParams);
    }

    swipeCoord: [number, number];
    swipeTime: number;

    swipe(e: TouchEvent, when: string): void {
        const coord: [number, number] = [
            e.changedTouches[0].clientX,
            e.changedTouches[0].clientY,
        ];
        const time = new Date().getTime();
        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [
                coord[0] - this.swipeCoord[0],
                coord[1] - this.swipeCoord[1],
            ];
            const duration = time - this.swipeTime;

            if (
                duration < 1000 && //
                Math.abs(direction[0]) > 30 && // Long enough
                Math.abs(direction[0]) > Math.abs(direction[1] * 3)
            ) {
                // Horizontal enough
                // const swipe = direction[0] < 0 ? 'next' : 'previous';
                // Do whatever you want with swipe
                this.closeSidebar();
            }
        }
    }

    redirectToLogin() {
        this.eventService.emitEvent(EventType.LOGIN);
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryListComponent } from './category-deals-list/category-deals-list.component';
import { HomeComponent } from './home/home.component';
import { EgiftCardComponent } from './egift-card/egift-card.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CheckoutComponent } from './user/checkout/checkout.component';
import { NoInternetComponent } from './no-internet/no-internet.component';
import { SearchComponent } from './search/search.component';
import { canAccessLoggedInResource } from './guards/auth.guard';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: { title: 'Home' },
    },
    {
        path: 'checkRedirectPage',
        component: HomeComponent,
    },
    {
        path: 'search/:sectionId',
        component: SearchComponent,
    },
    {
        path: 'search',
        component: SearchComponent,
    },
    {
        path: 'plp/:sectionName/22',
        component: EgiftCardComponent,
        data: { title: 'Gift Voucher' },
    },
    {
        path: 'plp/:sectionName/:sectionId',
        component: CategoryListComponent,
        data: { title: 'Product list' },
    },
    {
        path: 'gift-voucher',
        component: EgiftCardComponent,
        data: { title: 'Gift Voucher' },
    },
    {
        path: 'giftcard',
        component: EgiftCardComponent,
        data: { title: 'Gift Card' },
    },
    {
        path: 'giftcard.html',
        component: EgiftCardComponent,
        data: { title: 'Gift Card' },
    },
    {
        path: 'detail',
        component: ProductDetailComponent,
        data: { title: 'Product Detail' },
    },
    {
        path: 'product-detail',
        component: ProductDetailComponent,
        data: { title: 'Product Detail' },
    },
    {
        path: 'deal/:productPath/:productId',
        component: ProductDetailComponent,
        data: { title: 'Product Detail' },
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Checkout' },
    },
    {
        path: 'cart',
        component: CheckoutComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Cart' },
    },
    {
        path: 'no-internet',
        component: NoInternetComponent,
        data: { title: 'No Internet' },
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'register',
        loadChildren: () =>
            import('./register/register.module').then((m) => m.RegisterModule),
    },
    {
        path: 'password',
        loadChildren: () =>
            import('./forgotten-password/forgotten-password.module').then(
                (m) => m.ForgottenPasswordModule
            ),
    },
    {
        path: 'customer/account',
        loadChildren: () =>
            import('./customer-account/customer-account.module').then(
                (m) => m.CustomerAccountModule
            ),
    },
    {
        path: 'error',
        component: ErrorPageComponent,
        data: { title: 'Error' },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

<div>
    <div *ngIf="isUserLoggedIn && !isStoreCreditData" style="margin-top: 55px">
        <ul class="ma-gc-list">
            <li mdDesktopOnly class="ma-gc-list-item header">
                <div>{{ "Date" | translate }}</div>
                <div>{{ "Description" | translate }}</div>
                <div>{{ "Amount" | translate }}</div>
                <div>{{ "Balance" | translate }}</div>
            </li>
            <li
                class="ma-gc-list-item"
                *ngFor="let transactions of storeCreditHistory"
            >
                <div>
                    <p mdMobileOnly>{{ "Date" | translate }}:</p>
                    <p>{{ transactions?.date }}</p>
                </div>
                <div>
                    <p mdMobileOnly>{{ "Description" | translate }}:</p>
                    <p>{{ transactions?.description }}</p>
                </div>
                <div>
                    <p mdMobileOnly>{{ "Amount" | translate }}:</p>
                    <p>
                        {{
                            transactions?.operator +
                                (transactions?.amount
                                    | currency
                                        : currencySymbol
                                        : "symbol"
                                        : "1.0-2"
                                        : locale)
                        }}
                    </p>
                </div>
                <div>
                    <p mdMobileOnly>{{ "Balance" | translate }}:</p>
                    <p>
                        {{
                            transactions?.balance
                                | currency
                                    : currencySymbol
                                    : "symbol"
                                    : "1.0-2"
                                    : locale
                        }}
                    </p>
                </div>
            </li>
        </ul>
    </div>
</div>
<div style="margin-top: 52px">
    <md-rewards-and-wallet-loading *ngIf="isStoreCreditData" />
</div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, tap, throwError } from 'rxjs';
import { getAuthUrl } from '../utilities/url.utils';
import { environment } from 'src/environments/environment';
import { UserDataDao } from './user.service';
import { GeneralErrorService } from '../components/general-error/general-error.service';
import { TranslateService } from '@ngx-translate/core';

export const TOKEN_KEY = 'auth-token-new';
const basicToken = `Basic ${btoa(
    `${environment.tokenShard1}:${environment.tokenShard2}`
)}`;

@Injectable({
    providedIn: 'root',
})
export class AuthHttpClientService {
    constructor(
        private httpClient: HttpClient,
        private generalErrorService: GeneralErrorService,
        private translate: TranslateService
    ) {}

    getNotLoggedIn<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(url, {
            headers: this.getBasicTokenHeaders(),
        });
    }

    postNotLoggedIn<T, TIn>(url: string, data: TIn): Observable<T> {
        return this.httpClient.post<T>(url, data, {
            headers: this.getBasicTokenHeaders(),
        });
    }

    get<T>(url: string) {
        return this.request<T, null>('GET', url);
    }

    post<T, TIn>(url: string, data: TIn) {
        return this.request<T, TIn>('POST', url, data);
    }

    put<T, TIn>(url: string, data: TIn) {
        return this.request<T, TIn>('PUT', url, data);
    }

    delete<T>(url: string) {
        return this.request<T, null>('DELETE', url);
    }

    request<T, TIn>(
        method: string,
        url: string,
        data: TIn = null,
        onlyBasicToken: boolean = false
    ): Observable<T> {
        return this.retry<T, TIn>(method, url, data, 3, onlyBasicToken);
    }

    decodeToken(retries = 3): Observable<UserDataDao> {
        return this.request<UserDataDao, { token: string }>(
            'POST',
            `${getAuthUrl()}decode-token`,
            { token: localStorage.getItem(TOKEN_KEY) },
            true
        ).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && retries > 0) {
                    return this.refreshToken().pipe(
                        switchMap(() => this.decodeToken(retries - 1))
                    );
                }
                throw error;
            })
        );
    }

    private retry<T, TIn>(
        method: string,
        url: string,
        data: TIn,
        maxRetries: number = 3,
        onlyBasicToken: boolean = false
    ): Observable<T> {
        return this.httpClient
            .request<T>(method, url, {
                headers: onlyBasicToken
                    ? this.getBasicTokenHeaders()
                    : this.getAuthTokenHeaders(),
                body: data,
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 && maxRetries > 0) {
                        return this.refreshToken().pipe(
                            switchMap(() =>
                                this.retry<T, TIn>(method, url, data, maxRetries - 1)
                            )
                        );
                    }
                    throw error;
                })
            );
    }

    private refreshToken(): Observable<any> {
        const refreshToken = localStorage.getItem(TOKEN_KEY);
        if (refreshToken) {
            return this.httpClient
                .post(
                    `${getAuthUrl()}refresh-token/`,
                    { expiredToken: refreshToken },
                    { headers: this.getBasicTokenHeaders() }
                )
                .pipe(
                    tap((response: any) => {
                        localStorage.setItem(TOKEN_KEY, response.token);
                    })
                );
        } else {
            this.generalErrorService.showGeneralError(
                this.translate.instant(
                    'Something is wrong with your session. Please log in again.'
                )
            );
            return throwError(() => new Error('Refresh token not found'));
        }
    }

    private getAuthTokenHeaders() {
        const token = localStorage.getItem(TOKEN_KEY);
        return {
            'X-Bearer-Token': `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: basicToken,
        };
    }

    private getBasicTokenHeaders() {
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: basicToken,
        };
    }
}

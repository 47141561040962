import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ReviewsService } from '../reviews.service';
import {
    AddReviewResponseDto,
    NewReviewDataResponseDto,
    ReviewCustomerOutletData,
    transformNewReviewDataResponse,
} from '../reviews.model';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { encodeForUrl } from 'src/app/utilities/encoders';

type RatingOption = 'poor' | 'average' | 'good' | 'great';

@Component({
    selector: 'md-write-review',
    templateUrl: './write-review.component.html',
    styleUrls: ['./write-review.component.scss'],
})
export class WriteReviewComponent implements OnInit {
    @Input() productId: number;

    ratingOptionVal = new Map<RatingOption, string>([
        ['poor', '1'],
        ['average', '2'],
        ['good', '3'],
        ['great', '4'],
    ]);
    writeReview = {
        name: '',
        review: '',
        service: '',
        food: '',
        hotel: '',
        room: '',
        rwOption: '',
        outlet: '',
    };
    rwOption = '';
    customer_outletdata: ReviewCustomerOutletData[] = [];
    customerName: string;
    promptMessage = '';
    isshowWriteReview = true;
    reviewFormGroup = new UntypedFormGroup({
        customerName: new UntypedFormControl({ value: '', disabled: true }, [
            Validators.required,
        ]),
        selectCategory: new UntypedFormControl('', [Validators.required]),
        experience: new UntypedFormControl('', [Validators.required]),
        yourReview: new UntypedFormControl('', [Validators.required]),
    });
    isWriteReviewSkeletonShow = true;
    isReviewBtnEnable = true;
    hasError = false;
    selectedOutlet_data: ReviewCustomerOutletData;

    @Output() reviewSubmitted = new EventEmitter();

    constructor(
        private reviewService: ReviewsService,
        private common: CommonService,
        private translate: TranslateService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        this.fetchUserOutletData();
    }

    fetchUserOutletData() {
        this.isWriteReviewSkeletonShow = true;
        // this.reviewService.getNewReviewData(this.productId).subscribe(
        //     (response: NewReviewDataResponseDto) => {
        //         this.isWriteReviewSkeletonShow = false;
        //         if (response) {
        //             const newReviewResponse =
        //                 transformNewReviewDataResponse(response);
        //             const currOutlet = newReviewResponse.currentOutlet;
        //             this.customerName = newReviewResponse.customerName;
        //             if (currOutlet) {
        //                 this.reviewFormGroup.controls['customerName'].setValue(
        //                     newReviewResponse.customerName
        //                 );

        //                 this.selectedOutlet_data = currOutlet;
        //             }
        //             const selectedOutlet =
        //                 newReviewResponse.customerOutletData.find(
        //                     (item: ReviewCustomerOutletData) =>
        //                         item.outletId === currOutlet?.outletId
        //                 );
        //             if (selectedOutlet) {
        //                 this.reviewFormGroup.controls['yourReview'].setValue(
        //                     selectedOutlet.reviewData?.detail
        //                 );
        //                 this.reviewFormGroup.controls['experience'].setValue(
        //                     selectedOutlet.reviewData?.title?.toLowerCase()
        //                 );
        //                 this.reviewFormGroup.controls[
        //                     'selectCategory'
        //                 ].setValue(currOutlet.outletId);
        //             }

        //             this.customer_outletdata =
        //                 newReviewResponse.customerOutletData.filter(
        //                     (item: ReviewCustomerOutletData) =>
        //                         item.outletId !== 0
        //                 );

        //             if (this.selectedOutlet_data?.outletId) {
        //                 const outletName = this.selectedOutlet_data.outletName;
        //                 const selectedOutlet = this.customer_outletdata.find(
        //                     (item) => item.outletName === outletName
        //                 );
        //                 const outletId = selectedOutlet
        //                     ? selectedOutlet.outletId
        //                     : this.selectedOutlet_data.outletId;
        //                 this.reviewFormGroup.controls[
        //                     'selectCategory'
        //                 ].setValue(outletId);
        //             }

        //             if (this.customer_outletdata.length > 0) {
        //                 this.promptMessage =
        //                     "You haven't placed an order with " + currOutlet
        //                         ? currOutlet.outletName
        //                         : '' + '<br><b>Please select another Property:';
        //             } else {
        //                 this.isshowWriteReview = false;
        //                 this.promptMessage =
        //                     "You haven't placed any order - Please write a review after placing an order";
        //             }
        //         }
        //     },
        //     () => {
        //         this.isWriteReviewSkeletonShow = false;
        //     }
        // );
    }

    changeReview() {
        const outletId = this.reviewFormGroup.controls['selectCategory'].value;
        this.customer_outletdata.forEach((item) => {
            if (item.outletId == outletId) {
                this.reviewFormGroup.controls['yourReview'].setValue(
                    item.reviewData?.detail
                );
                this.reviewFormGroup.controls['experience'].setValue(
                    item.reviewData?.title?.toLowerCase()
                );
            }
        });
        if (!outletId) {
            this.reviewFormGroup.controls['yourReview'].setValue('');
        }
    }

    submitReview() {
        this.isReviewBtnEnable = false;
        if (!this.reviewFormGroup.controls['selectCategory'].value) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Please select the property to submit the review'
                )
            );
            this.hasError = true;
            this.isReviewBtnEnable = true;
            return;
        }

        if (!this.reviewFormGroup.controls['experience'].value) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Please select the your experience to submit the review'
                )
            );
            this.hasError = true;
            this.isReviewBtnEnable = true;
            return;
        }

        const selectedOutlet = this.customer_outletdata.find(
            (item) =>
                item.outletId ===
                Number(this.reviewFormGroup.controls['selectCategory'].value)
        );

        const rwlen = this.reviewFormGroup.controls['yourReview'].value.length;
        if (rwlen < 35) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant('Please enter at least 35 characters')
            );
            this.isReviewBtnEnable = true;
            return;
        }

        const writeReviewObj = {
            detail: this.reviewFormGroup.controls['yourReview'].value,
            title: this.reviewFormGroup.controls['experience'].value,
            room_rating: this.ratingOptionVal.get(
                this.reviewFormGroup.controls['experience'].value
            ),
            food_rating: this.ratingOptionVal.get(
                this.reviewFormGroup.controls['experience'].value
            ),
            name: this.customerName,
            service_rating: this.ratingOptionVal.get(
                this.reviewFormGroup.controls['experience'].value
            ),
            order_number: '',
            hotel_rating: this.ratingOptionVal.get(
                this.reviewFormGroup.controls['experience'].value
            ),
            outlet_id: this.reviewFormGroup.controls['selectCategory'].value,
            review_id: selectedOutlet ? selectedOutlet.reviewData.reviewId : '',
        };
        const payload = {
            details: encodeForUrl(JSON.stringify(writeReviewObj)),
            productId: this.productId,
        };
        this.common.startProgress();
        this.reviewSubmitted.emit();
        this.reviewService
            .addReviewData(payload)
            .subscribe((response: AddReviewResponseDto) => {
                this.common.endProgress();
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant(response.review)
                );
            });
    }

    rwOptionSelected(type: RatingOption) {
        this.reviewFormGroup.controls['experience'].setValue(type);
    }
}

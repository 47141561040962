import { Location } from '@angular/common';
import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SidebarService } from '../sidebar/sidebar.service';
import { CommonService } from '../shared/common.service';
import { getCache } from '../utilities/cache.utils';
import {
    ACTIVITY_CATEGORY,
    DAY_PACKAGE_CATEGORY,
    EVENT_CATEGORY,
    HOTEL_CATEGORY,
    MARIBNB_CATEGORY,
    RESTAURANT_CATEGORY,
    RODRIGUES_CAR_RENTAL_CATEGORY,
    RODRIGUES_CATEGORY,
    RODRIGUES_EXCURSIONS_CATEGORY,
    RODRIGUES_HOTELS_CATEGORY,
    RODRIGUES_RESTAURANTS_CATEGORY,
    SPA_CATEGORY,
} from '../static-content/menu-routes';
import { removeQueryParams } from '../utilities/url.utils';
import { EventService, EventType } from '../utilities/event.service';
import { ScreenSizeService } from '../services/screen-size.service';
import { UserService } from '../services/user.service';

export type SearchBarType =
    | 'datesGuests'
    | 'dates'
    | 'months'
    | 'plain'
    | undefined;

@Component({
    selector: 'md-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    showBackButton = true;
    showMobileLanguagePicker = true;
    showMobileLogo = true;
    showMobilePageTitle = false;
    visibleSearchBarType: SearchBarType;
    catId?: number;
    isUserLoggedIn = false;

    @ViewChild('login') login: TemplateRef<never>;

    popperOptions = (options) => {
        options.onFirstUpdate = (state) => {
            if (state.elements?.arrow) {
                state.elements.arrow.style.display = 'none';
            }
        };
        return options;
    };

    private routingSubscription: Subscription;
    private tempVisibleSearchBarType: SearchBarType;
    private tempPageTitle: string;

    constructor(
        public common: CommonService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private eventService: EventService,
        private location: Location,
        private sidebarService: SidebarService,
        private screenSizeService: ScreenSizeService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.routingSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.catId = this.activatedRoute.snapshot.params['sectionId'];
                this.setSearchBarVariant();

                this.showBackButton = !this.isHomePage() && this.hasHistory();
                this.showMobileLanguagePicker = this.isHomePage();
                this.showMobileLogo = this.isHomePage();
                this.showMobilePageTitle =
                    !this.showMobileLogo &&
                    this.visibleSearchBarType === undefined;
            }
        });
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.isUserLoggedIn = isLoggedIn;
        });
    }

    private setSearchBarVariant() {
        if (
            !this.screenSizeService.isMobile ||
            this.isPageWithVisibleSearchBar()
        ) {
            this.visibleSearchBarType = 'plain';
        } else {
            this.visibleSearchBarType = this.setSearchTypeBasedOnCatId(
                this.catId
            );
        }
    }

    ngOnDestroy(): void {
        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }
    }

    goBack() {
        this.location.back();
    }

    openSidebar() {
        this.sidebarService.toggle();
    }

    getCartItemCount(): number {
        const localCart = this.userService.getLocalCart();
        return localCart?.itemCount || 0;
    }

    logout() {
        this.userService.setIsLoggedIn(false, {
            withMessage: true,
            notifyServerOfSignOut: true,
            isManualSignOut: true,
        });
    }

    goToCheckout() {
        this.router.navigate(['checkout']);
    }

    togglePlainSearch() {
        if (this.visibleSearchBarType !== 'plain') {
            this.tempVisibleSearchBarType = this.visibleSearchBarType;
            this.tempPageTitle = this.common.pageTitle;
            this.common.pageTitle = '';
            this.visibleSearchBarType = 'plain';
        } else {
            this.visibleSearchBarType = this.tempVisibleSearchBarType;
            this.common.pageTitle = this.tempPageTitle;
        }
    }

    private setSearchTypeBasedOnCatId(
        catId: number
    ): 'datesGuests' | 'dates' | 'months' | 'plain' | undefined {
        switch (catId) {
            case HOTEL_CATEGORY:
            case MARIBNB_CATEGORY:
                this.visibleSearchBarType = 'datesGuests';
                return this.visibleSearchBarType;
            case DAY_PACKAGE_CATEGORY:
                this.visibleSearchBarType = 'dates';
                return this.visibleSearchBarType;
            case EVENT_CATEGORY:
                this.visibleSearchBarType = 'months';
                return this.visibleSearchBarType;
            case SPA_CATEGORY:
            case ACTIVITY_CATEGORY:
            case RESTAURANT_CATEGORY:
            case RODRIGUES_CATEGORY:
            case RODRIGUES_CAR_RENTAL_CATEGORY:
            case RODRIGUES_EXCURSIONS_CATEGORY:
            case RODRIGUES_HOTELS_CATEGORY:
            case RODRIGUES_RESTAURANTS_CATEGORY:
                this.visibleSearchBarType = 'plain';
                return this.visibleSearchBarType;
            default:
                this.visibleSearchBarType = undefined;
                return this.visibleSearchBarType;
        }
    }

    private isPageWithVisibleSearchBar(): boolean {
        const isSearchPage = this.router.url.includes('search');
        const isDatesSearch = this.router.url.includes('fromDate');
        return isSearchPage && !isDatesSearch;
    }

    // TODO: These will most likely need to be moved to some other place
    private hasHistory(): boolean {
        return window.history.length > 0;
    }

    private isHomePage(): boolean {
        return removeQueryParams(this.router.url) === '/';
    }

    openLogin() {
        this.eventService.emitEvent(EventType.LOGIN);
    }
}

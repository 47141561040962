import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'md-dob-picker',
    templateUrl: './dob-picker.component.html',
    styleUrls: ['./dob-picker.component.scss'],
})
export class DobPickerComponent implements OnInit {
    @Input() isDobEditable = true;
    private monthsArray: { label: string; value: string }[] = [];

    @Output() dobSelected = new EventEmitter<NgbDate>();

    dobFormGroup = new UntypedFormGroup({
        dobmonth: new UntypedFormControl('', []),
        dobdate: new UntypedFormControl('', []),
        dobyear: new UntypedFormControl('', []),
    });

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.monthsArray = [
            {
                label: this.translate.instant('January'),
                value: '01',
            },
            {
                label: this.translate.instant('February'),
                value: '02',
            },
            {
                label: this.translate.instant('March'),
                value: '03',
            },
            {
                label: this.translate.instant('April'),
                value: '04',
            },
            {
                label: this.translate.instant('May'),
                value: '05',
            },
            {
                label: this.translate.instant('June'),
                value: '06',
            },
            {
                label: this.translate.instant('July'),
                value: '07',
            },
            {
                label: this.translate.instant('August'),
                value: '08',
            },
            {
                label: this.translate.instant('September'),
                value: '09',
            },
            {
                label: this.translate.instant('October'),
                value: '10',
            },
            {
                label: this.translate.instant('November'),
                value: '11',
            },
            {
                label: this.translate.instant('December'),
                value: '12',
            },
        ];
    }

    monthSelectionChanged() {
        this.onDateItemChanged();
    }

    dateSelectionChanged() {
        this.onDateItemChanged();
    }

    yearSelectionChanged() {
        this.onDateItemChanged();
    }

    private onDateItemChanged() {
        if (
            this.dobFormGroup.controls['dobmonth'].value &&
            this.dobFormGroup.controls['dobdate'].value &&
            this.dobFormGroup.controls['dobyear'].value
        ) {
            this.dobSelected.emit(
                new NgbDate(
                    parseInt(this.dobFormGroup.controls['dobyear'].value, 10),
                    parseInt(this.dobFormGroup.controls['dobmonth'].value, 10),
                    parseInt(this.dobFormGroup.controls['dobdate'].value, 10)
                )
            );
        }
    }

    getMonthArray() {
        return this.monthsArray;
    }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { screenSizes } from 'src/app/utilities/theme';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../services/user.service';

export enum ProfileSection {
    ORDERS,
    TRIPS,
    CHALLENGES,
    REVIEWS,
    GIFT_CARDS,
    STORE_CREDIT,
    EDIT_PROFILE,
    SETTINGS,
}

@Component({
    selector: 'md-profile-wrapper',
    templateUrl: './profile-wrapper.component.html',
    styleUrls: ['./profile-wrapper.component.scss'],
})
export class ProfileWrapperComponent implements OnInit {
    isMobile = new BehaviorSubject(false);

    @Input() activeSection: ProfileSection = null;

    ngOnInit(): void {
        this.checkScreenSize();
    }

    constructor(private userService: UserService) {}

    @HostListener('window:resize')
    onResize(): void {
        this.checkScreenSize();
    }

    private checkScreenSize(): void {
        const width = window.innerWidth;
        this.isMobile.next(width <= screenSizes.mobile);
    }

    logout() {
        this.userService.setIsLoggedIn(false, {
            withMessage: true,
            notifyServerOfSignOut: true,
            isManualSignOut: true,
        });
    }

    protected readonly ProfileSection = ProfileSection;
}

<div class="pane">
    <div *ngIf="!networkStatus">
        <app-no-internet />
    </div>
    <!-- <md-overlay></md-overlay> -->
    <div *ngIf="networkStatus" class="home-content">
        <md-header />
        <md-navbar />
        <md-call-to-action />
        <app-timer
            [promotionBanner]="promotionBanner"
            [promotionBanner_1]="promotionBanner_1"
            [is_promotion_started]="is_promotion_started"
            [is_promotion_activated]="is_promotion_activated"
            *ngIf="showTimer"
            [minutes]="minutesLeftInPromotion"
        />
        <div class="routerContainer">
            <router-outlet></router-outlet>
        </div>
        <md-footer *ngIf="showFooter" />
        <md-sidebar />
    </div>
    <ngx-spinner id="ngx-spinner" type="ball-scale-multiple">
        <p
            *ngIf="commonService.loadingText"
            style="font-size: 20px; color: white"
        >
            {{ commonService.loadingText }}
        </p>
    </ngx-spinner>
    <md-general-error />
</div>

<md-events-wrapper></md-events-wrapper>
<md-screen-size-util></md-screen-size-util>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceService } from './invoice.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-vat-invoice',
    templateUrl: './vat-invoice.component.html',
    styleUrls: ['./vat-invoice.component.scss'],
})
export class VatInvoiceComponent implements OnInit {
    @Output() requestSent = new EventEmitter<void>();

    companyProfileStatus: any;
    isVatInvoiceShowSkeleton: boolean = false;
    customerId: any;
    loginStatus!: number;
    showPopupBox: boolean = false;
    showPopupBoxMsg: string = '';

    invoiceFormGroup = new UntypedFormGroup({
        personName: new UntypedFormControl('', []),
        companyName: new UntypedFormControl('', []),
        companyAddress: new UntypedFormControl('', []),
        brn: new UntypedFormControl('', []),
        vat: new UntypedFormControl('', []),
    });
    userCompanyProfile: any;

    constructor(
        private invoiceService: InvoiceService,
        private router: Router,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            if (isLoggedIn) {
                this.customerId = userData?.userId;
                this.getComapnyProfileInfo();
            }
        });
    }

    getComapnyProfileInfo() {
        this.isVatInvoiceShowSkeleton = true;
        this.invoiceService.getCompanyProfile().subscribe(
            (response: any) => {
                this.isVatInvoiceShowSkeleton = false;
                if (response) {
                    this.userCompanyProfile = response;
                    this.invoiceFormGroup.controls['personName'].setValue(
                        this.userCompanyProfile.legal_personal_name
                    );
                    this.invoiceFormGroup.controls['companyName'].setValue(
                        this.userCompanyProfile.legal_company_name
                    );
                    //    console.log(this.userCompanyProfile.legal_company_name);
                    this.invoiceFormGroup.controls['companyAddress'].setValue(
                        this.userCompanyProfile.company_address
                    );
                    this.invoiceFormGroup.controls['brn'].setValue(
                        this.userCompanyProfile.brn
                    );
                    this.invoiceFormGroup.controls['vat'].setValue(
                        this.userCompanyProfile.vat
                    );
                    this.companyProfileStatus = this.userCompanyProfile.status;
                    if (
                        this.companyProfileStatus == 'Pending' ||
                        this.companyProfileStatus == 'Approved'
                    ) {
                        this.invoiceFormGroup.disable();
                    }
                }
            },
            (error) => {
                console.log(error);
                this.isVatInvoiceShowSkeleton = false;
            }
        );
    }

    saveCompanyProfile(data: any) {
        if (
            this.invoiceFormGroup.controls['personName'].value == '' ||
            this.invoiceFormGroup.controls['personName'].value == null
        ) {
            this.showPopupBox = true;
            this.showPopupBoxMsg = 'Please enter the legal personal name.';
        } else if (
            this.invoiceFormGroup.controls['companyName'].value == '' ||
            this.invoiceFormGroup.controls['companyName'].value == null
        ) {
            this.showPopupBox = true;
            this.showPopupBoxMsg = 'Please enter the legal company name.';
        } else if (
            this.invoiceFormGroup.controls['companyAddress'].value == '' ||
            this.invoiceFormGroup.controls['companyAddress'].value == null
        ) {
            this.showPopupBox = true;
            this.showPopupBoxMsg = this.translate.instant(
                'Please enter the legal company address'
            );
        } else {
            const payload = {
                customerId: this.customerId,
                personName: this.invoiceFormGroup.controls['personName'].value,
                companyName:
                    this.invoiceFormGroup.controls['companyName'].value,
                companyAddress:
                    this.invoiceFormGroup.controls['companyAddress'].value,
                brn: this.invoiceFormGroup.controls['brn'].value,
                vat: this.invoiceFormGroup.controls['vat'].value,
            };
            this.invoiceService
                .submitCompanyProfile(payload)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.companyProfileStatus = response.invoice_status;
                        if (
                            this.companyProfileStatus == 'Pending' ||
                            this.companyProfileStatus == 'Approved'
                        ) {
                            this.invoiceFormGroup.disable();
                        }
                        this.requestSent.emit();
                    }
                    this.showPopupBox = true;
                    this.showPopupBoxMsg = response.message;
                });
        }
    }
}

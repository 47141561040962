import { Injectable } from '@angular/core';
import { AuthHttpClientNoXService } from './auth-http-client-noX.service';
import { getAvailabilityUrl, getBookingUrl } from '../utilities/url.utils';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { LocaleData } from '../shared/currency-language.service';
import { RewardsData } from '../product-detail/booking-config/booking-config.model';

export interface RewardPointsData {
    rewardPointsBalance: number;
    rewardPointsMoney: number;
}

export interface RewardsResponseDto {
    amount: number;
    earningPoints: number;
}

@Injectable({
    providedIn: 'root',
})
export class RewardsService {
    private rewardsPointsSubject = new BehaviorSubject<RewardPointsData>(null);
    rewardsPoints$ = this.rewardsPointsSubject.asObservable();

    private lastCustomerInfo: { customerGroupId: number; countryCode: string };

    constructor(private authHttpClient: AuthHttpClientNoXService) {}

    retreiveRewardPoints(customerGroupId: number, countryCode: string) {
        this.lastCustomerInfo = { customerGroupId, countryCode };
        this.authHttpClient
            .get<{ rewardPointsBalance: number }>(
                `${getBookingUrl()}reward-points`
            )
            .subscribe({
                next: (response) => {
                    const points = response.rewardPointsBalance;

                    const postBody = {
                        orgId: 1,
                        customerGroupId,
                        countryCode,
                        currencyId: 1,
                        rewardPoints: points,
                    };
                    this.authHttpClient
                        .post<{ amount: number }, typeof postBody>(
                            `${getAvailabilityUrl()}reward-points/convert`,
                            postBody
                        )
                        .subscribe({
                            next: (response) => {
                                this.rewardsPointsSubject.next({
                                    rewardPointsBalance: points,
                                    rewardPointsMoney: response.amount,
                                });
                            },
                            error: (error) => {
                                console.error(
                                    'Error converting reward points to money',
                                    error
                                );
                            },
                        });
                },
            });
    }

    // **
    //  * Invalidates the rewards points cache. This is to be used when we know the rewards points have changed.
    //  */
    invalidateRewardsPoints() {
        if (this.lastCustomerInfo) {
            return;
        }

        this.retreiveRewardPoints(
            this.lastCustomerInfo.customerGroupId,
            this.lastCustomerInfo.countryCode
        );
    }

    getRewardPoints(subtotal: number, locale: LocaleData): Observable<number> {
        const postBody = {
            orgId: 1,
            customerGroupId: locale.customerGroupId,
            countryCode: locale.country,
            subtotal,
        };

        return this.authHttpClient
            .post<RewardsResponseDto, typeof postBody>(
                `${getAvailabilityUrl()}reward-points/calc`,
                postBody
            )
            .pipe(
                map((response) => {
                    // TODO: earning_points is returned as 0 when the user is not logged in. This is temporary
                    const { earningPoints, amount } =
                        this.transformRewardsData(response);
                    if (earningPoints) {
                        return earningPoints;
                    } else {
                        return this.calculatePointsBasedOnPrice(amount, locale);
                    }
                })
            );
    }

    getRewardsPointsMoney() {}

    private transformRewardsData(
        rewardsResponseDto: RewardsResponseDto
    ): RewardsData {
        return {
            amount: rewardsResponseDto.amount,
            earningPoints: rewardsResponseDto.earningPoints,
        };
    }

    private calculatePointsBasedOnPrice(
        price: number,
        locale: LocaleData
    ): number {
        const rewardPointRate = 0.1;
        const currencySymbol = locale.currency;
        if (currencySymbol === 'EUR') {
            const conversionRate = 0.02;
            const priceInRupees = price * conversionRate;
            return priceInRupees * rewardPointRate;
        } else if (currencySymbol === 'Rs.') {
            return price * rewardPointRate;
        }
    }
}

<div
    class="lmp-globe"
    [ngClass]="{ 'lmp-globe--dark': isDark }"
    (click)="openModal(content)"
>
    <div>
        <ion-icon class="lmp-globe-icon" name="globe-outline"></ion-icon>
    </div>
    <div *ngIf="isCountryVisible">
        <p class="para">{{ country }} ({{ language?.toUpperCase() }})</p>
    </div>
</div>

<ng-template #content let-modal id="country-picker-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{ "Choose settings" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form class="modalform">
            <div class="drp1" [hidden]="countryPickerHidden">
                <div>
                    <label id="Citizenship">
                        {{ "Country of Citizenship or Residency" | translate }}
                    </label>
                </div>
                <select
                    id="Country"
                    (change)="switchCountry($event.target.value)"
                >
                    <option
                        *ngFor="let countryOption of countries"
                        [value]="countryOption.code"
                        [selected]="countryOption.code === country"
                    >
                        {{ countryOption.name }}
                    </option>
                </select>
            </div>

            <div class="drp2">
                <div>
                    <label id="Langu">
                        <label> {{ "Language" | translate }} </label>
                    </label>
                </div>
                <select
                    id="Language"
                    (change)="switchLanguage($event.target.value)"
                >
                    <option
                        *ngFor="let lang of languages"
                        [value]="lang.code"
                        [selected]="lang.code === language"
                    >
                        {{ lang.name }}
                    </option>
                </select>
            </div>

            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-primary modalbtn"
                    (click)="applyLangBtn($event)"
                    (click)="modal.close('Save click')"
                    data-mdb-ripple-color="dark"
                >
                    {{ "Apply" | translate }}
                </button>
            </div>
        </form>
    </div>
</ng-template>

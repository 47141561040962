import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChallengesComponent } from './my-account/challenges/challenges.component';
import { HelpComponent } from './help/help.component';
import { ReviewsComponent } from './my-account/reviews/reviews.component';
import { NotificationComponent } from './notification/notification.component';
import { MyBadgesComponent } from './my-account/challenges/my-badges/my-badges.component';
import { SettingsComponent } from './my-account/settings/settings.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { VatInvoiceComponent } from './vat-invoice/vat-invoice.component';
import { OrderReviewComponent } from './order-review/order-review.component';
import { PaymentSuccessComponent } from './checkout-payment/payment-success/payment-success.component';
import { PaymentFailureComponent } from './checkout-payment/payment-failure/payment-failure.component';
import { EditProfileComponent } from './my-account/edit-profile/edit-profile.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { TripsDetailComponent } from './trips/trips-detail/trips-detail.component';
import { canAccessLoggedInResource } from '../guards/auth.guard';
import { RewardsAndWalletComponent } from './my-account/rewards-and-wallet/rewards-and-wallet.component';
import { GiftVoucherComponent } from './my-account/rewards-and-wallet/gift-voucher/gift-voucher.component';
import { GiftCardBalanceComponent } from './my-account/rewards-and-wallet/gift-voucher/gift-card-balance/gift-card-balance.component';
import { BookingsComponent } from './my-account/bookings/bookings.component';

const routes: Routes = [
    {
        path: 'customer-notification',
        component: NotificationComponent,
        data: { title: 'Notification' },
    },
    {
        path: 'addgift-voucher',
        component: GiftVoucherComponent,
        data: { title: 'Add Gift Voucher' },
    },
    {
        path: 'giftvoucher/index/history',
        component: GiftCardBalanceComponent,
        data: { title: 'Gift Voucher History' },
    },
    {
        path: 'rewardpoints',
        component: ChallengesComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Reward Points' },
    },
    {
        path: 'rewardpoints/index/index',
        component: ChallengesComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Reward Points' },
    },
    {
        path: 'customer/storecredit',
        component: RewardsAndWalletComponent,
        data: { title: 'Store Credit' },
    },
    {
        path: 'customer/storecredit.html',
        component: RewardsAndWalletComponent,
        data: { title: 'Store Credit' },
    },
    {
        path: 'review/customer',
        component: ReviewsComponent,
        data: { title: 'Review' },
    },
    {
        path: 'customer/mainhelp',
        component: HelpComponent,
        data: { title: 'Mainhelp' },
    },
    {
        path: 'help-new',
        component: HelpComponent,
        data: { title: 'Help' },
    },
    {
        path: 'badges',
        component: MyBadgesComponent,
        data: { title: 'Badges' },
    },
    {
        path: 'customeraccount/settings',
        component: SettingsComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Settings' },
    },
    {
        path: 'customer/account/edit',
        component: SettingsComponent,
        data: { title: 'Edit Account' },
    },
    {
        path: 'gdpr/customer/settings/',
        component: SettingsComponent,
        data: { title: 'Settings' },
    },
    {
        path: 'customer/trips-detail',
        component: TripsDetailComponent,
        data: { title: 'Trip Detail' },
    },
    {
        path: 'invoicenew',
        component: OrderConfirmationComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Invoice' },
    },
    {
        path: 'companyprofile/index/index',
        component: VatInvoiceComponent,
        data: { title: 'Company Profile' },
    },
    {
        path: 'invoice',
        component: OrderReviewComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Invoice' },
    },
    {
        path: 'checkoutpaymentSuccess',
        component: PaymentSuccessComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Payment Success' },
    },
    {
        path: 'checkoutpaymentFailure',
        component: PaymentFailureComponent,
        canActivate: [canAccessLoggedInResource],
        data: { title: 'Payment Failure' },
    },
    {
        path: 'customer/address/edit',
        component: EditProfileComponent,
        data: { title: 'Edit Address' },
    },
    {
        path: 'customer/address/edit/id/:addressId',
        component: EditProfileComponent,
        data: { title: 'Edit Address' },
    },
    {
        path: 'companyprofile/index/invoiceform/:order_id',
        component: CreateInvoiceComponent,
        data: { title: 'Invoice' },
    },
    {
        path: 'customer/rewardsandwallet',
        component: RewardsAndWalletComponent,
        data: { title: 'Rewards and Wallet' },
    },
    {
        path: 'customer/bookings',
        component: BookingsComponent,
        data: { title: 'Bookings' },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class UserRoutingModule {}

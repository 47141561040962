<div class="main-div" *ngIf="isUserLoggedIn">
    <div class="skeleton" *ngIf="orderReviewSkeleton">
        <div
            class="animated-background"
            style="
                height: 94vh;
                border: 2px solid white;
                width: auto;
                box-sizing: border-box;
            "
        ></div>
    </div>
    <div
        class="invoice_page"
        *ngIf="!orderReviewSkeleton"
        scroll="false"
        has-header="true"
    >
        <div class="page-bg-color">
            <div class="page-side-space">
                <div class="card order-review-page-div">
                    <div
                        class="order-review-page-div-text"
                        *ngIf="
                            orderdata.payment_method != 'ipay' &&
                            orderdata.payment_method !=
                                'mipspaymentsystemiframe'
                        "
                    >
                        <label
                            [innerHTML]="localeTxt.order_success"
                            class="invoice-label order-review-page-div-label"
                            *ngIf="paymentStatus == 'success'"
                        ></label>
                        <label
                            class="invoice-label error-message"
                            *ngIf="paymentStatus == 'failure'"
                            >{{
                                "There was some error while creating the payment, please try again after some time"
                                    | translate
                            }}.</label
                        >
                    </div>
                    <div
                        class="order-review-page-div-text"
                        *ngIf="orderdata.payment_method == 'ipay'"
                    >
                        <label
                            class="invoice-label order-review-page-div-label"
                            *ngIf="paymentStatus == 'success'"
                            >{{ ipaytext }}</label
                        >
                        <label
                            class="invoice-label error-message"
                            *ngIf="paymentStatus == 'failure'"
                            >{{
                                "There was some error while creating the payment, please try again after some time"
                                    | translate
                            }}.</label
                        >
                    </div>

                    <div
                        class="order-review-page-div-text"
                        *ngIf="
                            orderdata.payment_method ==
                            'mipspaymentsystemiframe'
                        "
                    >
                        <label
                            class="invoice-label order-review-page-div-label"
                            *ngIf="paymentStatus == 'success'"
                            >{{ mipstext }}</label
                        >
                        <label
                            class="invoice-label error-message"
                            *ngIf="paymentStatus == 'failure'"
                            >{{
                                "There was some error while creating the payment, please try again after some time"
                                    | translate
                            }}.</label
                        >
                    </div>

                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.order_id }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{ orderdata.orderid }}
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.name }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{ orderdata.name }}
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.shipping_address }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{ orderdata.address }}
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.country }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{ localeTxt.countryName }}
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.amount_payable }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{
                                    orderdata.amount
                                        | currency
                                            : currencySymbol
                                            : "symbol"
                                            : "1.0-0"
                                            : locale
                                }}
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.contact_number }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{ orderdata.contactno }}
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                {{ localeTxt.email_address }}
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label">
                                {{ orderdata.recemail }}
                            </label>
                        </div>
                    </div>

                    <div *ngIf="paymentStatus == 'failure'">
                        <div class="invoice-left-div">
                            <label class="invoice-label">
                                Payment Status
                            </label>
                        </div>
                        <div class="invoice-right-div">
                            <label class="invoice-label"> Error </label>
                        </div>
                    </div>

                    <div
                        class="remember-text-div"
                        *ngIf="
                            orderdata.payment_method != 'ipay' &&
                            orderdata.payment_method !=
                                'mipspaymentsystemiframe'
                        "
                    >
                        <p>
                            {{
                                "Remember: Any interbank transfer will take 2 to 3 days to receive in our account, so if you want to redeem offer within the next 3 days or reduce the risk of offer selling out, finalize payment directly in 1 our accounts mentioned above"
                                    | translate
                            }}.
                        </p>
                    </div>

                    <div class="remember-text-div-below"></div>
                    <button
                        class="button button-block button-positiveBtn-rounded shopping-continue ripleEffect"
                        (click)="continueShoping()"
                    >
                        {{ "Continue Shopping" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CheckoutData, VerifiedCartDto } from './checkout.model';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';

export enum CheckoutErrors {
    DISCOUNT_NOT_ACTIVE = 'discount-not-active',
    DISCOUNT_MAX_USAGES_REACHED = 'discount-max-usages-reached',
    DISCOUNT_MAX_USAGES_PER_CUSTOMER_REACHED = 'discount-max-usages-per-customer-reached',
    DISCOUNT_CURRENCY_DOES_NOT_MATCH_CARTS = 'discount-currency-does-not-match-cart-currency',
    DISCOUNT_ALREADY_APPLIED = 'discount-already-applied',
    DISCOUNT_OUTSIDE_VALIDITY_DATES = 'discount-outside-validity-dates',
    DISCOUNT_CODE_NULL_OR_EMPTY = 'discount-code-null-or-empty',
    DISCOUNT_CODE_LENGTH_INVALID = 'discount-code-length-bigger-than-15',
    DISCOUNT_CODE_NOT_ALLOWED_CHARACTERS = 'discount-code-not-allowed-characters',
    DISCOUNT_DESCRIPTION_MAX_LENGTH_EXCEEDED = 'description-should-not-exceed-max-length-of-255',
    DISCOUNT_AMOUNT_INVALID = 'discount-amount-outside-0-',
    DISCOUNT_PERCENTAGE_AMOUNT_INVALID = 'discount-percentage-amount-outside-0.01(1%)-1.00(100%)',
    DISCOUNT_VALID_TO_BEFORE_NOW = 'discount-valid-to-date-before-now',
    DISCOUNT_VALID_TO_BEFORE_VALID_FROM = 'discount-valid-to-date-before-valid-from-date',
    DISCOUNT_MUST_HAVE_AT_LEAST_ONE_CONDITION = 'discount-must-have-at-least-one-condition',
    DISCOUNT_MAX_USAGES_INVALID = 'discount-max-usages-must-be-greater-than-0',
    DISCOUNT_PRIORITY_INVALID = 'discount-priority-must-be-greater-than-or-equal-to-0-and-lower-or-equal-to-10',
    DISCOUNT_MAX_USAGES_GREATER_THAN_MAX_USAGES_PER_CUSTOMER = 'discount-max-usages-greater-than-max-usages-per-customer',
    CART_DISCOUNT_ONLY_APPLIED_ON = 'cart-discount-only-applied-on-',
    CART_ITEM_DISCOUNT_ONLY_APPLIED_ON = 'cart-item-discount-only-applied-on-',
    DISCOUNT_CONDITION_VALUE_INVALID = 'discount-condition-value-null-or-empty',
    DISCOUNT_CONDITION_OPERATION_INVALID = 'discount-condition-operation-not-supported',
    DUPLICATED_DISCOUNT_CODE = 'duplicated-discount-code',
    DISCOUNT_NOT_FOUND = 'discount-not-found',
    CUSTOMER_CART_DOES_NOT_EXIST = 'customer-cart-does-not-exist',
    GUEST_CART_DOES_NOT_EXIST = 'guest-cart-does-not-exist',
    CUSTOMER_CART_HAS_EMPTY_CUSTOMER_ID = 'customer-cart-has-empty-customer-id',
    GUEST_CART_IS_CUSTOMER_CART = 'guest-cart-is-customer-cart',
    CARTS_WITH_DIFFERENT_CURRENCIES = 'carts-have-different-currencies',
    CHECK_IN_AFTER_CHECK_OUT = 'check-in-after-check-out',
    CART_QUANTITY_INVALID = 'cart-quantity-invalid',
    CART_PAYABLE_AMOUNTS_INVALID = 'cart-payable-amounts-invalid',
    CART_ITEM_SUBTOTAL_TOO_BIG = 'cart-item-subtotal-too-big',
    CART_ITEM_GRAND_TOTAL_TOO_BIG = 'cart-item-grand-total-too-big',
    DEALS_NOT_AVAILABLE = 'deals-not-available',
    CART_SERVICE_PERCENTAGE_INVALID = 'cart-service-percentage-invalid',
    CART_NOT_FOUND = 'cart-not-found',
    CART_HAS_NO_ITEMS = 'cart-has-no-items',
    DEAL_INFO_EMPTY = 'deal-info-empty',
    WRONG_DEAL_INFO = 'deal-info-not-for-giftcard-hotel-or-no-deal-option-id',
    ORDER_CART_WRONG_ITEMS_COUNT = 'order-cart-wrong-items-count',
    ORDER_NOT_FOUND = 'order-not-found',
    CURRENCY_NOT_SUPPORTED = 'currency-not-supported',
    CURRENCY_INVALID = 'currency-invalid',
    SOMETHING_WENT_WRONG = 'Something went wrong',
    COUPON_NOT_FOUND = 'coupon-not-found',
    COUPON_CHECK_IN_CHECK_OUT_RESERVATION_STATUS_NOT_NULL = 'check-in-check-out-reservation-status-not-null-in-db',
    COUPON_PREFERRED_TIME_WRONG_FORMAT = 'preferred-time-wrong-format',
    PAYMENT_ZONE_FETCHING_FAILED = 'payment-zone-fetching-failed',
    INVALID_PAYMENT_STATUS = 'invalid-payment-status',
    COULD_NOT_RETRIEVE_CUSTOMER_BY_ID = 'could-not-retrieve-customer-by-id',
    COULD_NOT_RETRIEVE_CUSTOMER = 'cannot-retrieve-customer',
    CUSTOMER_ID_NOT_SPECIFIED = 'customer-id-not-specified',
    GIFT_CARD_NOT_FOUND_OR_NOT_SENT_TO_CUSTOMER = 'gift-card-not-found-or-not-sent-to-customer',
    GIFT_CARD_NOT_FOUND = 'gift-card-not-found',
    GIFT_CARD_CANNOT_APPLY_ON_ZERO_SUBTOTAL = 'cannot-apply-gift-card-on-zero-subtotal',
    NOT_ENOUGH_REWARD_POINTS_SPENT = 'not-enough-reward-points-spent',
    ERROR_RETRIEVING_RP_FROM_A_SERVICE = 'error-retrieving-rp-from-a-service',
}

export interface CheckoutError {
    errorCode: CheckoutErrors;
    info: string;
}

@Injectable({
    providedIn: 'root',
})
export class CheckoutErrorsService {
    constructor(
        private translate: TranslateService,
        private generalErrorService: GeneralErrorService
    ) {}

    getTranslatedErrorMessageFromErrorCode(
        error: CheckoutError,
        checkoutData?: CheckoutData
    ): string {
        const { errorCode, info } = error;
        switch (errorCode) {
            case CheckoutErrors.DEALS_NOT_AVAILABLE:
                return this.handleDealsNotAvailableErrorInfo(
                    this.extractErrorInfo(info),
                    checkoutData
                );
            default:
                return this.translate.instant(`checkoutErrors.${errorCode}`);
        }
    }

    private extractErrorInfo(info: string): number[] {
        const idsAsString = JSON.parse(info);
        return idsAsString.map((id: string) => parseInt(id));
    }

    private handleDealsNotAvailableErrorInfo(
        errorInfo: number[],
        checkoutData: CheckoutData
    ): string {
        const unavailableDealTitles = this.getUnavailableDealTitlesBasedOnIds(
            errorInfo,
            checkoutData
        );
        const text = this.translate.instant(
            `checkoutErrors.${CheckoutErrors.DEALS_NOT_AVAILABLE}`
        );

        return `${text}: ${unavailableDealTitles}`;
    }

    private getUnavailableDealTitlesBasedOnIds(
        ids: number[],
        checkoutData: CheckoutData
    ): string {
        return checkoutData.cartItems
            .filter((item) => ids.includes(item.dealId))
            .map((item) => item.name)
            .join(', ');
    }

    isKnownErrorCode(errorCode: string): boolean {
        return Object.values(CheckoutErrors).includes(
            errorCode as CheckoutErrors
        );
    }

    constructNotAvailableDealNamesStringAndShowError(
        response: VerifiedCartDto
    ): void {
        if (response.notAvailableCartItems.length === 0) {
            return;
        }

        const notAvailableDealNames = response.cart.items
            .filter((item) => response.notAvailableCartItems.includes(item.id))
            .map((item) => item.dealTitle);
        this.generalErrorService.showGeneralError(
            `The following deals are no longer available: ${notAvailableDealNames.join(
                ', '
            )}`,
            {
                showMailto: false,
            }
        );
    }
}

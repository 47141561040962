<div class="wr-container">
    <iframe
        style="width: 100%; height: 884px"
        src="https://app.formcrafts.com/81afce9e"
    ></iframe>
    <!-- <div class="dummy-reward-wrapper" *ngIf="isWriteReviewSkeletonShow">
        <div class="dummy-customer-section custom-write-review-div-dummy">
            <div
                style="border-radius: 6px"
                class="dummy-customer-field animated-background"
            ></div>
        </div>
        <div
            style="margin-top: 4rem"
            class="dummy-customer-section custom-write-review-div-section"
        >
            <div
                style="border-radius: 6px"
                class="dummy-customer-field animated-background"
            ></div>
        </div>
        <div
            style="
                font-weight: 700;
                margin-top: 15px;
                width: 100%;
                margin-bottom: 8px;
                color: #092e41;
                font-size: 15px;
            "
        >
            <span>{{ "How was your experience?" | translate }}</span>
        </div>
        <div>
            <div style="width: 100%; position: relative">
                <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 90px;
                    "
                >
                    <div style="margin: 0px 10px" class="rwOptionView">
                        <div for="radio-poor">
                            <div
                                class="animated-background reviewEmoji"
                                style="height: 30px !important"
                            ></div>
                            <div
                                style="
                                    width: 100%;
                                    height: 20px;
                                    margin-top: 15px;
                                "
                                class="rwLabel animated-background"
                            ></div>
                        </div>
                    </div>
                    <div style="margin: 0px 10px" class="rwOptionView">
                        <div for="radio-poor">
                            <div
                                class="animated-background reviewEmoji"
                                style="height: 18vw !important"
                            ></div>
                            <div
                                style="
                                    width: 100%;
                                    height: 20px;
                                    margin-top: 15px;
                                "
                                class="rwLabel animated-background"
                            ></div>
                        </div>
                    </div>
                    <div style="margin: 0px 10px" class="rwOptionView">
                        <div for="radio-poor">
                            <div
                                class="animated-background reviewEmoji"
                                style="height: 18vw !important"
                            ></div>
                            <div
                                style="
                                    width: 100%;
                                    height: 20px;
                                    margin-top: 15px;
                                "
                                class="rwLabel animated-background"
                            ></div>
                        </div>
                    </div>
                    <div style="margin: 0px 10px" class="rwOptionView">
                        <div for="radio-poor">
                            <div
                                class="animated-background reviewEmoji"
                                style="height: 18vw !important"
                            ></div>
                            <div
                                style="
                                    width: 100%;
                                    height: 20px;
                                    margin-top: 15px;
                                "
                                class="rwLabel animated-background"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 15px">
                <div
                    class="animated-background"
                    style="height: auto !important; border-radius: 6px"
                >
                    <div
                        class="textare-input reviewText"
                        style="
                            width: 100%;
                            height: 22vh;
                            background: transparent;
                            opacity: 1 !important;
                            padding: 8px;
                            color: #177cc0 !important;
                            font-size: 16px !important;
                            line-height: 1.2;
                            overflow: auto;
                        "
                    ></div>
                </div>
                <h6 style="text-align: right">
                    <span style="color: #177cc0">200</span>
                    {{ "Characters Remaining" | translate }}
                </h6>
            </div>
            <div style="display: flex; justify-content: center">
                <button
                    class="my-button button button-block write-review-btn"
                    style="
                        margin-top: 12vw;
                        height: 4rem !important;
                        border-radius: 6px;
                        width: 55vw;
                        background: #177cc0 !important;
                        font-weight: 700;
                    "
                >
                    {{ "Submit Your Review" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="custom-write-review-div-1" *ngIf="!isWriteReviewSkeletonShow">
        <form [formGroup]="reviewFormGroup">
            <label class="wr-label" for="customerName">
                {{ "Name" | translate }}
            </label>
            <input
                class="wr-input"
                type="text"
                [placeholder]="'Name*' | translate"
                formControlName="customerName"
            />
            <div
                *ngIf="promptMessage?.length > 0"
                [innerHTML]="promptMessage | safeHtml"
            ></div>

            <div *ngIf="isshowWriteReview" class="wr-write-container">
                <label class="wr-label" for="selectCategory">
                    {{ "Select Property" | translate }}
                </label>
                <select
                    class="wr-select"
                    id="dropdownoption"
                    formControlName="selectCategory"
                    (change)="changeReview()"
                >
                    <option value="">
                        {{ "Select Property" | translate }}
                    </option>
                    <option
                        [value]="item.outletId"
                        *ngFor="let item of customer_outletdata"
                    >
                        {{ item.outletName }}
                    </option>
                </select>
                <div class="wr-write-emoji-title">
                    {{ "How was your experience?" | translate }}
                </div>
                <div
                    class="wr-write-emoji-options"
                    [ngClass]="{ 'wr-error': hasError }"
                >
                    <div
                        class="wr-write-emoji-item"
                        (click)="rwOptionSelected('poor')"
                        [ngClass]="{
                            'wr-selected':
                                reviewFormGroup.controls.experience?.value ===
                                'poor'
                        }"
                    >
                        <div class="wr-emoji poor"></div>
                        <div>
                            {{ "Poor" | translate }}
                        </div>
                    </div>
                    <div
                        class="wr-write-emoji-item"
                        (click)="rwOptionSelected('average')"
                        [ngClass]="{
                            'wr-selected':
                                reviewFormGroup.controls.experience?.value ===
                                'average'
                        }"
                    >
                        <div class="wr-emoji good"></div>
                        <div>
                            {{ "Average" | translate }}
                        </div>
                    </div>
                    <div
                        class="wr-write-emoji-item"
                        (click)="rwOptionSelected('good')"
                        [ngClass]="{
                            'wr-selected':
                                reviewFormGroup.controls.experience?.value ===
                                'good'
                        }"
                    >
                        <div class="wr-emoji great"></div>
                        <div>
                            {{ "Good" | translate }}
                        </div>
                    </div>
                    <div
                        class="wr-write-emoji-item"
                        (click)="rwOptionSelected('great')"
                        [ngClass]="{
                            'wr-selected':
                                reviewFormGroup.controls.experience?.value ===
                                'great'
                        }"
                    >
                        <div class="wr-emoji superb"></div>
                        <div>
                            {{ "Great" | translate }}
                        </div>
                    </div>
                </div>
                <div class="wr-write-textarea-container">
                    <textarea
                        class="wr-write-textarea"
                        placeholder="{{ 'Your Review' | translate }}"
                        rows="8"
                        maxlength="200"
                        formControlName="yourReview"
                    ></textarea>
                    <span>
                        {{ "Characters Remaining" | translate }}
                        {{
                            200 -
                                reviewFormGroup.controls.yourReview?.value
                                    ?.length
                        }}
                    </span>
                </div>
                <div class="wr-write-button-container">
                    <button
                        mdMainButton="light"
                        [disabled]="!isReviewBtnEnable"
                        (click)="submitReview()"
                    >
                        {{ "Submit Your Review" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div> -->
</div>

<div class="c-os-ci-item-container" *ngFor="let booking of items">
    <div class="c-os-ci-left">
        <h3 class="c-os-ci-item-name">{{ booking.name }}</h3>
        <p>{{ booking.description }}</p>
        <ng-container *ngFor="let customOptions of booking.options">
            <ng-container *ngIf="customOptions.value">
                <p>
                    {{ customOptions.label }}
                </p>
                <p class="last" [innerHTML]="customOptions.value | safeHtml"></p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="itemHasCheckinCheckout(booking)">
            <p>
                {{ "Check-in: " | translate
                }}{{ convert_to_date(booking.check_in, false) }}
            </p>
            <p class="last">
                {{ "Check-out: " | translate
                }}{{ convert_to_date(booking.check_out, true) }}
            </p>
        </ng-container>
        <ng-container *ngIf="booking.product_addon_data">
            <p>
                <b>{{ "Add-ons:" | translate }}</b>
            </p>
            <p class="last" [innerHTML]="booking.product_addon_data | safeHtml"></p>
        </ng-container>

        <p *ngIf="itemHasSingleDate(booking)">
            {{ "Date: " | translate }}{{ convert_to_date(booking.check_in, true) }}
        </p>
        <ng-container *ngIf="booking.isGiftCard">
            <p>
                <b>{{ "Gift Card Value:" | translate }}</b>
            </p>
            <p class="last">
                {{
                    booking.eGiftCardDealInfo.amount
                        | currency : currencySymbol : "symbol" : "1.0-2" : locale
                }}
            </p>
            <p>
                <b>{{ "Recipient email address:" | translate }}</b>
            </p>
            <p class="last">
                {{ booking.eGiftCardDealInfo?.recipientEmail }}
            </p>
            <p>
                <b>{{ "Special message:" | translate }}</b>
            </p>
            <p class="last">
                {{ booking.eGiftCardDealInfo.specialMessage }}
            </p>
            <p>
                <b>{{ "Day to send:" | translate }}</b>
            </p>
            <p class="last">
                {{ booking.eGiftCardDealInfo.sendDate }}
            </p>
        </ng-container>
    </div>
    <div class="c-os-ci-right">
        <div class="c-os-ci-right-top">
            <button class="c-os-ci-edit" (click)="removeItem(booking)">
                {{ "Remove" | translate }}
                <ion-icon name="close-circle-outline"></ion-icon>
            </button>
            <div class="c-os-ci-quant-container">
                <button
                    class="c-os-ci-quant-btn"
                    [disabled]="booking.qty === 1"
                    (click)="decreaseQuantity(booking)"
                >
                    <ion-icon name="remove-outline"></ion-icon>
                </button>
                <span class="c-os-ci-quant">{{ booking.qty }}</span>
                <button
                    class="c-os-ci-quant-btn"
                    [disabled]="booking.qty >= booking.max_qty"
                    (click)="increaseQuantity(booking)"
                >
                    <ion-icon name="add-outline"></ion-icon>
                </button>
            </div>
        </div>
        <div class="c-os-ci-right-bottom">
            <div class="c-os-ci-price">
                {{
                    booking.item_row_total
                        | currency : currencySymbol : "symbol" : "1.0-2" : locale
                }}
            </div>
        </div>
    </div>
</div>

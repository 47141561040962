import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import {
    CalendarInventory,
    InventoryDto,
    DealDto,
} from 'src/app/model/deal.model';
import { transformInventoryResponseDto } from './dated-activity-booking.model';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import {
    getIsSelectedDateAvailable,
    transformNgbDateToDayJs,
    transformNgbDateToYmd,
} from 'src/app/controls/calendar/calendar.utils';
import { BookingConfiguredData } from '../booking-config.model';
import { ProductService } from '../../product.service';

@UntilDestroy()
@Component({
    selector: 'md-dated-activity-booking',
    templateUrl: './dated-activity-booking.component.html',
    styleUrls: ['./dated-activity-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatedActivityBookingComponent implements OnInit, AfterViewInit {
    private productDetailsSubject = new BehaviorSubject<DealDto>(null);

    private productDetails$ = this.productDetailsSubject
        .asObservable()
        .pipe(
            tap((value) => {
                if (!value) return;
                this.internalProductDetails = value;

                this.setCalendarInventory(this.internalProductDetails.inventory);
            })
        );

    @Input() set productDetails(value: DealDto) {
        this.productDetailsSubject.next(value);
    }

    totalAllotment = -1;
    currencySymbol: 'Rs.' | 'EUR';
    internalProductDetails: DealDto;
    pricingByDate: CalendarInventory[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedDate: NgbDate;
    formattedDate = '-';
    tempBookingData: BookingConfiguredData;
    calendarErrorMessage = '';

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarDatedActivity')
    calendarDatedActivity: CalendarComponent;

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private translate: TranslateService,
        private productService: ProductService,
        private ref: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        const currency$ = this.currencyLanguageService
            .getCurrency()
            .pipe(tap((currency) => (this.currencySymbol = currency)));

        combineLatest([this.productDetails$, currency$])
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    ngAfterViewInit() {
        this.calendarDatedActivity.openCalendar();
    }

    onDateRangeSelected(dateRange: CalendarSelectedDates): void {
        this.selectedDate = dateRange.singleDate;

        this.formattedDate = this.selectedDate
            ? transformNgbDateToDayJs(this.selectedDate).format('DD MMM YYYY')
            : '-';

        this.changeMaxPaxBasedOnInventory();

        if (this.tempBookingData) {
            this.onBookingConfigured(this.tempBookingData);
        }
    }

    onBookingConfigured(data: BookingConfiguredData): void {

        this.productService
            .fetchCalendarInventory(
                this.internalProductDetails,
                this.internalProductDetails.dealOffers
            )
            .pipe(
                untilDestroyed(this),
                tap(({ calendarInventory }) => {
                    this.internalProductDetails.inventory = calendarInventory;
                    this.internalProductDetails = { ...this.internalProductDetails };
                    this.setCalendarInventory(this.internalProductDetails.inventory, data);
                    this.ref.markForCheck();
                })
            )
            .subscribe();


        if (!this.selectedDate) {
            this.tempBookingData = data;
        }
    }

    private setCalendarInventory(
        inventory: InventoryDto[],
        bookingConfiguredData?: BookingConfiguredData
    ): void {

        if (!inventory?.length) {
            this.calendarErrorMessage = this.translate.instant('calendarErrors.no-matching-dates');
            return;
        }

        this.calendarErrorMessage = '';

        this.pricingByDate = transformInventoryResponseDto(inventory);

        this.additionalCalendarDayInfo.clear();

        this.pricingByDate.forEach((pricingByDate) =>
            this.additionalCalendarDayInfo.set(pricingByDate.dateAsString, {
                date: pricingByDate.date,
                tooltipContent: `${this.currencySymbol} ${pricingByDate.options[0].price}`,
                minLengthOfStay: 1,
            })
        );

        this.additionalCalendarDayInfo = new Map(this.additionalCalendarDayInfo);

        this.ref.detectChanges();
        this.calendarDatedActivity?.openCalendar?.();

        this.setSelectedDate(bookingConfiguredData);
    }

    private setSelectedDate(data: BookingConfiguredData) {
        if (!data?.productBookingData) return;

        if (!this.selectedDate) {
            this.calendarDatedActivity && (this.calendarDatedActivity.singleDate = null);
            this.formattedDate = '-';
            return;
        }

        const isSelectedDateAvailable = getIsSelectedDateAvailable(
            this.selectedDate,
            this.additionalCalendarDayInfo
        );

        // if this.selectedDate
        if (isSelectedDateAvailable) {
            data.productBookingData.selectedDate = this.selectedDate;
            this.tempBookingData = undefined;
            this.bookingConfigured.emit(data);
            return;
        }

        // if !isSelectedDateAvailable
        this.selectedDate = null;
        this.formattedDate = '-';

        if (this.calendarDatedActivity) {
            this.calendarDatedActivity.singleDate = null;
            this.calendarDatedActivity.openCalendar();
        }
    }

    private changeMaxPaxBasedOnInventory() {
        const selectedDateAsString = transformNgbDateToYmd(this.selectedDate);

        let hasAnyDealOptionChanged = false;

        this.internalProductDetails.dealOffers.forEach((dealOffer) =>
            dealOffer.dealOptions.forEach((dealOption) => {
                const { qty } =
                    this.internalProductDetails.inventory.find(
                        (item) =>
                            item.dealOptionId === dealOption.dealOptionId &&
                            item.date === selectedDateAsString
                    ) ?? {};

                if (qty > 0 && qty < dealOption.maxPax) {
                    dealOption.maxPaxDynamic = qty;
                    hasAnyDealOptionChanged = true;
                }

                if (dealOption.qty > dealOption.maxPaxDynamic)
                    dealOption.qty = dealOption.maxPaxDynamic;
            })
        );

        if (hasAnyDealOptionChanged)
            this.internalProductDetails = { ...this.internalProductDetails };
    }
}

import { DealReviewData } from 'src/app/model/deal.model';

export type RatingLabel = 'Great' | 'Good' | 'Average' | 'Poor' | 'No Reviews';
export type RatingColor = '#90c652' | '#6fc2eb' | '#feba02' | '#f68e8e';

export interface ReviewDataDto extends ReviewSummary {
    votesPercent: {
        count: number;
        label: string;
        percent: number;
    }[];
    all: {
        id: string;
        detailid: string;
        createdat: string;
        nickname: string;
        title: string;
        detail: string;
        average_rating: string;
    }[];
}

export interface ReviewSummary {
    average: number;
    total: number;
}

export const ratingColorsMap: Map<RatingLabel, RatingColor> = new Map([
    ['Great', '#90c652'],
    ['Good', '#6fc2eb'],
    ['Average', '#feba02'],
    ['Poor', '#f68e8e'],
]);

export interface ReviewData {
    average: number;
    total: number;
    ratingGroups: RatingGroup[];
    allReviews: Review[];
}

export interface RatingGroup {
    count: number;
    label: RatingLabel;
    percent: number;
}

export interface Review {
    id: number;
    detailId: number;
    createdAt: Date;
    createdAtString: string;
    userNickname: string;
    title: string;
    detail: string;
    rating: number;
    ratingLabel: RatingLabel;
}

export interface NewReviewDataResponseDto {
    customer_id: string;
    customer_name: string;
    current_outlet: {
        outlet_id: string;
        outlet_name: string;
        review_qty: string;
    };
    customer_outletdata: {
        outlet_id: string;
        outlet_name: string;
        review_qty: string;
        review_data: {
            detail: string;
            title: string;
            review_id: string;
        };
    }[];
}

export interface NewReviewDataResponse {
    customerId: number;
    customerName: string;
    currentOutlet: ReviewCustomerOutletData;
    customerOutletData: ReviewCustomerOutletData[];
}

export interface ReviewCustomerOutletData {
    outletId: number;
    outletName: string;
    reviewQty?: number;
    reviewData?: ReviewCustomerOutletReviewData;
}

export interface ReviewCustomerOutletReviewData {
    detail: string;
    title: string;
    reviewId: number;
}

export interface AddReviewData {
    productId: number;
    details: string;
}

export interface AddReviewResponseDto {
    review: string;
}

export const transformReviewData = (
    reviewDataDto: ReviewDataDto
): ReviewData => {
    return {
        average: Number(reviewDataDto.average),
        total: Number(reviewDataDto.total),
        ratingGroups: reviewDataDto.votesPercent.map((vote) => ({
            count: Number(vote.count),
            label: vote.label as RatingLabel,
            percent: Number(vote.percent),
        })),
        allReviews: reviewDataDto.all.map((review) => ({
            id: Number(review.id),
            detailId: Number(review.detailid),
            createdAt: new Date(review.createdat),
            createdAtString: review.createdat,
            userNickname: review.nickname,
            title: review.title,
            detail: review.detail,
            rating: Number(review.average_rating),
            ratingLabel: getRatingLabel(Number(review.average_rating)),
        })),
    };
};

export const transformReviewDataV2 = (
    reviewDataDto: DealReviewData
): ReviewData => {
    return {
        average: reviewDataDto.reviewScoreCard.reviewsScore,
        total: reviewDataDto.totalReviewCount,
        ratingGroups: transformReviewScoreCardIntoRatingGroups(reviewDataDto),
        allReviews: reviewDataDto.latestReviews.map((review, index) => ({
            id: index,
            detailId: review.outletId,
            createdAt: new Date(review.updatedDate),
            createdAtString: review.updatedDate,
            userNickname: review.guestName,
            title: review.reviewLabel,
            detail: review.reviewText,
            rating: review.reviewsScore,
            ratingLabel: getRatingLabel(review.reviewsScore),
        })),
    };
};

const transformReviewScoreCardIntoRatingGroups = (
    dealReviewData: DealReviewData
): RatingGroup[] => {
    const { totalReviewCount, reviewScoreCard } = dealReviewData;
    const { poorCount, averageCount, goodCount, greatCount } = reviewScoreCard;

    const poorRatingGroup: RatingGroup = {
        count: poorCount,
        label: 'Poor',
        percent: (poorCount / totalReviewCount) * 100,
    };
    const averageRatingGroup: RatingGroup = {
        count: averageCount,
        label: 'Average',
        percent: (averageCount / totalReviewCount) * 100,
    };
    const goodRatingGroup: RatingGroup = {
        count: goodCount,
        label: 'Good',
        percent: (goodCount / totalReviewCount) * 100,
    };
    const greatRatingGroup: RatingGroup = {
        count: greatCount,
        label: 'Great',
        percent: (greatCount / totalReviewCount) * 100,
    };
    return [
        poorRatingGroup,
        averageRatingGroup,
        goodRatingGroup,
        greatRatingGroup,
    ];
};

export const getRatingLabel = (rating: number): RatingLabel => {
    if (rating > 75) {
        return 'Great';
    } else if (rating > 50) {
        return 'Good';
    } else if (rating > 25) {
        return 'Average';
    } else {
        return 'Poor';
    }
};

export const transformNewReviewDataResponse = (
    newReviewDataResponseDto: NewReviewDataResponseDto
): NewReviewDataResponse => {
    return {
        customerId: Number(newReviewDataResponseDto.customer_id),
        customerName: newReviewDataResponseDto.customer_name,
        currentOutlet: {
            outletId: Number(newReviewDataResponseDto.current_outlet.outlet_id),
            outletName: newReviewDataResponseDto.current_outlet.outlet_name,
            reviewQty: Number(
                newReviewDataResponseDto.current_outlet.review_qty
            ),
        },
        customerOutletData: newReviewDataResponseDto.customer_outletdata.map(
            (customerOutletData) => ({
                outletId: Number(customerOutletData.outlet_id),
                outletName: customerOutletData.outlet_name,
                reviewQty: Number(customerOutletData.review_qty),
                reviewData: {
                    detail: customerOutletData.review_data.detail,
                    title: customerOutletData.review_data.title,
                    reviewId: Number(customerOutletData.review_data.review_id),
                },
            })
        ),
    };
};

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlayVisibleSubject = new BehaviorSubject<boolean>(false);
  overlayVisible$ = this.overlayVisibleSubject.asObservable();

  setOverlayVisible(isVisible: boolean) {
    this.overlayVisibleSubject.next(isVisible);
  }
}

import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ReviewsService } from './reviews.service';
import {
    RatingGroup,
    ReviewData,
    ratingColorsMap,
    transformReviewDataV2,
} from './reviews.model';
import { Subscription } from 'rxjs';
import { screenSizes } from 'src/app/utilities/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { DealReviewData } from 'src/app/model/deal.model';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit, OnDestroy {
    @Input() set productId(value: number) {
        // this should actually get the whole list of review stuff
        if (!value || value === this.internalProductId) return;
        this.internalProductId = value;
    }

    @Input() set dealReviewData(value: DealReviewData) {
        this.reviewData = transformReviewDataV2(value);
        this.ratingGroups = this.reviewData.ratingGroups.map((group) => {
            return {
                ...group,
                label: this.translate.instant(group.label),
            };
        });
    }

    internalProductId: number;
    ratingColorsMap = ratingColorsMap;
    ratingGroups: RatingGroup[];
    reviewData: ReviewData;
    loadingMore = false;
    isUserLoggedIn = false;

    private reviewDataSubscription: Subscription;

    @ViewChild('modalNewReviewForm') modalNewReviewForm: TemplateRef<unknown>;

    constructor(
        private service: ReviewsService,
        private modalService: NgbModal,
        private eventService: EventService,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.isUserLoggedIn = isLoggedIn;
        });
    }

    ngOnDestroy(): void {
        if (this.reviewDataSubscription) {
            this.reviewDataSubscription.unsubscribe();
        }
    }

    loadMoreReviews(): void {
        // TODO: Not currently provided by the backend
    }

    getNextPageNumber(): number {
        return this.reviewData.allReviews.length / 10 + 1;
    }

    writeReview(): void {
        if (!this.isUserLoggedIn) {
            // add some details that can be used on the login page to redirect back
            this.eventService.emitEvent(EventType.LOGIN);
            return;
        }
        this.openModal(this.modalNewReviewForm);
    }

    onReviewSubmitted(): void {
        this.modalService.dismissAll();
    }

    private openModal(content: TemplateRef<unknown>): void {
        const screenWidth = window.innerWidth;
        if (screenWidth > screenSizes.mobile) {
            this.modalService.open(content, { size: 'md', scrollable: true });
        } else {
            this.modalService.open(content, {
                fullscreen: true,
                scrollable: true,
            });
        }
    }
}

<div class="c-o-container">
    <div class="c-o-counter" *ngFor="let ageGroup of ageGroups">
        <div class="c-o-text">
            <div class="c-o-text-name">{{ ageGroup.ageGroupType | translate }}</div>
            <div class="c-o-text-description">
                {{ "Age" | translate }} {{ ageGroup.ageFrom }} - {{ ageGroup.ageTo }}
            </div>
        </div>
        <md-counter
            [startValue]="ageGroup.startValue ?? 0"
            [maxValue]="30"
            (valueChanged)="onCounterChange(ageGroup, $event)"
        ></md-counter>
    </div>
</div>
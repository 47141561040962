<div class="c-os-bs-container" [ngClass]="{ standalone: standalone }">
    <div class="c-os-bs-subtotal">
        <div>
            <h3>{{ "Subtotal:" | translate }}</h3>
            <span>{{
                cart_total_amount
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span>
        </div>
        <p>
            <span>{{ "Reward Points" | translate }}</span
            ><span>+ {{ rewardPointsEarned | number : "1.0-0" }}pts</span>
        </p>
        <p *ngIf="coupon_code_discount > 0">
            <span>{{ discountCodeTitle }}</span
            ><span
                >-
                {{
                    coupon_code_discount
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-2"
                            : locale
                }}</span
            >
        </p>
        <p *ngIf="storeCreditDiscount > 0">
            <span>{{ "Store Credit" | translate }}</span
            ><span
                >-
                {{
                    storeCreditDiscount
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-2"
                            : locale
                }}</span
            >
        </p>
        <p *ngIf="rewardPointDiscount > 0">
            <span>{{ "Reward Points Discount" | translate }}</span
            ><span>{{
                rewardPointDiscount
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span>
        </p>
        <p *ngIf="giftDiscount > 0">
            <span>{{ "Gift Card" | translate }}</span
            ><span
                >-
                {{
                    giftDiscount
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-2"
                            : locale
                }}</span
            >
        </p>
        <p>
            <span>{{ "Service Fee" | translate }}</span
            ><span>{{
                servicFeeVal
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span>
        </p>
    </div>
    <div class="c-os-bs-ordertotal">
        <div>
            <h3>{{ "Order Total:" | translate }}</h3>
            <span>{{
                totalPrice
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span>
        </div>
    </div>
</div>

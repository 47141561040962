import { Component, TemplateRef, ViewChild } from '@angular/core';
import { EventService, EventType } from '../utilities/event.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginSuccessService } from '../login/login-success.service';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Country } from '@capgo/ngx-intl-tel-input/lib/model/country.model';
import { MDCountry } from '../register/register.component';
import { RegisterService } from '../register/register.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsService } from '../user/my-account/settings/settings.service';
import { setCache } from '../utilities/cache.utils';
import { ModalOffcanvasComponent } from '../controls/modal-offcanvas/modal-offcanvas.component';
import { ModalOffcanvasServiceService } from '../services/modal-offcanvas-service.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user.service';

@Component({
    selector: 'md-events-wrapper',
    templateUrl: 'events-wrapper.component.html',
    styleUrls: ['./events-wrapper.component.scss', '../../common.scss'],
})
export class EventsWrapperComponent {
    messageModalText = '';
    userFullName = '';
    showBackButton = false;
    // profile
    selectedCountryCode = CountryISO.Mauritius;
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.Mauritius,
        CountryISO.Réunion,
    ];
    protected readonly CountryISO = CountryISO;
    separateDialCode = true;
    private readonly MAURITIUS = { name: 'Mauritius', code: 'mu' } as MDCountry;
    private readonly REUNION = { name: 'Reunion', code: 're' } as MDCountry;
    private readonly OTHER_COUNTRY = { name: 'Others', code: '2' } as MDCountry;
    countries = [this.MAURITIUS, this.REUNION, this.OTHER_COUNTRY];
    fullMobileNumber!: number;
    otpInput: string;

    private customerId: number;
    private callbacks = new Map<string, () => void>();

    @ViewChild('login') loginModal: ModalOffcanvasComponent;
    @ViewChild('register') registerModal: ModalOffcanvasComponent;
    @ViewChild('messageModal') messageModal: TemplateRef<never>;
    @ViewChild('forgottenPassword')
    forgottenPasswordModal: ModalOffcanvasComponent;
    @ViewChild('welcomeMessage') welcomeMessageModal: ModalOffcanvasComponent;
    @ViewChild('createProfile') createProfileModal: ModalOffcanvasComponent;
    @ViewChild('verifyOtpAccount')
    verifyOtpAccountModal: ModalOffcanvasComponent;

    constructor(
        private eventService: EventService,
        private loginSuccessService: LoginSuccessService,
        private modal: NgbModal,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private modalOffcanvasService: ModalOffcanvasServiceService,
        private userService: UserService
    ) {
        this.subscribeForLogin();
        this.subscribeForRegister();
        this.subscribeForError();
        this.subscribeForModalInfo();
        this.subscribeForForgottenAndChangePassword();
        this.subscribewelcomeMessage();
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            if (isLoggedIn) {
                this.userFullName = userData?.userName;
                this.customerId = userData?.userId;
            }
        });
    }

    ngOnInit(): void {
        this.registerForm = new UntypedFormGroup({
            country: new UntypedFormControl('', [Validators.required]),
            others: new UntypedFormControl('', []),
            dialCode: new UntypedFormControl('', []),
            countryCode: new UntypedFormControl('', []),
            otp: new UntypedFormControl('', []),
            customerId: new UntypedFormControl('', []),
            mobile: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
        });
        this.registerForm.controls['country'].setValue('mu');
    }

    executeModalCallback(): void {
        if (this.callbacks.has('modal') && this.callbacks.get('modal')) {
            this.callbacks.get('modal')();
        }
    }
    showEmail() {
        this.eventService.togglePassword(false);
        this.showBackButton = false;
    }

    // when this loads it has to look at the url.
    // If the URL contains something about login it should open it up and remove from URL
    // then we need a service that, if login is done and the url contains something, it needs to redirect
    private subscribeForLogin(): void {
        this.eventService.onEvent(EventType.LOGIN, (payload) => {
            this.openModal(this.loginModal);
            this.loginSuccessService.loginSuccessRedirectPath =
                payload?.message;
        });
        this.eventService.onEvent(EventType.LOGIN_SUCCESS, () => {
            this.modal.dismissAll();
            this.modalOffcanvasService.dismissAllOffcanvases();
        });
    }

    private subscribeForRegister(): void {
        this.eventService.onEvent(EventType.REGISTER, () => {
            this.openModal(this.registerModal);
        });
    }

    private subscribeForError(): void {
        this.eventService.onEvent(EventType.MODAL_ERROR, (payload) => {
            this.callbacks.set('modal', payload?.callback);
            this.messageModalText = payload?.message || 'Error';
            this.modal.open(this.messageModal, {
                ariaLabelledBy: 'error-modal',
                modalDialogClass: 'error-modal',
                size: 'sm',
                centered: true,
            });
        });
    }

    private subscribeForModalInfo(): void {
        this.eventService.onEvent(EventType.MODAL_INFO, (payload) => {
            this.callbacks.set('modal', payload?.callback);
            this.messageModalText = payload?.message || 'Info';
            this.modal.open(this.messageModal, {
                ariaLabelledBy: 'info-modal',
                modalDialogClass: 'info-modal',
                size: 'sm',
                centered: true,
            });
        });
    }

    private subscribeForForgottenAndChangePassword(): void {
        this.eventService.onEvent(EventType.FORGOTTEN_PASSWORD, () => {
            this.openModal(this.forgottenPasswordModal);
        });
        this.eventService.onEvent(EventType.CHANGE_PASSWORD, () =>
            this.modal.dismissAll()
        );
    }

    // profile
    registerForm!: UntypedFormGroup;
    mobileErrorClass = '';
    mobileClass = '';
    otpMobileNumber: string;

    private subscribewelcomeMessage(): void {
        this.eventService.onEvent(EventType.WELCOME, () => {
            this.openModal(this.welcomeMessageModal);
        });
    }

    onChangeCountry(value) {
        this.selectedCountryCode = this.getCountryISO(
            this.registerForm.controls['country'].value
        );
    }

    getCountryISO(value: string): CountryISO {
        const keys = Object.keys(CountryISO).filter(
            (x) => CountryISO[x] === value
        );
        return keys.length > 0 ? CountryISO[keys[0]] : null;
    }

    onMobileChange(): void {
        this.mobileClass = this.registerForm.controls['mobile'].value
            ? 'has-input'
            : '';
        if (this.mobileErrorClass) {
            this.mobileErrorClass = '';
        }
    }

    validateMobile(shouldFocus = false): boolean {
        if (this.registerForm.controls['mobile'].invalid) {
            if (shouldFocus) {
                document.getElementById('phone').focus();
            }
            this.mobileErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    onPhoneCountryChange(event: Country) {
        this.registerForm.controls['countryCode'].setValue(event.iso2);
        this.registerForm.controls['dialCode'].setValue(event.dialCode);
    }

    navigateToProfile() {
        this.openModal(this.createProfileModal);
    }

    navigateToWelcome() {
        this.openModal(this.welcomeMessageModal);
    }

    veryfyMobileNumber() {
        // if (this.validateMobile(true)) {
        //     const payload = this.registerForm.getRawValue();
        //     payload.email = getUser().email;
        //     payload.id = getUser().id;
        //     this.otpMobileNumber =
        //         this.registerForm.controls['mobile'].value?.e164Number;
        //     this.spinner.show();
        //     this.registerService
        //         .veryfyMobileNumber(payload)
        //         .pipe(finalize(() => this.spinner.hide()))
        //         .subscribe({
        //             next: (response: ResendOtpResponseDto) => {
        //                 this.spinner.hide();
        //                 if (response.status == 'success') {
        //                     this.openModal(this.verifyOtpAccountModal);
        //                 } else {
        //                     this.eventService.emitEvent(
        //                         EventType.MODAL_ERROR,
        //                         response.message
        //                     );
        //                 }
        //             },
        //         });
        // }
    }

    verifyMobileOtp() {
        // if (this.otpInput.length == 4) {
        //     const otp = this.otpInput;
        //     const encodedNewMob = btoa(
        //         this.registerForm.controls['mobile'].value?.e164Number
        //     );
        //     this.settingsService
        //         .verifyMobileOtp(this.customerId, otp, encodedNewMob)
        //         .subscribe((response: ResendOtpResponseDto) => {
        //             if (response.status == 'error') {
        //                 this.eventService.emitEvent(
        //                     EventType.MODAL_ERROR,
        //                     response.message
        //                 );
        //             } else if (response.status == 'success') {
        //                 this.modal.dismissAll();
        //                 this.eventService.emitEvent(
        //                     EventType.MODAL_ERROR,
        //                     response.message
        //                 );
        //             }
        //         });
        // }
    }

    navigateToLogin() {
        this.eventService.emitEvent(EventType.LOGIN);
    }

    navigateToConfirmNumber() {
        this.modal.dismissAll();
        this.openModal(this.createProfileModal);
    }

    resendOtp() {
        this.onSubmitMobileLoginForm();
        this.eventService.emitEvent(
            EventType.MODAL_INFO,
            this.translate.instant('OTP resent successfully')
        );
    }

    onSubmitMobileLoginForm() {
        if (this.validateMobile(true)) {
            this.spinner.show();
            this.fullMobileNumber =
                this.registerForm.controls['mobile'].value.e164Number;
            // this.loginService.sendOtp(this.fullMobileNumber).subscribe((response: ResendOtpResponseDto) => {
            //     this.spinner.hide();
            //     const status = response['status'];
            //     if (status === 'error') {
            //         this.eventService.emitEvent(EventType.MODAL_ERROR, response['message'])
            //     }
            //     if (status == 'success') {
            //         this.openModal(this.verifyOtpAccountModal);
            //     }
            // });
        }
    }

    doItLater() {
        this.modal.dismissAll();
        this.modalOffcanvasService.dismissAllOffcanvases();
        setCache('welcome_pop_up', false);
    }

    onOtpChange($event: string) {
        this.otpInput = $event;
    }

    openModal(content: ModalOffcanvasComponent) {
        content.open();
    }

    enableBackButton() {
        this.showBackButton = true;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, map, of, switchMap, take } from 'rxjs';
import { getApiUrlV2 } from '../utilities/url.utils';

import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';
import { DealDto } from '../model/deal.model';
import {
    AgeGroup,
    AgeGroupResponseDto,
    CalendarInventoryDto,
    CalendarInventoryResponseDto,
    DealOffer,
    ProductDetailsDtoV2
} from './model/product-detail.model';

@Injectable({
    providedIn: 'root',
})
export class ProductService {

    // TODO > WIP
    private packageTypeIdToEndpointMap: Record<number, string> = {
        0: 'non-calendar',
        1: 'non-calendar',
        2: 'non-calendar',
        3: 'calendar',
        4: 'non-calendar'
    };

    constructor(
        private httpClient: HttpClient,
        private currencyLanguageService: CurrencyLanguageService
    ) { }

    getProductDetails(
        dealId: number,
        productUrl: string,
        fromDate: string,
        toDate: string
    ): Observable<[DealDto, { dealOffers: DealOffer[], inventory: CalendarInventoryDto[] }, AgeGroup[]]> {

        return this.fetchProductDetailsDto(dealId)
            .pipe(
                switchMap((productDetails) => {
                    const [deal] = productDetails.deals;

                    return forkJoin([
                        of(deal),
                        this.fetchDealOffers(dealId, deal),
                        this.fetchAgeGroups(deal),
                    ]);
                })
            )
    }

    private fetchProductDetailsDto(
        dealId: number,
    ) {
        return this.currencyLanguageService
            .getLocaleData()
            .pipe(
                switchMap((localeData: LocaleData) => {

                    const params = new HttpParams()
                        .set('cc', localeData.country)
                        .set('lang', localeData.language)
                        .set('pid', dealId);

                    return this.httpClient.get<ProductDetailsDtoV2>(
                        `${getApiUrlV2()}results/deals/pdp`,
                        { params }
                    )
                }),
                take(1) // Ensure completion
            );
    }

    private fetchDealOffers(
        dealId: number,
        deal: DealDto
    ) {
        return this.currencyLanguageService
            .getLocaleData()
            .pipe(
                switchMap((localeData: LocaleData) => {
                    const endpoint = this.packageTypeIdToEndpointMap[deal.packageTypeId]

                    const params = new HttpParams()
                        .set('countryCode', 'MU' ?? localeData.country) // TODO > TESTING (to be removed)
                        .set('language', localeData.language)
                        .set('dealId', 547 ?? dealId); // TODO > TESTING (to be removed)

                    const mockedData: DealOffer[] = [ // TODO > TESTING (to be removed) (while migration is not yet done)
                        {
                            id: 1,
                            title: 'Quad Adventure',
                            dealOptions: [
                                {
                                    id: 70513,
                                    title: 'Single Quad',
                                    subTitle: '16+ Years',
                                    sellingRate: 2600,
                                    crossedRate: 3295,
                                    flightPrice: 0,
                                    maxPax: 6,
                                    minPax: 2,
                                    occupancyId: 1,
                                    categoryId: 1,
                                    maribnbStayData: undefined,
                                    optionDiscounts: [],
                                },

                                {
                                    id: 70514,
                                    title: 'Double Quad',
                                    subTitle: '6+ Years',
                                    sellingRate: 3600,
                                    crossedRate: 4475,
                                    flightPrice: 0,
                                    maxPax: 7,
                                    minPax: 1,
                                    occupancyId: 1,
                                    categoryId: 1,
                                    maribnbStayData: undefined,
                                    optionDiscounts: [],
                                },
                            ],
                        }
                    ]

                    return this.httpClient
                        .get<{ dealOffers: DealOffer[] }>(
                            `${getApiUrlV2()}availability/deals/pdp/${endpoint}`,
                            { params }
                        )
                        .pipe(
                            map(res => res.dealOffers?.length ? res.dealOffers : mockedData) // TODO > TESTING (to be removed)
                        )
                }),
                switchMap(dealOffers => this.fetchCalendarInventory(dealOffers)),
                take(1) // Ensure completion
            );
    }

    private fetchCalendarInventory(dealOffers: DealOffer[]) {
        const params = new HttpParams()
            // TODO > TESTING (to be removed)
            .set('orgId', 1)
            .set('customerGroupId', 1)
            .set('packageTypeId', 1)
            .set('dealOptionIds', [1234, 456, 777].join());

        const mockedData: CalendarInventoryResponseDto = {
            inventory: [
                {
                    dealOptionId: 70513,
                    date: "2024-11-28",
                    qty: 5,
                    sellingPrice: 1800.00,
                    crossedOutPrice: 2250
                },
                {
                    dealOptionId: 70514,
                    date: "2024-11-28",
                    qty: 3,
                    sellingPrice: 900.00,
                    crossedOutPrice: 1125
                },
                {
                    dealOptionId: 70513,
                    date: "2024-11-29",
                    qty: 4,
                    sellingPrice: 1800.00,
                    crossedOutPrice: 2250
                },
                {
                    dealOptionId: 70514,
                    date: "2024-11-29",
                    qty: 4,
                    sellingPrice: 900.00,
                    crossedOutPrice: 1125
                },
                {
                    dealOptionId: 70513,
                    date: "2024-11-30",
                    qty: 5,
                    sellingPrice: 1800.00,
                    crossedOutPrice: 2250
                },
                {
                    dealOptionId: 70514,
                    date: "2024-11-30",
                    qty: 3,
                    sellingPrice: 900.00,
                    crossedOutPrice: 1125
                }
            ],
            total: 6
        };

        return this.httpClient
            .get<CalendarInventoryResponseDto>(`${getApiUrlV2()}availability/deals/cta/pdp/calendar`, { params })
            .pipe(
                map(res => /*res.inventory?.length ? res : */mockedData), // TODO > TESTING (to be removed)
                map(res => ({ inventory: res.inventory, dealOffers }))
            );
    }

    private fetchAgeGroups(deal: DealDto): Observable<AgeGroup[]> {
        if (![0, 1].includes(deal.packageTypeId)) // For some reason, some packageTypeId are 0
            return of(null);

        const params = new HttpParams()
            // TODO > TESTING (to be removed)
            .set('orgId', 1)
            .set('customerGroupId', 1)
            .set('outletId', 360);

        return this.httpClient
            .get<AgeGroupResponseDto>(`${getApiUrlV2()}availability/occupancies/age-groups`, { params })
            .pipe(
                map(res => res.ageGroups),
                map(this.setAdultStartValueTo2)
            )
    }

    private setAdultStartValueTo2(ageGroups: AgeGroup[]): AgeGroup[] {
        return ageGroups.map(ageGroup => {
            if (ageGroup.ageGroupType === 'Adult') {
                ageGroup.startValue = 2;
            }
            return ageGroup;
        })

    }
}

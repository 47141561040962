import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import {
    CalendarInventory,
    InventoryDto,
    DealDto,
    DealOffer,
} from 'src/app/model/deal.model';
import { transformInventoryResponseDto } from './dated-activity-booking.model';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import {
    getIsSelectedDateAvailable,
    transformNgbDateToDayJs,
    transformNgbDateToYmd,
} from 'src/app/controls/calendar/calendar.utils';
import { BookingConfiguredData } from '../booking-config.model';
import { ProductService } from '../../product.service';
import { ProductDetailTransformer } from '@app/product-detail/model/product-detail.transformers';
import { debounce } from 'lodash';

@UntilDestroy()
@Component({
    selector: 'md-dated-activity-booking',
    templateUrl: './dated-activity-booking.component.html',
    styleUrls: ['./dated-activity-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatedActivityBookingComponent implements OnInit {
    private productDetailsSubject = new BehaviorSubject<DealDto>(null);

    private productDetails$ = this.productDetailsSubject.asObservable().pipe(
        tap((value) => {
            if (!value) return;
            this.internalProductDetails = value;
        })
    );

    @Input() set productDetails(value: DealDto) {
        this.productDetailsSubject.next(value);
    }

    private lastDealOffers: DealOffer[];
    private lastBookingData: BookingConfiguredData;

    totalAllotment = -1;
    currencySymbol: 'Rs.' | 'EUR';
    internalProductDetails: DealDto;
    pricingByDate: CalendarInventory[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedDate: NgbDate;
    formattedDate = '-';
    calendarErrorMessage = '';
    isProcessingOptions = false;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarDatedActivity')
    calendarDatedActivity: CalendarComponent;

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private translate: TranslateService,
        private productService: ProductService,
        private productDetailTransformer: ProductDetailTransformer,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const currency$ = this.currencyLanguageService
            .getCurrency()
            .pipe(tap((currency) => (this.currencySymbol = currency)));

        combineLatest([this.productDetails$, currency$])
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    onDateRangeSelected(dateRange: CalendarSelectedDates): void {
        this.selectedDate = dateRange.singleDate;

        this.formattedDate = this.selectedDate
            ? transformNgbDateToDayJs(this.selectedDate).format('DD MMM, YYYY')
            : '-';

        this.productService
            .fetchDealOffers(
                this.internalProductDetails,
                transformNgbDateToYmd(this.selectedDate),
                false
            )
            .pipe(
                untilDestroyed(this),
                tap(({ dealOffers }) => {
                    this.internalProductDetails.dealOffers =
                        this.productDetailTransformer.transformDealOffers(
                            dealOffers
                        );
                    this.restoreLastDealOffers();
                    this.changeMaxPaxBasedOnInventory();
                    this.internalProductDetails = {
                        ...this.internalProductDetails,
                    };

                    if (this.lastBookingData) {
                        this.lastBookingData.productBookingData.selectedDate =
                            this.selectedDate;
                        // update price as well
                        const { price, priceBeforeDiscount } =
                            this.calculateDatePricingBasedOnOccupancy(
                                this.lastBookingData,
                                this.pricingByDate.find((item) =>
                                    this.selectedDate.equals(item.date)
                                )
                            );
                        this.lastBookingData.totalPrice = price;
                        this.lastBookingData.totalFullPrice =
                            priceBeforeDiscount;
                        this.bookingConfigured.emit(this.lastBookingData);
                    }

                    this.ref.markForCheck();
                })
            )
            .subscribe();
    }

    onQuantityChanged(dealOffers: DealOffer[]): void {
        this.lastDealOffers = dealOffers;
    }

    onBookingConfigured(data: BookingConfiguredData): void {
        this.debounceOnBookingConfigured(data);
    }

    private doBookingConfiguredWork(data: BookingConfiguredData) {
        if (this.isProcessingOptions) return;

        this.isProcessingOptions = true;
        if (!this.selectedDate) {
            this.lastBookingData = data;
            this.calendarDatedActivity?.openCalendar?.();
        }

        this.productService
            .fetchCalendarInventory(
                this.internalProductDetails,
                this.internalProductDetails.dealOffers
            )
            .pipe(
                untilDestroyed(this),
                tap(({ calendarInventory }) => {
                    this.internalProductDetails.inventory = calendarInventory;
                    this.internalProductDetails = {
                        ...this.internalProductDetails,
                    };

                    const { ignoreBookingConfigured } =
                        this.setCalendarInventory(
                            this.internalProductDetails.inventory,
                            data
                        ) ?? {};

                    if (!ignoreBookingConfigured)
                        this.bookingConfigured.emit(data);

                    this.ref.markForCheck();

                    this.isProcessingOptions = false;
                })
            )
            .subscribe();
    }

    private debounceOnBookingConfigured = debounce(
        (data: BookingConfiguredData) => {
            this.doBookingConfiguredWork(data);
        },
        1000
    );

    private setCalendarInventory(
        inventory: InventoryDto[],
        bookingConfiguredData?: BookingConfiguredData
    ): { ignoreBookingConfigured?: boolean } {
        if (!inventory?.length) {
            this.calendarErrorMessage = this.translate.instant(
                'calendarErrors.no-matching-dates'
            );
            return;
        }

        this.calendarErrorMessage = '';

        this.pricingByDate = transformInventoryResponseDto(inventory);

        this.additionalCalendarDayInfo.clear();

        this.pricingByDate.forEach((pricingByDate) =>
            this.additionalCalendarDayInfo.set(pricingByDate.dateAsString, {
                date: pricingByDate.date,
                tooltipContent: `${this.currencySymbol} ${
                    this.calculateDatePricingBasedOnOccupancy(
                        bookingConfiguredData,
                        pricingByDate
                    ).price
                }`,
                minLengthOfStay: 1,
            })
        );

        this.additionalCalendarDayInfo = new Map(
            this.additionalCalendarDayInfo
        );

        this.ref.detectChanges();
        this.calendarDatedActivity?.refreshCalendarWithoutOpen?.();

        return this.setSelectedDate(bookingConfiguredData);
    }

    private calculateDatePricingBasedOnOccupancy(
        bookingConfiguredData: BookingConfiguredData,
        pricingByDate: CalendarInventory
    ): { price: number; priceBeforeDiscount: number } {
        let price = 0;
        let priceBeforeDiscount = 0;
        bookingConfiguredData.productBookingData.selectedDealOptionQuantities.forEach(
            (qty, dealOfferOption) => {
                const priceByDateOption = pricingByDate.options.find(
                    (option) => option.optionId === dealOfferOption.dealOptionId
                );

                price += priceByDateOption?.price * qty || 0;
                priceBeforeDiscount +=
                    priceByDateOption?.priceBeforeDiscount * qty || 0;
            }
        );

        return { price, priceBeforeDiscount };
    }

    private setSelectedDate(data: BookingConfiguredData): {
        ignoreBookingConfigured?: boolean;
    } {
        if (!data?.productBookingData) return;

        if (!this.selectedDate) {
            this.calendarDatedActivity &&
                (this.calendarDatedActivity.singleDate = null);
            this.formattedDate = '-';
            return;
        }

        const isSelectedDateAvailable = getIsSelectedDateAvailable(
            this.selectedDate,
            this.additionalCalendarDayInfo
        );

        // if this.selectedDate
        if (isSelectedDateAvailable) {
            data.productBookingData.selectedDate = this.selectedDate;
            this.lastBookingData = data;
            this.bookingConfigured.emit(data);
            return { ignoreBookingConfigured: true };
        }

        // if !isSelectedDateAvailable
        this.selectedDate = null;
        this.formattedDate = '-';

        if (this.calendarDatedActivity) {
            this.calendarDatedActivity.singleDate = null;
            this.calendarDatedActivity.openCalendar();
        }
    }

    private changeMaxPaxBasedOnInventory() {
        const selectedDateAsString = transformNgbDateToYmd(this.selectedDate);

        this.internalProductDetails.dealOffers.forEach((dealOffer) =>
            dealOffer.dealOptions.forEach((dealOption) => {
                const { qty } =
                    this.internalProductDetails.inventory.find(
                        (item) =>
                            item.dealOptionId === dealOption.dealOptionId &&
                            item.date === selectedDateAsString
                    ) ?? {};

                if (qty > 0 && qty < dealOption.maxPax) {
                    dealOption.maxPaxDynamic = qty;
                }

                if (dealOption.qty > dealOption.maxPaxDynamic) {
                    dealOption.qty = dealOption.maxPaxDynamic;
                }
            })
        );
    }

    private restoreLastDealOffers() {
        if (!this.lastDealOffers) return;

        this.internalProductDetails.dealOffers.forEach((dealOffer, i) => {
            dealOffer.dealOptions.forEach((dealOption, j) => {
                const lastDealOption = this.lastDealOffers[i].dealOptions[
                    j
                ] ?? { qty: null };

                dealOption.qty = lastDealOption.qty ?? dealOption.qty;
            });
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { ChallengesService } from './challenges.service';
import { SwiperOptions } from 'swiper';
import { EventService, EventType } from '../../../utilities/event.service';
import { ProfileSection } from '../../profile-wrapper.component';
import { getCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';
import { RewardsService } from 'src/app/services/rewards.service';

@Component({
    selector: 'app-challenges',
    templateUrl: './challenges.component.html',
    styleUrls: ['./challenges.component.scss'],
})
export class ChallengesComponent implements OnInit {
    isCustomerData: boolean = false;
    isBadgesData: boolean = false;
    earnSpendPoints = false;
    // TODO: All of these need actual types
    customerPointsAndBalance: any;
    rewardPointBadgesData: any;
    availedBadgesArray: any = [];
    notAvailedBadgesArray: any = [];

    availableBadgeCount: number = 0;
    totalBadgeCount: number = 0;

    constructor(
        private rewardsService: RewardsService,
        private eventService: EventService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            if (isLoggedIn) {
                this.getCustomerDetails();
                this.getBadgesData();
                const welcome = getCache('welcome_pop_up').data;
                if (welcome) {
                    this.eventService.emitEvent(EventType.WELCOME);
                }
            }
        });
    }

    public config: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 27,
        slidesPerView: 3,
    };

    getCustomerDetails() {
        this.isCustomerData = true;
        this.rewardsService.rewardsPoints$.subscribe((points) => {
            const { rewardPointsBalance } = points;
            this.customerPointsAndBalance = {
                balance_points: rewardPointsBalance,
            };
            this.isCustomerData = false;
        });
    }

    getBadgesData() {
        // this.isBadgesData = true;
        // this.challengesService.getRewardPointBadge().subscribe(
        //     (response: any) => {
        //         this.rewardPointBadgesData = response;
        //         for (let badges_in_groups of this.rewardPointBadgesData
        //             ?.badges_info) {
        //             for (let all_badges of badges_in_groups?.data) {
        //                 if (all_badges.is_availed === true) {
        //                     this.availedBadgesArray.push(all_badges);
        //                     this.isBadgesData = false;
        //                 }
        //                 if (all_badges.is_availed === false) {
        //                     this.notAvailedBadgesArray.push(all_badges);
        //                     this.isBadgesData = false;
        //                 }
        //             }
        //         }
        //         this.availableBadgeCount = this.availedBadgesArray.length;
        //         this.totalBadgeCount =
        //             this.availableBadgeCount +
        //             this.notAvailedBadgesArray.length;
        //     },
        //     (error) => {
        //         this.isBadgesData = false;
        //     }
        // );
    }

    showHideEarnSpendPointsAns() {
        this.earnSpendPoints = !this.earnSpendPoints;
    }

    protected readonly ProfileSection = ProfileSection;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { HomeDealsDto } from './home.model';
import { getApiUrlV2 } from '../utilities/url.utils';
import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    constructor(
        private httpClient: HttpClient,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    // getUpcomingTrip() {
    //     const customerId = getCustomerId();
    //     const upcomingTripUrl =
    //         getApiUrlMofluid() +
    //         'myupcomingtrip?service=myupcomingtrip&customerid=' +
    //         customerId +
    //         '&category=all' +
    //         '&customer_group_id=' +
    //         this.common.getCustomerGroupID();

    //     return this.httpClient
    //         .get(upcomingTripUrl, { headers: this.common.getTokenHeaders() })
    //         .pipe(
    //             map((res: UpcomingTripDto) => {
    //                 return res;
    //             })
    //         );
    // }

    getDeals(): Observable<HomeDealsDto> {
        return this.currencyLanguageService
            .getLocaleData()
            .pipe(
                switchMap((localeData: LocaleData) =>
                    this.httpClient.get<HomeDealsDto>(
                        `${getApiUrlV2()}results/home?lang=${
                            localeData.language
                        }&cc=${localeData.country}`
                    )
                )
            );
    }
}

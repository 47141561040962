import { AbstractControl, ValidationErrors } from '@angular/forms';

export const isNullOrWhiteSpace = (value: string): boolean => {
    return !value || (value + '').trim() === '';
};

export const addClassToSearchDatesComponent = (): void => {
    const searchDates = document.getElementsByTagName('md-call-to-action')[0];
    if (searchDates) {
        searchDates.classList.add('filters-aware');
    }
};

export const removeClassFromSearchDatesComponent = (): void => {
    const searchDates = document.getElementsByTagName('md-call-to-action')[0];
    if (searchDates) {
        searchDates.classList.remove('filters-aware');
    }
};

export const isSafari = (): boolean => {
    const ua = navigator.userAgent.toLowerCase();
    return (
        ua.indexOf('safari') !== -1 &&
        ua.indexOf('chrome') === -1 &&
        ua.indexOf('android') === -1
    );
};

export const isFirefox = (): boolean => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('firefox') > -1;
};

type RibbonStyle = {
    color?: string;
    background?: string;
};

type RibbonEnabledDeal = {
    ribbon: { ribbonName: string; colorCode: string };
};

export const getRibbonStyles = (dealEntry: RibbonEnabledDeal): RibbonStyle => {
    if (!dealEntry || !dealEntry.ribbon) {
        return {};
    }

    const ribbon = dealEntry.ribbon;
    const textColor =
        ribbon.ribbonName.toUpperCase() === 'FLASH SALES'
            ? '#ffffff'
            : '#092e41';

    return {
        color: textColor,
        background: ribbon.colorCode,
    };
};
export function noWhitespaceValidator(
    control: AbstractControl
): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
}

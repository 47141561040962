import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Locale } from 'src/app/config/locale';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
    availableNotifications: any | null | undefined;
    parsedData: any;
    noOfDays: number = 0;
    daysHrsMinsSecs!: {
        day: number;
        hour: number;
        minute: number;
        second: number;
    };
    noOfWeeks!: number;
    noOfMonths!: number;
    noOfYears!: number;
    parsedDataLength: any;
    loginStatus!: number;
    isShowNotificationSkeleton: boolean = false;
    isUserLoggedIn: boolean = false;
    badgeImgCdn: string = 'https://assets.marideal.mu/media/';
    userData: any;
    uId: string;
    quoteid: string;
    localeAlert: any;
    @Input() isNewNotification: boolean = true;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private localeObj: Locale,
        private translate: TranslateService,
        private userService: UserService
    ) {
        this.localeAlert = this.localeObj.getAlertMessages();
    }

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            this.isUserLoggedIn = isLoggedIn;
            if (isLoggedIn) {
                this.getNotifications(userData.userId);
            }
        });
    }

    getNotifications(customerId: number) {
        this.isShowNotificationSkeleton = true;
        this.httpClient.get('no url').subscribe({
            next: (response: any) => {
                this.isShowNotificationSkeleton = false;
                this.parsedData = response.notification_list;
                this.parsedDataLength = this.parsedData.length;
                this.isNewNotification = false;
            },
        });
    }

    decodeBase64(value: any) {
        return atob(value);
    }

    convertTZ(date: any, tzString: any) {
        return new Date(
            (typeof date === 'string' ? new Date(date) : date).toLocaleString(
                'en-US',
                { timeZone: tzString }
            )
        );
    }

    timeAgo(strTime: any) {
        const date = new Date();
        var start = new Date(strTime).getTime();
        var end = this.convertTZ(date, 'Indian/Mauritius').getTime();
        let diff = end - start;
        var myTimediff = Math.round(Math.abs(diff / 1000));
        var strTimeAgo = '';
        if (myTimediff <= 60) {
            strTimeAgo = this.translate.instant('less than a minute ago')
                ? this.translate.instant('less than a minute ago')
                : 'less than a minute ago';
        } else if (myTimediff >= 60 && myTimediff < 3600) {
            strTimeAgo =
                Math.round(myTimediff / 60) == 1
                    ? this.translate.instant('a minute')
                        ? this.translate.instant('a minute')
                        : 'a minute'
                    : Math.round(myTimediff / 60) +
                      (this.translate.instant('minutes ago')
                          ? this.translate.instant('minutes ago')
                          : 'minutes ago');
        } else if (myTimediff >= 3600 && myTimediff < 86400) {
            strTimeAgo =
                Math.round(myTimediff / 3600) == 1
                    ? this.translate.instant('a hour ago')
                        ? this.translate.instant('a hour ago')
                        : 'a hour ago'
                    : Math.round(myTimediff / 3600) +
                      (this.translate.instant('hours ago')
                          ? this.translate.instant('hours ago')
                          : 'hours ago');
        } else if (myTimediff >= 86400 && myTimediff < 604800) {
            strTimeAgo =
                Math.round(myTimediff / 86400) == 1
                    ? this.translate.instant('a day ago')
                        ? this.translate.instant('a day ago')
                        : 'a day ago'
                    : Math.round(myTimediff / 86400) +
                      (this.translate.instant('days ago')
                          ? this.translate.instant('days ago')
                          : 'days ago');
        } else if (myTimediff >= 604800 && myTimediff < 2600640) {
            strTimeAgo =
                Math.round(myTimediff / 604800) == 1
                    ? this.translate.instant('a week ago')
                        ? this.translate.instant('a week ago')
                        : 'a week ago'
                    : Math.round(myTimediff / 604800) +
                      (this.translate.instant('weeks ago')
                          ? this.translate.instant('weeks ago')
                          : 'weeks ago');
        } else if (myTimediff >= 2600640 && myTimediff < 31207680) {
            strTimeAgo =
                Math.round(myTimediff / 2600640) == 1
                    ? this.translate.instant('a month ago')
                        ? this.translate.instant('a month ago')
                        : 'a month ago'
                    : Math.round(myTimediff / 2600640) +
                      (this.translate.instant('months ago')
                          ? this.translate.instant('months ago')
                          : 'months ago');
        } else if (myTimediff >= 31207680) {
            strTimeAgo =
                Math.round(myTimediff / 31207680) == 1
                    ? this.translate.instant('a year ago')
                        ? this.translate.instant('a year ago')
                        : 'a year ago'
                    : Math.round(myTimediff / 31207680) +
                      (this.translate.instant('years ago')
                          ? this.translate.instant('years ago')
                          : 'years ago');
        }
        return strTimeAgo;
    }

    calculateDateDiff(dateSent: Date) {
        let currentDate = new Date();
        dateSent = new Date(dateSent);

        this.daysHrsMinsSecs = this.getDataDiff(dateSent, currentDate);
    }

    getDataDiff(startDate: any, endDate: any) {
        let diff = endDate.getTime() - startDate.getTime();
        let days = Math.floor(diff / (60 * 60 * 24 * 1000));
        let hours = Math.floor(diff / (60 * 60 * 1000)) - days * 24;
        let minutes =
            Math.floor(diff / (60 * 1000)) - (days * 24 * 60 + hours * 60);
        let seconds =
            Math.floor(diff / 1000) -
            (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60);
        return { day: days, hour: hours, minute: minutes, second: seconds };
    }

    convertDaysToWeeks(days: any) {
        this.noOfWeeks = Math.round(days / 7);
        return this.noOfWeeks;
    }

    convertWeeksToMonths(weeks: any) {
        this.noOfMonths = Math.round(weeks * 0.229984);
        return this.noOfMonths;
    }

    convertMonthsToYears(months: any) {
        this.noOfYears = Math.round(months / 12);
        return this.noOfYears;
    }

    decodeNgetQueryParam(encodedQueryParams: any) {
        const object = JSON.parse(this.decodeBase64(encodedQueryParams));
        let queryParamsObj = {};
        for (const [key, value] of Object.entries(object)) {
            if (Array.isArray(key) && Array.isArray(value)) {
                queryParamsObj[key[0]] = value[0];
            } else if (Array.isArray(key) && !Array.isArray(value)) {
                queryParamsObj[key[0]] = value;
            } else if (!Array.isArray(key) && Array.isArray(value)) {
                queryParamsObj[key] = value[0];
            } else {
                queryParamsObj[key] = value;
            }
        }
        return queryParamsObj;
    }

    redirectFromCatDealNoti(url: any, urlParam: any) {
        let queryParamsObj = this.decodeNgetQueryParam(urlParam);
        this.router.navigate([url], {
            queryParams: queryParamsObj,
        });
    }

    redirectFromProdDealNoti(url: any) {
        let urlPathname = url.split('?')[0];
        let queryParamsArray = url.split('?')[1].split('&');

        let allKeyValuePair = [];
        for (let keys of queryParamsArray) {
            keys = keys.split('=');
            allKeyValuePair.push(keys);
        }
        let queryParamsObj = {};
        for (let pair of allKeyValuePair) {
            queryParamsObj[pair[0]] = pair[1];
        }
        console.log(queryParamsObj);
        this.router.navigate(['/deal/' + urlPathname], {
            queryParams: queryParamsObj,
        });
    }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { RecentlyViewedService } from './recently-viewed.service';
import { BestSellerEntry } from 'src/app/home/home.model';
import { ProductCardEntry } from 'src/app/controls/product-card/product-card.component';
import { DeviceCommsService } from 'src/app/services/device-comms.service';

@Component({
    selector: 'md-recently-viewed',
    templateUrl: './recently-viewed.component.html',
})
export class RecentlyViewedComponent {
    recentlyViewedEntries: BestSellerEntry[];

    constructor(
        private service: RecentlyViewedService,
        private deviceCommsService: DeviceCommsService
    ) {}

    @Output() dealSelected = new EventEmitter<ProductCardEntry>();

    onDealSelected(dealEntry: ProductCardEntry): void {
        const { productPath, id } = dealEntry;
        this.deviceCommsService.openLinkInNewTabIfBrowser(
            `deal/${productPath}/${id}`
        );
        this.dealSelected.emit(dealEntry);
    }
}

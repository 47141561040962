import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface OptionData {
    label: string;
    value: string;
}

@Injectable({
    providedIn: 'root',
})
export class EditProfileUtilsService {
    constructor(private translate: TranslateService) {}
    genderArray: OptionData[] = [];
    monthsArray: OptionData[] = [];
    childrenArray: OptionData[] = [];
    relationshipArray: OptionData[] = [];

    countryCodeToCountryMap = {
        MU: 'Mauritius',
        RE: 'Reunion',
        OT: 'Other',
    };

    populateData(): void {
        this.genderArray = [
            {
                label: this.translate.instant('Male'),
                value: '1',
            },
            {
                label: this.translate.instant('Female'),
                value: '2',
            },
            {
                label: this.translate.instant('Open Minded'),
                value: '3',
            },
        ];
        this.monthsArray = [
            {
                label: this.translate.instant('January'),
                value: '01',
            },
            {
                label: this.translate.instant('February'),
                value: '02',
            },
            {
                label: this.translate.instant('March'),
                value: '03',
            },
            {
                label: this.translate.instant('April'),
                value: '04',
            },
            {
                label: this.translate.instant('May'),
                value: '05',
            },
            {
                label: this.translate.instant('June'),
                value: '06',
            },
            {
                label: this.translate.instant('July'),
                value: '07',
            },
            {
                label: this.translate.instant('August'),
                value: '08',
            },
            {
                label: this.translate.instant('September'),
                value: '09',
            },
            {
                label: this.translate.instant('October'),
                value: '10',
            },
            {
                label: this.translate.instant('November'),
                value: '11',
            },
            {
                label: this.translate.instant('December'),
                value: '12',
            },
        ];
        this.childrenArray = Array.from({ length: 11 }, (_, i) => ({
            label: i.toString(),
            value: i.toString(),
        }));
        this.relationshipArray = [
            {
                label: this.translate.instant('Single'),
                value: '1',
            },
            {
                label: this.translate.instant('Married'),
                value: '2',
            },
            {
                label: this.translate.instant('Couple'),
                value: '3',
            },
        ];
    }

    getMonthArray(): OptionData[] {
        return this.monthsArray;
    }

    getChildrenArray(): OptionData[] {
        return this.childrenArray;
    }

    getGenderArray(): OptionData[] {
        return this.genderArray;
    }

    getRelationshipArray(): OptionData[] {
        return this.relationshipArray;
    }

    getGenderLabel(value: string): string {
        return this.genderArray.find((item) => item.value === value)?.label;
    }
}

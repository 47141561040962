import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { EventService, EventType } from '../utilities/event.service';
import { UserData, UserService } from '../services/user.service';
import { AuthHttpClientService } from '../services/auth-http-client.service';
import { getMyAccountUrl } from '../utilities/url.utils';

const LOCALE_STORAGE_KEY = 'localeData';

export interface LocaleData {
    language: 'en' | 'fr';
    currency: 'Rs.' | 'EUR';
    country: 'MU' | 'RE' | 'OT';
    localeForCurrency: 'en-MU' | 'fr-RE';
    threeLetterCurrency: 'MUR' | 'EUR';
    customerGroupId: number;
}

interface BdcResponse {
    isoAlpha2: string;
    isoAlpha3: string;
    isoName: string;
    currency: {
        code: string;
    };
}

@Injectable({
    providedIn: 'root',
})
export class CurrencyLanguageService {
    private localeData: LocaleData;

    constructor(
        private httpClient: HttpClient,
        private translate: TranslateService,
        private eventService: EventService,
        private authHttpClientService: AuthHttpClientService,
        private userService: UserService
    ) {}

    getLocaleData(): Observable<LocaleData> {
        return this.eventService
            .onEventOrAlreadyHappened(EventType.LOCALE_CHANGED)
            .pipe(
                map(() => {
                    return this.localeData;
                })
            );
    }

    /** This returns the locale data immediately. It does not guarantee its correctness
     * nor does it retrigger when the locale changes.
     * This shouldn't be used to load data that is expected to change when the locale is changed
     **/
    getLocaleDataImmediate(): LocaleData {
        return this.localeData;
    }

    getCurrency(): Observable<'Rs.' | 'EUR'> {
        return this.eventService
            .onEventOrAlreadyHappened(EventType.LOCALE_CHANGED)
            .pipe(
                map(() => {
                    return this.localeData.currency;
                })
            );
    }

    // TODO: This is not quite right. The locale should regard both the language and the country but there is no en-RE for example
    getLocaleForCurrency(): Observable<'en-MU' | 'fr-RE'> {
        return this.eventService
            .onEventOrAlreadyHappened(EventType.LOCALE_CHANGED)
            .pipe(
                map(() => {
                    return this.localeData.localeForCurrency;
                })
            );
    }

    setLocaleData(
        country: 'MU' | 'RE' | 'OT',
        language: 'en' | 'fr',
        updateUserIfLoggedIn = false
    ): void {
        this.setLocaleBasedOnCountryLanguageAndEmit(country, language);
        if (updateUserIfLoggedIn && this.userService.isLoggedIn) {
            this.changeUserLanguage(language);
        }
    }

    setCurrencyAndLanguageOnAppInit(
        userLoggedIn: boolean,
        userData: UserData
    ): void {
        if (userLoggedIn) {
            this.setCountryLanguageForLoggedInUser(userData);
        } else {
            const localeData = this.getLocaleFromLocalStorage();
            if (localeData) {
                this.setCountryLanguageFromLocalStorage(localeData);
            } else {
                const url = new URL(window.location.href);
                const args = new URLSearchParams(url.search);
                if (!args.has('iso')) {
                    this.setCountryLanguageBasedOnServerResponse();
                } else {
                    this.setCountryLanguageBasedOnIso(args);
                }
            }
        }
    }

    addIsoToUrlIfNeeded() {
        const url = new URL(window.location.href);
        const args = new URLSearchParams(url.search);
        if (this.localeData) {
            const language = this.localeData.language;
            const country = this.localeData.country;

            if (language != null && country != null) {
                const urlh = new URL(window.location.href);
                urlh.searchParams.set(
                    'iso',
                    `${country}-${language.toUpperCase()}`
                );
                history.replaceState(null, '', urlh.toString());
            }
        }
    }

    private setCountryLanguageFromLocalStorage(localeData: LocaleData) {
        this.localeData = localeData;
        const { language, country } = localeData;
        this.setLocaleBasedOnCountryLanguageAndEmit(country, language);
    }

    private setCountryLanguageBasedOnServerResponse() {
        return this.bdcCall()
            .pipe(
                map((response: BdcResponse) => {
                    const countryRaw = response.isoAlpha2;
                    const country =
                        this.getCountryFromCountryString(countryRaw);
                    const language = country === 'RE' ? 'fr' : 'en';
                    this.setLocaleBasedOnCountryLanguageAndEmit(
                        country,
                        language
                    );
                })
            )
            .subscribe();
    }

    private getCountryFromCountryString(country: string): 'MU' | 'RE' | 'OT' {
        return country === 'RE' ? 'RE' : country === 'MU' ? 'MU' : 'OT';
    }

    private setCountryLanguageBasedOnIso(args: URLSearchParams) {
        // if we have stuff in the URL we just set as that\
        const isoShards = args.get('iso')?.split('-');
        const country = isoShards?.[0] as 'MU' | 'RE' | 'OT';
        const languageUpperCase = isoShards?.[1] as 'EN' | 'FR';
        const language = languageUpperCase === 'EN' ? 'en' : 'fr';
        this.setLocaleBasedOnCountryLanguageAndEmit(country, language);
    }

    private countryCodeToCurrencyMap: { [key: string]: 'Rs.' | 'EUR' } = {
        MU: 'Rs.',
        RE: 'EUR',
        OT: 'EUR',
    };
    private countryCodeToLocaleForCurrencyMap: {
        [key: string]: 'en-MU' | 'fr-RE';
    } = {
        MU: 'en-MU',
        RE: 'fr-RE',
        OT: 'fr-RE',
    };
    private countryCodeToCustomerGroupIdMap: { [key: string]: number } = {
        MU: 1,
        RE: 8,
        OT: 11,
    };
    private setCountryLanguageForLoggedInUser(userData: UserData): void {
        const country = userData.countryCode;
        const language = userData.language;
        this.setLocaleBasedOnCountryLanguageAndEmit(country, language);
    }

    private setLocaleBasedOnCountryLanguageAndEmit(
        country: 'MU' | 'RE' | 'OT',
        language: 'en' | 'fr'
    ): void {
        this.localeData = {
            country,
            currency: this.countryCodeToCurrencyMap[country],
            threeLetterCurrency:
                this.countryCodeToCurrencyMap[country] === 'Rs.'
                    ? 'MUR'
                    : 'EUR',
            language,
            localeForCurrency: this.countryCodeToLocaleForCurrencyMap[country],
            customerGroupId: this.countryCodeToCustomerGroupIdMap[country],
        };
        this.setLocaleInLocalStorage(this.localeData);
        this.checkLanguageAndResetIfInvalid(language);
        this.translate.use(language);
        this.eventService.emitEvent(EventType.LOCALE_CHANGED);
        this.addIsoToUrlIfNeeded();
    }

    private checkLanguageAndResetIfInvalid(language: string): void {
        if (this.isInvalidLanguage(language)) {
            this.setLocaleBasedOnCountryLanguageAndEmit('MU', 'en');
        }
    }

    private isInvalidLanguage(language: string): boolean {
        return language !== 'en' && language !== 'fr';
    }

    private getLocaleFromLocalStorage(): LocaleData {
        const localeData = localStorage.getItem(LOCALE_STORAGE_KEY);
        if (localeData) {
            return JSON.parse(localeData);
        }
        return null;
    }

    private setLocaleInLocalStorage(localeData: LocaleData) {
        localStorage.setItem(LOCALE_STORAGE_KEY, JSON.stringify(localeData));
    }

    private bdcCall(): Observable<BdcResponse> {
        return this.httpClient.get<BdcResponse>(
            'https://api-bdc.net/data/country-by-ip?key=bdc_1e143401716241d79e575f569367dd2e'
        );
    }

    private changeUserLanguage(language: 'en' | 'fr'): void {
        this.userService.changeLanguage(language);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ProfileSection } from '../../profile-wrapper.component';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { UserService } from 'src/app/services/user.service';

declare let $: any;

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    loginStatus!: number;
    isUserLoggedIn: boolean = false;
    customerId: any;
    customerVerificationInfoResponse: any;
    privacySettingsResponse: any;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    accountVerification!: UntypedFormGroup;
    emailVerifyOtpForm!: UntypedFormGroup;
    mobileVerifyOtpForm!: UntypedFormGroup;
    changePasswordForm!: UntypedFormGroup;
    deleteAccountForm!: UntypedFormGroup;
    downloadPersonalDataForm!: UntypedFormGroup;
    verifyVerifiedImg: string = '';
    showChangePasswordBlock: boolean = false;
    showPersonalDataBlock: boolean = false;
    showDeleteAccountBlock: boolean = false;
    verifyEmailOtpPopup: boolean = false;
    verifyMobileOtpPopup: boolean = false;
    showRequiredFieldMsg: boolean = false;
    showRequiredFieldMsgInPersonalDataBlock: boolean = false;
    showPassBlockInDeleteAcc: boolean = false;
    showRequiredFieldMsgInDeleteAcc: boolean = false;
    isSettingsData: boolean = false;
    CountryPlaceholder: string = this.translate.instant('Search Country');

    sectionBeingEdited: 'password' | 'personalData' | 'deleteAccount' | '' = '';

    constructor(
        private router: Router,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private eventService: EventService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            if (isLoggedIn) {
                this.customerId = userData?.userId;
                this.isUserLoggedIn = true;
            }
        });
        this.accountVerification = new UntypedFormGroup({
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ]),
            mobile: new UntypedFormControl('', [
                Validators.pattern('[0-9]{0-10}'),
            ]),
        });

        this.emailVerifyOtpForm = new UntypedFormGroup({
            emailVerifyOtp: new UntypedFormControl('', [
                Validators.maxLength(4),
            ]),
        });

        this.mobileVerifyOtpForm = new UntypedFormGroup({
            mobileVerifyOtp: new UntypedFormControl('', [
                Validators.maxLength(4),
            ]),
        });

        this.changePasswordForm = new UntypedFormGroup({
            currentPassword: new UntypedFormControl('', [Validators.required]),
            newPassword: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(8),
            ]),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
        });

        this.downloadPersonalDataForm = new UntypedFormGroup({
            currentPassword: new UntypedFormControl('', [Validators.required]),
        });

        this.deleteAccountForm = new UntypedFormGroup({
            consentCheckbox: new UntypedFormControl('', [
                Validators.requiredTrue,
            ]),
            currentPassword: new UntypedFormControl('', [Validators.required]),
        });
    }

    dialcode: string = '';

    changePassword() {
        if (
            this.changePasswordForm.value.currentPassword == '' ||
            this.changePasswordForm.value.newPassword == '' ||
            this.changePasswordForm.value.confirmPassword == ''
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Password should be at least 8 characters'
                )
            );
            this.showRequiredFieldMsg = true;
        } else if (
            this.changePasswordForm.value.newPassword.length < 8 ||
            this.changePasswordForm.value.confirmPassword.length < 8
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Password should be at least 8 characters'
                )
            );
        } else if (
            this.changePasswordForm.value.newPassword !=
            this.changePasswordForm.value.confirmPassword
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'New password and Confirm password do not match'
                )
            );
        } else {
            this.settingsService
                .changePassword(
                    this.changePasswordForm.value.currentPassword,
                    this.changePasswordForm.value.newPassword,
                    this.changePasswordForm.value.confirmPassword
                )
                .subscribe({
                    next: (response: any) => {
                        if (response.change_status == 0) {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.message
                            );
                        } else if (response.change_status == 1) {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.message
                            );
                            this.router.navigate(['/']);
                        }
                    },
                    error: (error) => {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            error.error.message
                        );
                    },
                });
        }
    }

    downloadPersonalData() {
        if (
            this.downloadPersonalDataForm.value.currentPassword == '' ||
            this.downloadPersonalDataForm.value.currentPassword == undefined
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Password should be at least 6 characters'
                )
            );
            this.showRequiredFieldMsgInPersonalDataBlock = true;
        } else {
            let encodedCurrentPass = btoa(
                this.downloadPersonalDataForm.value.currentPassword
            );
            this.settingsService
                .downloadPrivacyCsv(this.customerId, encodedCurrentPass)
                .subscribe((response: any) => {
                    if (response.status == 0) {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.errorMessage
                        );
                    } else if (response.status == 1) {
                        window.location.href = response.pdfUrl;
                    }
                });
        }
    }

    onCheckboxChange(e: any) {
        if (e.target.checked) {
            this.showPassBlockInDeleteAcc = true;
            this.showRequiredFieldMsgInDeleteAcc = false;
        } else {
            this.showPassBlockInDeleteAcc = false;
        }
    }

    deleteAccount() {
        if (
            this.deleteAccountForm.controls['consentCheckbox'].value[0] ==
                false ||
            this.deleteAccountForm.controls['consentCheckbox'].value == false
        ) {
            this.showRequiredFieldMsgInDeleteAcc = true;
        } else if (
            this.deleteAccountForm.controls['consentCheckbox'].value == true
        ) {
            if (
                this.deleteAccountForm.value.currentPassword == '' ||
                this.deleteAccountForm.value.currentPassword == undefined
            ) {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant(
                        'Password should be at least 6 characters'
                    )
                );
                this.showRequiredFieldMsgInDeleteAcc = true;
            } else {
                let encodedCurrentPass = btoa(
                    this.deleteAccountForm.value.currentPassword
                );
                this.settingsService
                    .deleteRequest(this.customerId, encodedCurrentPass)
                    .subscribe((response: any) => {
                        if (response.status == 0) {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.errorMessage
                            );
                        } else if (response.status == 1) {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.errorMessage
                            );
                            this.router.navigate(['customer/account']);
                        }
                    });
            }
        }
    }

    getcountryCodeForMob() {
        let selectedCodes = document.querySelectorAll('.selected-dial-code');
        let codes = selectedCodes[0].innerHTML;
        if (selectedCodes.length > 1) {
            codes = selectedCodes[1].innerHTML;
        }
        return codes;
    }

    protected readonly ProfileSection = ProfileSection;
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PopupOffcanvasComponent } from 'src/app/controls/popup-offcanvas/popup-offcanvas.component';

export interface LocationOption {
    filterTypeId: number;
    filterId: number;
    name: string;
}

@Component({
    selector: 'md-search-location',
    templateUrl: './search-location.component.html',
    styleUrls: ['./search-location.component.scss'],
})
export class SearchLocationComponent {
    // TODO: this ideally comes from a service somewhere
    locationOptions = [
        {
            filterTypeId: 2,
            filterId: 2,
            name: 'North',
        },
        {
            filterTypeId: 2,
            filterId: 1,
            name: 'South',
        },
        {
            filterTypeId: 2,
            filterId: 4,
            name: 'East',
        },
        {
            filterTypeId: 2,
            filterId: 3,
            name: 'West',
        },
        {
            filterTypeId: 2,
            filterId: 5,
            name: 'Centre',
        },
        {
            filterTypeId: 2,
            filterId: 6,
            name: 'Islands',
        },
    ];
    selectedLocationOption: LocationOption;

    @Output() locationChanged = new EventEmitter<LocationOption>();

    @ViewChild('searchLocationOffcanvas') offcanvas: PopupOffcanvasComponent;

    constructor() {
        this.onModalContinueClick = this.onModalContinueClick.bind(this);
        this.onLocationChanged = this.onLocationChanged.bind(this);
    }

    onLocationChanged(location: LocationOption): void {
        this.selectedLocationOption = location;
        this.locationChanged.emit(this.selectedLocationOption);
        this.offcanvas.close();
    }

    onModalContinueClick(): void {
        this.locationChanged.emit(this.selectedLocationOption);
    }

    openLocation() {
        this.offcanvas.open();
    }
}

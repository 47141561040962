export const DESKTOP_PLP_THUMB_IMG_TYPE = 2;
export const DESKTOP_PDP_MAIN_IMG_TYPE = 4;
export const MOBILE_PLP_THUMB_IMG_TYPE = 6;
export const MOBILE_PDP_MAIN_IMG_TYPE = 8;

export interface DealDto {
    dealId: number;
    outletId: number;
    categoryId: number;
    sectionIds: number[];
    customerGroupIds: number[];
    dealTitle: string;
    subTitle: string;
    baseSellingPrice: number;
    baseCrossedOutPrice: number;
    currencySymbol: string;
    currencyPosition: string;
    percentDiscount: string;
    url: string;
    countryCode: string;
    language: string;
    bestSellerQtyRank: number;
    reviewsCount: number;
    starRating: number;
    townName: string;
    images: DealImageDto[];
    information: DealInformationDto;
    conditions: DealConditionsDto[];
    outletFacilities: OutletFacilitiesDto;
    outletReviews: OutletReviewsDto;
    ribbon: DealRibbonDto;
    packageTypeId: number;
}

export interface DealInformationDto {
    about_description: string;
    about_description_fr: string;
    latitude: number;
    longitude: number;
    meta_description: string;
    meta_description_fr: string;
    meta_keywords: string;
    meta_keywords_fr: string;
    meta_title: string;
    meta_title_fr: string;
    package_details: string;
    package_details_fr: string;
    purchase_includes: string;
    purchase_includes_fr: string;
}

export interface DealConditionsDto {
    condition: string;
    conditionId: number;
    conditionTitle: string;
}

export interface DealImageDto {
    dealId: number;
    imgId: number;
    imgUrl: string;
    imgType: ImageTypeDto;
    imgMeta: ImageMetaDto;
}

export interface ImageTypeDto {
    imgTypeId: number; // constants at the top
    imgTypeName: string;
    imgWidth: number;
    imgHeight: number;
    imgSize: number;
}

export interface ImageMetaDto {
    dealId: number;
    imgId: number;
    imgAltText: string;
    imgBase: number;
    imgOrder: number;
}

export interface OutletFacilitiesDto {
    facilities: FacilityDto[];
    roomCategories: DealRoomCategoryDto[];
}

export interface DealRoomCategoryDto {
    facilities: FacilityDto[];
    room: {
        outletId: number;
        roomId: number;
        roomImage: string;
        roomName: string;
        roomNameFr: string;
    };
}

export interface FacilityDto {
    facilityId: number;
    facilityName: string;
    icon: string;
    importance: number;
}

export interface OutletReviewsDto {
    totalReviewCount: number;
    latestReviews: LatestReviewDto[];
    reviewScoreCard: ReviewScoreCardDto;
}

export interface LatestReviewDto {
    outletId: number;
    guestName: string;
    updatedDate: string;
    reviewText: string;
    reviewsScore: number;
    reviewLabel: string;
}

export interface ReviewScoreCardDto {
    poorCount: number;
    averageCount: number;
    goodCount: number;
    greatCount: number;
    reviewsScore: number;
}

export interface DealRibbonDto {
    ribbonName: string;
    colorCode: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { Locale } from 'src/app/config/locale';
import { CheckoutUtilsService } from '../checkout.utils.service';
import { Section, SectionsService } from 'src/app/shared/sections.service';
import { FormControl, Validators } from '@angular/forms';
import { CheckoutGuest } from '../checkout.model';

@Component({
    selector: 'md-reservation-details',
    templateUrl: './reservation-details.component.html',
    styleUrls: ['./reservation-details.component.scss'],
})
export class ReservationDetailsComponent implements OnInit {
    @Input() set guests(value: CheckoutGuest[]) {
        this.showGiftOnly = false; //Object.keys(this.guests).length === 0;
        this.guestsInternal = [];

        value.forEach((guest, index) => {
            this.guestsInternal.push({
                value: guest,
                validation: {
                    nameControl: new FormControl('', [Validators.required]),
                },
            });
        });

        this.checkoutUtilService.areChildrenAgesValid =
            !this.checkoutUtilService.has_children(value);

        this.specialRequestStatus['00'] = true;
    }

    showGiftOnly: boolean = false;
    localeTxt: { [key: string]: string } = {};
    guestsInternal: {
        value: CheckoutGuest;
        validation: { nameControl: FormControl };
    }[] = [];

    specialRequestStatus: boolean[] = [];

    sectionPaths: Map<string, string> = new Map<string, string>();

    constructor(
        private localeObj: Locale,
        private checkoutUtilService: CheckoutUtilsService,
        private sectionsService: SectionsService
    ) {}

    ngOnInit(): void {
        this.localeTxt = this.localeObj.getTextMessages();
        this.sectionsService.getSections().subscribe((data) => {
            this.sectionPaths = new Map<string, string>(
                data.sections.map((section: Section) => [
                    section.sectionId.toString(),
                    section.icon,
                ])
            );
        });
    }

    getDealCategoryIcon(categoryIds: string[]) {
        const categoryId =
            categoryIds && categoryIds.length > 0 && categoryIds[0];
        if (categoryId) {
            return this.sectionPaths.get(categoryId);
        }
    }

    toggleRequest(parentIndex: number, index: number) {
        let indexNumber = '' + parentIndex + index;
        if (this.specialRequestStatus[indexNumber]) {
            this.specialRequestStatus[indexNumber] = false;
        } else {
            this.specialRequestStatus[indexNumber] = true;
        }
    }

    guestNameChange() {
        const guestNames = this.guestsInternal.map((guest) => guest.value);
        this.checkoutUtilService.validate_guest(guestNames, true);
    }

    guestChildDataChange() {
        const guestNames = this.guestsInternal.map((guest) => guest.value);
        this.checkoutUtilService.validate_child_age(guestNames);
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CommonService } from '../shared/common.service';
import { ProductService } from './product.service';
import { combineLatest, Subscription } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';

import { EventService, EventType } from '../utilities/event.service';
import { untranslatedMenuNames } from '../static-content/menu-routes';
import { ProductStaticContentComponent } from './static-content/static-content.component';
import { setRecentEntries } from '../utilities/cache.utils';
import { DealDto } from '../model/deal.model';
import { transformProductDetailsV2 } from './model/product-detail.transformers';
import { SEOServiceService } from '../services/seoservice.service';
import { GeneralErrorService } from '../components/general-error/general-error.service';

@UntilDestroy()
@Component({
    selector: 'md-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    productDetails: DealDto;
    productDetailsLoading = true;

    private productDetailsSubscription: Subscription;

    @ViewChild('pdContainer') pdContainer: ElementRef<HTMLElement>;
    @ViewChild('staticContent') staticContent: ProductStaticContentComponent;

    reviewsComponent: ElementRef;
    cmsBlockContentEdited: string;

    constructor(
        private commonService: CommonService,
        private productService: ProductService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
        private seoService: SEOServiceService,
        private ref: ChangeDetectorRef,
        private generalErrorService: GeneralErrorService,
    ) { }

    ngOnDestroy(): void {
        if (this.productDetailsSubscription) {
            this.productDetailsSubscription.unsubscribe();
        }
    }

    // TODO: these should be on route change and not on init
    ngOnInit(): void {
        // One of pId or productUrl is enought to get the product details but we're using both in case one or the other is missing

        combineLatest([this.route.queryParams, this.route.params])
            .pipe(untilDestroyed(this))
            .subscribe(
                ([queryParams, params]) => {
                    const productPath = params['productPath'];
                    const productId = params['productId'];
                    const fromDate: string = queryParams['fromDate'];
                    const toDate: string = queryParams['toDate'];

                    this.productDetailsSubscription = this.productService
                        .getProductDetails(
                            Number(productId),
                            productPath,
                            fromDate,
                            toDate
                        )
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: ([
                                deal,
                                { dealOffers, calendarInventory },
                                { accomOptions, accomInventory },
                                ageGroups
                            ]) => {
                                this.productDetails = transformProductDetailsV2(
                                    deal,
                                    dealOffers,
                                    accomOptions,
                                    calendarInventory,
                                    accomInventory,
                                    ageGroups
                                );
                                this.productDetailsLoading = false;
                                this.cmsBlockContentEdited = this.getCmsBlockContent();

                                this.cmsBlockContentEdited =
                                    this.getCmsBlockContent();

                                this.translate.onLangChange.subscribe(() => {
                                    this.cmsBlockContentEdited =
                                        this.getCmsBlockContent();
                                });

                                this.commonService.pageTitle =
                                    this.getPageTitleBasedOnCategory(
                                        this.productDetails
                                    );

                                setRecentEntries([this.productDetails.dealId]);

                                this.ref.markForCheck();

                                this.seoService.updateTitle(
                                    this.productDetails.staticContentV2?.information
                                        ?.metaTitle
                                );
                                this.seoService.updateDescription(
                                    this.productDetails.staticContentV2?.information
                                        ?.metaDescription
                                );
                                this.seoService.updateKeyword(
                                    this.productDetails.staticContentV2?.information
                                        ?.metaKeywords
                                );
                            },
                            error: (e: Error) => {
                                if (e.message?.startsWith?.('DEAL_NOT_FOUND')) {
                                    Sentry.captureMessage(
                                        'No product with id: ' + productId,
                                        'error'
                                    );
                                    this.router.navigate(['/error']);
                                    return;
                                }

                                this.productDetailsLoading = false;
                                this.ref.markForCheck();

                                this.generalErrorService.showGeneralError(
                                    'Something is wrong with this deal. Please try again later or contact support.'
                                );

                                Sentry.captureException(e, {
                                    extra: {
                                        productId,
                                        message: 'Error occurred during product data fetching'
                                    }
                                });
                            }
                        });
                }
            );
    }

    onReviewSummaryClick(): void {
        this.staticContent.scrollToReviews();
    }

    onDealSelected(): void {
        // const stuff = document.getElementsByClassName('pane')[0];
        // stuff.scrollTo({ top: 0, behavior: 'smooth' });
    }

    getPageTitleBasedOnCategory(productDetails: DealDto): string {
        const { categoryId } = productDetails;
        return this.translate.instant(untranslatedMenuNames.get(categoryId));
    }
    getCmsBlockContent(): string {
        const hereString = this.translate.instant('here');
        const cmsBlockContentRaw = this.translate.instant('CMS_CONTENT', {
            link: `<a href="/login" (click)="toLogin()">${hereString}</a>`,
        });

        return cmsBlockContentRaw;
    }

    toLogin(): void {
        this.eventService.emitEvent(EventType.LOGIN);
    }

    getRepeatArray(length: number): number[] {
        return new Array(length);
    }
}

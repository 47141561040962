import { Injectable } from '@angular/core';
import {
    AddCartItemDto,
    BookingConfiguredData,
    CartDto,
    HotelDealInfoDto,
    ProductBookingData,
    ProductBookingDto,
    RewardsResponseDto,
} from './booking-config.model';
import { Observable } from 'rxjs';
import { getAvailabilityUrl, getBookingUrl } from 'src/app/utilities/url.utils';
import {
    createDateString,
    createDateStringReverse,
} from 'src/app/controls/calendar/calendar.utils';
import { ProductDetails, DealOfferOption } from '../model/product-detail.model';
import { UserService } from 'src/app/services/user.service';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    isUserLoggedIn = false;

    constructor(
        private userService: UserService,
        private authHttpClient: AuthHttpClientNoXService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    getRewardPoints(subtotal: number): Observable<RewardsResponseDto> {
        const locale = this.currencyLanguageService.getLocaleDataImmediate();

        const postBody = {
            orgId: 1,
            customerGroupId: locale.customerGroupId,
            countryCode: locale.country,
            subtotal,
        };

        return this.authHttpClient.post<RewardsResponseDto, typeof postBody>(
            `${getAvailabilityUrl()}reward-points/calc`,
            postBody
        );
    }

    addToCartV2(
        bookingConfigedData: BookingConfiguredData,
        productDetails: ProductDetails
    ): Observable<CartDto> {
        const apiUrl = this.userService.isLoggedIn
            ? `${getBookingUrl()}carts/items`
            : `${getBookingUrl()}carts/items/as-guest`;
        const localCart = this.userService.getLocalCart();
        // if there is a user cart use that, else use the local cart
        const cartId = localCart?.userCartId || localCart?.localCartId;
        // TODO: this is mock data. Replace once availability is done
        const {
            productBookingData = {
                id: bookingConfigedData?.productBookingData?.id,
                type: 'virtual',
                checkinDate:
                    bookingConfigedData?.productBookingData?.checkinDate,
                checkoutDate:
                    bookingConfigedData?.productBookingData?.checkoutDate,
            },
            totalPrice,
            totalFullPrice,
        } = bookingConfigedData;

        const postBody: AddCartItemDto = {
            cartId,
            outletId: 2,
            dealId: productBookingData?.id,
            dealCategoryId: productDetails.category,
            sectionId: productDetails.category,
            dealTitle: productDetails.name,
            dealSubtitle: productDetails.shortDescription,
            quantity: 1,
            subtotal: totalPrice || 10,
            purchaseCurrencyId: 1,
            grandTotal: totalFullPrice || 11,
            dealInfo: this.createDealInfo(productBookingData, productDetails),
            checkIn: createDateStringReverse(productBookingData.checkinDate),
            checkOut: createDateStringReverse(productBookingData.checkoutDate),
        };

        return this.authHttpClient.post<CartDto, AddCartItemDto>(
            apiUrl,
            postBody
        );
    }

    private createDealInfo(
        productData: ProductBookingData,
        productDetails: ProductDetails
    ): string {
        const dealInfo: HotelDealInfoDto = {
            dealConditions: JSON.stringify(
                productDetails.staticContentV2.conditions
            ),
            roomCategory: 'Superior Garden View Room',
            mealPlan: 'All-Inclusive: All Meals and Drinks',
            packageTypeId: productDetails.packageTypeId,
            dealOptionId: 201,
            roomId: 101,
        };

        return JSON.stringify(dealInfo);
    }

    private createProductDataDto(productData: ProductBookingData) {
        const productDto = {
            qty: 1, // this is encoded to always be 1 and can be changed from the cart
            type: productData.type,
            id: productData.id,
            options: productData.selectedServiceOptions
                ? this.extractServiceOptionAndCategoryIdFromProduct(
                      productData.selectedServiceOptions
                  )
                : '',
            dropdown_options: productData.selectedServiceOptionQuantities
                ? this.extractServiceOptionQuantitiesAndCategoryIdFromProduct(
                      productData.selectedServiceOptionQuantities
                  )
                : '',
            booking_from: productData.checkinDate
                ? createDateString(productData.checkinDate)
                : '',
            booking_to: productData.checkoutDate
                ? createDateString(productData.checkoutDate)
                : '',
            booking_date: productData.selectedDate
                ? createDateString(productData.selectedDate)
                : '',
            total_nights: productData.totalNights,
        };

        this.addMariBnbDataToProductDataDto(productData, productDto);

        return productDto;
    }

    private addMariBnbDataToProductDataDto(
        productData: ProductBookingData,
        productDto: ProductBookingDto
    ) {
        if (productData.mariBnbOccupancy) {
            productDto.adults = productData.mariBnbOccupancy.adults;
            productDto.children = productData.mariBnbOccupancy.children;
            productDto.infants = productData.mariBnbOccupancy.infants;
        }
    }

    private createAddonDataDto(productData: ProductBookingData) {
        return productData.addOnConfigurations
            ? productData.addOnConfigurations.map((addonConfig) => {
                  return {
                      id: addonConfig.addonItem.id,
                      price_type: addonConfig.addonItem.priceType,
                      sku: addonConfig.addonItem.sku,
                      title: addonConfig.addonItem.title,
                      qty: addonConfig.quantity,
                  };
              })
            : '';
    }

    private extractServiceOptionAndCategoryIdFromProduct(
        serviceOptions: DealOfferOption[]
    ): { [key: number]: number } {
        const serviceOptionAndCategoryId = {};
        serviceOptions.forEach((serviceOption) => {
            serviceOptionAndCategoryId[serviceOption.categoryId.toString()] =
                serviceOption.id.toString();
        });
        return serviceOptionAndCategoryId;
    }

    private extractServiceOptionQuantitiesAndCategoryIdFromProduct(
        serviceOptionQuantities: Map<DealOfferOption, number>
    ): { [key: number]: number } {
        const serviceOptionAndCategoryId = {};
        serviceOptionQuantities.forEach((quantity, serviceOption) => {
            if (!serviceOptionAndCategoryId[serviceOption.categoryId]) {
                serviceOptionAndCategoryId[serviceOption.categoryId] = {
                    [serviceOption.id]: quantity,
                };
            } else {
                serviceOptionAndCategoryId[serviceOption.categoryId][
                    serviceOption.id
                ] = quantity;
            }
        });
        return serviceOptionAndCategoryId;
    }
}

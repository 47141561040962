<div class="overlay" *ngIf="isVisible" #overlay></div>
<div class="image-close-container">
    <img
        src="assets/img/bf-banners/pop-up.png"
        alt="Overlay Image"
        class="overlay-image"
        *ngIf="isVisible"
    />
    <a class="overlay-close" routerLink="/" *ngIf="showCloseButton">x</a>
</div>

<form [formGroup]="dobFormGroup" class="c-dob-form">
    <label
        class="form-label-signup custom-user-profile-label-2 dob-label"
        [ngClass]="{ 'has-content': dobFormGroup.controls['dobmonth'].value }"
        for="dobmonth"
    >
        {{ "Birthdate" | translate }}</label
    >
    <select
        class="c-dob-month-select"
        formControlName="dobmonth"
        (change)="monthSelectionChanged()"
    >
        <option [value]="x.value" *ngFor="let x of getMonthArray()">
            {{ x.label }}
        </option>
    </select>

    <input
        class="c-dob-input middle"
        type="number"
        max="31"
        min="1"
        placeholder="DD"
        formControlName="dobdate"
        [readonly]="!isDobEditable"
        (change)="dateSelectionChanged()"
    />
    <input
        class="c-dob-input last"
        type="number"
        max="2021"
        min="1900"
        placeholder="YYYY"
        formControlName="dobyear"
        [readonly]="!isDobEditable"
        (change)="yearSelectionChanged()"
    />
</form>

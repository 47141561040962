<md-profile-wrapper [activeSection]="ProfileSection.CHALLENGES">
    <md-profile-page-title
        [title]="'Loyalty Program' | translate"
        [description]="'Earn Badges and Reward Points.' | translate"
    />
    <div class="ma-ch-recently-earned">
        <div class="ma-ch-recently-earned-row desktopOnly">
            <div class="ma-ch-points">
                {{
                    customerPointsAndBalance?.balance_points | number : "1.0-0"
                }}
                {{ "Points" | translate }}
            </div>
            <div class="ma-ch-right">
                <!-- <div class="ma-ch-recently-earned-title">
                    {{ "Recently Earned Badges" | translate }}
                </div>
                <a class="ma-ch-link" [routerLink]="['/badges']">{{
                    "See all" | translate
                }}</a> -->
                <div class="ma-ch-recently-earned-title disabled">
                    Badges returning soon.
                </div>
            </div>
        </div>
        <div class="ma-ch-recently-earned-row mobileOnly">
            <div class="ma-ch-points">
                {{ "My balance" | translate }}
                <strong
                    >{{
                        customerPointsAndBalance?.balance_points
                            | number : "1.0-0"
                    }}
                    {{ "Points" | translate }}</strong
                >
            </div>
            <!-- <div class="ma-ch-how-to">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ "How to earn/ spend points" | translate }}
            </div> -->
            <div class="ma-ch-progress">
                <p class="ma-ch-progress-title disabled">
                    {{ "Badges returning soon." | translate }}
                </p>
                <!-- <p class="ma-ch-progress-title">
                    {{ "My Badges" | translate }}
                </p>

                <progress
                    class="ma-ch-progress-bar"
                    value="{{ availableBadgeCount }}"
                    max="{{ totalBadgeCount }}"
                ></progress>

                <p class="ma-ch-progress-text">
                    {{ "Badges progress" | translate }}
                    {{ availableBadgeCount }}/{{ totalBadgeCount }}
                </p> -->
            </div>
        </div>
        <!-- <div class="ma-ch-recently-earned-row">
            <md-meter
                mdDesktopOnly
                [loading]="isBadgesData"
                [percentage]="(availableBadgeCount / totalBadgeCount) * 100"
                [label]="availableBadgeCount + '/' + totalBadgeCount"
                [sublabel]="'Badges Unlocked' | translate"
            />
            <div class="ma-ch-right ma-ch-width-calc">
                <swiper [config]="config" style="width: 100%">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            *ngFor="let badge of availedBadgesArray"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    badgeTemplate;
                                    context: { badge: badge }
                                "
                            ></ng-container>
                        </div>
                        <div
                            class="swiper-slide"
                            *ngFor="let badge of notAvailedBadgesArray"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    badgeTemplate;
                                    context: { badge: badge }
                                "
                            ></ng-container>
                        </div>
                        <ng-container *ngIf="totalBadgeCount === 0">
                            <div
                                class="swiper-slide"
                                *ngFor="let badge of [].constructor(3)"
                            >
                                <div
                                    mdSkeleton
                                    class="ma-ch-badge ma-ch-badge-skeleton"
                                ></div>
                            </div>
                        </ng-container>
                    </div>
                </swiper>
            </div>
        </div>
        <div class="ma-ch-recently-earned-row mobileOnly">
            <button
                mdMainButton="light"
                class="ma-ch-button"
                [routerLink]="['/badges']"
            >
                {{ "See all Badges" | translate }}
            </button>
        </div> -->
    </div>
    <!-- <div style="display: flex; flex-direction: column; align-items: flex-start">
        <h2 class="ma-ch-recent-title">
            {{ "Recent Transactions" | translate }}
        </h2>
        <ng-container *ngIf="!isCustomerData">
            <div class="ma-ch-recent-head">
                <h3 class="ma-ch-recent-head-date">{{ "Date" | translate }}</h3>
                <div>
                    <h3>{{ "Title" | translate }}</h3>
                    <h3>{{ "Points" | translate }}</h3>
                </div>
            </div>
            <div
                class="ma-ch-recent-item"
                *ngFor="
                    let transactions of customerPointsAndBalance?.transacion
                        ?.data
                "
            >
                <div class="ma-ch-recent-item-date">
                    {{ transactions?.creating_date | date : "M/d/yy" }}
                </div>
                <div class="ma-ch-recent-item-right">
                    <div>
                        {{ transactions?.title }}
                    </div>
                    <div>
                        {{ transactions?.is_deduct
                        }}{{ transactions?.points | number : "1.0-0" }}
                    </div>
                </div>
            </div>
        </ng-container>
    </div> -->
</md-profile-wrapper>
<ng-template #badgeTemplate let-badge="badge">
    <div class="ma-ch-badge">
        <a [routerLink]="['/badges']" class="ma-ch-badge-a">
            <img style="width: 100%" [src]="badge?.icon" alt="" />
        </a>
        <p>{{ badge?.title }}</p>
        <p>
            (+{{ badge?.reward_points | number : "1.0-0"
            }}{{ "pts" | translate }})
        </p>
    </div>
</ng-template>

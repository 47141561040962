import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'md-points-discount-badge',
    templateUrl: './points-discount-badge.component.html',
    styleUrls: ['./points-discount-badge.component.scss'],
})
export class PointsDiscountBadgeComponent implements OnInit {
    @Input() set pointsAsCurrency(value: number) {
        this.setPointsAsCurrency(value);
    }

    @Input() priceToDeductFrom: number;

    internalPointsAsCurrency: number;
    isFree: boolean;

    foreText: string;
    afterText: string;
    discountText: string;

    constructor(
        private translate: TranslateService,
        private decimalPipe: DecimalPipe
    ) {}

    ngOnInit(): void {
        this.setPointsAsCurrency(this.internalPointsAsCurrency);
    }

    private setPointsAsCurrency(value: number) {
        this.internalPointsAsCurrency = value;
        this.isFree = value >= this.priceToDeductFrom;
        if (this.isFree) {
            this.foreText = this.translate.instant('with pts');
            this.afterText = this.translate.instant(
                'no payment required using pts'
            );
            this.discountText = this.translate.instant('FREE');
        } else {
            this.foreText = this.translate.instant('Extra');
            this.afterText = this.translate.instant(
                'on Selling Price using Rewards Points'
            );
            const numberToFormat = (value / this.priceToDeductFrom) * -100;
            this.discountText = this.decimalPipe.transform(
                numberToFormat,
                '2.0-0'
            );
        }
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { DealDto, PackageTypeId } from 'src/app/model/deal.model';
import {
    BookingConfigVariant,
    BookingConfiguredData,
    CartDto,
    HotelSearchBookingData,
    getBookingVariantBasedOnCategory,
} from './booking-config.model';
import { BookingService } from './booking-config.service';
import { Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { ProductAddOnConfiguration } from './add-ons/add-ons.model';
import { applyDiscountToSubtotal } from './special-offers/special-offers.utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { createDateFromString } from 'src/app/controls/calendar/calendar.utils';
import { transformToMinimalProductBookingData } from './booking-config.transformer';
import { UserService } from 'src/app/services/user.service';
import { RewardsService } from 'src/app/services/rewards.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare const fbq: Function;

@Component({
    selector: 'md-booking-config',
    templateUrl: './booking-config.component.html',
    styleUrls: ['./booking-config.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingConfigComponent implements OnInit {
    @Input() set productDetails(value: DealDto) {
        this.productDetailsInternal = value;
        const { categoryId, packageTypeId } = value;

        const showCalendar = packageTypeId !== PackageTypeId.NON_CALENDAR;

        this.bookingConfigVariant = getBookingVariantBasedOnCategory(
            categoryId,
            showCalendar
        );
    }

    productDetailsInternal: DealDto;
    bookingConfigVariant: BookingConfigVariant;
    bookingData: BookingConfiguredData = {};
    initialBookingData: HotelSearchBookingData;
    addonConfigsData: ProductAddOnConfiguration[];
    rewardsPoints = 0;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private bookingService: BookingService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private generalErrorService: GeneralErrorService,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private translate: TranslateService,
        private ref: ChangeDetectorRef,
        private rewardsService: RewardsService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });

        // const queryParams = this.router.parseUrl(this.router.url).queryParams;
        // this.initialBookingData =
        //     this.getBookingDataFromQueryParams(queryParams);
    }

    onBookingConfigured(data: BookingConfiguredData) {
        this.bookingData = applyDiscountToSubtotal(
            this.productDetailsInternal,
            data
        );

        if (this.bookingData.productBookingData) {
            this.bookingData.productBookingData.addOnConfigurations =
                this.addonConfigsData;
        }

        const addOnsTotalPrice = this.calculateAddOnsTotalPrice(
            this.addonConfigsData
        );

        if (this.bookingData.totalPrice > 0) {
            this.bookingData.totalPrice += addOnsTotalPrice;
            this.bookingData.totalFullPrice += addOnsTotalPrice;
        }

        if (this.bookingData.totalPrice) {
            this.rewardsService
                .getRewardPoints(
                    this.bookingData.totalPrice,
                    this.currencyLanguageService.getLocaleDataImmediate()
                )
                .subscribe((response) => {
                    this.rewardsPoints = response;
                    this.bookingData.rewardPointsEarned = this.rewardsPoints;
                    this.ref.markForCheck();
                });

            // this.addBookingDataToUrl(this.bookingData);
        }

        this.ref.detectChanges();
    }

    calculateAddOnsTotalPrice(
        addonConfigs: ProductAddOnConfiguration[]
    ): number {
        return addonConfigs
            ? addonConfigs.reduce(
                  (totalPrice, addOn) =>
                      totalPrice + addOn.quantity * addOn.addonItem.price,
                  0
              )
            : 0;
    }

    onAddonConfigChanged(addonConfigs: ProductAddOnConfiguration[]) {
        this.bookingData.productBookingData.addOnConfigurations = addonConfigs;
        this.bookingData = this.addAddonPricesToBookingData(
            this.bookingData,
            addonConfigs
        );
        this.addBookingDataToUrl(this.bookingData);
    }

    onAddToCartClicked(): Observable<void> {
        // if there is no booking data show a message saying user should select something first
        if (
            !this.bookingData.productBookingData ||
            !this.bookingData.totalPrice
        ) {
            this.generalErrorService.showGeneralError(
                this.translate.instant('Nothing to add to cart'),
                { showMailto: false, showImage: false }
            );
            return;
        }
        this.spinner.show();
        // this should just do the call to add booking to cart
        // in case the booking is not valid it should point to what is invalid
        fbq('track', 'AddToCart', {
            source: 'magento',
            version: '1.9.3.8',
            pluginVersion: '2.2.4',
            content_type: 'product',
            content_ids: [this.productDetailsInternal.dealId],
        });

        return new Observable<void>((observer) => {
            this.bookingService
                .addToCartV2(this.bookingData, this.productDetailsInternal)
                .subscribe({
                    next: (response: CartDto) => {
                        this.spinner.hide();
                        this.userService.setLocalCart({
                            userCartId: response.id,
                            itemCount: response.itemsCount,
                        });
                        observer.next();
                        observer.complete();
                    },
                    error: () => {
                        this.spinner.hide();
                        this.generalErrorService.showGeneralError(
                            this.translate.instant(
                                'Could not add item to cart. Please refresh the page and try again.'
                            )
                        );
                        observer.error();
                    },
                });
        });
    }

    // Need to subscribe to the onAddToCartClicked or
    // else the call won't get made because no one is expecting a result
    onAddToCartClickedExternal() {
        this.onAddToCartClicked().subscribe();
    }

    onBookNowClicked() {
        if (!this.bookingData.productBookingData) {
            this.generalErrorService.showGeneralError(
                this.translate.instant(
                    'Nothing to book. Please select something first'
                ),
                { showMailto: false, showImage: false }
            );
            return;
        }
        this.onAddToCartClicked().subscribe(() => {
            this.router.navigate(['/checkout']);
        });
    }

    private getBookingDataFromQueryParams(
        queryParams: Params
    ): HotelSearchBookingData {
        const fromDateString = queryParams['fromDate'];
        const toDateString = queryParams['toDate'];
        const occupancyId = queryParams['occupancyId'];
        const fromDate = createDateFromString(fromDateString);
        const toDate = createDateFromString(toDateString);
        return { fromDate, toDate, occupancyId };
    }

    private addAddonPricesToBookingData(
        bookingData: BookingConfiguredData,
        addonConfigs: ProductAddOnConfiguration[]
    ): BookingConfiguredData {
        const bookingDataClone = { ...bookingData };
        this.addonConfigsData = addonConfigs;
        let totalPrice = 0;
        let totalFullPrice = 0;
        for (const [
            serviceOption,
            quantity,
        ] of bookingDataClone.productBookingData.selectedDealOptionQuantities.entries()) {
            totalPrice += serviceOption.sellingPrice * quantity;
            totalFullPrice += serviceOption.crossedPrice * quantity;
        }

        const addOnsTotalPrice = this.calculateAddOnsTotalPrice(addonConfigs);
        bookingDataClone.totalPrice = totalPrice + addOnsTotalPrice;
        bookingDataClone.totalFullPrice = totalFullPrice + addOnsTotalPrice;
        return bookingDataClone;
    }

    private addBookingDataToUrl(bookingData: BookingConfiguredData) {
        const nonUndefinedQueryParams = transformToMinimalProductBookingData(
            bookingData.productBookingData
        );
        const queryParams = {
            bookingData: JSON.stringify(nonUndefinedQueryParams),
        };

        const currentUrlTree = this.router.parseUrl(this.router.url);
        const mergedQueryParams = {
            ...currentUrlTree.queryParams,
            ...queryParams,
        };
        const updatedUrlTree = this.router.createUrlTree([], {
            queryParams: mergedQueryParams,
        });
        const updatedUrl = this.router.serializeUrl(updatedUrlTree);
        history.replaceState({}, '', updatedUrl);
    }
}

<div class="pdbc-occupancy-container">
    <md-special-offers [productDetails]="productDetails" />
    <md-occupancy [ageGroups]="productDetails.ageGroups" (occupancyChanged)="onOccupancyChanged($event)" />
</div>
<div
    *ngIf="
        visibleServiceCategoriesAndOptions &&
        visibleServiceCategoriesAndOptions.length > 0
    "
>
    <div *ngFor="let dealOffer of visibleServiceCategoriesAndOptions">
        <h3 class="pdhb-service-name">{{ dealOffer.title }}</h3>
        <label
            *ngFor="let dealOption of dealOffer.dealOptions"
            class="pdbc-service-option"
        >
            <input
                type="radio"
                name="serviceOption"
                [id]="dealOption.id"
                [value]="dealOption"
                [(ngModel)]="selectedServiceOptionInternal"
                (click)="onServiceOptionChanged(dealOption)"
            />
            <div class="pdbc-service-option-details">
                <div class="pdbc-service-option-data">
                    <span class="pdbc-service-option-name">{{
                        dealOption.title
                    }}</span>
                    <span class="pdbc-service-option-occupancy">{{
                        dealOption.subTitle
                    }}</span>
                </div>
                <div class="pdbc-service-option-price">
                    {{
                        dealOption.sellingRate
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-2"
                                : locale
                    }}
                </div>
            </div>
        </label>
    </div>
</div>
<div
    mdMobileOnly
    *ngIf="selectedServiceOptionInternal"
    (click)="onDatesClick()"
>
    <div class="pdbc-mobile-dates-container">
        <div class="pdbc-mobile-dates-split">
            <div class="pdbc-mobile-dates-title">
                {{ "Check-in" | translate }}
            </div>
            <div class="pdbc-mobile-dates-content empty" *ngIf="!fromDate">
                -
            </div>
            <div class="pdbc-mobile-dates-content" *ngIf="fromDate">
                {{ fromDate.format("DD MMM, YYYY") }}
            </div>
        </div>
        <div class="pdbc-mobile-dates-split">
            <div class="pdbc-mobile-dates-title">
                {{ "Check-out" | translate }}
            </div>
            <div class="pdbc-mobile-dates-content empty" *ngIf="!toDate">-</div>
            <div class="pdbc-mobile-dates-content" *ngIf="toDate">
                {{ toDate.format("DD MMM, YYYY") }}
            </div>
        </div>
        <div class="pdbc-mobile-dates-split">
            <div class="pdbc-mobile-dates-title">
                {{ "Night(s)" | translate }}
            </div>
            <div class="pdbc-mobile-dates-content">{{ totalNights }}</div>
        </div>
    </div>
</div>
<div mdDesktopOnly>
    <div style="display: flex; justify-content: center; margin-top: 20px">
        <md-calendar
            #calendarHotel
            [infoForEachDay]="additionalCalendarDayInfo"
            [strictDates]="isTravel"
            (dateRangeSelected)="onDateRangeSelected($event)"
            ><ng-content summaryControl select="[summaryControl]"></ng-content
        ></md-calendar>
        <div
            *ngIf="desktopCalendarLoading"
            mdSkeleton
            style="width: 298px; height: 40px; border-radius: 8px"
        ></div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceCommsService } from 'src/app/services/device-comms.service';

@Component({
    selector: 'app-trips-detail',
    templateUrl: './trips-detail.component.html',
    styleUrls: ['./trips-detail.component.scss'],
})
export class TripsDetailComponent implements OnInit {
    tripDetail: any;
    constructor(
        private router: Router,
        private deviceCommsService: DeviceCommsService
    ) {
        this.tripDetail = this.router.getCurrentNavigation().extras.state;
    }

    ngOnInit(): void {
        if (this.tripDetail) {
            localStorage.setItem('tripDetail', JSON.stringify(this.tripDetail));
        }
        this.loadTripDetail(JSON.parse(localStorage.getItem('tripDetail')));
    }

    /**
     * Method to load trip data
     * @param tripDetail
     */

    loadTripDetail(tripDetail: any) {
        this.tripDetail = tripDetail;
        console.log('trip Detail', this.tripDetail);
    }

    /**
     * Method to download PDF file
     */

    downloadTripDetailPdf(tripsProduct: any) {
        let pdfUrl = tripsProduct.tripDetail.pdf_url;

        this.deviceCommsService.openLinkInNewTabOrBrowser(pdfUrl);
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'md-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent {
    @Input() public sitemapValue: string;
    @Input() public shownMap: boolean | undefined;
}

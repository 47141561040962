import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ProductDetails,
    DealOffer,
    DealOfferOption,
    AgeGroup
} from 'src/app/product-detail/model/product-detail.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelBookingService } from './hotel-booking.service';
import {
    AccomInventoryByDate,
    transformAccomInventoryResponseDto,
} from './hotel-booking.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import {
    BookingConfiguredData,
    HotelSearchBookingData,
    emptyBookingConfiguredData,
} from '../booking-config.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import * as dayjs from 'dayjs';
import { transformNgbDateToDayJs } from 'src/app/controls/calendar/calendar.utils';
import { TRAVEL_CATEGORY } from 'src/app/static-content/menu-routes';
import { screenSizes } from 'src/app/utilities/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'md-hotel-booking',
    templateUrl: './hotel-booking.component.html',
    styleUrls: ['./hotel-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelBookingComponent implements OnInit {
    @Input() set productDetailsInput(value: ProductDetails) {
        this.productDetails = value;
        this.isTravel = this.productDetails.category === TRAVEL_CATEGORY;
        if (this.initialBookingData) {
            // TODO: this is for query string parameters
        } else {
            this.selectedOccupancyId = 44; // the id for 2 (should be 1332? For now, only 44 makes deal offer options appear)
            this.setServiceOptionOnDesktop();
        }
    }
    @Input() initialBookingData: HotelSearchBookingData;

    visibleServiceCategoriesAndOptions: DealOffer[];
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    pricingByDateForSelectedServiceOption: AccomInventoryByDate[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedServiceOptionInternal: DealOfferOption;
    selectedOccupancyIdInternal: number;
    bookingSelected = false;
    productDetails: ProductDetails;
    isTravel = false;
    desktopCalendarLoading = false;

    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
    totalNights = 0;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarHotel') calendarHotel: CalendarComponent;

    set selectedOccupancyId(selectedOccupancyId: number) {
        this.resetBooking();
        this.selectedOccupancyIdInternal = selectedOccupancyId;
        this.selectedServiceOptionInternal = undefined;

        // TODO > TESTING: Just to prevent day package to crash before its A-service's endpoint is implemented
        // TO BE REMOVED
        this.productDetails.dealOffers.forEach((dealOffer) => {
            dealOffer.dealOptions[0].occupancyId = 44
        });

        this.visibleServiceCategoriesAndOptions =
            this.productDetails.dealOffers
                .filter((dealOffer) =>
                    dealOffer.dealOptions.some(
                        (dealOption) =>
                            dealOption.occupancyId === selectedOccupancyId
                    )
                )
                .map((dealOffer) => ({
                    ...dealOffer,
                    options: dealOffer.dealOptions.filter(
                        (dealOption) =>
                            dealOption.occupancyId === selectedOccupancyId
                    ),
                }));
    }

    setSelectedServiceOption(
        productServiceOption: DealOfferOption,
        showSpinner = true
    ) {
        this.resetBooking();
        this.selectedServiceOptionInternal = productServiceOption;
        this.setMultiplePrices(productServiceOption, showSpinner);
    }

    constructor(
        private spinnerService: NgxSpinnerService,
        private hotelBookingService: HotelBookingService,
        private currencyLanguageService: CurrencyLanguageService,
        private ref: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.currencyLanguageService
            .getCurrency()
            .pipe(untilDestroyed(this))
            .subscribe((currency) => {
                this.currencySymbol = currency;
            });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .pipe(untilDestroyed(this))
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    onOccupancyChanged(ageGroups: AgeGroup[]): void {
        this.hotelBookingService
            .getOccupancyId(ageGroups)
            .pipe(
                untilDestroyed(this),
                tap(({ id }) => this.selectedOccupancyId = id)
            )
            .subscribe()
    }

    onServiceOptionChanged(dealOption: DealOfferOption): void {
        if (this.selectedServiceOptionInternal?.id === dealOption.id) {
            this.calendarHotel.openCalendar();
        } else {
            this.setSelectedServiceOption(dealOption, false);
        }
    }

    onDatesClick() {
        this.calendarHotel.openCalendar();
    }

    onDateRangeSelected(selectedDates: CalendarSelectedDates): void {
        const { fromDate, toDate, totalNights } = selectedDates;
        if (!fromDate || !toDate) {
            this.bookingConfigured.emit(emptyBookingConfiguredData);
            this.setCheckinCheckoutUI(undefined, undefined, undefined);
            this.bookingSelected = false;
            return;
        }
        this.setCheckinCheckoutUI(fromDate, toDate, totalNights);
        this.bookingSelected = true;
        const allDatesBooked = this.getAllBookedDates(
            fromDate,
            toDate,
            totalNights
        );

        const total = allDatesBooked.reduce(
            ({ totalBooked, totalFull }, pricingByDate) => {
                return {
                    totalBooked: totalBooked + pricingByDate.sellingPrice,
                    totalFull: totalFull + pricingByDate.crossedOutPrice,
                };
            },
            { totalBooked: 0, totalFull: 0 }
        );

        const { flightPrice } = this.selectedServiceOptionInternal;

        if (flightPrice) {
            total.totalBooked += flightPrice;
            total.totalFull += flightPrice;
        }

        this.bookingConfigured.emit({
            totalPrice: total.totalBooked,
            totalFullPrice: total.totalFull,
            flightPrice: flightPrice,
            productBookingData: {
                id: this.productDetails.id,
                type: this.productDetails.type,
                selectedServiceOptions: [this.selectedServiceOptionInternal],
                checkinDate: fromDate,
                checkoutDate: toDate,
                totalNights,
            },
        });
    }

    private getAllBookedDates(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): AccomInventoryByDate[] {
        const allBookedDates =
            this.pricingByDateForSelectedServiceOption.filter(
                (pricingByDate) =>
                    fromDate.equals(pricingByDate.date) ||
                    (fromDate.before(pricingByDate.date) &&
                        toDate.after(pricingByDate.date))
            );

        if (allBookedDates.length !== totalNights) {
            Sentry.captureMessage(
                `HB-DATES-MISMATCH-${this.productDetails.id}`
            );
        }

        return allBookedDates;
    }

    private resetBooking() {
        this.bookingConfigured.emit(emptyBookingConfiguredData);
        this.calendarHotel?.clearDateSelection();
        this.calendarHotel?.closeCalendar();
        this.bookingSelected = false;
    }

    private setCheckinCheckoutUI(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): void {
        this.fromDate = fromDate
            ? transformNgbDateToDayJs(fromDate)
            : undefined;
        this.toDate = toDate ? transformNgbDateToDayJs(toDate) : undefined;
        this.totalNights = totalNights;
    }

    private setMultiplePrices(
        productServiceOption: DealOfferOption,
        showSpinner = true
    ): void {
        this.startLoading(showSpinner);

        const { id, category } = this.productDetails;

        this.hotelBookingService
            .getAccomInventory()
            .subscribe((response) => {
                this.stopLoading();
                this.pricingByDateForSelectedServiceOption = transformAccomInventoryResponseDto(response);
                this.additionalCalendarDayInfo.clear();
                this.pricingByDateForSelectedServiceOption.forEach(
                    (pricingByDate) =>
                        this.additionalCalendarDayInfo.set(
                            pricingByDate.dateAsString,
                            {
                                date: pricingByDate.date,
                                tooltipContent: `${this.currencySymbol} ${pricingByDate.sellingPrice}`,
                                minLengthOfStay: pricingByDate.minLengthOfStay,
                            }
                        )
                );
                this.ref.markForCheck();
                this.calendarHotel.openCalendar();
            });
    }

    private setServiceOptionOnDesktop(): void {
        const isMobile = window.innerWidth < screenSizes.mobile;

        if (!isMobile) {
            this.setSelectedServiceOption(
                this.visibleServiceCategoriesAndOptions[0]?.dealOptions[0],
                false
            );
        }
    }

    private startLoading(showSpinner: boolean): void {
        const isMobile = window.innerWidth < screenSizes.mobile;
        if (showSpinner || isMobile) {
            this.spinnerService.show();
        } else {
            this.desktopCalendarLoading = true;
        }
    }

    private stopLoading(): void {
        this.spinnerService.hide();
        this.desktopCalendarLoading = false;
    }
}

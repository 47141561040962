import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { OccupancyConfig, OccupancySelection } from './calendar-occupancy.model';
import { screenSizes } from 'src/app/utilities/theme';
import { NgbOffcanvas, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

// TODO: Deprecated? (maribnb no more used + this component only referenced there)
@Component({
    selector: 'md-calendar-occupancy',
    templateUrl: './calendar-occupancy.component.html',
    styleUrls: ['./calendar-occupancy.component.scss'],
})
// TODO: this has to also use the popup-offcanvas component
export class CalendarOccupancyComponent {
    @Input() config: OccupancyConfig;

    maxAdults: number;
    maxChildren: number;
    maxInfants: number;
    adults = 1;
    children = 0;
    infants = 0;
    hasRenderedAsMobile = false;

    @Output() occupancyChanged = new EventEmitter<OccupancySelection>();

    @ViewChild('mobileOccupancy') mobileOccupancy: TemplateRef<unknown>;
    @ViewChild('d') ngbPopover: NgbPopover;

    constructor(private offcanvasService: NgbOffcanvas) {
        this.openOccupancy = this.openOccupancy.bind(this);
    }

    openOccupancy() {
        this.adults = this.config.adults;
        this.children = this.config.children;
        this.infants = this.config.infants;
        this.maxAdults = this.config.maxNrOfAdults;
        this.maxChildren = this.config.maxNrOfChildren;
        this.maxInfants = this.config.maxNrOfInfants;
        this.recalculateMaxOccupants();

        this.hasRenderedAsMobile = window.innerWidth < screenSizes.mobile;
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.open(this.mobileOccupancy, {
                position: 'bottom',
                panelClass: 'c-o-mobile-occupancy',
                scroll: true,
            });
        } else {
            this.ngbPopover.open();
        }
    }

    onAdultsChanged(adults: number): void {
        this.adults = adults;
        this.recalculateMaxOccupants();
        this.occupancyChanged.emit({
            adults: this.adults,
            children: this.children,
            infants: this.infants,
        });
    }

    onChildrenChanged(children: number): void {
        this.children = children;
        this.recalculateMaxOccupants();
        this.occupancyChanged.emit({
            adults: this.adults,
            children: this.children,
            infants: this.infants,
        });
    }

    onInfantsChanged(infants: number): void {
        this.infants = infants;
        this.recalculateMaxOccupants();
        this.occupancyChanged.emit({
            adults: this.adults,
            children: this.children,
            infants: this.infants,
        });
    }

    recalculateMaxOccupants(): void {
        const occupancyLeft =
            this.config.maxNrOfOccupants -
            this.adults -
            this.children -
            this.infants;
        const adultOccupancyLeft = this.adults + occupancyLeft;
        this.maxAdults =
            adultOccupancyLeft < this.config.maxNrOfAdults
                ? adultOccupancyLeft
                : this.config.maxNrOfAdults;
        const childOccupancyLeft = this.children + occupancyLeft;
        this.maxChildren =
            childOccupancyLeft < this.config.maxNrOfChildren
                ? childOccupancyLeft
                : this.config.maxNrOfChildren;
        const infantOccupancyLeft = this.infants + occupancyLeft;
        this.maxInfants =
            infantOccupancyLeft < this.config.maxNrOfInfants
                ? infantOccupancyLeft
                : this.config.maxNrOfInfants;
    }

    onModalContinueClick() {
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.dismiss();
        } else {
            this.ngbPopover.close();
        }
    }

    onModalCloseClick() {
        this.offcanvasService.dismiss();
        this.occupancyChanged.emit({
            adults: 0,
            children: 0,
            infants: 0,
        });
    }
}

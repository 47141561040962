import * as _ from 'lodash';
import { createDateString } from 'src/app/controls/calendar/calendar.utils';
import { parseDate } from 'src/app/utilities/date.utils';
import { AccomInventory, InventoryDto } from 'src/app/model/deal.model';

export const transformInventoryDto = (
    inventory: InventoryDto[]
): AccomInventory[] => {

    const result: AccomInventory[] = [];

    inventory.forEach((accomInventoryDto) => {
        const date = parseDate(accomInventoryDto.date);

        result.push({
            date,
            'dateAsString': createDateString(date),
            'sellingPrice': accomInventoryDto.sellingPrice,
            'crossedOutPrice': accomInventoryDto.crossedOutPrice,
            'minLengthOfStay': accomInventoryDto.minLengthOfStay
        });
    });

    return result;
};

export interface OccupancyIdDto {
    id: number
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { getCache, removeCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-order-confirmation',
    templateUrl: './order-confirmation.component.html',
    styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnInit {
    ipaytext: string = this.translate.instant(
        'Thank you for your order,Payment is pending to finalize this reservation,Please check your email for our bank details'
    );
    ipaytextSuccess: string = this.translate.instant(
        'Thank you for your order'
    );
    ipayBottomtext: string =
        this.translate.instant('Remember') +
        ':' +
        this.translate.instant(
            'Any interbank transfer will take 2 to 3 days to receive in our account'
        ) +
        ',' +
        this.translate.instant(
            'so if you want to redeem offer within the next 3 days or reduce the risk of offer selling out'
        ) +
        ',' +
        this.translate.instant(
            'finalize payment directly in 1 our accounts mentioned above'
        );
    mipstext: string =
        this.translate.instant('Payment Successful!') +
        '\n' +
        this.translate.instant(
            'Booking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal'
        ) +
        '.';
    userLogin!: any;
    orderdata: any;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private router: Router,
        private translate: TranslateService,
        private currencyLanguageService: CurrencyLanguageService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.userLogin = isLoggedIn;
            if (isLoggedIn) {
                this.orderdata = JSON.parse(
                    getCache('orderdata').data as string
                );
                removeCache('cartItemsdataCount');
            }
        });

        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    continueShoping() {
        removeCache('orderdata');
        this.router.navigate(['/']);
    }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AdvertisingBanner } from '../home.model';
import { SwiperOptions } from 'swiper';
import { DeviceCommsService } from 'src/app/services/device-comms.service';
import { screenSizes } from 'src/app/utilities/theme';

const swiperConfig: SwiperOptions = {
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    autoplay: true,
};

@Component({
    selector: 'md-home-banner',
    templateUrl: './home-banner.component.html',
    styleUrls: ['./home-banner.component.scss'],
})
export class HomeBannerComponent implements OnInit {
    @Input() bannerData: AdvertisingBanner[];

    swiperConfig = swiperConfig;
    showMobileBanner: boolean;
    hideBannerIfNoContent = false;

    private hasMobileBannerData = false;
    private hasDesktopBannerData = false;

    constructor(private deviceCommsService: DeviceCommsService) {}

    ngOnInit(): void {
        this.hasMobileBannerData = this.bannerData?.some(
            (banner) => banner.fileName !== ''
        );
        this.hasDesktopBannerData = this.bannerData?.some(
            (banner) => banner.fileNameDesktop !== ''
        );

        this.setShowMobileBanner();
    }

    @HostListener('window:resize')
    onResize(): void {
        this.setShowMobileBanner();
    }

    goToPromotionLink(link: string) {
        this.deviceCommsService.openLinkInNewTabOrBrowser(link);
    }

    private setShowMobileBanner() {
        this.showMobileBanner = window.innerWidth < screenSizes.mobile;
        this.hideBannerIfNoContent = this.showMobileBanner
            ? !this.hasMobileBannerData
            : !this.hasDesktopBannerData;
    }
}

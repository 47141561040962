import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { getBookingUrl } from 'src/app/utilities/url.utils';

export interface StoreCreditHistory {
    storeCreditBalance: number;
    expirationDate: string;
    history: StoreCreditHistoryItem[];
}

export interface StoreCreditHistoryItem {
    operator: '+' | '-';
    amount: number;
    balance: number;
    adminId: string;
    description: string;
    orderId: string;
    date: string;
}

@Injectable({
    providedIn: 'root',
})
export class StoreCreditService {
    constructor(private authHttpClient: AuthHttpClientNoXService) {}

    getStoreCreditBalanceAndHistory(): Observable<StoreCreditHistory> {
        const url = getBookingUrl() + 'store-credit/customer';
        return this.authHttpClient.get<StoreCreditHistory>(url).pipe(
            map((response) => {
                return {
                    ...response,
                    history: this.transformDates(response.history),
                };
            }),
            catchError(
                (error: HttpErrorResponse) => throwError(error.message) // TODO: maybe this hsould just show a dialog
            )
        );
    }

    private transformDates(
        history: StoreCreditHistoryItem[]
    ): StoreCreditHistoryItem[] {
        return history.map((item) => {
            return {
                ...item,
                date: dayjs(item.date).format('DD MMM YYYY'),
            };
        });
    }
}

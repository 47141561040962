import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const parseDate = (dateString: string): NgbDateStruct => {
    const dateShards = dateString.split('-');
    return {
        year: Number(dateShards[0]),
        month: Number(dateShards[1]),
        day: Number(dateShards[2]),
    };
};

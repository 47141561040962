import { Injectable } from '@angular/core';
import { getAuthUrl } from '../utilities/url.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthHttpClientService } from '../services/auth-http-client.service';
import { UserData } from '../services/user.service';
import { CurrencyLanguageService } from '../shared/currency-language.service';
import { Observable } from 'rxjs';
import {
    LoginRegisterService,
    SignInRegisterResponse,
} from '../login-register/shared-service/login-register.service';

interface SignInBody {
    orgId: number;
    email: string;
    password: string;
    countryCode: 'MU' | 'RE' | 'OT';
    language: 'en' | 'fr';
}

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private spinner: NgxSpinnerService,
        private authHttpClient: AuthHttpClientService,
        private loginRegisterService: LoginRegisterService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    logInV2(email: string, password: string): Observable<void | UserData> {
        this.spinner.show();
        const { country, language } =
            this.currencyLanguageService.getLocaleDataImmediate();
        return new Observable((observer) => {
            const subscription$ = this.authHttpClient
                .postNotLoggedIn<SignInRegisterResponse, SignInBody>(
                    `${getAuthUrl()}sign-in`,
                    {
                        orgId: 1,
                        email,
                        password,
                        countryCode: country,
                        language,
                    }
                )
                .subscribe(
                    this.loginRegisterService.getLoginRegisterAction(observer)
                );

            return () => {
                subscription$.unsubscribe();
            };
        });
    }

    verifyNormalLogin(
        email: string,
        password: string,
        isFromRegistrationForm = false
    ): void {
        // This will be deleted
    }
}

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { createDateString } from 'src/app/controls/calendar/calendar.utils';
import { parseDate } from 'src/app/utilities/date.utils';

export interface AccomInventoryResponseDto {
    inventory: AccomInventoryDto[];
    total: number;
}

export interface AccomInventoryDto {
    date: string;
    qty: number;
    sellingPrice: number;
    crossedOutPrice: number;
}

export interface AccomInventoryByDate {
    date: NgbDateStruct;
    dateAsString: string;
    sellingPrice: number;
    crossedOutPrice: number;
    minLengthOfStay: number;
}

export const transformAccomInventoryResponseDto = (
    accomInventoryResponseDto: AccomInventoryResponseDto
): AccomInventoryByDate[] => {

    const result: AccomInventoryByDate[] = [];

    accomInventoryResponseDto.inventory.forEach((accomInventoryDto) => {
        const date = parseDate(accomInventoryDto.date);

        result.push({
            date,
            'dateAsString': createDateString(date),
            'sellingPrice': Number(accomInventoryDto.sellingPrice),
            'crossedOutPrice': Number(accomInventoryDto.crossedOutPrice),
            'minLengthOfStay': 1
        });
    });

    return result;
};

export interface OccupancyIdDto {
    id: number
}
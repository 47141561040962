import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public pageTitle: string = '';
    public loadingText: string = '';

    constructor(private translate: TranslateService) {}

    organizeTitle(url: string = ''): string {
        let routeTitlesArray = {
            '/': '',
            '/help-new': this.translate.instant('Help'),
            '/customer/mainhelp': this.translate.instant('Help'),
            '/review/customer': this.translate.instant('My Reviews'),
            '/addgift-voucher': this.translate.instant('My Gift Card'),
            '/invoice': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/invoicenew': this.translate.instant('Success!'),
            '/customeraccount/settings': this.translate.instant('Settings'),
            '/write-review/': this.translate.instant('Write Review'),
            '/writereview': this.translate.instant('Write Review'),
            '/rewardpoints': this.translate.instant('My Rewards'),
            '/customer/address/edit': this.translate.instant('My Profile'),
            '/badges': this.translate.instant('My Badges'),
            '/customer-notification': this.translate.instant('Notifications'),
            '/checkoutpaymentSuccess': this.translate.instant('Order Review'),
            '/checkoutpaymentFailure': '',
            '/customer/bookings': this.translate.instant('My Bookings'),
            '/customer/rewardsandwallet':
                this.translate.instant('Rewards and Wallet'),
        };
        let dynamicRouteTitlesArray: any = {
            '/companyprofile/index/invoiceform': this.translate.instant(
                'Generate VAT Invoice'
            ),
            '/writereview': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/checkout': this.translate.instant('Checkout')
                ? this.translate.instant('Checkout')
                : 'Checkout',
            '/deal/': '',
            '/search': this.translate.instant('Search'),
            'plp/Stays': this.translate.instant('Stays'),
            '/plp/Day%20Package/': this.translate.instant('Day Package'),
            '/plp/Beauty%20&%20Spa/': this.translate.instant('Beauty & Spa'),
            '/plp/Restaurants/': this.translate.instant('Restaurants'),
            '/plp/Things%20to%20do/': this.translate.instant('Things to do'),
            '/plp/Rodrigues/': this.translate.instant('Rodrigues'),
            '/plp/Events/': this.translate.instant('Events'),
            '/plp/Gift%20Card/': this.translate.instant('Gift Card'),
            '/plp/Black%20Friday/': this.translate.instant('Black Friday'),
        };
        let arrayUrls = Object.keys(routeTitlesArray);
        for (let arrUrl of arrayUrls) {
            if (arrUrl.length > url.length || arrUrl.length == url.length) {
                if (arrUrl.includes(url)) {
                    return routeTitlesArray[arrUrl];
                }
            } else {
                let dynamicArrayUrls = Object.keys(dynamicRouteTitlesArray);
                for (let dynamicArrUrl of dynamicArrayUrls) {
                    if (url.includes(dynamicArrUrl)) {
                        return dynamicRouteTitlesArray[dynamicArrUrl];
                    }
                }
            }
        }
        return 'Home';
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ProductAddOnGroup,
    ProductAddOnItem,
    DealDto,
} from 'src/app/model/deal.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { ProductAddOnConfiguration } from './add-ons.model';

@Component({
    selector: 'md-add-ons',
    templateUrl: './add-ons.component.html',
    styleUrls: ['./add-ons.component.scss'],
})
export class AddOnsComponent implements OnInit {
    @Input() set productDetails(value: DealDto) {
        this.productDetailsInternal = value;
        this.productHasAddOns = value.addons?.length > 0;
        this.productAddOns = value.addons;
    }

    productHasAddOns = false;
    productAddOns: ProductAddOnGroup[] = [];
    productDetailsInternal: DealDto;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    addOnConfigurations: ProductAddOnConfiguration[] = [];

    @Output() addonConfigChanged = new EventEmitter<
        ProductAddOnConfiguration[]
    >();

    constructor(private currencyLanguageService: CurrencyLanguageService) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    onQuantityChanged(addon: ProductAddOnItem, count: number): void {
        const addOnConfiguration = this.addOnConfigurations.find(
            (addOnConfiguration) => addOnConfiguration.addonItem.id === addon.id
        );
        if (addOnConfiguration) {
            addOnConfiguration.quantity = count;
        } else {
            this.addOnConfigurations.push({
                addonItem: addon,
                quantity: count,
            });
        }
        this.addonConfigChanged.emit(this.addOnConfigurations);
    }
}

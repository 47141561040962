import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { MoreDealsService } from './more-deals.service';
import { Subscription } from 'rxjs';
import { transformGetMerchantDealsResponse } from './more-detals.model';
import { ProductCardEntry } from 'src/app/controls/product-card/product-card.component';
import { DeviceCommsService } from 'src/app/services/device-comms.service';

@Component({
    selector: 'md-more-deals',
    templateUrl: './more-deals.component.html',
    styleUrls: [],
})
export class MoreDealsComponent implements OnDestroy {
    @Input() set productId(value: number) {
        if (!value || value === this.internalProductId) return;
        this.internalProductId = value;
        // this.subscription = this.moreDealsService
        //     .getDeals(Number(value))
        //     .subscribe((data) => {
        //         const response = transformGetMerchantDealsResponse(data);
        //         this.merchantName = response.merchantName;
        //         this.merchantDeals = response.merchantDeals;
        //     });
    }

    internalProductId: number;
    merchantName: string;
    merchantDeals: ProductCardEntry[];

    @Output() dealSelected = new EventEmitter<ProductCardEntry>();

    private subscription: Subscription;

    constructor(
        private moreDealsService: MoreDealsService,
        private deviceCommsService: DeviceCommsService
    ) {}

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onDealSelected(dealEntry: ProductCardEntry): void {
        const { productPath, id } = dealEntry;
        this.deviceCommsService.openLinkInNewTabIfBrowser(
            `deal/${productPath}/${id}`
        );

        this.dealSelected.emit(dealEntry);
    }
}

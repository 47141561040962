import {
    MinimalProductBookingData,
    ProductBookingData,
} from './booking-config.model';

export const transformToMinimalProductBookingData = (
    productBookingData: ProductBookingData
): MinimalProductBookingData => {
    return {
        id: productBookingData.id,
        checkinDate: productBookingData.checkinDate,
        checkoutDate: productBookingData.checkoutDate,
        selectedDate: productBookingData.selectedDate,
        selectedDealOptions: productBookingData.selectedDealOptions
            ? productBookingData.selectedDealOptions.map((option) => {
                  return {
                      id: option.dealOptionId,
                      occupancyId: option.occupancyId,
                      categoryId: option.categoryId,
                  };
              })
            : undefined,
        selectedDealOptionQuantities:
            productBookingData.selectedDealOptionQuantities
                ? new Map(
                      Array.from(
                          productBookingData.selectedDealOptionQuantities.entries()
                      ).map(([option, quantity]) => {
                          return [
                              {
                                  id: option.dealOptionId,
                                  occupancyId: option.occupancyId,
                                  categoryId: option.categoryId,
                              },
                              quantity,
                          ];
                      })
                  )
                : undefined,
    };
};

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import * as dayjs from 'dayjs';
import {
    DealDto,
    AgeGroup,
    AccomOffer,
    AccomOptionDto,
    AccomInventory,
} from 'src/app/model/deal.model';
import { HotelBookingService } from './hotel-booking.service';
import { transformInventoryDto } from './hotel-booking.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import {
    BookingConfiguredData,
    HotelSearchBookingData,
    emptyBookingConfiguredData,
} from '../booking-config.model';
import {
    transformAccomOptions,
    transformInventory,
} from '../../model/product-detail.transformers';
import { transformNgbDateToDayJs } from 'src/app/controls/calendar/calendar.utils';
import { TRAVEL_CATEGORY } from 'src/app/static-content/menu-routes';
import { screenSizes } from 'src/app/utilities/theme';
import { ProductService } from '../../product.service';
import { OccupancyComponent } from 'src/app/controls/occupancy/occupancy.component';

@UntilDestroy()
@Component({
    selector: 'md-hotel-booking',
    templateUrl: './hotel-booking.component.html',
    styleUrls: ['./hotel-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelBookingComponent implements OnInit, AfterViewInit {
    @Input() set productDetailsInput(value: DealDto) {
        this.productDetails = value;

        this.isTravel = this.productDetails.categoryId === TRAVEL_CATEGORY;

        this.visibleServiceCategoriesAndOptions = this.productDetails.accomOffers;

        this.selectedOccupancyId = 1332;

        if (this.initialBookingData) {
            // TODO: this is for query string parameters
        } else {
            this.setServiceOptionOnDesktop();
        }
    }
    @Input() initialBookingData: HotelSearchBookingData;

    visibleServiceCategoriesAndOptions: AccomOffer[];
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    pricingByDateForSelectedServiceOption: AccomInventory[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedServiceOptionInternal: AccomOptionDto;
    selectedOccupancyIdInternal: number;
    bookingSelected = false;
    productDetails: DealDto;
    isTravel = false;
    calendarErrorMessage = '';

    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
    totalNights = 0;

    private hasSetOccupancyFirstRan = false;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarHotel') calendarHotel: CalendarComponent;
    @ViewChild('occupancy') occupancyComponent: OccupancyComponent

    set selectedOccupancyId(selectedOccupancyId: number) {
        this.resetBooking();
        this.selectedOccupancyIdInternal = selectedOccupancyId;

        if (!this.hasSetOccupancyFirstRan) {
            this.hasSetOccupancyFirstRan = true;
            return;
        }

        this.hasSetOccupancyFirstRan &&
            this.productService
                .fetchAccomOptions(this.productDetails, undefined, selectedOccupancyId)
                .pipe(
                    untilDestroyed(this),
                    tap(({ accomOptions, accomInventory }) => {
                        this.productDetails.accomOffers =
                            transformAccomOptions(accomOptions);
                        this.productDetails.inventory = transformInventory(
                            this.productDetails,
                            [],
                            accomInventory
                        );
                        this.productDetails = { ...this.productDetails };
                        this.visibleServiceCategoriesAndOptions =
                            this.productDetails.accomOffers;
                        this.setServiceOptionOnDesktop();
                    })
                )
                .subscribe();
    }

    setSelectedServiceOption(productServiceOption: AccomOptionDto) {
        this.resetBooking();
        this.selectedServiceOptionInternal = productServiceOption;
        this.setAccomInventory();
    }

    constructor(
        private hotelBookingService: HotelBookingService,
        private currencyLanguageService: CurrencyLanguageService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private productService: ProductService
    ) { }

    ngOnInit(): void {
        this.currencyLanguageService
            .getCurrency()
            .pipe(untilDestroyed(this))
            .subscribe((currency) => {
                this.currencySymbol = currency;
            });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .pipe(untilDestroyed(this))
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    ngAfterViewInit(): void {
        this.occupancyComponent.occupancyChanged
            .pipe(
                switchMap((ageGroups: AgeGroup[]) =>
                    this.hotelBookingService.getOccupancyId(ageGroups)
                ),
                tap(({ id }) => (this.selectedOccupancyId = id))
            )
            .subscribe();
    }

    onServiceOptionChanged(accomOption: AccomOptionDto): void {
        const accomOptions = this.productDetails.accomOffers.flatMap(
            (accomOffer) => accomOffer.accomOptions
        );

        this.productService
            .fetchAccomInventory(
                this.productDetails,
                this.selectedOccupancyIdInternal,
                accomOptions,
                accomOption
            )
            .pipe(
                untilDestroyed(this),
                tap(({ accomInventory }) => {
                    this.productDetails.inventory = transformInventory(
                        this.productDetails,
                        [],
                        accomInventory
                    );
                    this.productDetails = { ...this.productDetails };
                    this.setSelectedServiceOption(accomOption);
                })
            )
            .subscribe();

        if (this.selectedServiceOptionInternal?.mealPlanId === accomOption.mealPlanId) {
            this.calendarHotel.openCalendar();
        }
    }

    onDatesClick() {
        this.calendarHotel.openCalendar();
    }

    onDateRangeSelected(selectedDates: CalendarSelectedDates): void {
        const { fromDate, toDate, totalNights } = selectedDates;
        if (!fromDate || !toDate) {
            this.bookingConfigured.emit(emptyBookingConfiguredData);
            this.setCheckinCheckoutUI(undefined, undefined, undefined);
            this.bookingSelected = false;
            return;
        }
        this.setCheckinCheckoutUI(fromDate, toDate, totalNights);
        this.bookingSelected = true;
        const allDatesBooked = this.getAllBookedDates(fromDate, toDate, totalNights);

        const total = allDatesBooked.reduce(
            ({ totalBooked, totalFull }, pricingByDate) => {
                return {
                    totalBooked: totalBooked + pricingByDate.sellingPrice,
                    totalFull: totalFull + pricingByDate.crossedOutPrice,
                };
            },
            { totalBooked: 0, totalFull: 0 }
        );

        const { flightPrice } = this.selectedServiceOptionInternal ?? {};

        if (flightPrice) {
            total.totalBooked += flightPrice;
            total.totalFull += flightPrice;
        }

        this.bookingConfigured.emit({
            totalPrice: total.totalBooked,
            totalFullPrice: total.totalFull,
            flightPrice: flightPrice,
            productBookingData: {
                id: this.productDetails.dealId,
                selectedAccomOptions: [this.selectedServiceOptionInternal],
                checkinDate: fromDate,
                checkoutDate: toDate,
                totalNights,
            },
        });
    }

    private getAllBookedDates(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): AccomInventory[] {
        const allBookedDates = this.pricingByDateForSelectedServiceOption.filter(
            (pricingByDate) =>
                fromDate.equals(pricingByDate.date) ||
                (fromDate.before(pricingByDate.date) && toDate.after(pricingByDate.date))
        );

        if (allBookedDates.length !== totalNights) {
            Sentry.captureMessage(`HB-DATES-MISMATCH-${this.productDetails.dealId}`);
        }

        return allBookedDates;
    }

    private resetBooking() {
        this.bookingConfigured.emit(emptyBookingConfiguredData);
        this.calendarHotel?.clearDateSelection?.();
        this.bookingSelected = false;
    }

    private setCheckinCheckoutUI(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): void {
        this.fromDate = fromDate ? transformNgbDateToDayJs(fromDate) : undefined;

        this.toDate = toDate ? transformNgbDateToDayJs(toDate) : undefined;

        this.totalNights = totalNights;
    }

    private setAccomInventory(): void {
        const { inventory } = this.productDetails;

        this.calendarErrorMessage = !inventory?.length
            ? this.translate.instant('calendarErrors.no-matching-dates')
            : '';

        this.pricingByDateForSelectedServiceOption = transformInventoryDto(inventory);

        this.additionalCalendarDayInfo.clear();
        this.pricingByDateForSelectedServiceOption.forEach((pricingByDate) =>
            this.additionalCalendarDayInfo.set(pricingByDate.dateAsString, {
                date: pricingByDate.date,
                tooltipContent: `${this.currencySymbol} ${pricingByDate.sellingPrice}`,
                minLengthOfStay: pricingByDate.minLengthOfStay,
            })
        );
        this.additionalCalendarDayInfo = new Map(this.additionalCalendarDayInfo);

        this.ref.markForCheck();
        this.calendarHotel?.openCalendar?.();
    }

    private setServiceOptionOnDesktop(): void {
        const isMobile = window.innerWidth < screenSizes.mobile;

        if (!isMobile) {
            this.setSelectedServiceOption(
                this.visibleServiceCategoriesAndOptions[0]?.accomOptions[0]
            );
        }
    }
}

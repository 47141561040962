export const MAURITIUS_NOT_LOGGED_IN_CURR = 0;
export const MAURITIUS_LOGGED_IN_CURR = 1;
export const REUNION_NOT_LOGGED_IN_CURR = 10;
export const REUNION_LOGGED_IN_CURR = 8;
export const OTHER_NOT_LOGGED_IN_CURR = 12;
export const OTHER_LOGGED_IN_CURR = 11;
export const EMPLOYEE_LOGGED_IN_CURR = 5;
export const CORPORATE_LOGGED_IN_CURR = 6;

export const currencyIdToCountryCodeMap = new Map<number, 'MU' | 'RE' | 'OT'>([
    [MAURITIUS_NOT_LOGGED_IN_CURR, 'MU'],
    [MAURITIUS_LOGGED_IN_CURR, 'MU'],
    [REUNION_NOT_LOGGED_IN_CURR, 'RE'],
    [REUNION_LOGGED_IN_CURR, 'RE'],
    [OTHER_NOT_LOGGED_IN_CURR, 'OT'],
    [OTHER_LOGGED_IN_CURR, 'OT'],
    [EMPLOYEE_LOGGED_IN_CURR, 'MU'],
    [CORPORATE_LOGGED_IN_CURR, 'MU'],
]);

export const languageIdToLanguageCodeMap = new Map<
    string,
    'English' | 'French'
>([
    ['en', 'English'],
    ['fr', 'French'],
]);

export const loggedInToNotLoggedInSameCurrencyMap = new Map<number, number>([
    [MAURITIUS_LOGGED_IN_CURR, MAURITIUS_NOT_LOGGED_IN_CURR],
    [REUNION_LOGGED_IN_CURR, REUNION_NOT_LOGGED_IN_CURR],
    [OTHER_LOGGED_IN_CURR, OTHER_NOT_LOGGED_IN_CURR],
    [EMPLOYEE_LOGGED_IN_CURR, MAURITIUS_NOT_LOGGED_IN_CURR],
    [CORPORATE_LOGGED_IN_CURR, MAURITIUS_NOT_LOGGED_IN_CURR],
]);

export const countryCodeToFullCountryNameMap = new Map<
    'MU' | 'RE' | 'OT',
    'Mauritius' | 'Reunion' | 'Other'
>([
    ['MU', 'Mauritius'],
    ['RE', 'Reunion'],
    ['OT', 'Other'],
]);

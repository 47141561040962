import { Injectable } from '@angular/core';
import { AuthHttpClientNoXService } from './auth-http-client-noX.service';
import { getAvailabilityUrl, getBookingUrl } from '../utilities/url.utils';
import { BehaviorSubject } from 'rxjs';

export interface RewardPointsData {
    rewardPointsBalance: number;
    rewardPointsMoney: number;
}

@Injectable({
    providedIn: 'root',
})
export class RewardsService {
    private rewardsPointsSubject = new BehaviorSubject<RewardPointsData>(null);
    rewardsPoints$ = this.rewardsPointsSubject.asObservable();

    private lastCustomerInfo: { customerGroupId: number; countryCode: string };

    constructor(private authHttpClient: AuthHttpClientNoXService) {}

    retreiveRewardPoints(customerGroupId: number, countryCode: string) {
        this.lastCustomerInfo = { customerGroupId, countryCode };
        this.authHttpClient
            .get<{ rewardPointsBalance: number }>(
                `${getBookingUrl()}reward-points`
            )
            .subscribe({
                next: (response) => {
                    const points = response.rewardPointsBalance;

                    const postBody = {
                        orgId: 1,
                        customerGroupId,
                        countryCode,
                        currencyId: 1,
                        rewardPoints: points,
                    };
                    this.authHttpClient
                        .post<{ amount: number }, typeof postBody>(
                            `${getAvailabilityUrl()}reward-points/convert`,
                            postBody
                        )
                        .subscribe({
                            next: (response) => {
                                this.rewardsPointsSubject.next({
                                    rewardPointsBalance: points,
                                    rewardPointsMoney: response.amount,
                                });
                            },
                            error: (error) => {
                                console.error(
                                    'Error converting reward points to money',
                                    error
                                );
                            },
                        });
                },
            });
    }

    // **
    //  * Invalidates the rewards points cache. This is to be used when we know the rewards points have changed.
    //  */
    invalidateRewardsPoints() {
        if (this.lastCustomerInfo) {
            return;
        }

        this.retreiveRewardPoints(
            this.lastCustomerInfo.customerGroupId,
            this.lastCustomerInfo.countryCode
        );
    }

    getRewardsPointsMoney() {}
}

// TODO: this is a temporary utility file where we will keep all user related
// functions until we rewrite the login and user app data model

import { LocalStorageMaridealPwaDao } from './cache.utils';
import { MARIDEAL_PWA_USER, QUOTE_ID } from './local-storage.keys';
import { User } from '../model/user.model';

/**
 * Get the customer id of the logged in user
 * @returns the customer id of the logged in user. Or an empty string if no user is logged in
 */
export const getCustomerId = (): string => {
    const user = getUser();
    if (user) {
        return user.id;
    }
    return '';
};

export const getCustomerIdV2 = (): number | null => {
    const user = getUser();
    if (user) {
        return Number(user.id);
    }
    return null;
};

export const getUser = () => {
    const customerData = localStorage.getItem(MARIDEAL_PWA_USER);
    if (customerData) {
        const localStoragePwaDao = JSON.parse(
            customerData
        ) as LocalStorageMaridealPwaDao;
        return localStoragePwaDao.data as User;
    }
    return null;
};

export const getQuoteId = (): string => {
    const quoteid = localStorage.getItem(QUOTE_ID);

    return quoteid ?? '';
};

export const setQuoteId = (quoteid: string): void => {
    localStorage.setItem(QUOTE_ID, quoteid);
};

// TODO: this should just be kept in memory
/**
 * Warning: this one only sets the reward points, not the reward money
 *  */
export const setRewardPoints = (points: number): void => {
    const user = getUser();
    if (user && user.rewardData) {
        user.rewardData.balancePoints = points;
        const date = new Date();
        const cacheableData = JSON.stringify({
            time: date.getTime(),
            data: user,
        });
        localStorage.setItem(MARIDEAL_PWA_USER, cacheableData);
    }
};

export const setBadgeCount = (badges: number): void => {
    const user = getUser();
    if (user) {
        user.availableBadges = badges;
        const date = new Date();
        const cacheableData = JSON.stringify({
            time: date.getTime(),
            data: user,
        });
        localStorage.setItem(MARIDEAL_PWA_USER, cacheableData);
    }
};

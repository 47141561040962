import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DealDto } from 'src/app/model/deal.model';

export type ProductSpecialOfferType =
    | 'serviceOptionDiscount'
    | 'subtotalDiscount';

export interface ProductSpecialOffer {
    type: ProductSpecialOfferType;
    minNights: number;
    discountPercent: number;
}

@Component({
    selector: 'md-special-offers',
    templateUrl: './special-offers.component.html',
    styleUrls: ['./special-offers.component.scss'],
})
export class SpecialOffersComponent implements OnInit {
    @Input() productDetails: DealDto;

    specialOffers: ProductSpecialOffer[] = [];

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.computeSpecialOffers();
    }

    private computeSpecialOffers(): void {
        // have to also extract the special offers from each service option and add them to the list.
        // what if there are several service options with the same offer?
        // we'll just add them all to the list
        const serviceOptionsDiscounts =
            this.productDetails.dealOffers.flatMap((category) => {
                return category.dealOptions.flatMap((option) => {
                    if (!option.optionDiscounts) return [];

                    return option.optionDiscounts.map((offer) => ({
                        type: 'serviceOptionDiscount' as ProductSpecialOfferType,
                        minNights: offer.minNights,
                        discountPercent: offer.discountPercent,
                    }));
                });
            });

        const subtotalDiscounts = [
            ...this.productDetails.subtotalDiscounts.map((discount) => ({
                type: 'subtotalDiscount' as ProductSpecialOfferType,
                minNights: discount.minNights,
                discountPercent: discount.discountPercent,
            })),
        ];

        const uniqueServiceOptionsDiscounts = this.removeDuplicates(
            serviceOptionsDiscounts
        );

        this.specialOffers = [
            ...subtotalDiscounts,
            ...uniqueServiceOptionsDiscounts,
        ];
    }

    private removeDuplicates(
        specialOffers: ProductSpecialOffer[]
    ): ProductSpecialOffer[] {
        const uniqueOffers: ProductSpecialOffer[] = [];
        const uniqueOfferStrings: string[] = [];

        for (const offer of specialOffers) {
            const offerString = JSON.stringify(offer);

            if (!uniqueOfferStrings.includes(offerString)) {
                uniqueOffers.push(offer);
                uniqueOfferStrings.push(offerString);
            }
        }

        return uniqueOffers;
    }

    getOfferText(offer: ProductSpecialOffer): string {
        switch (offer.type) {
            case 'serviceOptionDiscount': {
                const discountedNightsPercentText =
                    offer.discountPercent < 100
                        ? this.translate.instant(
                              `at {{count}}% off`,
                              offer.discountPercent
                          )
                        : this.translate.instant('free');
                return this.translate.instant(
                    `Book {{minNights}} nights and get the {{nextNight}}th Night {{discount}}`,
                    {
                        minNights: offer.minNights,
                        nextNight: offer.minNights + 1,
                        discount: discountedNightsPercentText,
                    }
                );
            }
            case 'subtotalDiscount':
                return this.translate.instant(
                    `Book {{minNights}} nights and get {{discount}}% off`,
                    {
                        minNights: offer.minNights,
                        discount: offer.discountPercent,
                    }
                );
        }
    }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CreateInvoiceService {
    constructor() {}
    //   saveOrderInvoice(data:any){
    //  return this.httpClient.get(
    //     this.common.api_url+`generateinvoice?service=generateinvoice&customerid=${data.customerId}&orderid=${data.order_id}&po=${data.po}` + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenJWTheaders()})
}

import { getPathFromProductUrl } from '../utilities/url.utils';

export interface TempDataServiceObjectType {
    store_details: {
        bannerData: {
            banner1: AdvertisingBannerDto[];
            banner2: AdvertisingBannerDto[];
            top_banner: AdvertisingBannerDto[];
        };
    };
}

export interface BestSellerEntry {
    id: number;
    productUrl: string;
    productPath: string;
    categoryId: number;
    price: number;
    imageUrl: string;
    bannerImages?: Array<string>;
    name: string;
    shortDescription: string;
    specialPrice: number;
    currencySymbol: 'Rs.' | 'EUR';
    starCount: number;
    average: number;
    startDate: Date;
    endDate: Date;
}

export interface BestSellerCategories {
    categories: {
        category: string;
        data: BestSellerEntry[];
    }[];
    trendingOffers?: BestSellerEntry[];
}

export interface AdvertisingBanner {
    // TODO: seems only fileName and urlString are used. Remove the rest if this turns out to be true
    fileName: string;
    fileNameDesktop: string;
    urlString: string;
    isProduct: boolean;
    productData: {
        productId: string;
        productUrl: string;
    }[];
    categoryId: string;
}

export interface BestSellerEntryDto {
    id: string;
    product_url: string;
    category_id: string;
    saved_price: string;
    price: string;
    imageurl: string;
    name: string;
    short_description: string;
    spclprice: string;
    currencysymbol: string;
    star_count: string;
    average: number;
    event_start_date: string;
    event_end_date: string;
    banner_images: string[];
}

export interface AdvertisingBannerDto {
    filename: string;
    filename_desktop: string;
    urlstring: string;
    is_product: boolean;
    product_data: {
        product_id: string;
        product_url: string;
    }[];
    categoryid: string;
}

export interface RecentlyViewedDto {
    data: BestSellerEntryDto[];
}

// TODO: this has to be changed to the actual type when we finish the trips-detail component
export interface UpcomingTripDto {
    outlet_name: string;
    location_name: string;
    checkin: string;
    checkout: string;
    pdf_url: string;
}

export const trasnformBestSellerEntryDto = (
    bestSellerEntryDto: BestSellerEntryDto
): BestSellerEntry => {
    return {
        id: Number(bestSellerEntryDto.id),
        productUrl: bestSellerEntryDto.product_url,
        productPath: getPathFromProductUrl(bestSellerEntryDto.product_url),
        categoryId: Number(bestSellerEntryDto.category_id),
        price: Math.ceil(Number(bestSellerEntryDto.price)),
        imageUrl: bestSellerEntryDto.imageurl,
        bannerImages: bestSellerEntryDto.banner_images
            .slice(0, 5)
            .map((image) => image),
        name: bestSellerEntryDto.name,
        shortDescription: bestSellerEntryDto.short_description,
        specialPrice: Math.ceil(Number(bestSellerEntryDto.spclprice)),
        currencySymbol: bestSellerEntryDto.currencysymbol as 'Rs.' | 'EUR',
        starCount: Number(bestSellerEntryDto.star_count),
        average: bestSellerEntryDto.average,
        startDate: new Date(bestSellerEntryDto.event_start_date),
        endDate: new Date(bestSellerEntryDto.event_end_date),
    };
};

export interface HomeDealsDto {
    [key: string]: { name: string; deals: HomeDealDto[] };
}

export interface HomeDealDto {
    dealId: number;
    outletId: number;
    categoryId: number;
    sectionIds: number[];
    customerGroupIds: number[];
    dealTitle: string;
    subTitle: string;
    baseSellingPrice: number;
    baseCrossedOutPrice: number;
    currencySymbol: string;
    currencyPosition: string;
    percentDiscount: string;
    url: string;
    countryCode: string;
    language: string;
    bestSellerQtyRank: number;
    reviewsCount: number;
    starRating: number;
    images: {
        dealId: number;
        imgId: number;
        imgMeta: { imgAltText: string };
        imgUrl: string;
    }[];
}

export const transformHomeDealDtoToBestSellerEntry = (
    homeDealDto: HomeDealDto
): BestSellerEntry => {
    return {
        id: homeDealDto.dealId,
        productUrl: homeDealDto.url,
        productPath: getPathFromProductUrl(homeDealDto.url),
        categoryId: homeDealDto.categoryId,
        price: homeDealDto.baseCrossedOutPrice,
        imageUrl: homeDealDto.images[0]?.imgUrl,
        bannerImages: homeDealDto.images
            .slice(0, 5)
            .map((image) => image.imgUrl),
        name: homeDealDto.dealTitle,
        shortDescription: homeDealDto.subTitle,
        specialPrice: homeDealDto.baseSellingPrice,
        currencySymbol: homeDealDto.currencySymbol as 'Rs.' | 'EUR',
        starCount: homeDealDto.starRating,
        average: homeDealDto.bestSellerQtyRank,
        // TODO: When we get these from the backend they have to be set properly
        startDate: undefined,
        endDate: undefined,
    };
};

export const transformHomeDealsDtoToBestSellerEntry = (
    homeDealsDto: HomeDealsDto
): BestSellerCategories => {
    const transformedBestSeller: BestSellerCategories = {
        categories: [],
        trendingOffers: homeDealsDto['trending'].deals.map((entry) =>
            transformHomeDealDtoToBestSellerEntry(entry)
        ),
    };
    Object.keys(homeDealsDto).forEach((key) => {
        if (key !== 'trending' && key !== 'stateRefreshAt') {
            transformedBestSeller.categories.push({
                category: homeDealsDto[key].name,
                data: homeDealsDto[key].deals.map((entry) =>
                    transformHomeDealDtoToBestSellerEntry(entry)
                ),
            });
        }
    });

    return transformedBestSeller;
};

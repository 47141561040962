import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from './shared/common.service';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConsoleToggleService } from './shared/console-toogle.service';
import { UpdateService } from './shared/update.service';
import { Title } from '@angular/platform-browser';
import { gaPageTransition } from './utilities/ga.utils';
import { CurrencyLanguageService } from './shared/currency-language.service';
import { removeGunk } from './utilities/gunk.remover3000';
import * as dayjs from 'dayjs';
import { removeQueryParams } from './utilities/url.utils';
import { getCategoryRoutesArray } from './static-content/menu-routes';
import { MdDeviceDetectorService } from './services/device-detector.service';
import { UserService } from './services/user.service';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    showLogo = true;
    is_promotion_started = false;
    is_promotion_activated = false;
    currentRoute = '';
    promotionBanner: any;
    promotionBanner_1: any;
    promotion_start_date!: string;
    promotion_end_date!: string;
    subscription: Subscription = new Subscription();
    networkStatus = false;
    networkStatus$: Subscription = Subscription.EMPTY;
    showTimer = true;
    showFooter = true;
    minutesLeftInPromotion: number;

    constructor(
        public commonService: CommonService,
        private router: Router,
        private translate: TranslateService,
        private consoleToggleService: ConsoleToggleService,
        private sw: UpdateService,
        private titleService: Title,
        private currencyLanguageService: CurrencyLanguageService,
        private deviceDetectorService: MdDeviceDetectorService,
        private userService: UserService
    ) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        removeGunk();
        // if it's not the product description page and the last part of the url looks like a product url, redirect to the PDP
        // this is only done once per app load
        this.redirectToPdpIfDesktopLink();
        this.sw.checkForUpdates();
        this.checkNetworkStatus();
        this.consoleToggleService.disableConsoleInProduction();
        // in here is where we set the country/language stuff
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            // may need to hook up to this and set some stuff after the country/language is set. Like get the data
            this.currencyLanguageService.setCurrencyAndLanguageOnAppInit(
                isLoggedIn,
                userData
            );
        });
        this.userService.onAppLoaded();

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.setShowTimerStateBasedOnPathname(event.urlAfterRedirects);
                this.setShowFooterStateBasedOnPathname(event.urlAfterRedirects);

                gaPageTransition(event.urlAfterRedirects);

                this.enableDisablePullToRefresh(event.urlAfterRedirects);
                // Hide progress spinner or progress bar
                this.currentRoute = window.location.pathname;
                this.commonService.pageTitle = this.commonService.organizeTitle(
                    this.currentRoute
                );

                if (localStorage.getItem('isFav')) {
                    this.commonService.pageTitle = this.translate.instant(
                        'My Favourites'
                    )
                        ? this.translate.instant('My Favourites')
                        : 'My Favourites';
                }
                this.currencyLanguageService.addIsoToUrlIfNeeded();

                this.showLogo =
                    this.commonService.pageTitle == '' ? true : false;

                let route: ActivatedRoute = this.router.routerState.root;
                let routeTitle = '';
                while (route!.firstChild) {
                    route = route.firstChild;
                }
                if (route.snapshot.data['title']) {
                    routeTitle = route!.snapshot.data['title'];
                }
                if (routeTitle) {
                    this.titleService.setTitle(`MariDeal - ${routeTitle}`);
                }

                this.scrollToTop();
            }
        });
    }

    redirectToPdpIfDesktopLink() {
        // TODO: this is to keep old links that were pointing to the magento site working.
        const url = window.location.href;
        const canonicalUrl = url.split('?')[0];
        const canonicalUrlLastPart = canonicalUrl.split('/').pop();
        const endsInHtml = canonicalUrlLastPart.includes('.html');
        const isPdp = canonicalUrl.includes('/deal/');
        if (
            canonicalUrlLastPart &&
            endsInHtml &&
            !getCategoryRoutesArray().find(
                (url) => url === canonicalUrlLastPart
            ) &&
            !isPdp
        ) {
            this.router.navigateByUrl('/deal/' + canonicalUrlLastPart, {
                skipLocationChange: true,
            });
        }
    }

    calculateDiff() {
        const todayDate = new Date();
        const promotionStartDate = new Date(this.promotion_start_date);
        const endDate =
            promotionStartDate > todayDate
                ? promotionStartDate
                : new Date(this.promotion_end_date);
        const minutesDifference = dayjs(endDate).diff(
            dayjs(todayDate),
            'minute'
        );

        this.minutesLeftInPromotion = minutesDifference;
    }

    enableDisablePullToRefresh(toState) {
        const { isAndroid, isIos } = this.deviceDetectorService;
        if (toState == '/' || toState.includes('deal')) {
            if (isAndroid) {
                (window as any).AndroidShareHandler.pullToRefreshDisable(
                    'disable'
                );
            }
        } else {
            if (isAndroid) {
                (window as any).AndroidShareHandler.pullToRefreshDisable(
                    'disable'
                );
            } else if (isIos) {
                (
                    window as any
                ).webkit.messageHandlers.pullToRefershAction.postMessage(
                    'enable'
                );
            }
        }
    }

    // To check internet connection stability
    checkNetworkStatus() {
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(map(() => navigator.onLine))
            .subscribe((status) => {
                this.networkStatus = status;
            });
    }

    setShowTimerStateBasedOnPathname(url: string) {
        const urlNoQueryParams = removeQueryParams(url);
        this.showTimer =
            urlNoQueryParams === '/' || urlNoQueryParams === '/checkout';
    }

    setShowFooterStateBasedOnPathname(urlAfterRedirects: string) {
        const urlNoQueryParams = removeQueryParams(urlAfterRedirects);
        this.showFooter =
            !this.isAuthPage(urlNoQueryParams) &&
            !(urlNoQueryParams === '/checkout');
    }

    isAuthPage(urlNoQueryParams: string) {
        return (
            urlNoQueryParams === '/login' ||
            urlNoQueryParams === '/register' ||
            urlNoQueryParams === '/password' ||
            urlNoQueryParams === 'customer/account/createPassword'
        );
    }

    scrollToTop() {
        const element = document.getElementsByClassName('pane')[0];
        element.scrollTop = 0;
    }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'md-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
    constructor(private router: Router) {}

    goHome() {
        this.router.navigate(['/']);
    }
}

import { Injectable } from '@angular/core';
import { Product } from '../category-deals-list/model/product.model';

@Injectable({
    providedIn: 'root',
})
export class RibbonStyleService {
    private startTime: Date = new Date('2024-11-26T23:55:00');
    private endTime: Date = new Date('2024-12-01T23:59:59');
    private flashSalesImageUrl = 'assets/img/bf-banners/BlackFridayFS.png';
    private bestSellerImageUrl =
        'assets/img/bf-banners/BlackFridayBestValue.png';

    private isWithinRestrictedTime(): boolean {
        const currentTime = new Date();
        return currentTime >= this.startTime && currentTime <= this.endTime;
    }

    getRibbonStyles(dealEntry: Product) {
        if (this.isWithinRestrictedTime() || !dealEntry || !dealEntry.ribbon) {
            return null;
        }

        const ribbon = dealEntry.ribbon;
        const textColor =
            ribbon.ribbonName.toUpperCase() === 'FLASH SALES'
                ? '#ffffff'
                : '#092e41';

        return {
            color: textColor,
            background: ribbon.colorCode,
        };
    }

    shouldHideRibbon(): boolean {
        return this.isWithinRestrictedTime();
    }

    getRibbonContent(dealEntry: Product) {
        const ribbonName = dealEntry?.ribbon?.ribbonName.toUpperCase();
        if (this.isWithinRestrictedTime()) {
            if (ribbonName === 'FLASH SALES') {
                return { type: 'image', value: this.flashSalesImageUrl };
            }
        }
        // Default to showing the ribbon name as text
        return {
            type: 'text',
            value: dealEntry?.ribbon?.ribbonName?.toUpperCase() || '',
        };
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ProductDetails,
    DealOfferOption,
} from 'src/app/product-detail/model/product-detail.model';
import {
    BookingConfiguredData,
    MinimalProductBookingData,
    getProductServiceOptionsFromProductDetails,
} from '../booking-config.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'md-activity-booking',
    templateUrl: './activity-booking.component.html',
    styleUrls: ['./activity-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityBookingComponent implements OnInit {
    @Input() productDetails: ProductDetails;
    @Input() initialBookingData: MinimalProductBookingData;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    selectedServiceOptionQuantities: Map<DealOfferOption, number> =
        new Map();

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();

    constructor(private currencyLanguageService: CurrencyLanguageService) { }

    ngOnInit(): void {
        if (this.initialBookingData) {
            const minimalProductServiceOptions = Array.from(
                this.initialBookingData.selectedServiceOptionQuantities.keys()
            );
            const productServiceOptions =
                getProductServiceOptionsFromProductDetails(
                    this.productDetails,
                    minimalProductServiceOptions
                );
            minimalProductServiceOptions.forEach((option) => {
                const quantity =
                    this.initialBookingData.selectedServiceOptionQuantities.get(
                        option
                    );
                const productServiceOption = productServiceOptions.find(
                    (serviceOption) =>
                        serviceOption.id === option.id &&
                        serviceOption.occupancyId === option.occupancyId
                );
                this.selectedServiceOptionQuantities.set(
                    productServiceOption,
                    quantity
                );
            });
        }

        this.currencyLanguageService
            .getCurrency()
            .subscribe((currency) => {
                this.currencySymbol = currency;
            });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    onQuantityChanged(option: DealOfferOption, qty: number) {
        option.qty = qty;
        this.selectedServiceOptionQuantities.set(option, qty);
        this.emitBookingConfigured();
    }

    private emitBookingConfigured() {
        const totalPrice = this.calculateTotalSellingPrice();
        const totalFullPrice = this.calculateTotalCrossedPrice();
        this.bookingConfigured.emit({
            totalPrice,
            totalFullPrice,
            productBookingData: {
                id: this.productDetails.id,
                type: this.productDetails.type,
                selectedServiceOptionQuantities:
                    this.selectedServiceOptionQuantities,
            },
        });
    }

    private calculateTotalSellingPrice() {
        let totalPrice = 0;
        this.selectedServiceOptionQuantities.forEach((quantity, option) => {
            totalPrice += option.sellingRate * quantity;
        });
        return totalPrice;
    }

    private calculateTotalCrossedPrice() {
        let totalFullPrice = 0;
        this.selectedServiceOptionQuantities.forEach((quantity, option) => {
            totalFullPrice += option.crossedRate * quantity;
        });
        return totalFullPrice;
    }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { StaticContentV2 } from 'src/app/model/deal.model';

declare const $;

@Component({
    selector: 'md-product-static-content',
    templateUrl: './static-content.component.html',
    styleUrls: ['./static-content.component.scss'],
})
export class ProductStaticContentComponent implements OnInit {
    @Input() staticContent: StaticContentV2;
    @Input() productId: number;

    activeNavItem = 0;

    @ViewChild('mdStaticContent') mdStaticContent: ElementRef;

    scrollToReviews() {
        this.mdStaticContent.nativeElement.scrollIntoView({
            behavior: 'smooth',
        });
    }

    ngOnInit(): void {
        $(document).on('click', '.show_all_fac_btn', () => {
            const facility = $('.facility-span');
            facility.show();
            $('.show_all_fac_btn').hide();
        });
    }
}

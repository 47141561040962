import { DealDto } from 'src/app/model/deal.model';
import {
    ProductDetailsAddonItemDto,
    ProductDetailsStayDataDto,
} from './product-detail.dto.model';
import {
    MaribnbStayData,
    ProductAddOnGroup,
    ProductDetails,
    DealOffer,
    StaticContentV2,
    AgeGroup,
    CalendarInventoryDto,
} from './product-detail.model';

const packageTypeIdToShowCalendarMap: Record<number, boolean> = {
    0: false,
    1: true,
    2: true,
    3: true,
    4: false,
};

// TODO: keeping the following two because we'll need addon stuff
const transformAddonItems = (
    addonItems: ProductDetailsAddonItemDto[]
): ProductAddOnGroup[] => {
    return addonItems.map((addonItem) => ({
        name: addonItem.name,
        items: addonItem.addon_data.map((addonData) => ({
            id: Number(addonData.id),
            maxQty: Number(addonData.max_qty),
            price: Number(addonData.price),
            priceType: addonData.price_type,
            sku: addonData.sku,
            title: addonData.title,
        })),
    }));
};

const transformMaribnbData = (
    maribnbData: ProductDetailsStayDataDto
): MaribnbStayData => {
    if (!maribnbData) {
        return undefined;
    }
    return {
        adultPricing: maribnbData.adult_pricing?.map((item) => Number(item)),
        childPricing: maribnbData.children_pricing?.map((item) => Number(item)),
        infantPricing: maribnbData.infant_pricing?.map((item) => Number(item)),
        maximumOccupancy: Number(maribnbData.maximum_occupancy),
        numberOfAdults: Number(maribnbData.number_of_adult) || 0,
        numberOfChildren: Number(maribnbData.number_of_children) || 0,
        numberOfInfants: Number(maribnbData.number_of_infant) || 0,
    };
};

const transformDealOffers = (dealOffers: DealOffer[]): DealOffer[] => {
    dealOffers
        .forEach(dealOffer => dealOffer.dealOptions
            .forEach(dealOption => {
                dealOption.maxPaxDynamic = dealOption.maxPax;
                dealOption.qty = dealOption.minPax;
            })
        )

    return dealOffers;
}

export const transformProductDetailsV2 = (
    deal: DealDto,
    dealOffers: DealOffer[],
    inventory: CalendarInventoryDto[],
    ageGroups: AgeGroup[]
): ProductDetails => {
    if (!deal) return undefined;

    return {
        staticContentV2: transformStaticContentV2(deal),
        id: deal.dealId,
        name: deal.dealTitle,
        shortDescription: deal.subTitle,
        rating: deal.starRating,
        category: deal.categoryId,
        images: deal.images.map((image) => ({
            url: image.imgUrl,
            caption: image.imgMeta?.imgAltText,
            imageTypeId: image.imgType?.imgTypeId,
        })),
        packageTypeId: deal.packageTypeId,
        // TODO: I think this will be useless once off-magento
        type: 'virtual',
        cmsBlockContent: 'THIS IS A PLACEHOLDER. FIX THIS',
        showCalendar: transformShowCalendar(deal),
        occupancyOptions: [],
        dealOffers: transformDealOffers(dealOffers),
        inventory,
        ageGroups,
        addons: [],
        subtotalDiscounts: [],
        badge: {
            label: '',
            group: '',
            iconUrl:
                'https://assets-dev.marideal.mu/media//rewardpoints/images/default/Day-Package_Lover---badge_1.png',
        },
    };
};

export const transformStaticContentV2 = (
    productDetailsDto: DealDto
): StaticContentV2 => {
    const infoDto = productDetailsDto.information;
    if (!infoDto) {
        return {
            information: undefined,
            location: undefined,
            conditions: undefined,
            facilities: [],
            roomCategories: [],
            reviews: undefined,
        };
    }

    return {
        // TODO: this is a temporary solution. This data should come translated from the server
        information: {
            about: infoDto.about_description || infoDto.about_description_fr,
            metaDescription:
                infoDto.meta_description || infoDto.meta_description_fr,
            metaKeywords: infoDto.meta_keywords || infoDto.meta_keywords_fr,
            metaTitle: infoDto.meta_title || infoDto.meta_title_fr,
            packageDetails:
                infoDto.package_details || infoDto.package_details_fr,
            purchaseIncludes:
                infoDto.purchase_includes || infoDto.purchase_includes_fr,
        },
        location: {
            latitude: infoDto.latitude,
            longitude: infoDto.longitude,
        },
        conditions: productDetailsDto.conditions,
        facilities: productDetailsDto.outletFacilities.facilities.map(
            (facility) => ({
                id: facility.facilityId,
                name: facility.facilityName,
                iconUrl: facility.icon,
                importance: facility.importance,
            })
        ),
        roomCategories: productDetailsDto.outletFacilities.roomCategories.map(
            (roomCategory) => ({
                facilities: roomCategory.facilities.map((facility) => ({
                    id: facility.facilityId,
                    name: facility.facilityName,
                    iconUrl: facility.icon,
                    importance: facility.importance,
                })),
                room: {
                    outletId: roomCategory.room.outletId,
                    roomId: roomCategory.room.roomId,
                    roomImage: roomCategory.room.roomImage,
                    roomName: roomCategory.room.roomName,
                    roomNameFr: roomCategory.room.roomNameFr,
                },
            })
        ),
        reviews: productDetailsDto.outletReviews,
    };
};

export const transformShowCalendar = (deal: DealDto): boolean =>
    packageTypeIdToShowCalendarMap[deal.packageTypeId] ?? false;

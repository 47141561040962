import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import { ForgetPasswordService } from './forget-password.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../utilities/event.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'md-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Mauritius];
    forgotPasswordForm!: UntypedFormGroup;
    mobileOtp: string;
    verifyMobileOtpPopup = false;
    emailPara = true;
    mobileOpacity = false;
    customerId!: string;
    isOtpDisabled = false;
    isForgotByEmail = true;
    private forgotBySubscription: Subscription;

    @ViewChild('verifyOtpModal') verifyOtpModal: TemplateRef<never>;

    constructor(
        private fpService: ForgetPasswordService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private eventService: EventService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.forgotPasswordForm = new UntypedFormGroup({
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ]),
        });
    }

    ngOnDestroy(): void {
        if (this.forgotBySubscription) {
            this.forgotBySubscription.unsubscribe();
        }
    }

    clearForm() {
        this.forgotPasswordForm.reset();
    }

    onSubmitForgotPassForm() {
        const emailDetail = this.forgotPasswordForm.controls['email'];
        const email = this.forgotPasswordForm.controls['email'].value; //either value or blank

        if (this.emailPara) {
            emailDetail.status;
            if (emailDetail?.status === 'INVALID') {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant('Please enter a valid email id')
                );
            } else if (emailDetail?.status === 'VALID') {
                this.spinner.show();
                this.fpService.forgotPassword(email).subscribe({
                    next: () => {
                        this.eventService.emitEvent(EventType.CHANGE_PASSWORD);
                        this.router.navigate(['/']);
                        this.eventService.emitEvent(
                            EventType.MODAL_INFO,
                            this.translate.instant(
                                'You will receive an email with a link to reset your password'
                            )
                        );
                    },
                    error: (error) => {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            this.translate.instant(error.error.message)
                        );
                    },
                    complete: () => {
                        this.spinner.hide();
                    },
                });
            }
        } else {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant('Please provide a valid email address')
            );
        }
    }
}

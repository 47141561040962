import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';

export const getApiUrlV2 = (): string => {
    return `${environment.apiUrl}`;
};

export const getApiUrlMofluid = (): string => {
    return `${environment.apiUrlMofluid}rest/V1/mofluidapi/`;
};

export const getTokenUrlMofluid = (): string => {
    return `${environment.apiUrlMofluid}rest/V1/getAuth`;
};

export const getBookingUrl = (): string => {
    return `${environment.apiUrl}booking/`;
};

export const getProfileUrl = (): string => {
    return `${environment.apiUrl}profile/`;
};

export const getMyAccountUrl = (): string => {
    return `${environment.apiUrl}profile/myaccount/`;
};

export const getAuthUrl = (): string => {
    return `${environment.apiUrl}profile/login/`;
};

export const getAvailabilityUrl = (): string => {
    return `${environment.apiUrl}availability/`;
};

export const getResultsUrl = (): string => {
    return `${environment.apiUrl}results/`;
};

export const removeQueryParams = (url: string): string => {
    return url.split('?')[0];
};

export const getPathFromProductUrl = (productUrl: string): string => {
    try {
        const urlShards = productUrl.split('/');
        const lastShard = urlShards[urlShards.length - 1];
        return lastShard !== '' ? lastShard : 'unknown.html';
    } catch (error) {
        // this should for no reason break the app. Log to Sentry
        Sentry.captureException(error);
        return 'unknown.html';
    }
};

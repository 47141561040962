import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    catchError,
    map,
    Observable,
    of,
    Subject,
} from 'rxjs';
import { AuthHttpClientService, TOKEN_KEY } from './auth-http-client.service';
import { getBookingUrl, getMyAccountUrl } from '../utilities/url.utils';
import { EventService, EventType } from '../utilities/event.service';
import { Router } from '@angular/router';
import { AuthHttpClientNoXService } from './auth-http-client-noX.service';
import { RewardsService } from './rewards.service';

export const LOGGED_IN_KEY = 'loggedIn';

export interface UserData {
    userName?: string;
    email?: string;
    badgeCount?: number;
    countryCode?: 'MU' | 'RE' | 'OT';
    language?: 'en' | 'fr';
    userId?: number;
    customerGroupId?: number;
}

export interface LocalCart {
    userCartId?: string;
    localCartId?: string;
    itemCount: number;
}

export interface UserDataDao {
    countryCode: 'MU' | 'RE' | 'OT';
    customerGroupId: number;
    email: string;
    language: 'en' | 'fr';
    orgId: number;
    roles: ('md-storefront-user' | 'md-storefront-admin')[];
    userId: number;
    userName: string;
}

interface SetLoginProps {
    token?: string;
    withMessage?: boolean;
    notifyServerOfSignOut?: boolean;
    isManualSignOut?: boolean;
}

interface LoggedInProps {
    isLoggedIn: boolean;
    userData: UserData;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private userData: UserData = {};

    private isLoggedInSubject: BehaviorSubject<LoggedInProps> =
        new BehaviorSubject<LoggedInProps>({
            isLoggedIn: false,
            userData: {},
        });
    isLoggedIn$: Observable<LoggedInProps> =
        this.isLoggedInSubject.asObservable();
    isLoggedIn: boolean = false;

    private countrySubject: BehaviorSubject<'MU' | 'RE' | 'OT'> =
        new BehaviorSubject<'MU' | 'RE' | 'OT'>('MU');
    country$: Observable<'MU' | 'RE' | 'OT'> =
        this.countrySubject.asObservable();

    constructor(
        private authHttpClientService: AuthHttpClientService,
        private rewardsService: RewardsService,
        private eventService: EventService,
        private router: Router
    ) {}

    getCustomerOrUserId(): number | null {
        return this.userData.userId || null;
    }

    getUserDataImmediate(): UserData {
        return this.userData;
    }

    onAppLoaded(): void {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            this.setIsLoggedIn(true, { token }).subscribe();
        } else {
            this.setIsLoggedIn(false).subscribe();
        }
    }

    setIsLoggedIn(
        isLoggedIn: boolean,
        props: SetLoginProps = {}
    ): Observable<UserData | null> {
        if (isLoggedIn) {
            localStorage.setItem(TOKEN_KEY, props.token);
            localStorage.setItem(LOGGED_IN_KEY, 'true');
            // TODO: if this call fails then the user is not actually logged in and default to logged out
            return this.setUserDataFromToken();
        } else {
            if (props.notifyServerOfSignOut) {
                this.signOut();
            }
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(LOGGED_IN_KEY);
            this.userData = {};
            this.isLoggedInSubject.next({
                isLoggedIn: false,
                userData: this.userData,
            });
            this.isLoggedIn = false;
            if (props.withMessage) {
                this.eventService.emitEvent(
                    EventType.MODAL_INFO,
                    'You have been logged out'
                );
            }

            if (props.isManualSignOut) {
                this.deleteLocalCart();
                this.router.navigate(['/']);
            }
            return of(null);
        }
    }

    changeLanguage(language: 'en' | 'fr'): void {
        this.authHttpClientService
            .request<{ token: string }, { language: 'en' | 'fr' }>(
                'POST',
                `${getMyAccountUrl()}change-language`,
                { language }
            )
            .subscribe({
                next: (response: { token: string }) => {
                    localStorage.setItem(TOKEN_KEY, response.token);
                },
                error: () => {
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        'Could not change language'
                    );
                },
            });
    }

    getLocalCart(): LocalCart | null {
        const localCart = localStorage.getItem('localCart');
        if (localCart) {
            return JSON.parse(localCart);
        }
        return null;
    }

    setLocalCart(localCart: LocalCart): void {
        localStorage.setItem('localCart', JSON.stringify(localCart));
    }

    deleteLocalCart(): void {
        localStorage.setItem('localCart', JSON.stringify({}));
    }

    private setUserDataFromToken(): Observable<UserData | null> {
        return this.authHttpClientService.decodeToken().pipe(
            map((response: UserDataDao) => {
                this.setUserData(response);
                this.isLoggedInSubject.next({
                    isLoggedIn: true,
                    userData: this.userData,
                });
                this.isLoggedIn = true;
                return this.userData;
            }),
            catchError(() => {
                // this means that the user could not be set so we need to set the user as logged out
                // TODO: we may want to log this case too
                this.setIsLoggedIn(false);
                this.isLoggedInSubject.next({
                    isLoggedIn: false,
                    userData: {},
                });
                this.isLoggedIn = false;
                return of(null);
            })
        );
    }

    private signOut(): void {
        this.authHttpClientService
            .post(`${getMyAccountUrl()}sign-out`, {})
            .subscribe();
    }

    private setUserData(userData: UserDataDao): void {
        this.userData = this.transformUserDataDaoToUserData(userData);
        this.rewardsService.retreiveRewardPoints(
            userData.customerGroupId,
            userData.countryCode
        );

        this.userData.badgeCount = 0;
    }

    private transformUserDataDaoToUserData(userData: UserDataDao): UserData {
        return {
            userName: userData.userName,
            email: userData.email,
            countryCode: userData.countryCode,
            userId: userData.userId,
            language: userData.language.toLocaleLowerCase() as 'en' | 'fr',
            customerGroupId: userData.customerGroupId,
        };
    }
}

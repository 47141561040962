import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    MaribnbStayData,
    DealDto,
    DealOfferOption,
    AccomInventory,
} from 'src/app/model/deal.model';
import {
    BookingConfiguredData,
    MinimalProductBookingData,
    emptyBookingConfiguredData,
} from '../booking-config.model';
import {
    transformInventoryDto,
} from '../hotel-booking/hotel-booking.model';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { transformNgbDateToDayJs } from 'src/app/controls/calendar/calendar.utils';
import {
    OccupancyConfig,
    OccupancySelection,
} from 'src/app/controls/calendar-occupancy/calendar-occupancy.model';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import * as Sentry from '@sentry/angular-ivy';
import { TranslateService } from '@ngx-translate/core';
import { CalendarOccupancyComponent } from 'src/app/controls/calendar-occupancy/calendar-occupancy.component';

@Component({
    selector: 'md-maribnb-booking',
    templateUrl: './maribnb-booking.component.html',
    styleUrls: ['./maribnb-booking.component.scss'],
})
export class MaribnbBookingComponent implements OnInit {
    @Input() productDetails: DealDto;
    @Input() initialBookingData: MinimalProductBookingData;

    pricingByDate: AccomInventory[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    currencySymbol: 'Rs.' | 'EUR';
    fromDate: NgbDate;
    toDate: NgbDate;
    formattedFromDate = '-';
    formattedToDate = '-';
    totalNights = 0;
    occupancyConfig: OccupancyConfig;
    occupancyString: string;
    maribnbServiceOption: DealOfferOption;

    private maribnbStayData: MaribnbStayData;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();

    @ViewChild('occupancyControl') desktopOccupancy: CalendarOccupancyComponent;
    @ViewChild('calendarMariBnb') calendarMariBnb: CalendarComponent;

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private generalErrorService: GeneralErrorService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        try {
            this.maribnbServiceOption =
                this.productDetails.dealOffers[0].dealOptions[0];
            this.maribnbStayData = this.maribnbServiceOption.maribnbStayData;

            const {
                maximumOccupancy,
                numberOfAdults,
                numberOfChildren,
                numberOfInfants,
            } = this.maribnbStayData;
            const totalMaxOccupancy =
                numberOfAdults +
                numberOfChildren +
                numberOfInfants +
                maximumOccupancy;
            this.occupancyConfig = {
                adults: 0,
                children: 0,
                infants: 0,
                maxNrOfAdults: totalMaxOccupancy,
                maxNrOfChildren: numberOfChildren,
                maxNrOfInfants: numberOfInfants,
                maxNrOfOccupants: totalMaxOccupancy,
            };
            this.setAccomInventory();
        } catch (e) {
            this.generalErrorService.showGeneralError(
                'Something is wrong with this deal. Please try again later or contact support.'
            );
            Sentry.captureException(e, {
                extra: {
                    models: {
                        'this.productDetails': this.productDetails,
                        'this.maribnbServiceOption': this.maribnbServiceOption,
                        'thisinitialBookingData': this.initialBookingData
                    },
                    message: 'Error occurred during MaribnbBookingComponent > ngOnInit'
                }
            });
        }
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
    }

    onOccupancyClicked(): void {
        if (!this.fromDate || !this.toDate) {
            this.calendarMariBnb.openCalendar();
        } else {
            this.desktopOccupancy.openOccupancy();
        }
    }

    onDateRangeSelected(selectedDates: CalendarSelectedDates): void {
        const { fromDate, toDate, totalNights } = selectedDates;
        this.fromDate = fromDate;
        this.toDate = toDate;

        if (!fromDate || !toDate) {
            this.bookingConfigured.emit(emptyBookingConfiguredData);
            this.formattedFromDate = '-';
            this.formattedToDate = '-';
            this.occupancyString = '';
            return;
        }

        const fromDateActualDate = transformNgbDateToDayJs(fromDate);
        const toDateActualDate = transformNgbDateToDayJs(toDate);
        this.totalNights = totalNights;
        this.formattedFromDate = fromDateActualDate.format('DD MMM YYYY');
        this.formattedToDate = toDateActualDate.format('DD MMM YYYY');

        this.calendarMariBnb.closeCalendar();
        // TODO: it seems that the close of the calendar and the opening of the occupancy somehow overlap on the
        // offcanvas service and the occupancy can no longer be dismissed.
        setTimeout(() => {
            this.desktopOccupancy.openOccupancy();
        }, 0);
    }

    onOccupancyChanged(occupancy: OccupancySelection): void {
        this.occupancyString = this.composeOccupancyString(occupancy);
        this.occupancyConfig = {
            ...this.occupancyConfig,
            ...occupancy,
        };

        const { totalPrice, totalFullPrice } =
            this.calculatePriceBasedOnOccupancy(occupancy);
        this.bookingConfigured.emit({
            totalPrice,
            totalFullPrice,
            productBookingData: {
                checkinDate: this.fromDate,
                checkoutDate: this.toDate,
                totalNights: this.totalNights,
                mariBnbOccupancy: occupancy,
                id: this.productDetails.dealId,
                selectedDealOptions: [this.maribnbServiceOption],
            },
        });
    }

    private composeOccupancyString(occupancy: OccupancySelection): string {
        const { adults, children, infants } = occupancy;
        const guestsTranslatedString = this.translate.instant(
            adults + children === 1 ? 'Guest' : 'Guests'
        );
        const infantsTranslatedString = this.translate.instant(
            infants === 1 ? 'Infant' : 'Infants'
        );

        const infantsFinalString = infants
            ? `, ${infants} ${infantsTranslatedString}`
            : '';

        return `${adults + children} ${guestsTranslatedString} ${infantsFinalString}`;
    }

    private calculatePriceBasedOnOccupancy(occupancy: OccupancySelection): {
        totalPrice: number;
        totalFullPrice: number;
    } {
        const { adults, children, infants } = occupancy;
        const {
            maximumOccupancy,
            adultPricing,
            numberOfChildren,
            numberOfInfants,
            childPricing,
            infantPricing,
        } = this.maribnbStayData;

        const { totalPrice, totalFullPrice } = this.getPriceForDates(
            this.fromDate,
            this.toDate
        );
        let totalPriceFinal = totalPrice;
        let totalFullPriceFinal = totalFullPrice;

        const totalGuests = adults + children + infants;
        if (totalGuests > maximumOccupancy) {
            const diff = totalGuests - maximumOccupancy;
            let extra_guest = 0;
            if (adults > maximumOccupancy) {
                extra_guest = adults - (maximumOccupancy + children + infants);
                for (let i = 0; i < extra_guest; i++) {
                    const extra_price = adultPricing[i] * this.totalNights;
                    totalPriceFinal = totalPriceFinal + extra_price;
                    totalFullPriceFinal = totalFullPriceFinal + extra_price;
                }
            }
            if (numberOfChildren > 0 && numberOfInfants > 0) {
                let extra_child = adults + children - maximumOccupancy;
                if (extra_child < 0) extra_child = 0;
                let extra_Infants =
                    totalGuests - maximumOccupancy - extra_child;
                if (extra_Infants > numberOfInfants) {
                    const infDiff = extra_Infants - numberOfInfants;
                    extra_child = Math.min(infDiff, children);
                    extra_Infants = extra_Infants - extra_child;
                }
                for (let i = 0; i < extra_child; i++) {
                    const extra_price = childPricing[i] * this.totalNights;
                    totalPriceFinal = totalPriceFinal + extra_price;
                    totalFullPriceFinal = totalFullPriceFinal + extra_price;
                }

                for (let i = 0; i < extra_Infants; i++) {
                    const extra_price = infantPricing[i] * this.totalNights;
                    totalPriceFinal = totalPriceFinal + extra_price;
                    totalFullPriceFinal = totalFullPriceFinal + extra_price;
                }
            } else {
                const extra_child = extra_guest > 0 ? diff - extra_guest : diff;
                if (extra_child > 0) {
                    if (numberOfChildren > 0) {
                        for (let i = 0; i < extra_child; i++) {
                            const extra_price =
                                childPricing[i] * this.totalNights;
                            totalPriceFinal = totalPriceFinal + extra_price;
                            totalFullPriceFinal =
                                totalFullPriceFinal + extra_price;
                        }
                    } else if (numberOfInfants > 0) {
                        for (let i = 0; i < extra_child; i++) {
                            const extra_price =
                                infantPricing[i] * this.totalNights;
                            totalPriceFinal = totalPriceFinal + extra_price;
                            totalFullPriceFinal =
                                totalFullPriceFinal + extra_price;
                        }
                    } else {
                        for (let i = 0; i < extra_child; i++) {
                            const extra_price =
                                adultPricing[i] * this.totalNights;
                            totalPriceFinal = totalPriceFinal + extra_price;
                            totalFullPriceFinal =
                                totalFullPriceFinal + extra_price;
                        }
                    }
                }
            }
        }

        return {
            totalPrice: totalPriceFinal,
            totalFullPrice: totalFullPriceFinal,
        };
    }

    private getPriceForDates(
        fromDate: NgbDate,
        toDate: NgbDate
    ): { totalPrice: number; totalFullPrice: number } {
        const pricingForDates = this.pricingByDate.filter(
            (pricingByDate) =>
                fromDate.equals(pricingByDate.date) ||
                (fromDate.before(pricingByDate.date) &&
                    toDate.after(pricingByDate.date))
        );

        return pricingForDates.reduce(
            ({ totalPrice, totalFullPrice }, pricingByDate) => {
                return {
                    totalPrice: totalPrice + pricingByDate.sellingPrice,
                    totalFullPrice: totalFullPrice + pricingByDate.crossedOutPrice,
                };
            },
            { totalPrice: 0, totalFullPrice: 0 }
        );
    }

    private getAdultPriceForOccupancy(occupancy: OccupancySelection): number {
        const { adults } = occupancy;
        const { numberOfAdults } = this.maribnbStayData;
        if (numberOfAdults !== 0 && adults > numberOfAdults) {
            // if this is not 0 then for any number of adults above this value we charge
            const additionalAdults = adults - numberOfAdults;
            const additionalAdultsPrice =
                this.maribnbStayData.adultPricing[0] || 0;
            return additionalAdults * additionalAdultsPrice;
        }
        return 0;
    }

    private getChildrenPriceForOccupancy(
        occupancy: OccupancySelection
    ): number {
        const { children } = occupancy;
        const { numberOfChildren } = this.maribnbStayData;
        if (numberOfChildren !== 0 && children > numberOfChildren) {
            const additionalChildren = children - numberOfChildren;
            const additionalChildrenPrice =
                this.maribnbStayData.childPricing[0] || 0;
            return additionalChildren * additionalChildrenPrice;
        }
        return 0;
    }

    private getInfantsPriceForOccupancy(occupancy: OccupancySelection): number {
        const { infants } = occupancy;
        const { numberOfInfants } = this.maribnbStayData;
        if (numberOfInfants !== 0 && infants > numberOfInfants) {
            const additionalInfants = infants - numberOfInfants;
            const additionalInfantsPrice =
                this.maribnbStayData.infantPricing[0] || 0;
            return additionalInfants * additionalInfantsPrice;
        }
        return 0;
    }

    private setAccomInventory(): void {
        const { inventory } = this.productDetails;

        this.pricingByDate = transformInventoryDto(inventory);
        this.additionalCalendarDayInfo.clear();
        this.pricingByDate.forEach((pricingByDate) =>
            this.additionalCalendarDayInfo.set(
                pricingByDate.dateAsString,
                {
                    date: pricingByDate.date,
                    tooltipContent: `${this.currencySymbol} ${pricingByDate.sellingPrice}`,
                    minLengthOfStay: pricingByDate.minLengthOfStay,
                }
            )
        );
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import {
    createDateString,
    generateDatesNextMonths,
    transformNgbDateToDayJs,
} from 'src/app/controls/calendar/calendar.utils';
import { OccupancyOption } from '../search-occupancy/search-occupancy.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SearchOccupancyComponent } from '../search-occupancy/search-occupancy.component';
import { NavigationEnd, Params, Router } from '@angular/router';
import { removeQueryParams } from 'src/app/utilities/url.utils';
import {
    LocationOption,
    SearchLocationComponent,
} from '../search-location/search-location.component';
import { RouteParamsService } from 'src/app/services/route-params.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'md-call-to-action',
    templateUrl: './call-to-action.component.html',
    styleUrls: ['./call-to-action.component.scss'],
})
export class SearchDatesComponent implements OnInit, AfterViewInit {
    additionalInfoForCalendar: Map<string, CalendarDayInfo> = new Map<
        string,
        CalendarDayInfo
    >();
    fromDate: NgbDate;
    toDate: NgbDate;
    formattedFromDate = 'Fri, 22 Mar';
    formattedToDate = 'Sat, 23 Mar';
    occupancyString = '2 Adults';
    selectedOccupancy: OccupancyOption;

    singleDate: NgbDate;
    formattedSingleDate = 'Fri, 22 Mar';
    locationString = 'Add Location';
    selectedLocation: LocationOption;

    isScrolled: boolean;
    isOnDayPackagePage: boolean;

    sectionId: number;

    @ViewChild('searchOccupancy') desktopOccupancy: SearchOccupancyComponent;
    @ViewChild('searchLocation') desktopLocation: SearchLocationComponent;
    @ViewChild('calendarSearchDates') calendarMariBnb: CalendarComponent;
    @ViewChild('calendarSingleSearchDates')
    calendarSingleSearchDates: CalendarComponent;

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private routeParamsService: RouteParamsService
    ) {}

    ngOnInit(): void {
        this.additionalInfoForCalendar = generateDatesNextMonths(6);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlNoQueryParams = removeQueryParams(
                    event.urlAfterRedirects
                );
                // TODO: this most likely can be done better through the router
                this.showSearchBasedOnPage(urlNoQueryParams);
            }
        });
        this.routeParamsService.sectionId$.subscribe((sectionId) => {
            this.sectionId = sectionId;
        });
    }

    ngAfterViewInit(): void {
        const element = document.getElementsByClassName('pane')[0];
        element.addEventListener('scroll', () => {
            const scrollTop = element.scrollTop;
            this.isScrolled = scrollTop > 44;
            const routerContainer =
                document.getElementsByClassName('routerContainer')[0];
            if (routerContainer) {
                routerContainer.classList.toggle(
                    'search-scrolled',
                    this.isScrolled
                );
            }
            const filtersContainer = document.getElementsByClassName(
                'filters-container-mobile'
            )[0];
            if (filtersContainer) {
                filtersContainer.classList.toggle(
                    'search-scrolled',
                    this.isScrolled
                );
            }
            const searchDates = this.elementRef.nativeElement;
            searchDates.classList.toggle('search-scrolled', this.isScrolled);
        });
    }

    onOccupancyClicked(): void {
        if (!this.fromDate || !this.toDate) {
            this.calendarMariBnb.openCalendar();
        } else {
            this.desktopOccupancy.openOccupancy();
        }
    }

    onLocationClicked(): void {
        if (!this.singleDate) {
            this.calendarSingleSearchDates.openCalendar();
        } else {
            this.desktopLocation.openLocation();
        }
    }

    onDateRangeSelected(selectedDates: CalendarSelectedDates): void {
        const { fromDate, toDate } = selectedDates;

        if (!fromDate || !toDate) {
            return;
        }

        this.fromDate = fromDate;
        this.toDate = toDate;
        this.formattedFromDate = this.getFormattedDate(fromDate);
        this.formattedToDate = this.getFormattedDate(toDate);

        this.calendarMariBnb.closeCalendar();
        // TODO: it seems that the close of the calendar and the opening of the occupancy somehow overlap on the
        // offcanvas service and the occupancy can no longer be dismissed.
        if (!this.selectedOccupancy) {
            setTimeout(() => {
                this.desktopOccupancy.openOccupancy();
            }, 0);
        }
    }

    onSingleDateSelected(selectedDates: CalendarSelectedDates): void {
        const { singleDate } = selectedDates;

        if (!singleDate) {
            return;
        }

        this.singleDate = singleDate;
        this.formattedSingleDate = this.getFormattedDate(singleDate);

        this.calendarSingleSearchDates.closeCalendar();
        if (!this.selectedLocation) {
            setTimeout(() => {
                this.desktopLocation.openLocation();
            }, 0);
        }
    }

    onLocationChanged(location: LocationOption): void {
        this.selectedLocation = location;
        this.locationString = location?.name;
    }

    onOccupancyChanged(occupancy: OccupancyOption): void {
        this.selectedOccupancy = occupancy;
        this.occupancyString = occupancy.label;
    }

    search() {
        const urlToNavigate = this.sectionId
            ? `/search/${this.sectionId}`
            : '/search';
        this.router.navigate([urlToNavigate], {
            queryParams: this.compileSearchQueryParameters(),
        });
    }

    private compileSearchQueryParameters(): Params {
        if (this.singleDate) {
            const fromDate = createDateString(this.singleDate);
            const toDate = createDateString(this.singleDate);
            const filterTypeId = this.selectedLocation?.filterTypeId;
            const filterId = this.selectedLocation?.filterId;
            const filterQueryParamValue = filterId
                ? `${filterTypeId}-${filterId}`
                : null;

            return {
                fromDate,
                toDate,
                filters: filterQueryParamValue,
            };
        }
        const fromDate = createDateString(this.fromDate);
        const toDate = createDateString(this.toDate);
        const occupancyId = this.selectedOccupancy?.id;

        return {
            fromDate,
            toDate,
            occupancyId,
        };
    }

    private setInitialData(singleDate: boolean): void {
        if (singleDate) {
            this.setInitialDataForSingleDate();
        } else {
            this.setInitialDataForMultiDate();
        }
    }
    private setInitialDataForSingleDate(): void {
        this.resetDates();
        const today = new Date();
        const firstFriday = new Date();
        firstFriday.setDate(today.getDate() + ((5 - today.getDay() + 7) % 7));
        this.singleDate = new NgbDate(
            firstFriday.getFullYear(),
            firstFriday.getMonth() + 1,
            firstFriday.getDate()
        );
        this.formattedSingleDate = this.getFormattedDate(this.singleDate);
        this.selectedLocation = undefined;
    }

    private setInitialDataForMultiDate(): void {
        this.resetDates();

        const [firstFriday, firstSunday] = this.getNextFridayAndSunday();

        this.fromDate = new NgbDate(
            firstFriday.getFullYear(),
            firstFriday.getMonth() + 1,
            firstFriday.getDate()
        );
        this.toDate = new NgbDate(
            firstSunday.getFullYear(),
            firstSunday.getMonth() + 1,
            firstSunday.getDate()
        );
        this.formattedFromDate = this.getFormattedDate(this.fromDate);
        this.formattedToDate = this.getFormattedDate(this.toDate);
        this.selectedOccupancy = {
            id: '44',
            label: '2 Adults',
        };
        this.occupancyString = this.selectedOccupancy.label;
    }

    private getNextFridayAndSunday(): [Date, Date] {
        const today = new Date();
        // move to next week so there's no confusion with the current day
        if (today.getDay() === 5 || today.getDay() === 6) {
            today.setDate(today.getDate() + 3);
        }
        const firstFriday = new Date();
        firstFriday.setDate(today.getDate() + ((5 - today.getDay() + 7) % 7));
        const firstSunday = dayjs(firstFriday).add(2, 'day').toDate();

        return [firstFriday, firstSunday];
    }

    private resetDates(): void {
        this.fromDate = undefined;
        this.toDate = undefined;
        this.formattedFromDate = '';
        this.formattedToDate = '';
        this.occupancyString = 'Add Guests';
        this.selectedOccupancy = undefined;
        this.singleDate = undefined;
        this.formattedSingleDate = '';
        this.locationString = 'Add Location';
        this.selectedLocation = undefined;
    }

    private getFormattedDate(date: NgbDate): string {
        if (!date) {
            return '-';
        }
        const actualDate = transformNgbDateToDayJs(date);
        return actualDate.format('DD MMM YYYY');
    }

    private showSearchBasedOnPage(urlNoQueryParams: string): void {
        const isHomePage = urlNoQueryParams === '/';
        const isCategoryPage = urlNoQueryParams.includes('/plp/');
        const showCTAOnDealPage =
            urlNoQueryParams.includes('/3') ||
            urlNoQueryParams.includes('/8') ||
            urlNoQueryParams.includes('/9');
        const isDayPackagePage = urlNoQueryParams.includes('/8');
        const isSearchPage = urlNoQueryParams.includes('/search');
        const showSearch =
            isSearchPage || isHomePage || (isCategoryPage && showCTAOnDealPage);

        this.elementRef.nativeElement.classList.toggle(
            'search-hidden',
            !showSearch
        );

        if (showSearch && !isSearchPage) {
            this.isOnDayPackagePage = isDayPackagePage;
            this.setInitialData(isDayPackagePage);
        }

        const routerContainer =
            document.getElementsByClassName('routerContainer')[0];
        if (routerContainer) {
            routerContainer.classList.toggle(
                'a-router-container--no-search',
                !showSearch
            );
        }
    }
}

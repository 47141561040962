import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OccupancyIdDto } from './hotel-booking.model';
import { AgeGroup } from 'src/app/model/deal.model';
import { getApiUrlV2 } from 'src/app/utilities/url.utils';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Injectable({
    providedIn: 'root',
})
export class HotelBookingService {
    constructor(
        private httpClient: HttpClient,
        private currencyLanguageService: CurrencyLanguageService
    ) { }

    get locale() {
        return this.currencyLanguageService.getLocaleDataImmediate();
    }

    getOccupancyId(ageGroups: AgeGroup[]): Observable<OccupancyIdDto> {
        let params = new HttpParams()
            .set('orgId', 1)
            .set('customerGroupId', this.locale.customerGroupId);

        ageGroups.forEach(
            (ageGroup) =>
            (params = params.set(
                ageGroup.ageGroupType.toLowerCase(),
                ageGroup.qty ?? 0
            ))
        );

        !params.has('teen') && (params = params.set('teen', 0));
        !params.has('child') && (params = params.set('child', 0));
        !params.has('infant') && (params = params.set('infant', 0));

        return this.httpClient.get<OccupancyIdDto>(
            `${getApiUrlV2()}availability/occupancies/match-occupancy`,
            { params }
        );
    }
}

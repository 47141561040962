import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const DESKTOP_PLP_THUMB_IMG_TYPE = 2;
export const DESKTOP_PDP_MAIN_IMG_TYPE = 4;
export const MOBILE_PLP_THUMB_IMG_TYPE = 6;
export const MOBILE_PDP_MAIN_IMG_TYPE = 8;

export enum SectionId {
    STAY = 3,
    DAY_PACKAGE = 8,
    RODRIGUES = 9,
}

export enum PackageTypeId {
    STAY = 1,
    ROOM_USE = 2,
    CALENDAR = 3,
    NON_CALENDAR = 4,
}

export interface DealsResponseDto {
    deals: DealDto[];
}

export interface DealDto {
    baseCrossedOutPrice: number;
    baseSellingPrice: number;
    bestSellerQtyRank: number;
    categoryId: number;
    conditions: DealConditionsDto[];
    countryCode: string;
    currencyPosition: string;
    currencySymbol: string;
    customerGroupIds: number[];
    dealId: number;
    dealTitle: string;
    images: DealImageDto[];
    information: DealInformationDto;
    language: string;
    outletFacilities: OutletFacilitiesDto;
    outletId: number;
    outletReviews: OutletReviewsDto;
    packageTypeId: number;
    percentDiscount: string;
    reviewsCount: number;
    ribbon: DealRibbonDto;
    sectionIds: number[];
    starRating: number;
    subTitle: string;
    townName: string;
    url: string;
    redeemLimit: number;
    validityDays: number;
    expirationDate: Date;

    // AIR
    accomOffers: AccomOffer[];
    ageGroups?: AgeGroup[];
    dealOffers: DealOffer[];
    inventory?: InventoryDto[];

    // From former ProductDetails model
    addons: ProductAddOnGroup[];
    badge: RewardBadge;
    cmsBlockContent: string;
    eventStartDate?: Date;
    eventEndDate?: Date;
    showCalendar: boolean;
    staticContentV2?: StaticContentV2;
    subtotalDiscounts: ProductDiscount[];
}

export interface DealInformationDto {
    about_description: string;
    about_description_fr: string;
    latitude: number;
    longitude: number;
    meta_description: string;
    meta_description_fr: string;
    meta_keywords: string;
    meta_keywords_fr: string;
    meta_title: string;
    meta_title_fr: string;
    package_details: string;
    package_details_fr: string;
    purchase_includes: string;
    purchase_includes_fr: string;
}

export interface DealConditionsDto {
    condition: string;
    conditionId: number;
    conditionTitle: string;
}

export interface DealImageDto {
    dealId: number;
    imgId: number;
    imgUrl: string;
    imgType: ImageTypeDto;
    imgMeta: ImageMetaDto;
}

export interface ImageTypeDto {
    imgTypeId: number; // constants at the top
    imgTypeName: string;
    imgWidth: number;
    imgHeight: number;
    imgSize: number;
}

export interface ImageMetaDto {
    dealId: number;
    imgId: number;
    imgAltText: string;
    imgBase: number;
    imgOrder: number;
}

export interface OutletFacilitiesDto {
    facilities: FacilityDto[];
    roomCategories: DealRoomCategoryDto[];
}

export interface DealRoomCategoryDto {
    facilities: FacilityDto[];
    room: {
        outletId: number;
        roomId: number;
        roomImage: string;
        roomName: string;
        roomNameFr: string;
    };
}

export interface FacilityDto {
    facilityId: number;
    facilityName: string;
    icon: string;
    importance: number;
}

export interface OutletReviewsDto {
    totalReviewCount: number;
    latestReviews: LatestReviewDto[];
    reviewScoreCard: ReviewScoreCardDto;
}

export interface LatestReviewDto {
    outletId: number;
    guestName: string;
    updatedDate: string;
    reviewText: string;
    reviewsScore: number;
    reviewLabel: string;
}

export interface ReviewScoreCardDto {
    poorCount: number;
    averageCount: number;
    goodCount: number;
    greatCount: number;
    reviewsScore: number;
}

export interface DealRibbonDto {
    ribbonName: string;
    colorCode: string;
}

export interface MaribnbStayData {
    adultPricing: number[];
    childPricing: number[];
    infantPricing: number[];
    maximumOccupancy: number; // maximum occupancy included in the price
    numberOfAdults: number;
    numberOfChildren: number;
    numberOfInfants: number;
}

export interface DealOffer {
    dealOfferId: number;
    title: string;
    dealOptions: DealOfferOption[];
}

export interface DealOfferOption {
    dealOptionId: number;
    title: string;
    subTitle: string;
    sellingPrice: number;
    crossedPrice: number;
    stock?: number;
    maxPax: number;
    maxPaxDynamic?: number;
    minPax: number;
    qty?: number;
    occupancyId: number;
    categoryId: number;
    flightPrice?: number;
    maribnbStayData: MaribnbStayData;
    optionDiscounts: ProductServiceOptionDiscount[];
}

export interface AccomOffer {
    title: string;
    accomOptions: AccomOptionDto[];
    subTitle: string;
}

export interface AccomOptionDto {
    mealPlanId: number;
    mealPlanType: string;
    roomId: number;
    roomName: string;
    sellingPrice: number;
    crossedPrice: number;
    crossedPercent: number;
    maxPax: number;
    maxGuest: number;
    maxAdult: number;
    maxTeen: number;
    maxChild: number;
    maxInfant: number;

    isHighestMaxGuest?: boolean;
    flightPrice?: number;
}

export interface RewardBadge {
    label: string;
    group: string;
    iconUrl: string;
}

export interface StaticContentV2 {
    information: DealInfo;
    location: LocationCoordinates;
    conditions: DealCondition[];
    facilities: DealFacility[];
    roomCategories: DealRoomCategory[];
    reviews: DealReviewData;
}

export interface DealRoomCategory {
    facilities: DealFacility[];
    room: DealRoom;
}

export interface DealReviewData {
    totalReviewCount: number;
    latestReviews: {
        outletId: number;
        guestName: string;
        updatedDate: string;
        reviewText: string;
        reviewsScore: number;
        reviewLabel: string;
    }[];
    reviewScoreCard: DealReviewScoreCard;
}

export interface DealReviewScoreCard {
    poorCount: number;
    averageCount: number;
    goodCount: number;
    greatCount: number;
    reviewsScore: number;
}

export interface DealInfo {
    about: string;
    metaDescription: string;
    metaKeywords: string;
    metaTitle: string;
    packageDetails: string;
    purchaseIncludes: string;
}

export interface DealCondition {
    conditionTitle: string;
    conditionId: number;
    condition: string;
}

export interface DealFacility {
    id: number;
    name: string;
    iconUrl: string;
    importance: number;
}

export interface DealRoom {
    outletId: number;
    roomId: number;
    roomImage: string;
    roomName: string;
    roomNameFr: string;
}

export interface LocationCoordinates {
    latitude: number;
    longitude: number;
}

export interface ProductDiscount {
    minNights: number;
    discountPercent: number;
}

export interface ProductServiceOptionDiscount {
    minNights: number;
    discountPercent: number;
}

export interface ProductAddOnGroup {
    name: string;
    items: ProductAddOnItem[];
}

export interface ProductAddOnItem {
    id: number;
    maxQty: number;
    price: number;
    priceType: string;
    sku: string;
    title: string;
}

export interface AgeGroup {
    outletId: number;
    ageGroupId: number;
    ageGroupType: string;
    ageFrom: number;
    ageTo: number;

    qty: number;
    maxPax: number;
}

export interface AgeGroupResponseDto {
    ageGroups: AgeGroup[];
    total: number;
}

export interface InventoryDto {
    date: string;
    qty: number;
    sellingPrice: number;
    crossedOutPrice: number; // this is what comes back for hotels
    crossedPrice: number; // TODO: this is what comes back for day packages
    dealOptionId?: number;
    minLengthOfStay?: number;
}

export interface InventoryResponseDto {
    inventory: InventoryDto[];
    total: number;
}

export interface CalendarInventory {
    date: NgbDateStruct;
    dateAsString: string;
    options: {
        optionId: number;
        price: number;
        priceBeforeDiscount: number;
        parentId: number;
    }[];
}

export interface AccomInventory {
    date: NgbDateStruct;
    dateAsString: string;
    sellingPrice: number;
    crossedOutPrice: number;
    minLengthOfStay: number;
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, tap, throwError } from 'rxjs';
import { getAuthUrl } from '../utilities/url.utils';
import { encodeForUrl } from '../utilities/encoders';
import { environment } from 'src/environments/environment';

export const TOKEN_KEY = 'auth-token-new';
const basicToken = `Basic ${encodeForUrl(
    `${environment.tokenShard1}:${environment.tokenShard2}`
)}`;

@Injectable({
    providedIn: 'root',
})
export class AuthHttpClientNoXService {
    constructor(private httpClient: HttpClient) {}

    get<T>(url: string) {
        return this.request<T, null>('GET', url);
    }

    post<T, TIn>(url: string, data: TIn) {
        return this.request<T, TIn>('POST', url, data);
    }

    put<T, TIn>(url: string, data: TIn) {
        return this.request<T, TIn>('PUT', url, data);
    }

    delete<T, TIn>(url: string, data?: TIn) {
        return this.request<T, TIn>('DELETE', url, data);
    }

    request<T, TIn>(
        method: string,
        url: string,
        data: TIn = null
    ): Observable<T> {
        return this.retry<T>(method, url, data, 3);
    }

    private retry<T>(
        method: string,
        url: string,
        data: any,
        maxRetries: number = 3
    ): Observable<T> {
        return this.httpClient
            .request<T>(method, url, {
                headers: this.getAuthTokenHeaders(),
                body: data,
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 && maxRetries > 0) {
                        return this.refreshToken().pipe(
                            switchMap(() =>
                                this.retry<T>(method, url, data, maxRetries - 1)
                            )
                        );
                    }
                    throw error;
                })
            );
    }

    private refreshToken(): Observable<any> {
        const refreshToken = localStorage.getItem(TOKEN_KEY);
        if (refreshToken) {
            return this.httpClient
                .post(
                    `${getAuthUrl()}refresh-token/`,
                    { expiredToken: refreshToken },
                    { headers: this.getBasicTokenHeaders() }
                )
                .pipe(
                    tap((response: any) => {
                        localStorage.setItem(TOKEN_KEY, response.token);
                    })
                );
        } else {
            return throwError(() => new Error('Refresh token not found'));
        }
    }

    private getAuthTokenHeaders() {
        const token = localStorage.getItem(TOKEN_KEY);
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        };
    }

    private getBasicTokenHeaders() {
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: basicToken,
        };
    }
}

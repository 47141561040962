import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { LoginService } from './login.service';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import { EventService, EventType } from '../utilities/event.service';
import { setCache } from '../utilities/cache.utils';
import { ModalOffcanvasComponent } from '../controls/modal-offcanvas/modal-offcanvas.component';

@Component({
    selector: 'md-app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss', '../../common.scss'],
})
export class LoginComponent implements OnInit {
    emailLoginForm!: UntypedFormGroup;
    mobileLoginForm!: UntypedFormGroup;
    otpForm!: UntypedFormGroup;

    showEmailForm = true;
    showMobileForm = false;
    isValidEmail = false; // To show/hide password field
    isPasswordFieldVisible = false;
    mobileErrorClass = '';

    showPassword = false;
    fullMobileNumber!: number;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Mauritius];
    customerId = '';
    otpInput: string;

    emailErrorClass = '';
    passwordErrorClass = '';
    formErrorClass = '';

    passwordClass = 'fa-eye';

    @ViewChild('verifyOtpTemplate') verifyOtpModal: ModalOffcanvasComponent;

    @ViewChild('mobileAfterApiCallError')
    mobileAfterApiCallErrorModal: TemplateRef<never>;
    @ViewChild('otpResent') otpResentModal: TemplateRef<never>;

    @ViewChild('email') emailInput: ElementRef;
    @ViewChild('password') passwordInput: ElementRef;
    @Output() showBackButton = new EventEmitter<void>();

    constructor(
        private loginService: LoginService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        this.subscribeForFailedLogin();

        this.emailLoginForm = new UntypedFormGroup({
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.email,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ]),
            password: new UntypedFormControl(
                '',
                Validators.compose([Validators.required])
            ),
        });

        this.mobileLoginForm = new UntypedFormGroup({
            mobile: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
        });

        this.eventService.isPasswordField$.subscribe((data) => {
            this.isPasswordFieldVisible = data;
        });
    }

    validateEmail(shouldFocus = false): boolean {
        if (this.emailLoginForm.controls['email'].invalid) {
            if (shouldFocus) {
                this.emailInput.nativeElement.focus();
            }
            this.emailErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validatePassword(shouldFocus = false): boolean {
        if (this.emailLoginForm.controls['password'].invalid) {
            if (shouldFocus) {
                this.passwordInput.nativeElement.focus();
            }
            this.passwordErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    onSubmitEmailLoginForm() {
        if (this.emailLoginForm.invalid) {
            let shouldFocus = true;
            shouldFocus = this.validateEmail(shouldFocus);
            this.validatePassword(shouldFocus);
            return;
        }
        this.emailErrorClass = '';
        this.passwordErrorClass = '';
        this.formErrorClass = '';
        const email = this.emailLoginForm.value.email;
        const pass = this.emailLoginForm.value.password;
        setCache('welcome_pop_up', false);
        this.loginService.logInV2(email, pass).subscribe(() => {
            this.eventService.emitEvent(EventType.LOGIN_SUCCESS);
        });
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
        this.passwordClass = this.showPassword ? 'fa-eye-slash' : 'fa-eye';
    }

    openModal(content: ModalOffcanvasComponent) {
        content.open();
    }
    closeModal(content: ModalOffcanvasComponent) {
        content.close();
    }

    private openIncorrectUserPassErrorPopup(text: string): void {
        this.eventService.emitEvent(EventType.MODAL_ERROR, text);
    }

    navigateToRegister() {
        this.eventService.emitEvent(EventType.REGISTER);
    }

    navigateToForgottenPassword() {
        this.eventService.emitEvent(EventType.FORGOTTEN_PASSWORD);
    }

    private subscribeForFailedLogin(): void {
        this.eventService.onEvent(EventType.LOGIN_FAILED, () => {
            this.formErrorClass = 'md-error-shake';
        });
    }

    onContinueClick() {
        if (this.emailLoginForm.get('email')?.valid) {
            this.isPasswordFieldVisible = true; // Show password field after valid email
            setTimeout(() => {
                this.passwordInput.nativeElement.focus();
            }, 0);
            this.showBackButton.emit();
        }
    }
}

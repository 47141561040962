import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
    OccupancyIdDto,
    AccomInventoryDto,
    AccomInventoryResponseDto,
} from './hotel-booking.model';
import { AgeGroup } from '../../model/product-detail.model';
import { getApiUrlV2 } from 'src/app/utilities/url.utils';

@Injectable({
    providedIn: 'root',
})
export class HotelBookingService {
    constructor(private httpClient: HttpClient) {}

    getAccomInventory(): Observable<AccomInventoryResponseDto> {
        const params = new HttpParams()
            // TODO > TESTING (to be removed)
            .set('orgId', 1)
            .set('customerGroupId', 1)
            .set('packageTypeId', 1)
            .set('outletId', 1234);

        const mockedData = {
            inventory: [
                {
                    date: '2024-11-07',
                    qty: 10,
                    sellingPrice: 1000.0,
                    crossedOutPrice: 1200.0,
                },
                {
                    date: '2024-11-08',
                    qty: 20,
                    sellingPrice: 5000.0,
                    crossedOutPrice: 6000.0,
                },
                {
                    date: '2024-11-28',
                    qty: 30,
                    sellingPrice: 6000.0,
                    crossedOutPrice: 7000.0,
                },
                {
                    date: '2024-11-29',
                    qty: 40,
                    sellingPrice: 7000.0,
                    crossedOutPrice: 8000.0,
                },
                {
                    date: '2024-12-02',
                    qty: 40,
                    sellingPrice: 7000.0,
                    crossedOutPrice: 8000.0,
                },
                {
                    date: '2024-12-23',
                    qty: 40,
                    sellingPrice: 7000.0,
                    crossedOutPrice: 8000.0,
                },
            ],
            total: 4,
        };

        return this.httpClient
            .get<AccomInventoryResponseDto>(
                `${getApiUrlV2()}availability/deals/cta/pdp/accommodation`,
                { params }
            )
            .pipe(
                map((res) => /*res.inventory?.length ? res : */ mockedData) // TODO > TESTING (to be removed)
            );
    }

    getOccupancyId(ageGroups: AgeGroup[]): Observable<OccupancyIdDto> {
        let params = new HttpParams()
            // TODO > TESTING (to be removed)
            .set('orgId', 1)
            .set('customerGroupId', 1);

        ageGroups.forEach(
            (ageGroup) =>
                (params = params.set(
                    ageGroup.ageGroupType.toLowerCase(),
                    ageGroup.qty ?? 0
                ))
        );

        return this.httpClient.get<OccupancyIdDto>(
            `${getApiUrlV2()}availability/occupancies/match-occupancy`,
            { params }
        );
    }
}

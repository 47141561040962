import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccomInventoryResponseDto } from '../hotel-booking/hotel-booking.model';
import { getCustomerId } from 'src/app/utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class MaribnbBookingService {
    constructor(private httpClient: HttpClient) {}

    getMultiplePrices(
        productid: number,
        catId: number,
        // this is the actual option id (on custom_option_value_array)
        optId: number
    ): Observable<AccomInventoryResponseDto> {
        const customerId = getCustomerId();
        return this.httpClient.get<AccomInventoryResponseDto>('no-url');
    }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrlMofluid: 'https://api1m.marideal.mu/',
    apiUrl: 'https://apimdbdev.marideal.mu/api/',
    tempBookingUrl: 'http://localhost:8080/api/',
    tokenShard1: 'qZPxoWMulT5644331200#_;)|(&ljNL&',
    tokenShard2: '02397125717368609379059750786356',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HelpService {
    // sumbitTicket(data: any): Observable<any> {
    //     return this.httpClient.post(
    //         getApiUrlMofluid() +
    //             `submitZDTicket?service=submitZDTicket&subject=${data.subject}&body=${data.body}&customerid=${data.customerId}&upload=${data.token}&email=${data.email}&type=${data.fileType}&filename=${data.filename}` +
    //             '&customer_group_id=' +
    //             this.common.getCustomerGroupID(),
    //         '',
    //         { headers: this.common.getTokenHeaders() }
    //     );
    // }
    // upload(data: any): Observable<any> {
    //     const http = new HttpClient(this.httpBackend);
    //     return http.post<any>(this.common.api_url + 'uploadTicketFile', data, {
    //         headers: this.common.getTokenHeaders(),
    //     });
    // }
    // headers() {
    //     return new HttpHeaders({
    //         'Content-Type':
    //             'application/json; application/x-www-form-urlencoded; charset=UTF-8',
    //         'Access-Control-Allow-Origin': '*',
    //         Authorization: 'Bearer ' + this.token.getToken(),
    //     });
    // }
}

<div *ngFor="let dealOffer of productDetails.dealOffers">
    <h3 class="pd-spa-title">{{ dealOffer.title }}</h3>
    <label
        *ngFor="let dealOption of dealOffer.dealOptions"
        class="pd-spa-service-option"
    >
        <input
            type="radio"
            name="serviceOption"
            [id]="dealOption.id"
            [(ngModel)]="selectedServiceOption"
            [value]="dealOption"
        />
        <div class="pd-spa-service-option-details">
            <div class="pd-spa-service-option-data">
                <span class="pd-spa-service-option-name">{{
                    dealOption.title
                }}</span>
                <div>
                    <span class="pd-spa-service-option-fullPrice">
                        {{
                            dealOption.crossedPrice
                                | currency
                                    : currencySymbol
                                    : "symbol"
                                    : "1.0-2"
                                    : locale
                        }}
                    </span>
                    <span class="pd-spa-service-option-price">
                        {{
                            dealOption.sellingPrice
                                | currency
                                    : currencySymbol
                                    : "symbol"
                                    : "1.0-2"
                                    : locale
                        }}
                    </span>
                </div>
            </div>
            <div class="pd-spa-service-option-discout">
                -{{
                    ((dealOption.crossedPrice - dealOption.sellingPrice) /
                        dealOption.crossedPrice) *
                        100 | number : "2.0-0"
                }}%
            </div>
        </div>
    </label>
</div>

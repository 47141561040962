<div class="homeSkeleton">
    <div class="skeleton-div-1">
        <div class="dm-home-page-cat-heading skeleton-div-2">
            <div class="dm-home-page-cat-title animated-background skeleton-div-3">
            </div>
            <div class="dm-home-page-cat-see-all animated-background skeleton-div-4">
            </div>
        </div>
        <div class="dm-home-page-cat-product-wrapper skeleton-div-5">
            <div class="dm-home-page-cat-product skeleton-div-6">
                <div class="dm-home-page-product-img animated-background height-63"></div>
                <div class="dm-home-page-product-name animated-background height-10"></div>
                <div class="dm-home-page-product-desc animated-background height-10"></div>
                <div class="dm-home-page-product-price animated-background height-8"></div>
            </div>
            <div class="dm-home-page-cat-product custom-home-screen-div-21">
                <div class="dm-home-page-product-img animated-background height-63"></div>
                <div class="dm-home-page-product-name animated-background height-10"></div>
                <div class="dm-home-page-product-desc animated-background height-8"></div>
                <div class="dm-home-page-product-price animated-background height-8"></div>
            </div>
        </div>
    </div>
</div>

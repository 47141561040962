import { Component, OnInit } from '@angular/core';
import {
    StoreCreditHistoryItem,
    StoreCreditService,
} from './store-credit.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { RewardsAndWalletService } from '../rewards-and-wallet.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-store-credit',
    templateUrl: './store-credit.component.html',
    styleUrls: ['../rewards-and-wallet.component.scss'],
})
export class StoreCreditComponent implements OnInit {
    isStoreCreditData: boolean = false;
    isUserLoggedIn: boolean = false;
    storeCreditHistory: StoreCreditHistoryItem[] = [];
    storeCreditDetails: any;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private scService: StoreCreditService,
        private currencyLanguageService: CurrencyLanguageService,
        private rewardsAndWalletService: RewardsAndWalletService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.isUserLoggedIn = isLoggedIn;
            if (isLoggedIn) {
                this.getStoreCreditData();
            }
        });
    }

    getStoreCreditData() {
        this.isStoreCreditData = true;
        this.scService.getStoreCreditBalanceAndHistory().subscribe({
            next: (response) => {
                this.isStoreCreditData = false;
                this.storeCreditHistory = response.history;
                this.rewardsAndWalletService.updateStoreCreditBalance(
                    response.storeCreditBalance
                );
            },
            error: () => {
                this.isStoreCreditData = false;
            },
        });
    }

    removeAdminCommentPrefix(str: string) {
        return str.slice(25);
    }

    protected readonly ProfileSection = ProfileSection;
}

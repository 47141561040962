import { Injectable } from '@angular/core';
import {
    AddCartItemDto,
    BookingConfiguredData,
    CartDto,
    DealInfoDto,
    DealInfoWithAccomOptionDto,
    DealInfoWithDealOptionDto,
    ProductBookingData,
    ProductBookingDto,
} from './booking-config.model';
import { Observable } from 'rxjs';
import { getBookingUrl } from 'src/app/utilities/url.utils';
import { createDateStringReverse } from 'src/app/controls/calendar/calendar.utils';
import { DealDto } from 'src/app/model/deal.model';
import { UserService } from 'src/app/services/user.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    isUserLoggedIn = false;

    constructor(
        private userService: UserService,
        private authHttpClient: AuthHttpClientNoXService,
        private currencyLanguageService: CurrencyLanguageService
    ) { }

    addToCartV2(
        bookingConfigedData: BookingConfiguredData,
        productDetails: DealDto
    ): Observable<CartDto> {
        const apiUrl = this.userService.isLoggedIn
            ? `${getBookingUrl()}carts/items`
            : `${getBookingUrl()}carts/items/as-guest`;
        const localCart = this.userService.getLocalCart();
        // if there is a user cart use that, else use the local cart
        const cartId = localCart?.userCartId || localCart?.localCartId;
        // TODO: this is mock data. Replace once availability is done
        const {
            productBookingData = {
                id: bookingConfigedData?.productBookingData?.id,
                checkinDate:
                    bookingConfigedData?.productBookingData?.checkinDate,
                checkoutDate:
                    bookingConfigedData?.productBookingData?.checkoutDate,
            },
            totalPrice,
            totalFullPrice,
            rewardPointsEarned,
        } = bookingConfigedData;

        const postBody: AddCartItemDto = {
            cartId,
            outletId: 2,
            dealId: productBookingData?.id,
            dealCategoryId: productDetails.categoryId,
            sectionId: productDetails.categoryId,
            dealTitle: productDetails.dealTitle,
            dealSubtitle: productDetails.subTitle,
            rewardPointsEarned,
            quantity: 1,
            subtotal: totalPrice,
            purchaseCurrencyId: 1,
            grandTotal: totalFullPrice,
            dealInfo: this.createDealInfo(productBookingData, productDetails),
            checkIn: createDateStringReverse(productBookingData.checkinDate),
            checkOut: createDateStringReverse(productBookingData.checkoutDate),
        };

        return this.authHttpClient.post<CartDto, AddCartItemDto>(
            apiUrl,
            postBody
        );
    }

    private createDealInfo(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        if (productData.selectedAccomOptions?.length > 0) {
            return this.createDealInfoWithAccomOption(productData, productDetails);
        }
        if (productData.selectedDealOptions?.length > 0) {
            return this.createDealInfoWithDealOption(productData, productDetails);
        }
        // TODO: Cater for productData.selectedDealOptionQuantities for TTD (multiple dealOptionIds here)
        return JSON.stringify(this.createBaseDealInfo(productDetails));
    }

    private createDealInfoWithDealOption(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        const baseDealInfoDto = this.createBaseDealInfo(
            productDetails
        );
        const dealInfoWithDealOptionDto: DealInfoWithDealOptionDto = {
            ...baseDealInfoDto,
            dealOptionId: productData.selectedDealOptions[0].dealOptionId,
        };
        return JSON.stringify(dealInfoWithDealOptionDto);
    }
    private createDealInfoWithAccomOption(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        const baseDealInfoDto = this.createBaseDealInfo(productDetails);

        const [selectedAccomOption] = productData.selectedAccomOptions;

        const dealInfoWithAccomOptionDto: DealInfoWithAccomOptionDto = {
            ...baseDealInfoDto,
            roomId: selectedAccomOption.roomId,
            roomCategory: selectedAccomOption.roomName,
            mealPlan: selectedAccomOption.mealPlanType,
        };
        return JSON.stringify(dealInfoWithAccomOptionDto);
    }

    private createBaseDealInfo(productDetails: DealDto): DealInfoDto {
        return {
            dealConditions: JSON.stringify(
                productDetails.staticContentV2.conditions
            ),
            packageTypeId: productDetails.packageTypeId,
        };
    }

    private addMariBnbDataToProductDataDto(
        productData: ProductBookingData,
        productDto: ProductBookingDto
    ) {
        if (productData.mariBnbOccupancy) {
            productDto.adults = productData.mariBnbOccupancy.adults;
            productDto.children = productData.mariBnbOccupancy.children;
            productDto.infants = productData.mariBnbOccupancy.infants;
        }
    }

    private createAddonDataDto(productData: ProductBookingData) {
        return productData.addOnConfigurations
            ? productData.addOnConfigurations.map((addonConfig) => {
                return {
                    id: addonConfig.addonItem.id,
                    price_type: addonConfig.addonItem.priceType,
                    sku: addonConfig.addonItem.sku,
                    title: addonConfig.addonItem.title,
                    qty: addonConfig.quantity,
                };
            })
            : '';
    }
}

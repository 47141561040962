import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy'
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import * as _ from 'lodash';

import { CalendarInventory, CalendarInventoryDto, ProductDetails } from 'src/app/product-detail/model/product-detail.model';
import { transformCalendarInventoryResponseDto } from './dated-activity-booking.model';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { getIsSelectedDateAvailable, transformNgbDateToDayJs, transformNgbDateToYmd } from 'src/app/controls/calendar/calendar.utils';
import { BookingConfiguredData } from '../booking-config.model';

@UntilDestroy()
@Component({
    selector: 'md-dated-activity-booking',
    templateUrl: './dated-activity-booking.component.html',
    styleUrls: ['./dated-activity-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatedActivityBookingComponent implements OnInit, AfterViewInit {
    private productDetailsSubject = new BehaviorSubject<ProductDetails>(null);

    private productDetails$ = this.productDetailsSubject
        .asObservable()
        .pipe(
            tap((value) => {
                if (!value) return;
                this.internalProductDetails = value;
                this.fullProductDetails = { ...value };
            })
        );

    @Input() set productDetails(value: ProductDetails) {
        this.productDetailsSubject.next(value);
    }

    currencySymbol: 'Rs.' | 'EUR';
    internalProductDetails: ProductDetails;
    fullProductDetails: ProductDetails;
    pricingByDate: CalendarInventory[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedDate: NgbDate;
    formattedDate = '-';
    tempBookingData: BookingConfiguredData;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarDatedActivity')
    calendarDatedActivity: CalendarComponent;

    constructor(
        private currencyLanguageService: CurrencyLanguageService
    ) { }
    ngOnInit(): void {
        const currency$ = this.currencyLanguageService
            .getCurrency()
            .pipe(
                tap(currency => this.currencySymbol = currency)
            );

        combineLatest([this.productDetails$, currency$])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setCalendarInventory(this.internalProductDetails.inventory);
            });
    }

    ngAfterViewInit() {
        this.calendarDatedActivity.openCalendar();
    }

    onDateRangeSelected(dateRange: CalendarSelectedDates): void {
        this.selectedDate = dateRange.singleDate;

        this.formattedDate = this.selectedDate ?
            transformNgbDateToDayJs(this.selectedDate).format(
                'DD MMM YYYY'
            ) :
            '-';

        this.changeMaxPaxBasedOnInventory();

        if (this.tempBookingData) {
            this.onBookingConfigured(this.tempBookingData);
        }
    }

    onBookingConfigured(data: BookingConfiguredData): void {
        if (!this.selectedDate) {
            this.tempBookingData = data;
            this.calendarDatedActivity?.openCalendar?.();
            return;
        }

        this.setCalendarInventory(this.internalProductDetails.inventory, data)
    }

    private setCalendarInventory(inventory: CalendarInventoryDto[], bookingConfiguredData?: BookingConfiguredData): void {
        this.pricingByDate = transformCalendarInventoryResponseDto(inventory);

        this.additionalCalendarDayInfo.clear();

        this.pricingByDate.forEach((pricingByDate) =>
            this.additionalCalendarDayInfo.set(pricingByDate.dateAsString, {
                date: pricingByDate.date,
                tooltipContent: `${this.currencySymbol} ${pricingByDate.options[0].price}`,
                minLengthOfStay: 1,
            })
        );

        this.calendarDatedActivity?.openCalendar?.();

        this.setSelectedDate(bookingConfiguredData);
    }

    private setSelectedDate(data: BookingConfiguredData) {
        if (!data?.productBookingData)
            return;

        if (!this.selectedDate) {
            this.calendarDatedActivity.singleDate = null;
            this.formattedDate = '-';
            return;
        }

        const isSelectedDateAvailable = getIsSelectedDateAvailable(this.selectedDate, this.additionalCalendarDayInfo, data.productBookingData);

        // if this.selectedDate
        if (isSelectedDateAvailable) {
            data.productBookingData.selectedDate = this.selectedDate;
            this.tempBookingData = undefined;
            this.bookingConfigured.emit(data);
            return;
        }

        // if !isSelectedDateAvailable
        this.selectedDate = null;
        this.calendarDatedActivity.singleDate = null;
        this.formattedDate = '-';
        this.calendarDatedActivity.openCalendar();
    }

    private changeMaxPaxBasedOnInventory() {

        const selectedDateAsString = transformNgbDateToYmd(this.selectedDate);

        let hasAnyDealOptionChanged = false;

        this.internalProductDetails.dealOffers
            .forEach(dealOffer => dealOffer.dealOptions
                .forEach(dealOption => {
                    const { qty } = this.internalProductDetails.inventory.find(item =>
                        item.dealOptionId === dealOption.id &&
                        item.date === selectedDateAsString
                    ) ?? {};

                    if (qty > 0 && qty < dealOption.maxPax) {
                        dealOption.maxPaxDynamic = qty;
                        hasAnyDealOptionChanged = true;
                    }

                    if (dealOption.qty > dealOption.maxPaxDynamic)
                        dealOption.qty = dealOption.maxPaxDynamic;
                }));

        if (hasAnyDealOptionChanged)
            this.internalProductDetails = { ...this.internalProductDetails };
    }
}

import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CheckoutCartItem } from '../checkout.model';

@Component({
    selector: 'md-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {
    @Input() cartItems: CheckoutCartItem[] = [];
    @Input() removeCartItem: (item: CheckoutCartItem) => void;
    @Input() changeQuantity: (item: CheckoutCartItem) => void;
    @Input() giftDiscount: number = 0;
    @Input() storeCreditDiscount: number = 0;
    @Input() rewardPointDiscount: number = 0;
    @Input() rewardPointsEarned: number = 0;

    @Input() cart_total_amount: number;
    @Input() coupon_code_discount: number;
    @Input() discountCodeTitle: string;
    @Input() totalPrice: number;
    @Input() desktopOnly: boolean = false;

    constructor(private translate: TranslateService) {}

    itemPluralMapping: { [k: string]: string } = {
        '=0': this.translate.instant(' No items in Cart'),
        '=1': this.translate.instant(' One item in Cart'),
        other: this.translate.instant(' # Items in Cart'),
    };
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RecentlyViewedService {
    /**
     * Service Method to get recently viewed data
    //  */
    // getRecentlyViewedData(): Observable<RecentlyViewedDto> {
    //     const storedRecentIds: string[] =
    //         JSON.parse(localStorage.getItem(RECENTLY_VIEWED_DEALS)) || [];
    //     storedRecentIds.reverse();
    //     const encodedRecentIds: string = encodeForUrl(
    //         JSON.stringify(storedRecentIds)
    //     );
    //     const customerId = getCustomerId();
    //     // TODO: all of these localstorage methods should be in their own service
    //     const api_url =
    //         getApiUrlMofluid() +
    //         'getNewRecentProducts?service=getNewRecentProducts&product_arr=' +
    //         encodedRecentIds +
    //         '&customerid=' +
    //         customerId +
    //         '&store=' +
    //         this.common.getStoreID() +
    //         '&customer_group_id=' +
    //         this.common.getCustomerGroupID();
    //     return this.httpClient
    //         .get(api_url, { headers: this.common.getTokenHeaders() })
    //         .pipe(
    //             map((res: RecentlyViewedDto) => {
    //                 return res;
    //             })
    //         );
    // }
}

<div class="pd-container" #pdContainer *ngIf="!productDetailsLoading">
    <div mdDesktopOnly>
        <div class="pd-desktop-summary">
            <div class="pd-summary-row">
                <h2
                    class="pd-summary-name"
                    [innerHTML]="productDetails.name | safeHtml"
                ></h2>
                <div class="pd-summary-row" *ngIf="productDetails.rating > 0">
                    <div class="pd-rating-container">
                        <span class="pd-star-rating">★</span>
                        <span class="pd-rating-count">{{
                            productDetails.rating
                        }}</span>
                    </div>
                </div>
            </div>
            <p
                class="pd-summary-description"
                [innerHTML]="productDetails.shortDescription | safeHtml"
            ></p>
        </div>
    </div>
    <div class="pd-images-and-booking">
        <div class="pd-presentation-container">
            <div class="pd-image-carousel">
                <md-product-image-carousel [productDetails]="productDetails" />
                <div mdMobileOnly *ngIf="productDetails.rating > 0">
                    <span class="pd-rating-container">
                        <span class="pd-star-rating">★</span>
                        <span class="pd-rating-count">{{
                            productDetails.rating
                        }}</span>
                    </span>
                </div>
            </div>
            <div mdMobileOnly>
                <div class="pd-mobile-summary pd-margin">
                    <div class="pd-mobile-summary-col">
                        <div style="display: inline-block">
                            <span
                                class="pd-summary-name"
                                [innerHTML]="productDetails.name | safeHtml"
                            ></span>
                            <md-review-score-bar
                                [averageScore]="
                                    productDetails.staticContentV2?.reviews
                                        ?.reviewScoreCard?.reviewsScore
                                "
                                [label]="
                                    '| ' +
                                    productDetails?.staticContentV2?.reviews
                                        ?.totalReviewCount +
                                    ('Reviews' | translate)
                                "
                            ></md-review-score-bar>
                        </div>
                    </div>
                </div>
            </div>
            <div mdDesktopOnly>
                <div
                    *ngIf="productDetails.cmsBlockContent"
                    class="pd-cms-block-content"
                    [innerHTML]="cmsBlockContentEdited | safeHtml"
                ></div>
                <md-product-static-content
                    [staticContent]="productDetails.staticContentV2"
                    [productId]="productDetails.id"
                />
            </div>
        </div>
        <div class="pd-booking-container">
            <md-booking-config [productDetails]="productDetails" />
        </div>
    </div>

    <div>
        <div mdMobileOnly>
            <div
                *ngIf="productDetails.cmsBlockContent"
                class="pd-cms-block-content"
                [innerHTML]="cmsBlockContentEdited | safeHtml"
            ></div>
            <md-product-static-content
                #staticContent
                [staticContent]="productDetails.staticContentV2"
                [cmsBlockContent]="productDetails.cmsBlockContent"
                [productId]="productDetails.id"
            />
        </div>
        <md-more-deals
            [productId]="productDetails.id"
            (dealSelected)="onDealSelected($event)"
        ></md-more-deals>
        <md-customers-also-viewed
            [productId]="productDetails.id"
        ></md-customers-also-viewed>
        <md-recently-viewed
            (dealSelected)="onDealSelected($event)"
        ></md-recently-viewed>
    </div>
</div>
<div class="pd-container" *ngIf="productDetailsLoading">
    <md-product-detail-loading />
</div>

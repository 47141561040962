import { Injectable } from '@angular/core';
import { MdDeviceDetectorService } from './device-detector.service';
import { Params, Router } from '@angular/router';

const AndroidShareHandler = 'AndroidShareHandler';
@Injectable({
    providedIn: 'root',
})
export class DeviceCommsService {
    constructor(
        private deviceDetectorService: MdDeviceDetectorService,
        private router: Router
    ) {}

    /**
     * This will open a given link in a new tab if it's used in a browser or in a new browser if it's used in a mobile app.
     *
     * @param {string} link - The link to be opened
     */
    openLinkInNewTabOrBrowser(link: string) {
        const { isAndroid, isIos } = this.deviceDetectorService;
        if (isIos) {
            (window as any).webkit?.messageHandlers.customLinkClick.postMessage(
                link
            );
        } else if (isAndroid) {
            (window as any).AndroidShareHandler.customLinkClick(link);
        } else {
            window.open(link, '_blank');
        }
    }

    openLinkInNewTabIfBrowser(
        linkWithoutQueryParams: string,
        queryParams?: Params
    ) {
        if (this.deviceDetectorService.isBrowser) {
            if (!queryParams) {
                window.open(linkWithoutQueryParams, '_blank');
                return;
            }

            const queryParamString = Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join('&');
            window.open(
                `${linkWithoutQueryParams}?${queryParamString}`,
                '_blank'
            );
        } else {
            this.router.navigate([linkWithoutQueryParams], { queryParams });
        }
    }

    successfullyLogin(id: string | number): void {
        if (this.deviceDetectorService.isIos) {
            (
                window as any
            ).webkit?.messageHandlers.successfullyLogin.postMessage(id);
        } else if (this.deviceDetectorService.isAndroid) {
            if ('successfullyLogin' in (window as any).AndroidShareHandler) {
                (window as any).AndroidShareHandler.successfullyLogin(id);
            }
        }
    }

    googleLogin(): void {
        const { isAndroid, isIos } = this.deviceDetectorService;
        if (isIos) {
            (
                window as any
            ).webkit.messageHandlers.googleButtonClick.postMessage(
                'Google click'
            );
        }
        if (isAndroid) {
            window[AndroidShareHandler].googleLogin('google');
        }
    }

    facebookLogin(): void {
        const { isAndroid, isIos } = this.deviceDetectorService;
        if (isIos) {
            (window as any).webkit.messageHandlers.fbButtonClick.postMessage(
                'Facebook click'
            );
        }
        if (isAndroid) {
            window[AndroidShareHandler].facebookLogin('facebook');
        }
    }

    appleLogin(): void {
        if (this.deviceDetectorService.isIos) {
            (window as any).webkit.messageHandlers.appleButtonClick.postMessage(
                'Apple click'
            );
        }
    }
}

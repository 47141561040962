<div class="help-component-parent-div">
    <div class="main" *ngIf="isUserLoggedIn">
        <div class="main-div">
            <div class="div-help-1">
                <div class="position-relative">
                    <div class="div-help-2">
                        <form [formGroup]="helpFormGroup" class="help-form">
                            <div class="div-help-4">
                                <div>
                                    <div>
                                        <div class="m-0 p-0 border-0">
                                            <label
                                                class="help-form-label"
                                                data-fieldid="email"
                                            >
                                                <div class="div-help-5">
                                                    <strong>{{
                                                        "Email address"
                                                            | translate
                                                    }}</strong>
                                                </div>
                                            </label>
                                            <input
                                                class="help-form-input"
                                                id="EmailId"
                                                name="email"
                                                required=""
                                                [(ngModel)]="userEmail"
                                                formControlName="email"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div class="div-help-6">
                                            <label
                                                class="help-form-label-question"
                                            >
                                                <div class="div-help-7">
                                                    <strong>{{
                                                        "How can we help you?"
                                                            | translate
                                                    }}</strong>
                                                </div>
                                            </label>
                                            <textarea
                                                class="help-form-text"
                                                rows="5"
                                                name="description"
                                                required=""
                                                value=""
                                                id="messageText"
                                                formControlName="subject"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            class="vhc3ib-0 ijWGSs sc-bZQynM AgCuY div-help-8"
                                        >
                                            <label
                                                class="help-form-label-attachment"
                                                >{{
                                                    "Attachments" | translate
                                                }}</label
                                            >
                                            <button
                                                class="help-form-button ripleEffect"
                                                type="button"
                                            >
                                                <input
                                                    class="upload-btn"
                                                    type="file"
                                                    multiple
                                                    (change)="
                                                        onUploadFile($event)
                                                    "
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div data-fieldid="FINAL_FORM/form-error"></div>
                            </div>
                            <footer class="help-form-footer">
                                <div class="div-help-9">
                                    <button
                                        class="help-form-send-button divLikeButton ripleEffect"
                                        (click)="submitForm()"
                                    >
                                        {{ "Send" | translate }}
                                    </button>
                                </div>
                            </footer>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page-bg-color" *ngIf="!isUserLoggedIn">
        <div class="main-help-page">
            <div>
                <img
                    src="assets/img/ic_question.png"
                    class="main-help-page-img"
                />
            </div>
            <div class="main-help-page-help">
                {{ "Sign In if you Need Help?" | translate }}
            </div>
            <div class="main-help-page-support">
                {{
                    "Did you know? Now you can use MariDeal app to contact support, We will respond to your query here itself"
                        | translate
                }}
            </div>
            <div class="main-help-page-text">
                {{ "Its fast, easy and convenient." | translate }}
            </div>
            <div
                (click)="redirectToLoginPage()"
                class="main-help-page-signin divLikeButton ripleEffect"
            >
                {{ "Sign In" | translate }}
            </div>
        </div>
    </div>
</div>

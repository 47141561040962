import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'md-datepicker-input',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
})
export class DatePickerInput implements OnInit {
    @Input() minDate: NgbDate;
    @Input() maxDate: NgbDate;
    @Input() startDate: NgbDate;

    @Output() dateSelected = new EventEmitter<NgbDateStruct>();

    dateModel: NgbDate;

    ngOnInit(): void {
        if (this.startDate) {
            this.dateModel = this.startDate;
        }
    }

    manuallyChangedDate(evt: Event) {
        const dateShards: string[] = (evt.target as HTMLInputElement).value
            .toString()
            .split('/');
        const unvalidatedDate = new NgbDate(
            Number(dateShards[2]),
            Number(dateShards[1]),
            Number(dateShards[0])
        );

        if (this.isInvalidDate(unvalidatedDate)) {
            (evt.target as HTMLInputElement).value = '';
            this.dateModel = undefined;
        } else {
            this.dateModel = unvalidatedDate;
        }

        this.dateSelected.emit(this.dateModel);
    }

    dateSelect(newDate: NgbDateStruct) {
        this.dateSelected.emit(newDate);
    }

    isInvalidDate(date: NgbDate): boolean {
        const isNotWithinRange =
            date.before(this.minDate) || date.after(this.maxDate);
        const isNotValidDate =
            date.year >= 9999 ||
            date.year <= 0 ||
            date.month <= 0 ||
            date.month > 12 ||
            date.day <= 0 ||
            date.day > 31;
        return isNotWithinRange || isNotValidDate;
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { removeCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-payment-failure',
    templateUrl: './payment-failure.component.html',
    styleUrls: ['./payment-failure.component.scss'],
})
export class PaymentFailureComponent implements OnInit {
    public isUserLoggedIn: boolean = false;

    constructor(private router: Router, private userService: UserService) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn }) => {
            this.isUserLoggedIn = isLoggedIn;
        });
    }

    continueShoping() {
        removeCache('orderdata');
        this.router.navigate(['/']);
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReviewService {
    getReviewData(data: any) {
        return new Observable<[]>((observer) => {
            observer.next([]);
            observer.complete();
        });
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { EditService, ProfileData } from './edit.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../../../utilities/event.service';
import { ProfileSection } from '../../profile-wrapper.component';
import { EditProfileUtilsService } from './edit-profile.utils.service';
import { isNullOrWhiteSpace } from 'src/app/utilities/utils';
import * as dayjs from 'dayjs';
import { ModalOffcanvasComponent } from 'src/app/controls/modal-offcanvas/modal-offcanvas.component';
import { setCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'md-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
    localeTxt: any;
    loginStatus!: number;
    isUserLoggedIn: boolean = false;
    customerId: number;
    verify: boolean;
    mailVerify: boolean;
    billing: any;
    isEditCustomerShowSkeleton: boolean;
    isWeddingElementEnabled: boolean = false;
    userFormGroup = new UntypedFormGroup({
        language: new UntypedFormControl('', []),
        firstname: new UntypedFormControl('', []),
        lastname: new UntypedFormControl('', []),
        email: new UntypedFormControl('', Validators.email),
        telephone: new UntypedFormControl('', []),
        dobmonth: new UntypedFormControl('', []),
        dobdate: new UntypedFormControl('', []),
        dobyear: new UntypedFormControl('', []),
        location: new UntypedFormControl('', []),
        married: new UntypedFormControl('', []),
        children: new UntypedFormControl('', []),
        street: new UntypedFormControl('', []),
        country: new UntypedFormControl('', []),
        gender: new UntypedFormControl('', []),
        wedmonth: new UntypedFormControl('', []),
        weddate: new UntypedFormControl('', []),
        wedyear: new UntypedFormControl('', []),
    });
    emailVerifyOtpForm = new UntypedFormGroup({
        emailVerifyOtp: new UntypedFormControl('', [Validators.maxLength(4)]),
    });
    mobileVerifyOtpForm = new UntypedFormGroup({
        mobileVerifyOtp: new UntypedFormControl('', [Validators.maxLength(4)]),
    });

    customerVerificationInfoResponse: any;
    preferred_holiday_values = [];
    location: any = [];
    isDobEditable: boolean = true;
    customerInfo: any;
    selectedLang: string = '';
    verifyMobileOtpPopup: boolean = false;
    saveDisabled = false;

    @ViewChild('otpModal') otpModal: ModalOffcanvasComponent;
    @ViewChild('otpPhoneModal') otpPhoneModal: ModalOffcanvasComponent;

    sectionBeingEdited: '' | 'name' | 'email' | 'phone' | 'address' | 'dob' | 'gender' =
        '';

    constructor(
        private editService: EditService,
        private eventService: EventService,
        private translate: TranslateService,
        public editProfileUtilsService: EditProfileUtilsService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.isLoggedIn$.subscribe(({ isLoggedIn, userData }) => {
            this.isEditCustomerShowSkeleton = true;
            if (isLoggedIn) {
                this.editService.getProfileData().subscribe((data) => {
                    this.isEditCustomerShowSkeleton = false;
                    this.userFormGroup.controls['firstname'].setValue(data.firstName);
                    this.userFormGroup.controls['lastname'].setValue(data.lastName);

                    this.userFormGroup.controls['email'].setValue(data.email);
                    this.userFormGroup.controls['telephone'].setValue(data.phone);
                    this.selectedLang = data.language;
                    if (data.address) {
                        this.userFormGroup.controls['location'].setValue(
                            data.address.town
                        );
                        this.userFormGroup.controls['street'].setValue(
                            data.address.street
                        );
                    }

                    // TODO: This needs to go in memory
                    setCache('billing_address', JSON.stringify(data.address));

                    if (data.dateOfBirth) {
                        const dateArray = data.dateOfBirth.split('-');
                        this.userFormGroup.controls['dobyear'].setValue(dateArray[2]);
                        this.userFormGroup.controls['dobdate'].setValue(dateArray[1]);
                        this.userFormGroup.controls['dobmonth'].setValue(dateArray[0]);
                    }
                    this.isDobEditable = data.dateOfBirth ? false : true;
                    if (data.gender) {
                        this.userFormGroup.controls['gender'].setValue(data.gender);
                    }

                    this.userFormGroup.controls['country'].setValue(
                        this.editProfileUtilsService.countryCodeToCountryMap[
                            userData.countryCode
                        ]
                    );
                });
            }
        });
        this.editProfileUtilsService.populateData();
    }

    private showMessageModal(message: string) {
        this.eventService.emitEvent(
            EventType.MODAL_INFO,
            this.translate.instant(message)
        );
    }

    updateProfile() {
        const isEmailValid = this.validateEmail();
        const isNameValid = this.validateName();
        const isDobValid = this.validateDob();
        const isPhoneValid = this.validatePhoneNumber();

        if (!isEmailValid || !isNameValid || !isDobValid || !isPhoneValid) {
            return;
        }

        this.saveDisabled = true;
        const data = this.userFormGroup.getRawValue();

        const newData: ProfileData = {
            firstName: data.firstname,
            lastName: data.lastname,
            email: data.email,
            phone: data.telephone,
            dateOfBirth: this.dobForServer,
            gender: data.gender,
            address: {
                street: data.street,
                town: data.location,
            },
            language: this.selectedLang,
            userRole: {
                role: 'md-storefront-user',
            },
        };

        this.editService.editProfile(newData).subscribe({
            next: () => {
                this.saveDisabled = false;
                this.sectionBeingEdited = '';
                this.userService.updateNameLocally(this.name);
            },
            error: (error) => {
                this.saveDisabled = false;
                this.eventService.emitEvent(EventType.MODAL_ERROR, error?.error?.message);
            },
        });
    }

    private validatePhoneNumber(): boolean {
        if (isNullOrWhiteSpace(this.userFormGroup?.controls['telephone']?.value)) {
            this.showMessageModal('Please enter the phone number');
            return false;
        }
        return true;
    }

    private validateDob(): boolean {
        if (
            isNullOrWhiteSpace(this.userFormGroup?.controls['dobmonth']?.value) ||
            isNullOrWhiteSpace(this.userFormGroup?.controls['dobdate']?.value) ||
            isNullOrWhiteSpace(this.userFormGroup?.controls['dobyear']?.value)
        ) {
            this.showMessageModal('Please enter the date of birth');
            return false;
        }
        if (
            isNaN(this.userFormGroup?.controls['dobdate']?.value) ||
            isNaN(this.userFormGroup?.controls['dobyear']?.value)
        ) {
            this.showMessageModal('Date must be a number');
            return false;
        }

        return true;
    }

    private validateName(): boolean {
        if (isNullOrWhiteSpace(this.userFormGroup?.controls['firstname']?.value)) {
            this.showMessageModal('Please enter the first name');
            return false;
        }
        if (isNullOrWhiteSpace(this.userFormGroup?.controls['lastname']?.value)) {
            this.showMessageModal('Please enter the last name');
            return false;
        }

        return true;
    }

    private validateEmail(): boolean {
        if (
            isNullOrWhiteSpace(this.userFormGroup?.controls['email']?.value) ||
            this.userFormGroup?.controls['email']?.invalid
        ) {
            this.showMessageModal('Please enter a valid email address');
            return false;
        }
        return true;
    }

    get name() {
        return `${this.userFormGroup.controls['firstname'].value} ${this.userFormGroup.controls['lastname'].value}`;
    }
    get email() {
        return this.userFormGroup.controls['email'].value;
    }
    get telephone() {
        return this.userFormGroup.controls['telephone'].value;
    }

    get dob() {
        const dobMonth = this.userFormGroup.controls['dobmonth'].value;
        const dobDate = this.userFormGroup.controls['dobdate'].value;
        const dobYear = this.userFormGroup.controls['dobyear'].value;
        const dob = dayjs(`${dobMonth}/${dobDate}/${dobYear}`).format('DD MMM YYYY');
        return dob;
    }

    get dobForServer() {
        const dobMonth = this.userFormGroup.controls['dobmonth'].value;
        const dobDate = this.userFormGroup.controls['dobdate'].value;
        const dobYear = this.userFormGroup.controls['dobyear'].value;
        const dob = dayjs(`${dobMonth}/${dobDate}/${dobYear}`).format('YYYY-MM-DD');
        return dob;
    }

    get gender() {
        return this.userFormGroup.controls['gender'].value;
    }
    get address() {
        return `${this.userFormGroup.controls['street'].value}, ${this.userFormGroup.controls['location'].value}, ${this.userFormGroup.controls['country'].value}`;
    }

    protected readonly ProfileSection = ProfileSection;
}

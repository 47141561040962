import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { getCache, removeCache } from 'src/app/utilities/cache.utils';
import { UserService } from 'src/app/services/user.service';
import { combineLatest } from 'rxjs';
import { PaymentSuccessData } from '../../checkout/checkout.model';

@Component({
    selector: 'md-payment-success',
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
    orderdata: PaymentSuccessData;
    paymentStatus: any;
    paymentSuccessSkeleton: boolean = false;
    isUserLoggedIn: boolean = false;
    payment_method: any;
    payment_data: any;
    order_details: any;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((symbol) => {
            this.currencySymbol = symbol;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });

        combineLatest([
            this.route.queryParams,
            this.userService.isLoggedIn$,
        ]).subscribe(([params, { isLoggedIn }]) => {
            this.isUserLoggedIn = isLoggedIn;
            this.orderdata = {
                orderId: params['orderId'],
                orderNumber: params['orderNumber'],
                orderStatus: params['orderStatus'],
                amount: params['amount'],
                name: params['name'],
                email: params['email'],
            };
        });
    }

    continueShoping() {
        removeCache('orderdata');
        this.router.navigate(['/']);
    }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EgiftCardService } from './egift-card.service';
import {
    EGiftAddToCartDto,
    EGiftCardDetailsResponse,
    EGiftCardFormData,
    EGiftCardTemplate,
    transform,
} from './egift-card.model';
import { SwiperOptions } from 'swiper';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as dayjs from 'dayjs';
import { screenSizes } from 'src/app/utilities/theme';

import { GeneralErrorService } from '../components/general-error/general-error.service';
import { TranslateService } from '@ngx-translate/core';

import { CartDto } from '../product-detail/booking-config/booking-config.model';
import { UserService } from '../services/user.service';

const cardTemplatesVisibleByDefault = 5;
const swiperConfig: SwiperOptions = {
    autoHeight: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    spaceBetween: 16,
    breakpoints: {
        [screenSizes.desktop]: {
            slidesPerView: 4,
        },
        [screenSizes.mobile]: {
            slidesPerView: 3,
        },
        0: {
            slidesPerView: 2,
        },
    },
};

@Component({
    selector: 'md-egift-card',
    templateUrl: './egift-card.component.html',
    styleUrls: ['./egift-card.component.scss'],
})
export class EgiftCardComponent implements OnInit {
    visibleCardTemplates: EGiftCardTemplate[] = [];
    showMoreTemplates = false;
    swiperConfig = swiperConfig;
    selectedTemplateIndex: number;
    selectedGiftCardImage: { name: string; path: string };
    giftCardDetails: EGiftCardDetailsResponse;
    loadingContent = true;
    isCustomGiftCardImage = false;
    formData: EGiftCardFormData;

    @ViewChild('modalDetailsForm') modalDetailsForm;

    constructor(
        private service: EgiftCardService,
        private modalService: NgbModal,
        private router: Router,
        private spinner: NgxSpinnerService,
        private generalErrorService: GeneralErrorService,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.service.getEGiftDetails().subscribe({
            next: (res) => {
                this.giftCardDetails = transform(res);
                this.visibleCardTemplates =
                    this.giftCardDetails.gitftcardTemplates.slice(
                        0,
                        cardTemplatesVisibleByDefault
                    );
                this.loadingContent = false;
            },
            error: () => {
                this.generalErrorService.showGeneralError(
                    this.translate.instant(
                        'Could not load gift card details. Please refresh the page and try again.'
                    )
                );
            },
        });
    }

    toggleShowMore() {
        this.showMoreTemplates = !this.showMoreTemplates;
        const { gitftcardTemplates } = this.giftCardDetails;
        if (this.showMoreTemplates) {
            this.visibleCardTemplates = gitftcardTemplates;
        } else {
            this.visibleCardTemplates = gitftcardTemplates.slice(
                0,
                cardTemplatesVisibleByDefault
            );
        }
    }

    uploadGiftCardImage(event: Event) {
        //     const file = (event.target as HTMLInputElement).files[0];
        //     if (!file) {
        //         this.isCustomGiftCardImage = false;
        //         this.selectedGiftCardImage = undefined;
        //     }
        //     const { maxImageUploadSizeKb } = this.giftCardDetails;
        //     if (file.size > maxImageUploadSizeKb * 1000) {
        //         this.generalErrorService.showGeneralError(
        //             `${this.translate.instant(
        //                 'File upload not allowed, allowed maximum size is:'
        //             )} ${maxImageUploadSizeKb}kb`,
        //             { showMailto: false }
        //         );
        //         return false;
        //     }
        //     this.spinner.show();
        //     const fd = new FormData();
        //     fd.append('templateimage', file);
        //     fd.append('sec_key', '===bW9mbHVpZC1tYXJpZGVhbA==');
        //     this.service.uploadGiftCardImage(fd).subscribe({
        //         next: (res: UploadGcImageDto | ServerError) => {
        //             this.spinner.hide();
        //             const serverError = res as ServerError;
        //             if (serverError?.status === 'error') {
        //                 this.generalErrorService.showGeneralError(
        //                     serverError.message,
        //                     { showMailto: false }
        //                 );
        //             } else {
        //                 const result = res as UploadGcImageDto;
        //                 this.selectedGiftCardImage = {
        //                     name: result.updated_filename,
        //                     path: result.url,
        //                 };
        //                 this.isCustomGiftCardImage = true;
        //                 this.openModal(this.modalDetailsForm);
        //             }
        //         },
        //         error: () => {
        //             this.spinner.hide();
        //             this.generalErrorService.showGeneralError(
        //                 this.translate.instant(
        //                     'Could not upload custom image. Please refresh the page and try again.'
        //                 )
        //             );
        //         },
        //     });
    }

    expandTemplate(content: TemplateRef<unknown>, index: number): void {
        this.selectedTemplateIndex = index;
        this.modalService.open(content, { fullscreen: true });
    }

    selectImage(
        content: TemplateRef<unknown>,
        template: EGiftCardTemplate,
        templateIndex: number,
        index: number
    ): void {
        this.selectedGiftCardImage = template.images[index];
        this.selectedTemplateIndex = templateIndex;
        // if in desktop mode this should be a small instead of fullscreen
        this.openModal(content);
    }

    formDataChanged(formData: EGiftCardFormData): void {
        this.formData = formData;
    }

    // TODO: this needs to be moved around
    transformDate(date: NgbDateStruct): Date {
        return new Date(date.year, date.month - 1, date.day);
    }

    buyGiftCard(): void {
        this.modalService.dismissAll();
        this.spinner.show();

        const dayToSent = this.transformDate(this.formData.deliveryDate);
        this.service.getRewardsPoints(this.formData.value).subscribe({
            next: (rewardPoints) => {
                this.doAddToCart(dayToSent, rewardPoints);
            },
            error: () => {
                // if rewards points call fails just don't add them
                this.doAddToCart(dayToSent, 0);
            },
        });
    }

    private doAddToCart(dayToSent: Date, rewardPoints: number): void {
        const productData: EGiftAddToCartDto = {
            price_amount: this.formData.value, // price from the form
            amount: this.formData.value, // price from form. Do we need both?
            sectionId: 2, // section id for eGift Card
            giftcard_template_id:
                this.giftCardDetails.gitftcardTemplates[
                    this.selectedTemplateIndex
                ]?.id, // EGiftCardTemplate id
            giftcard_template_image: this.selectedGiftCardImage.name, // EGiftCardTemplate image name
            giftcard_use_custom_image: false, // this is in fact a number sent to the server (0 or 1)
            send_friend: true,
            reward_points_eanred: rewardPoints,
            customer_name: this.formData.deliveryFromName, // sender name from form
            recipient_name: this.formData.recipientName, // recipient name from form
            recipient_email: this.formData.deliveryToEmail, // recipient email from form
            message: this.formData.deliveryMessage, // message from form
            notify_success: true,
            day_to_send: dayjs(dayToSent).format('YYYY-MM-DD'), // formData.deliveryDate is yyyy-mm-dd
            expiry_date: dayjs(dayToSent).add(1, 'year').format('YYYY-MM-DD'), // expiry date is 1 year from send date
            timezone_to_send: 'Indian/Mauritius',
            qty: this.formData.quantity, // qty from form
        };

        this.service.addToCartV2(productData).subscribe({
            next: (response: CartDto) => {
                this.spinner.hide();
                this.userService.setLocalCart({
                    userCartId: response.id,
                    itemCount: response.itemsCount,
                });
            },
            error: () => {
                this.spinner.hide();
                this.generalErrorService.showGeneralError(
                    this.translate.instant(
                        'Could not add item to cart. Please refresh the page and try again.'
                    )
                );
            },
        });
    }

    checkoutInit() {
        this.router.navigate(['checkout']);
    }

    openModal(content: TemplateRef<unknown>): void {
        const screenWidth = window.innerWidth;
        if (screenWidth > screenSizes.mobile) {
            this.modalService.open(content, { size: 'md', scrollable: true });
        } else {
            this.modalService.open(content, {
                fullscreen: true,
                scrollable: true,
            });
        }
    }
}

<div *ngIf="!isShowDummyGiftCardBalance" has-header="true" scroll="false">
    <ul class="ma-gc-list">
        <li mdDesktopOnly class="ma-gc-list-item header">
            <div>{{ "Date" | translate }}</div>
            <div>{{ "Gift Card Code" | translate }}</div>
            <div>{{ "Description" | translate }}</div>
            <div>{{ "Amount" | translate }}</div>
            <div>{{ "Balance" | translate }}</div>
            <div>{{ "Order #" | translate }}</div>
        </li>
        <li
            class="ma-gc-list-item"
            *ngFor="let item of giftCardTransactionHistory"
        >
            <div>
                <p mdMobileOnly>{{ "Date" | translate }}:</p>
                <p>{{ getGiftCodeDate(item.createdDate) }}</p>
            </div>
            <div>
                <p mdMobileOnly>{{ "Gift Card Code" | translate }}:</p>
                <p>{{ item.giftCode }}</p>
            </div>
            <div>
                <p mdMobileOnly>Description:</p>
                <p>
                    {{ item.action }}
                </p>
            </div>
            <div>
                <p mdMobileOnly>{{ "Amount" | translate }}:</p>
                <p>
                    {{ getMinus(item) }}
                    {{
                        item.amount
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-0"
                                : locale
                    }}
                </p>
            </div>
            <div>
                <p mdMobileOnly>{{ "Balance" | translate }}:</p>
                <p>
                    {{
                        item.current_balance
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-0"
                                : locale
                    }}
                </p>
            </div>
            <div>
                <p mdMobileOnly>{{ "Order" | translate }}</p>
                <p class="ma-gc-order-nr">{{ item.orderItemId }}</p>
            </div>
        </li>
    </ul>
</div>
<md-rewards-and-wallet-loading *ngIf="isShowDummyGiftCardBalance" />

import { Component, OnInit } from '@angular/core';
import {
    FacebookLoginProvider,
    SocialAuthService,
} from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { SocialService } from '../login-register/shared-service/social.service';
import { DeviceCommsService } from '../services/device-comms.service';
import { MdDeviceDetectorService } from '../services/device-detector.service';
import { SocialLogin } from './model/social-login.model';
import { EventService, EventType } from '../utilities/event.service';
import { isObject } from 'lodash';

@Component({
    selector: 'md-app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
    public cdn = '';
    isMobileApp = false;

    constructor(
        private socialAuthService: SocialAuthService,
        public router: Router,
        private socialService: SocialService,
        private deviceDetectorService: MdDeviceDetectorService,
        private deviceCommsService: DeviceCommsService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        this.isMobileApp = !this.deviceDetectorService.isBrowser;

        this.socialAuthService.authState.subscribe((userData: SocialLogin) => {
            if (userData) {
                this.socialService.socialLoginApi(userData).subscribe(() => {
                    this.eventService.emitEvent(EventType.LOGIN_SUCCESS);
                });
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).hadleSocialLogin = this.hadleSocialLogin.bind(this);
    }

    loginWithFacebook(): void {
        if (!this.deviceDetectorService.isBrowser) {
            this.deviceCommsService.facebookLogin();
        } else {
            this.socialAuthService
                .signIn(FacebookLoginProvider.PROVIDER_ID)
                .then((userData: SocialLogin) => {
                    this.hadleSocialLogin(userData);
                });
        }
    }

    private parseUserData(input) {
        if (isObject(input)) {
            return input; // Already an object
        }

        try {
            return JSON.parse(input);
        } catch (error) {
            console.error('Invalid JSON string:', input);
            return null;
        }
    }

    // this userData may come from the mobile app or from the browser. If it's from the app it'll be serialized
    hadleSocialLogin(userData) {
        const parsedUserData = this.parseUserData(userData);
        const first_name = parsedUserData.name.split(' ')[0];
        const last_name = parsedUserData.name.substring(first_name.length).trim();
        const properUserData = {
            ...parsedUserData,
            firstName: first_name,
            lastName: last_name,
            is_app_login: this.isMobileApp,
        };

        this.socialService.socialLoginApi(properUserData).subscribe(() => {
            this.eventService.emitEvent(EventType.LOGIN_SUCCESS);
        });
    }

    loginWithGoogle(): void {
        this.deviceCommsService.googleLogin();
    }

    checkIsiOSWrapperApp() {
        return this.deviceDetectorService.isIos;
    }

    loginWithApple() {
        this.deviceCommsService.appleLogin();
    }
}

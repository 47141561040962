import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    catchError,
    map,
    Observable,
    of,
    switchMap,
    throwError,
    timer,
} from 'rxjs';
import { getBookingUrl } from 'src/app/utilities/url.utils';
import {
    CheckoutCartItem,
    CheckoutData,
    CheckoutGuest,
    OrderStatusDto,
    transformCartDtoToCheckoutData,
    transformVerifiedCartDtoToCheckoutData,
    VerifiedCartDto,
} from './checkout.model';
import { CartDto } from 'src/app/product-detail/booking-config/booking-config.model';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import { UserService } from 'src/app/services/user.service';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { CheckoutErrorsService } from './checkout-errors.service';
import * as _ from 'lodash';

interface ApplyDiscountPostBody {
    cartId: string;
    discountCode: string;
}

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {
    constructor(
        private authHttpClient: AuthHttpClientNoXService,
        private generalErrorService: GeneralErrorService,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private checkoutErrorService: CheckoutErrorsService
    ) {}

    updateCart(item: CheckoutCartItem): Observable<CheckoutData> {
        const url = getBookingUrl() + 'carts/items/' + item.id;
        return this.authHttpClient
            .put<CartDto, { quantity: number }>(url, { quantity: item.qty })
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    private handleError(
        error: HttpErrorResponse,
        checkoutData?: CheckoutData
    ): Observable<null> {
        let message = error?.error?.errorCode;
        if (this.checkoutErrorService.isKnownErrorCode(message)) {
            message =
                this.checkoutErrorService.getTranslatedErrorMessageFromErrorCode(
                    error.error,
                    checkoutData
                );
        } else {
            message = error?.error;
        }

        this.generalErrorService.showGeneralError(message, {
            showMailto: false,
        });
        return of(null);
    }

    removeItemFromCart(item: CheckoutCartItem): Observable<CheckoutData> {
        const url = getBookingUrl() + 'carts/items/' + item.id;
        return this.authHttpClient.delete<CartDto, unknown>(url).pipe(
            map((response) => transformCartDtoToCheckoutData(response)),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    getCartItems(): Observable<CheckoutData> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        if (!cartId) {
            return of(null);
        }

        const url = getBookingUrl() + 'carts/' + cartId;

        return this.authHttpClient.get<VerifiedCartDto>(url).pipe(
            map((response) => {
                this.checkoutErrorService.constructNotAvailableDealNamesStringAndShowError(
                    response
                );
                return transformVerifiedCartDtoToCheckoutData(response);
            }),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    applyDiscountCode(code: string): Observable<CheckoutData> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'discounts/apply';

        const postBody: ApplyDiscountPostBody = {
            cartId,
            discountCode: code,
        };

        return this.authHttpClient
            .post<CartDto, ApplyDiscountPostBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeDiscountCode(code: string): Observable<CheckoutData> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'discounts/unapply/code';

        const postBody: ApplyDiscountPostBody = {
            cartId,
            discountCode: code,
        };

        return this.authHttpClient
            .post<CartDto, ApplyDiscountPostBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    placeOrder(
        paymentId: number,
        guests: CheckoutGuest[],
        checkoutData?: CheckoutData
    ): Observable<OrderStatusDto> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'orders';

        const postBody = {
            cartId,
            paymentMethod: paymentId?.toString(),
            guestData: guests.map((guest) => {
                return {
                    dealId: guest.dealId,
                    name: guest.name,
                    email: guest.email,
                    specialRequest: '',
                };
            }),
        };

        return this.authHttpClient
            .post<OrderStatusDto, typeof postBody>(url, postBody)
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error, checkoutData)
                )
            );
    }

    getMipsIframeUrl(orderId: string, amount: number): Observable<string> {
        // It's ok to use immediate here because this is triggered by the user so it's just whatever's set at the time
        const locale = this.currencyLanguageService.getLocaleDataImmediate();
        const { threeLetterCurrency, language } = locale;
        const url = getBookingUrl() + 'payment/zone';
        const customerid = this.userService.getCustomerOrUserId();
        const queryParams = `?orderId=${orderId}&amount=${amount}&currency=${threeLetterCurrency}&language=${language.toUpperCase()}&paymentMode=test&requestMode=simple&customerId=${customerid}`;
        return this.authHttpClient
            .get<{ paymentZone: string }>(`${url}${queryParams}`)
            .pipe(
                map((response) => response.paymentZone),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    applyGiftCode(code: string): Observable<CheckoutData> {
        const url = getBookingUrl() + 'gift-cards/apply';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            giftCardCode: code,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeGiftCode(code: string): Observable<CheckoutData> {
        const url = getBookingUrl() + 'gift-cards/unapply';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            giftCardCode: code,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    getStoreCreditAmount(): Observable<number> {
        const url = getBookingUrl() + 'store-credit/customer';
        return this.authHttpClient
            .get<{ storeCreditBalance: number }[]>(url)
            .pipe(
                map((response) =>
                    _.reduce(
                        response,
                        (acc, item) => acc + item.storeCreditBalance,
                        0
                    )
                ),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    applyStoreCredit(amount: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'store-credit';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeStoreCredit(amount: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'store-credit';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount,
        };

        return this.authHttpClient
            .delete<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    getRewardPointsBalance(): Observable<number> {
        const url = getBookingUrl() + 'reward-points';
        return this.authHttpClient
            .get<{ rewardPointsBalance: number }>(url)
            .pipe(
                map((response) => response.rewardPointsBalance),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    applyRewardPoints(rewardPointsToApply: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'reward-points';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount: rewardPointsToApply,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeRewardPoints(number: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'reward-points';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount: number,
        };

        return this.authHttpClient
            .delete<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    retryCheckMipsPaymentStatus(
        orderNumber: number
    ): Observable<OrderStatusDto> {
        const retryIntervals = [
            3000, 3000, 3000, 5000, 5000, 5000, 5000, 5000, 10000, 10000, 10000,
            10000, 10000,
        ];
        let retryCount = 0;

        const checkStatus = (): Observable<OrderStatusDto> => {
            return this.checkMipsPaymentStatus(orderNumber).pipe(
                catchError(() => {
                    return throwError('Failed to check payment status');
                }),
                switchMap((response) => {
                    if (retryCount < retryIntervals.length) {
                        if (response.status === 'Completed') {
                            return of(response);
                        } else {
                            const delay = retryIntervals[retryCount];
                            retryCount++;
                            return timer(delay).pipe(
                                switchMap(() => checkStatus())
                            );
                        }
                    } else {
                        return throwError('Failed to check payment status');
                    }
                })
            );
        };

        return checkStatus();
    }

    checkAvailability(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.authHttpClient
                .get<VerifiedCartDto>(
                    getBookingUrl() +
                        'carts/' +
                        this.userService.getLocalCart().userCartId
                )
                .subscribe((response) => {
                    if (response.notAvailableCartItems.length > 0) {
                        this.checkoutErrorService.constructNotAvailableDealNamesStringAndShowError(
                            response
                        );
                        observer.next(false);
                    } else {
                        observer.next(true);
                    }
                    observer.complete();
                });
        });
    }

    private checkMipsPaymentStatus(
        orderNumber: number
    ): Observable<OrderStatusDto> {
        return this.authHttpClient
            .get<OrderStatusDto>(
                `${getBookingUrl()}orders/number/${orderNumber}`
            )
            .pipe(
                map((response) => response),
                catchError((error: HttpErrorResponse) =>
                    throwError(error.message)
                )
            );
    }
}
